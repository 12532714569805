import React from "react";
import { Button, Row, Col, Typography, Card } from "antd";
import { useTranslation } from "react-i18next";
import "./Information.css";
import DocumentCardImage from "../../assets/DocumentCardImage.svg";
import { useBreakpoints } from "../CommonLogics/ResponsiveBreakPoints";
import _ from "lodash";
import { analytics } from "../../components/Mixpanel/Mixpanel";

const DocumentsCard = (props) => {
  const { Documents, setIsDocumentVisible, setSelectedDocument } = props;
  const handleViewDocumentMethod = (DocumentsObject) => {
    setIsDocumentVisible(true);
    setSelectedDocument(DocumentsObject);
  };
  const { IsSM, IsXS } = useBreakpoints();
  const { t } = useTranslation();
  return (
    <Card className="DocumentCard">
      <Row gutter={[0, { xs: 10 }]}>
        <Col xl={9} lg={9} xxl={9} md={9} sm={24} xs={24}>
          {IsXS || IsSM ? (
            <Row justify="center">
              <img src={DocumentCardImage} alt=""></img>
            </Row>
          ) : (
            <img src={DocumentCardImage} alt=""></img>
          )}
        </Col>
        <Col span={1} />

        <Col xl={13} xxl={13} lg={13} md={13} sm={24} xs={24}>
          <Row gutter={[0, 17]}>
            <Col span={24}>
              <Typography className="DocumentsTitle">
                {Documents?.file_name}
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className="DocumentsTitle1">
                {t("content.237")} :{" "}
                <Typography className="DocumentsTitle2" component="span">
                  {Documents?.upload_date}
                </Typography>
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className="DocumentsTitle1">
                {t("content.236")} :{" "}
                <Typography className="DocumentsTitle2" component="span">
                  {Documents?.user.first_name + " " + Documents?.user.last_name}
                </Typography>
              </Typography>
            </Col>
            <Col span={24}>
              <Button
                type="link"
                className="DocumentsButton"
                onClick={() => {
                  analytics?.track("Info_Doc_view");
                  handleViewDocumentMethod(Documents);
                }}
                disabled={_.isEmpty(Documents?.document_file)}
              >
                {t("content.238")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default DocumentsCard;
