import React from 'react';
import { Row, Col, Typography, Card, Avatar, Divider } from 'antd';
import './Trusteecard.css';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { CountryViseFormat, newImageUrl } from '../CommonLogics/CommonMethods';
function Trusteecard(props) {
  const { trustee, trusteeArray, i } = props;
  const { t } = useTranslation();
  const secondColor = '#' + Math.floor(Math.random() * 1677).toString(14);
  const noData1 = _.flatten(
    trustee?.user_addresses?.filter((address, i) => {
      return address.length && trustee.user_addresses[i].length === 0 ? 0 : 1;
    })
  );
  return (
    <Row>
      <Col span={24} key={trustee.id}>
        <Card className='card'>
          <Row gutter={[{ xl: 46, lg: 46, md: 46 }, 0]}>
            <Col xl={8} lg={7} md={7} sm={24} xxl={8}>
              <Avatar
                size={145}
                style={{ marginTop: '-50px', background: secondColor }}
                shape='square'
                src={newImageUrl(trusteeArray?.user_image?.image)}
                key={i}
              >
                <Typography style={{ fontSize: '70px', color: 'white' }}>
                  {trustee?.first_name[0]}
                </Typography>
              </Avatar>
            </Col>
            <Col xl={16} xxl={15} xs={24} md={17} lg={17} sm={24}>
              <Row gutter={[0, 13]}>
                <Col span={24} />
                <Col span={24}>
                  <Row
                    justify='end'
                    gutter={[{ xl: 20, lg: 20, md: 20, sm: 20, xs: 15 }, 0]}
                  ></Row>
                </Col>
              </Row>

              <Row gutter={[0, 10]}>
                <Col className='antZeroPadding'>
                  <Typography
                    className='Titletext1'
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {trustee?.first_name} {trustee?.last_name}
                    <Typography className='PhoneAndEmail' component='span'>
                      ({trusteeArray?.designation?.designation_title})
                    </Typography>
                  </Typography>
                </Col>
                <Col span={24}></Col>
                <Col span={24}>
                  <Typography
                    className='PhoneAndEmail'
                    style={{
                      paddingBottom: '10px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {_.isEmpty(trustee?.phone_with_country_code)
                      ? t('content.222')
                      : CountryViseFormat(
                          trustee?.phone_with_country_code
                        )}{' '}
                    |{' '}
                    {trustee?.email === 'undefined'
                      ? t('content.222')
                      : !trustee?.email
                      ? t('content.222')
                      : trustee?.email}
                  </Typography>
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider className='paddingBorder'></Divider>
          <Row gutter={[0, 10]}>
            <Col span={24} />
            <Col className='Address'>
              {noData1.length > 0 ? (
                noData1.map((Address, i) => {
                  return (
                    <Row key={i}>
                      <Col span={24}>
                        {Address?.address_line_1 +
                          ' ' +
                          Address.home_number +
                          ' ' +
                          Address.address_line_2 +
                          ' ' +
                          Address.city +
                          ',' +
                          Address.zip}
                      </Col>
                    </Row>
                  );
                })
              ) : (
                <Col>{t('content.222')}</Col>
              )}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}
export default React.memo(Trusteecard);
