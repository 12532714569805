import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  Row,
  Col,
  Input,
  Typography,
  Button,
  Upload,
  message,
  DatePicker,
  Checkbox,
  Select,
} from "antd";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { updateUserAction } from "../Actions/authenticationAction";
import "./../Screens/AddFamilymember/Addfamily.css";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateUserSchema } from "../Utils/Schema";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { getFamilies } from "../Actions/familyAction";
import { getEducationsAction } from "../Actions/educationAction";
import { getIndustriesAction } from "../Actions/industryAction";
import { getUsers } from "../Actions/authenticationAction";
import PhoneInput from "react-phone-input-international";
import "react-phone-input-international/lib/style.css";
import LoadingIndicator from "../Screens/LoadingIndicator/LoadingIndicator";
import { newImageUrl } from "../Screens/CommonLogics/CommonMethods";
import { analytics } from "../components/Mixpanel/Mixpanel";

const UserUpdate = (props) => {
  const {
    isEditProfileModalVisible,
    setIsEditProfileModalVisible,
    currentUser,
  } = props;
  const { isUpdateUserLoading, allEducations, allIndustries } = useSelector(
    (state) => {
      const { isUpdateUserLoading } = state.authentication;
      const { allEducations } = state.educations;
      const { allIndustries } = state.industry;
      return {
        isUpdateUserLoading,
        allEducations,
        allIndustries,
      };
    }
  );
  const dispatch = useDispatch();
  const date = isEditProfileModalVisible
    ? moment(
        `${currentUser?.birth_month}-${currentUser?.birth_date}-${currentUser?.birth_year}`,
        "MM-DD-YYYY"
      )
    : "";
  if (currentUser) {
    currentUser.date_of_birth = date ? date : moment();
    currentUser.education_id = currentUser?.education?.name;
    currentUser.industry_id = currentUser.industry?.name;
    currentUser.email =
      currentUser.email === "undefined" ? "" : currentUser.email;
  }
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues:
      currentUser?.birth_date === null
        ? {
            first_name: currentUser?.first_name,
            last_name: currentUser?.last_name,
            email: currentUser?.email,
            phone: currentUser?.phone,
            education_id: currentUser?.education_id,
            industry_id: currentUser?.industry_id,
          }
        : currentUser,
    resolver: yupResolver(updateUserSchema),
  });

  const getEducationsActionAndgetIndusriesActions = useCallback(() => {
    dispatch(getEducationsAction());
    dispatch(getIndustriesAction());
  }, [dispatch]);

  useEffect(() => {
    getEducationsActionAndgetIndusriesActions();
  }, [getEducationsActionAndgetIndusriesActions]);

  const onlyPublishedEducations = allEducations.filter(
    (Educations) => Educations.published === true
  );
  const onlyPublishedIndustries = allIndustries.filter(
    (Industries) => Industries.published === true
  );
  const { t } = useTranslation();
  const [newFiles, setNewFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [DateCustom, setDateCustom] = useState("");
  const [isDefaultImageVisible, setIsDefaultImageVisible] = useState(true);
  const monthOfBirth = DateCustom.toString().slice(0, 2);
  const dateOfBirth = DateCustom.toString().slice(3, 5);
  const yearOfBirth = DateCustom.toString().slice(6, 10);
  const [countryCode, setCountryCode] = useState("");
  const [number, setNumber] = useState("");
  const IsEmpty = _.isEmpty(newFiles);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [gender, setGender] = useState("");
  const [gendervalue, setGendervalue] = useState("");
  const closeEditProfileMethod = () => {
    setIsEditProfileModalVisible(false);
    setNewFiles([]);
    setValue("first_name", currentUser.first_name);
    setValue("last_name", currentUser.last_name);
    setValue("email", currentUser.email);
    setValue("phone", currentUser.phone);
    clearErrors();
    setShowUploadImage(false);
  };
  const onDateSelection = (date) => {
    const Date = moment(date);
    setDateCustom(moment(Date._d).calendar());
  };
  const genderValue = (e) => {
    const { checked, value } = e.target;
    setGender({
      value,
      [value]: checked,
    });
    setGendervalue(value);
  };
  const containsNumber = (str) => {
    return /\d/.test(str);
  };
  const updateUserMethod = (data) => {
    analytics?.track("EditProfile_Edit");
    const Text = number;
    const TextText = Text.replace(`+${countryCode}`, "");
    const capitalizeFirstName = _.capitalize(data.first_name);
    const capitalizeLastName = _.capitalize(data.last_name);
    data.user_image = IsEmpty ? null : newFiles;
    data.first_name = capitalizeFirstName;
    data.last_name = capitalizeLastName;
    data.id = currentUser?.id;
    data.birth_date = dateOfBirth ? dateOfBirth : currentUser.birth_date;
    data.birth_month = monthOfBirth ? monthOfBirth : currentUser.birth_month;
    data.birth_year = yearOfBirth ? yearOfBirth : currentUser.birth_year;
    data.gender = gendervalue ? gendervalue : currentUser.gender;
    data.education_id = containsNumber(data.education_id)
      ? data.education_id
      : currentUser?.education?.id;
    data.industry_id = containsNumber(data.industry_id)
      ? data.industry_id
      : currentUser?.industry?.id;

    data.phone = TextText ? TextText : currentUser?.phone;
    data.country_code = countryCode
      ? `+${countryCode}`
      : currentUser?.country_code;
    data.is_poused = currentUser?.is_poused;
    dispatch(updateUserAction(data, IsEmpty, updateUserSuccessCallback));
  };
  const updateUserSuccessCallback = (successResponse) => {
    setIsEditProfileModalVisible(false);
    setTimeout(() => {
      message.success(t("content.141"));
    }, 1000);
    setShowUploadImage(false);
    dispatch(getFamilies());
    dispatch(getUsers());
  };
  const propsForUpload = {
    action: updateUserMethod,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles(file);
      return false;
    },
    multiple: false,
  };
  if (isEditProfileModalVisible && gender?.female) {
    currentUser.gender = "female";
  } else if (isEditProfileModalVisible && gender?.male) {
    currentUser.gender = "male";
  }
  const phoneNumberMethod = (value, country, e, formattedValue) => {
    setCountryCode(country.dialCode);
    setNumber(formattedValue);
  };
  return (
    <Modal
      title={t("content.140")}
      open={isEditProfileModalVisible}
      onCancel={closeEditProfileMethod}
      footer={null}
      centered={true}
    >
      <LoadingIndicator loading={isUpdateUserLoading}>
        <form onSubmit={handleSubmit(updateUserMethod)}>
          <Row justify="center">
            <Col span={currentUser?.image ? 24 : ""}>
              {currentUser?.image ? (
                <>
                  <Row justify="center">
                    <Col>
                      {isDefaultImageVisible && (
                        <Upload
                          listType="picture-card"
                          openFileDialogOnClick={false}
                        >
                          <img
                            src={newImageUrl(currentUser?.user_image?.image)}
                            width={102}
                            alt=""
                            referrerPolicy="origin"
                          />
                        </Upload>
                      )}
                    </Col>
                    <Col>
                      <Upload
                        listType="picture-card"
                        onChange={() => setIsDefaultImageVisible(false)}
                        {...propsForUpload}
                        maxCount={1}
                      >
                        <PlusOutlined size={44} />
                        {isDefaultImageVisible ? "Edit Image" : "Upload Image"}
                      </Upload>
                    </Col>
                  </Row>
                </>
              ) : (
                <Upload
                  {...propsForUpload}
                  listType="picture-card"
                  maxCount={1}
                  showUploadList={showUploadImage}
                  onChange={() => setShowUploadImage(true)}
                >
                  <PlusOutlined size={44} />
                  Upload Image
                </Upload>
              )}
            </Col>
          </Row>

          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Row gutter={[10, 10]}>
                <Col xl={12} xs={24} lg={12} md={12} sm={12}>
                  <Typography
                    className="label"
                    style={{ paddingBottom: "4px" }}
                  >
                    {t("placeholder.4")}*
                  </Typography>
                  <Controller
                    as={<Input className="inputLabel" />}
                    name="first_name"
                    control={control}
                  />
                  {errors.first_name && (
                    <p style={{ color: "red" }}>{t("content.54")}</p>
                  )}
                </Col>
                <Col xl={12} xs={24} lg={12} md={12} sm={12}>
                  <Typography
                    className="label"
                    style={{ paddingBottom: "4px" }}
                  >
                    {t("placeholder.5")}*
                  </Typography>
                  <Controller
                    as={<Input className="inputLabel" />}
                    name="last_name"
                    control={control}
                  />
                  {errors.last_name && (
                    <p style={{ color: "red" }}>{t("content.121")}</p>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[10, 10]}>
                <Col xl={12} xs={24} lg={12} md={12} sm={12}>
                  <Typography
                    className="label"
                    style={{ paddingBottom: "4px" }}
                  >
                    {t("placeholder.6")}
                  </Typography>
                  <Controller
                    as={<Input className="inputLabel" />}
                    name="email"
                    control={control}
                  />
                  {errors.email && (
                    <p style={{ color: "red" }}>{t("content.122")}</p>
                  )}
                </Col>
                <Col xl={12} xs={24} lg={12} md={12} sm={12}>
                  <Typography
                    className="label"
                    style={{ paddingBottom: "4px" }}
                  >
                    {t("content.17")}
                  </Typography>
                  <PhoneInput
                    country={"in"}
                    onChange={(value, country, e, formattedValue) =>
                      phoneNumberMethod(value, country, e, formattedValue)
                    }
                    placeholder="Enter Your Contact Number"
                    onClick={() => setErrorMessage("")}
                    inputStyle={{ width: "100%" }}
                    value={currentUser?.phone_with_country_code}
                    autoFormat={false}
                    onlyCountries={["in", "ca", "us", "au", "nz"]}
                  />
                  {errorMessage ===
                    "The number you entered must be of 10 digits only" && (
                    <Typography style={{ color: "red" }}>
                      {errorMessage}
                    </Typography>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[10, 0]}>
                <Col
                  xl={12}
                  md={12}
                  sm={12}
                  xs={24}
                  style={{ paddingTop: "10px" }}
                >
                  <Typography style={{ paddingBottom: 8 }} className="label">
                    {t("content.79")}
                  </Typography>
                  <Controller
                    name="education_id"
                    as={
                      <Select
                        placeholder={t("content.159")}
                        style={{ width: "100%", borderRadius: "4px" }}
                        className="inputLabel"
                        showSearch={true}
                        options={
                          onlyPublishedEducations &&
                          onlyPublishedEducations.length > 0 &&
                          onlyPublishedEducations.map((val, i) => {
                            return {
                              label: val.name,
                              value: val.id,
                            };
                          })
                        }
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      ></Select>
                    }
                    control={control}
                  />
                  {errors.education_id && (
                    <p style={{ color: "red" }}>{t("content.119")}</p>
                  )}
                </Col>
                <Col
                  xl={12}
                  md={12}
                  sm={12}
                  xs={24}
                  style={{ paddingTop: "10px" }}
                >
                  <Typography style={{ paddingBottom: 8 }} className="label">
                    {t("content.80")}
                  </Typography>
                  <Controller
                    as={
                      <Select
                        placeholder={t("content.160")}
                        style={{ width: "100%", borderRadius: "4px" }}
                        className="inputLabel"
                        showSearch={true}
                        options={
                          onlyPublishedIndustries &&
                          onlyPublishedIndustries.length > 0 &&
                          onlyPublishedIndustries.map((val, i) => {
                            return {
                              label: val.name,
                              value: val.id,
                            };
                          })
                        }
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      ></Select>
                    }
                    name="industry_id"
                    control={control}
                  />
                  {errors.industry_id && (
                    <p style={{ color: "red" }}>{t("content.120")}</p>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[10, 0]}>
                <Col
                  xl={12}
                  xs={24}
                  sm={12}
                  md={12}
                  style={{ paddingTop: "10px" }}
                >
                  <Typography style={{ paddingBottom: 9 }} className="label">
                    {t("content.124")}*
                  </Typography>
                  <Controller
                    as={
                      <DatePicker
                        placeholder={t("placeholder.10")}
                        onSelect={(date, dateString) =>
                          onDateSelection(date, dateString)
                        }
                        className="inputLabel"
                      />
                    }
                    name="date_of_birth"
                    control={control}
                    style={{ width: "100%" }}
                  />
                  {errors.date_of_birth && (
                    <p style={{ color: "red" }}>{t("content.118")}</p>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row style={{ paddingTop: "10px" }} gutter={[0, 10]}>
                <Col span={24}>
                  <Typography className="label">{t("content.81")}*</Typography>
                </Col>
                <Col>
                  <Row>
                    <Col className="label">
                      <Checkbox
                        onChange={(e) => genderValue(e)}
                        value={"female"}
                        checked={currentUser?.gender === "female"}
                      >
                        {t("content.83")}
                      </Checkbox>
                    </Col>
                    <Col className="label">
                      <Checkbox
                        onChange={(e) => genderValue(e)}
                        value={"male"}
                        checked={currentUser?.gender === "male"}
                      >
                        {t("content.82")}
                      </Checkbox>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="end" gutter={[10, 0]}>
                <Col>
                  <Button
                    onClick={closeEditProfileMethod}
                    className="allButtonsCancel"
                  >
                    {t("btn.19")}
                  </Button>
                </Col>
                <Col>
                  <Button htmlType="submit" className="allButtons">
                    {t("btn.27")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </LoadingIndicator>
    </Modal>
  );
};
export default UserUpdate;
