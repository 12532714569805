import React from 'react';
import {
  Modal,
  Col,
  Row,
  Input,
  Typography,
  Button,
  message,
  Drawer,
} from 'antd';
import { Controller, useForm } from 'react-hook-form';
import {
  createFamilyAddressAction,
  updateFamilyAddressAction,
} from '../../Actions/familyAddressAction';
import { useDispatch } from 'react-redux';
import { getFamilies } from '../../Actions/familyAction';
import './Addfamily.css';
import { useTranslation } from 'react-i18next';
import './../../FindThePeoplePage/FindOtherPeople.css';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
const AddAddressModal = (props) => {
  const {
    isAddAddressModalVisible,
    setIsAddAddressModalVisible,
    editFamilyAddressId,
    isEditAddressModalVisible,
    setIsEditAddressModalVisible,
    familyAddressObject,
    familyIdFromObject,
    myObj,
    isCreateFamilyAddressLoading,
    isUpdateFamilyAddressLoading,
  } = props;
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    clearErrors,
    setValue,
  } = useForm({
    defaultValues: familyAddressObject,
  });
  const dispatch = useDispatch();
  const handleAddAddressMethod = (data) => {
    data.family_id = familyIdFromObject;
    dispatch(createFamilyAddressAction(data, successAddFamilyAddressMethod));
  };
  const successAddFamilyAddressMethod = () => {
    dispatch(getFamilies());
    setIsAddAddressModalVisible(false);
    setTimeout(() => {
      message.success(t('content.128'));
    }, 1000);
  };
  const hideAddAddressModal = () => {
    setIsAddAddressModalVisible(false);
    clearErrors();
  };
  const hideEditAddressModal = () => {
    setIsEditAddressModalVisible(false);
    clearErrors();
    reset();
  };
  const handleEditFamilyAddressMethod = (data) => {
    data.id = editFamilyAddressId;
    dispatch(updateFamilyAddressAction(data, successUpdateFamilyAddress));
  };
  const successUpdateFamilyAddress = () => {
    dispatch(getFamilies());
    setIsEditAddressModalVisible(false);
    setTimeout(() => {
      message.success(t('content.129'));
    }, 1000);
  };
  setValue('home_number', myObj?.home_number);
  setValue('landmark', myObj?.landmark);
  setValue('city', myObj?.city);
  setValue('state', myObj?.state);
  setValue('zip', myObj?.zip);
  setValue('country', myObj?.country);
  setValue('address_line_1', myObj?.address_line_1);
  setValue('address_line_2', myObj?.address_line_2);

  return (
    <Drawer
      open={
        editFamilyAddressId
          ? isEditAddressModalVisible
          : isAddAddressModalVisible
      }
      footer={null}
      onClose={editFamilyAddressId ? hideEditAddressModal : hideAddAddressModal}
      title={
        editFamilyAddressId ? (
          <Typography>{t('content.93')}</Typography>
        ) : (
          <Typography>{t('content.84')}</Typography>
        )
      }
      maskClassName='DrawerClass'
      className='DrawerBorderClass'
    >
      <LoadingIndicator
        loading={
          editFamilyAddressId
            ? isUpdateFamilyAddressLoading
            : isCreateFamilyAddressLoading
        }
      >
        <form
          onSubmit={handleSubmit(
            editFamilyAddressId
              ? handleEditFamilyAddressMethod
              : handleAddAddressMethod
          )}
        >
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Typography className='label' style={{ paddingBottom: '8px' }}>
                {t('content.252')}
              </Typography>
              <Controller
                as={<Input required className='inputLabel' />}
                name='home_number'
                control={control}
              />
              {errors.home_number && (
                <p style={{ color: 'red' }}>{errors.home_number.message}</p>
              )}
            </Col>
            <Col span={24}>
              <Typography className='label' style={{ paddingBottom: '8px' }}>
                {t('content.86')}
              </Typography>
              <Controller
                as={<Input required className='inputLabel' />}
                name='landmark'
                control={control}
              />
              {errors.landmark && (
                <p style={{ color: 'red' }}>{errors.landmark.message}</p>
              )}
            </Col>

            <Col span={24}>
              <Typography className='label' style={{ paddingBottom: '8px' }}>
                {t('content.251')}
              </Typography>
              <Controller
                as={<Input required className='inputLabel' />}
                name='address_line_1'
                control={control}
              />
            </Col>
            <Col span={24}>
              <Typography className='label' style={{ paddingBottom: '8px' }}>
                {t('content.250')}
              </Typography>
              <Controller
                as={<Input required className='inputLabel' />}
                name='address_line_2'
                control={control}
              />
            </Col>
            <Col span={24}>
              <Row gutter={[10, 0]}>
                <Col span={12}>
                  <Typography
                    className='label'
                    style={{ paddingBottom: '8px' }}
                  >
                    {t('content.89')} / {t('content.249')}
                  </Typography>
                  <Controller
                    as={<Input required className='inputLabel' />}
                    name='city'
                    control={control}
                  />
                  {errors.city && (
                    <p style={{ color: 'red' }}>{errors.city.message}</p>
                  )}
                </Col>
                <Col span={12}>
                  <Typography
                    className='label'
                    style={{ paddingBottom: '8px' }}
                  >
                    {t('content.90')}
                  </Typography>
                  <Controller
                    as={<Input required className='inputLabel' />}
                    name='state'
                    control={control}
                  />
                  {errors.state && (
                    <p style={{ color: 'red' }}>{errors.state.message}</p>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[10, 0]}>
                <Col span={12}>
                  <Typography
                    className='label'
                    style={{ paddingBottom: '8px' }}
                  >
                    {t('content.91')}
                  </Typography>
                  <Controller
                    as={<Input required className='inputLabel' />}
                    name='zip'
                    control={control}
                  />
                  {errors.zip && (
                    <p style={{ color: 'red' }}>{errors.zip.message}</p>
                  )}
                </Col>
                <Col span={12}>
                  <Typography
                    className='label'
                    style={{ paddingBottom: '8px' }}
                  >
                    {t('content.92')}
                  </Typography>
                  <Controller
                    as={<Input className='inputLabel' />}
                    control={control}
                    name='country'
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify='end' gutter={[10, 0]}>
                {editFamilyAddressId ? (
                  <Col>
                    <Button
                      onClick={() => hideEditAddressModal()}
                      className='allButtonsCancel'
                    >
                      {t('btn.19')}
                    </Button>
                  </Col>
                ) : (
                  <Col>
                    <Button
                      onClick={() => hideAddAddressModal()}
                      className='allButtonsCancel'
                    >
                      {t('btn.19')}
                    </Button>
                  </Col>
                )}
                {editFamilyAddressId ? (
                  <Col>
                    <Button htmlType='submit' className='allButtons'>
                      {t('btn.17')}
                    </Button>
                  </Col>
                ) : (
                  <Col>
                    <Button htmlType='submit' className='allButtons'>
                      {t('btn.18')}
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </form>
      </LoadingIndicator>
    </Drawer>
  );
};

export default AddAddressModal;
