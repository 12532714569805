import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Table, message, Pagination } from 'antd';
import '../../FindThePeoplePage/FindOtherPeople.css';
import './Admin.css';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useBreakpoints } from '../CommonLogics/ResponsiveBreakPoints';
import { useTranslation } from 'react-i18next';
import {
  getCommunityCardsAction,
  updateCommunityCardsAction,
} from '../../Actions/communityCardsAction';

const CommunityCardsTable = (props) => {
  const {
    allCommunityCards,
    setCommunityCardPage,
    communityCardPage,
    setIsEditCommunityCardVisible,
    setEditCommunityCardId,
  } = props;
  const { IsSM, IsXS } = useBreakpoints();
  const { t } = useTranslation();
  const columns = [
    {
      title: t('content.77'),
      dataIndex: 'name',
      width: IsXS || IsSM ? '63%' : '20%',
    },
    {
      title: t('content.284'),
      dataIndex: 'card_type',
      width: IsXS || IsSM ? '63%' : '20%',
    },
    {
      title: t('content.217'),
      dataIndex: 'description',
      width: IsXS || IsSM ? '63%' : '45%',
    },
    {
      dataIndex: 'id',
      render: (id, AllCommunityCardsData) => {
        return (
          <ActionComponent
            id={id}
            setIsEditCommunityCardVisible={setIsEditCommunityCardVisible}
            setEditCommunityCardId={setEditCommunityCardId}
            AllCommunityCardsData={AllCommunityCardsData}
            allCommunityCards={allCommunityCards}
            communityCardPage={communityCardPage}
          />
        );
      },
      width: '15em',
    },
  ];

  return (
    <Col>
      <Table
        dataSource={allCommunityCards?.community_cards}
        columns={columns}
        size='middle'
        className='table'
        rowKey={(record) => {
          return record.id;
        }}
        pagination={false}
      />
      <Row justify='end' style={{ margin: '10px 0px' }}>
        <Pagination
          total={allCommunityCards?.total_length}
          onChange={(e) => setCommunityCardPage(e)}
        />
      </Row>
    </Col>
  );
};
export default CommunityCardsTable;

const ActionComponent = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    id,
    setIsEditCommunityCardVisible,
    setEditCommunityCardId,
    AllCommunityCardsData,
    allCommunityCards,
    communityCardPage,
  } = props;
  const handleEditCommunityCardMethod = (Id) => {
    setIsEditCommunityCardVisible(true);
    setEditCommunityCardId(Id);
  };
  const handlePublishCommunityCardMethod = (ID) => {
    const SelectedRecord = _.filter(
      allCommunityCards.community_cards,
      (val) => {
        return val?.id === ID;
      }
    );

    SelectedRecord[0].published = true;
    dispatch(
      updateCommunityCardsAction(SelectedRecord[0], SuccessPublishMethod, true)
    );
  };
  const handleUnpublishCommunityCardMethod = (ID) => {
    const SelectedRecord = _.filter(
      allCommunityCards.community_cards,
      (val) => {
        return val.id === ID;
      }
    );
    SelectedRecord[0].published = false;

    dispatch(
      updateCommunityCardsAction(
        SelectedRecord[0],
        SuccessUnPublishMethod,
        true
      )
    );
  };
  const SuccessPublishMethod = () => {
    setTimeout(() => {
      message.success(t('content.325'));
    }, 1000);
  };
  const SuccessUnPublishMethod = () => {
    setTimeout(() => {
      message.success(t('content.326'));
    }, 1000);
  };
  return (
    <Col>
      <Row gutter={[10, 0]}>
        <Col span={10}>
          <Button
            onClick={() => handleEditCommunityCardMethod(id)}
            type='primary'
            className='editDesignationButton'
          >
            {t('btn.27')}
          </Button>
        </Col>
        <Col span={10}>
          {AllCommunityCardsData?.published ? (
            <Button
              type='primary'
              className='unPublishButton'
              onClick={() => handleUnpublishCommunityCardMethod(id)}
            >
              {t('content.164')}
            </Button>
          ) : (
            <Button
              type='primary'
              className='publishButton'
              onClick={() => handlePublishCommunityCardMethod(id)}
            >
              {t('content.163')}
            </Button>
          )}
        </Col>
      </Row>
    </Col>
  );
};
