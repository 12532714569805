import { Row, Col, Button, Pagination } from 'antd';
import React, { useEffect, useState } from 'react';
import MyProfileModalForm from '../Matrimonial/MyProfileModalForm';
import ViewMatrimonialProfile from '../Matrimonial/ViewMatrimonialProfile';
import ViewMyMatches from '../Matrimonial/ViewMyMatches';
import MatrimonialProfileDetails from './MatrimonialProfileDetails';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { history } from '../../Utils/history';

const MatrimonialPage = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [viewMatrimonialProfileId, setViewMatrimonialProfileId] = useState('');
  const [isViewMyMatchesVisible, setIsViewMyMatchesVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentMatchesPage, setCurrentMatchesPage] = useState(1);
  const [selectedUserId, setSelectedUserId] = useState('');
  const { allMatrimonial, matrimonialMatches, community, currentUser } =
    useSelector((state) => {
      const { allMatrimonial, isMatrimonialLoading, matrimonialMatches } =
        state.matrimonial;
      const { community } = state.communities;
      const { currentUser } = state.authentication;

      return {
        allMatrimonial,
        isMatrimonialLoading,
        matrimonialMatches,
        community,
        currentUser,
      };
    });
  const isMatrimonialVisibleByAdmin = community?.show_matrimonial;
  const redirectMethod = () => {
    history.push('/add_family');
  };
  useEffect(() => {
    if (
      currentUser?.verified === false
        ? true
        : isMatrimonialVisibleByAdmin === false
    ) {
      redirectMethod();
    }
  }, [isMatrimonialVisibleByAdmin]);
  const handleCancel = () => {
    setVisible(false);
    setEditId('');
  };
  const height = window.innerHeight - 64;

  return (
    <Col
      style={{
        height: height,
        overflow: 'hidden',
        overflowY: 'scroll',
        backgroundColor: '#FFFFFF',
      }}
      className='customLayout'
    >
      <Row justify='space-between' style={{ padding: '10px 20px' }}>
        <Col></Col>
        <Col style={{ textAlign: 'end' }}>
          {!viewMatrimonialProfileId && (
            <Button
              onClick={() => setVisible(true)}
              className='button-search'
              type='primary'
              style={{ width: '100%' }}
            >
              {t('btn.32')}
            </Button>
          )}
          <MyProfileModalForm
            visible={visible}
            setVisible={setVisible}
            editId={editId}
            setEditId={setEditId}
            handleCancel={handleCancel}
            currentPage={currentPage}
          />
        </Col>
      </Row>
      <Row style={{ padding: 20 }}>
        <Col span={24}>
          {viewMatrimonialProfileId ? (
            <>
              {isViewMyMatchesVisible ? (
                <>
                  <ViewMyMatches
                    viewMatrimonialProfileId={viewMatrimonialProfileId}
                    setViewMatrimonialProfileId={setViewMatrimonialProfileId}
                    setIsViewMyMatchesVisible={setIsViewMyMatchesVisible}
                    currentMatchesPage={currentMatchesPage}
                    selectedUserId={selectedUserId}
                  />

                  <Row>
                    <Col span={23}>
                      <Row justify='end' style={{ margin: '10px 0px' }}>
                        <Pagination
                          total={matrimonialMatches?.total_length}
                          onChange={(e) => setCurrentMatchesPage(e)}
                        />
                      </Row>
                    </Col>
                  </Row>
                </>
              ) : (
                <MatrimonialProfileDetails
                  editId={editId}
                  setEditId={setEditId}
                  visible={visible}
                  setVisible={setVisible}
                  viewMatrimonialProfileId={viewMatrimonialProfileId}
                  setViewMatrimonialProfileId={setViewMatrimonialProfileId}
                />
              )}
            </>
          ) : (
            <>
              <ViewMatrimonialProfile
                editId={editId}
                setEditId={setEditId}
                visible={visible}
                setVisible={setVisible}
                viewMatrimonialProfileId={viewMatrimonialProfileId}
                setViewMatrimonialProfileId={setViewMatrimonialProfileId}
                setIsViewMyMatchesVisible={setIsViewMyMatchesVisible}
                currentPage={currentPage}
                currentMatchesPage={currentMatchesPage}
                setSelectedUserId={setSelectedUserId}
              />
              <Row>
                <Col span={24}>
                  <Row justify='end' style={{ margin: '20px 0px' }}>
                    <Pagination
                      total={allMatrimonial?.total_length}
                      onChange={(e) => setCurrentPage(e)}
                    />
                  </Row>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </Col>
  );
};
export default MatrimonialPage;
