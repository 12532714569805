import constants from "../Utils/constants";
const initialState = {
  isGetTicketsLoading: false,
  isCreateTicketsLoading: false,
  isDeleteTICKETSLoading: false,
  isUpdateTICKETSLoading: false,
  isGetTicketByIdLoading: false,
  ticket: {},
  allTickets: [],
};

export const ticketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_TICKETS_REQUEST:
      return {
        ...state,
        isGetTicketsLoading: true,
        allTickets: [],
      };
    case constants.GET_TICKETS_SUCCESS:
      return {
        ...state,
        isGetTicketsLoading: false,
        allTickets: action.payload,
      };
    case constants.GET_TICKETS_FAILURE:
      return {
        ...state,
        isGetTicketsLoading: false,
      };
    case constants.GET_TICKET_BY_ID_REQUEST:
      return {
        ...state,
        isGetTicketByIdLoading: true,
      };
    case constants.GET_TICKET_BY_ID_SUCCESS:
      return {
        ...state,
        isGetTicketByIdLoading: false,
        ticket: action.payload,
      };
    case constants.GET_TICKET_BY_ID_FAILURE:
      return {
        ...state,
        isGetTicketByIdLoading: false,
      };
    case constants.CREATE_TICKETS_REQUEST:
      return {
        ...state,
        isCreateTicketsLoading: true,
      };
    case constants.CREATE_TICKETS_SUCCESS:
      return {
        ...state,
        isCreateTicketsLoading: false,
      };
    case constants.CREATE_TICKETS_FAILURE:
      return {
        ...state,
        isCreateTicketsLoading: false,
      };
    // case constants.DELETE_TICKETS_REQUEST:
    //   return {
    //     ...state,
    //     isDeleteTICKETSLoading: true,
    //   };
    // case constants.DELETE_TICKETS_SUCCESS:
    //   return {
    //     ...state,
    //     isDeleteTICKETSLoading: false,
    //   };
    // case constants.DELETE_TICKETS_FAILURE:
    //   return {
    //     ...state,
    //     isDeleteTICKETSLoading: false,
    //   };
    // case constants.UPDATE_TICKETS_REQUEST:
    //   return {
    //     ...state,
    //     isUpdateTICKETSLoading: true,
    //   };
    // case constants.UPDATE_TICKETS_SUCCESS:
    //   return {
    //     ...state,
    //     isUpdateTICKETSLoading: false,
    //   };
    // case constants.UPDATE_TICKETS_FAILURE:
    //   return {
    //     ...state,
    //     isUpdateTICKETSLoading: false,
    //   };

    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
