import {
  Input,
  Button,
  Row,
  Col,
  Typography,
  DatePicker,
  TimePicker,
  Upload,
  message,
  Drawer,
} from 'antd';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import LoadingIndicator from '../Screens/LoadingIndicator/LoadingIndicator';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventSchema } from '../Utils/Schema';
import './Events.css';
import { getEvents, updateEvents } from '../Actions/eventsAction';
import { useDispatch } from 'react-redux';
import { createEvents } from '../Actions/eventsAction';
import { useTranslation } from 'react-i18next';
import '../Screens/AddFamilymember/Addfamily.css';
import './../FindThePeoplePage/FindOtherPeople.css';
import _ from 'lodash';
import { PlusOutlined } from '@ant-design/icons';
import { newImageUrl } from '../Screens/CommonLogics/CommonMethods';
import { analytics } from '../components/Mixpanel/Mixpanel';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);
const EventsModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    isModalVisible,
    setIsModalVisible,
    updateModal,
    setUpdateModal,
    eventId,
    isCreateEventsLoading,
    isUpdateEventsLoading,
    eventname,
    eventlocation,
    cityname,
    event,
    updateDateTime,
    time,
    setIsUploadImageVisible,
    setCommunityId,
  } = props;
  if (event) {
    event.dummydate = dayjs(updateDateTime, 'YYYY-MM-DD');
  }
  if (event) {
    event.dummytime = dayjs(time, 'LT');
  }
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: event,
    resolver: yupResolver(eventSchema),
  });

  const [eventDate, setEventDate] = useState('');
  const [customTime, setCustomTime] = useState('');
  const [newFiles, setNewFiles] = useState([]);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [isDefaultImageVisible, setIsDefaultImageVisible] = useState(true);
  const IsEmpty = _.isEmpty(newFiles);
  const cancelModal = () => {
    setIsModalVisible(false);
    reset();
    setShowUploadImage(false);
    setNewFiles([]);
  };
  const onDateSelection = (date) => {
    const Date = dayjs(date);
    setEventDate(dayjs(Date._d).format('yyyy/MM/DD'));
  };
  const onTimeSelection = (time) => {
    const timeSplit = dayjs(time);
    setCustomTime(timeSplit._d);
  };
  const handleCreate = (data) => {
    data.community_id = setCommunityId;
    data.dummydate = dayjs(data.dummydate).format('YYYY/MM/DD');
    data.dummytime = dayjs(data.dummytime).format('LT');
    data.event_datetime = data.dummydate + '_' + data.dummytime;
    data.community_event_image = newFiles;
    dispatch(createEvents(data, successCreate, IsEmpty));
  };
  const successCreate = () => {
    analytics?.track('Events_CreateNewEvent');
    setIsModalVisible(false);
    dispatch(getEvents(setCommunityId));
    setNewFiles([]);
    setIsUploadImageVisible(false);
    setValue('event_name', '');
    setValue('city_name', '');
    setValue('event_location', '');
    setValue('event_detail', '');
    setValue('dummydate', '');
    setValue('dummytime', '');
    reset();
    setTimeout(() => {
      message.success(t('content.142'));
    }, 1000);
    setShowUploadImage(false);
  };
  const cancelUpdateModal = () => {
    setUpdateModal(false);
    setValue('event_name', eventname);
    setValue('city_name', cityname);
    setValue('event_location', eventlocation);
    setValue('event_detail', event.event_detail);
    clearErrors();
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
  };
  const handleUpdate = (data) => {
    data.id = eventId;
    data.dummydate = dayjs(data.dummydate).format('YYYY/MM/DD');
    data.dummytime = dayjs(data.dummytime).format('LT');
    data.event_datetime = data.dummydate + '_' + data.dummytime;
    data.community_event_image = newFiles;
    dispatch(updateEvents(data, successUpdate, IsEmpty));
  };
  const successUpdate = () => {
    analytics?.track('Events_UpdatedEvents');
    setUpdateModal(false);
    setNewFiles([]);
    dispatch(getEvents(setCommunityId));
    setTimeout(() => {
      message.success(t('content.143'));
    }, 1000);
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
  };
  const propsForUpload = {
    action: handleCreate || handleUpdate,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles(file);
      return false;
    },
    multiple: false,
  };
  const handleUploadMethod = () => {
    setShowUploadImage(true);
    setIsDefaultImageVisible(false);
  };
  return (
    <Drawer
      open={updateModal ? updateModal : isModalVisible}
      footer={null}
      onClose={() => (updateModal ? cancelUpdateModal() : cancelModal())}
      title={
        updateModal ? (
          <Typography className='title'>{t('welcomeText.17')}</Typography>
        ) : (
          <Typography className='title'>{t('welcomeText.16')}</Typography>
        )
      }
      maskClassName='DrawerClass'
      className='DrawerBorderClass'
    >
      <LoadingIndicator
        loading={updateModal ? isUpdateEventsLoading : isCreateEventsLoading}
      >
        <form
          onSubmit={handleSubmit(updateModal ? handleUpdate : handleCreate)}
        >
          <Row gutter={[0, 0]}>
            <Col span={24}>
              <Row justify='center'>
                <Col>
                  {updateModal &&
                    isDefaultImageVisible &&
                    event?.event_image_key && (
                      <Upload
                        listType='picture-card'
                        openFileDialogOnClick={false}
                      >
                        <img
                          src={newImageUrl(event?.event_image_key)}
                          width={102}
                          alt=''
                        ></img>
                      </Upload>
                    )}
                </Col>
                <Col>
                  <Upload
                    {...propsForUpload}
                    listType='picture-card'
                    maxCount={1}
                    showUploadList={showUploadImage}
                    onChange={() => handleUploadMethod()}
                  >
                    <PlusOutlined size={44} />
                    {event?.image ? 'Edit Image' : t('content.154')}
                  </Upload>
                </Col>
              </Row>
              <Row gutter={[10, { md: 15, sm: 15, lg: 15, xs: 0 }]}>
                <Col xl={12} md={12} sm={12} xs={24}>
                  <Typography
                    className='label'
                    style={{ paddingBottom: '5px', paddingTop: '10px' }}
                  >
                    {t('content.7')}
                  </Typography>
                  <Controller
                    as={<Input size='large' className='inputLabel' />}
                    name='event_name'
                    control={control}
                  />
                  {errors.event_name && (
                    <p style={{ color: 'red' }}>{t('content.106')}</p>
                  )}
                </Col>
                <Col xl={12} md={12} sm={12} xs={24}>
                  <Typography
                    className='label'
                    style={{ paddingBottom: '5px', paddingTop: '10px' }}
                  >
                    {t('content.8')}
                  </Typography>
                  <Controller
                    as={<Input size='large' className='inputLabel' />}
                    name='city_name'
                    control={control}
                  />
                  {errors.city_name && (
                    <p style={{ color: 'red' }}>{t('content.107')}</p>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[10, { md: 15, sm: 15, lg: 15, xs: 0 }]}>
                <Col xl={12} md={12} sm={12} xs={24}>
                  <Typography
                    className='label'
                    style={{ paddingBottom: '5px', paddingTop: '10px' }}
                  >
                    {t('content.9')}
                  </Typography>
                  <Controller
                    as={
                      <DatePicker
                        onSelect={(date, dateString) =>
                          onDateSelection(date, dateString)
                        }
                        placeholder={t('placeholder.10')}
                        size='large'
                        className='inputLabel'
                      />
                    }
                    name='dummydate'
                    control={control}
                    style={{ width: '100%' }}
                  />
                  {errors.dummydate && (
                    <p style={{ color: 'red' }}>{t('content.108')}</p>
                  )}
                </Col>
                <Col xl={12} md={12} sm={12} xs={24}>
                  <Typography
                    className='label'
                    style={{ paddingBottom: '5px', paddingTop: '10px' }}
                  >
                    {t('content.10')}
                  </Typography>
                  <Controller
                    as={
                      <TimePicker
                        use12Hours
                        format='h:mm a'
                        onSelect={(time) => onTimeSelection(time)}
                        size='large'
                        placeholder={t('placeholder.11')}
                        className='inputLabel'
                      />
                    }
                    name='dummytime'
                    control={control}
                    style={{ width: '100%' }}
                  />
                  {errors.dummytime && (
                    <p style={{ color: 'red' }}>{t('content.109')}</p>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[10, { md: 15, sm: 15, lg: 15, xs: 0 }]}>
                <Col xl={12} md={12} sm={12} xs={24}>
                  <Typography
                    className='label'
                    style={{ paddingBottom: '5px', paddingTop: '10px' }}
                  >
                    {t('content.11')}
                  </Typography>
                  <Controller
                    as={<Input size='large' className='inputLabel' />}
                    name='event_detail'
                    control={control}
                  />
                  {errors.event_detail && (
                    <p style={{ color: 'red' }}>{t('content.110')}</p>
                  )}
                </Col>
                <Col xl={12} md={12} sm={12} xs={24}>
                  <Typography
                    className='label'
                    style={{ paddingBottom: '5px', paddingTop: '10px' }}
                  >
                    {t('content.12')}
                  </Typography>
                  <Controller
                    as={<Input size='large' className='inputLabel' />}
                    name='event_location'
                    control={control}
                  />
                  {errors.event_location && (
                    <p style={{ color: 'red' }}>{t('content.111')}</p>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[10, 15]} justify='end'>
                <Col span={24} />
                {updateModal ? (
                  <Col>
                    <Button
                      onClick={cancelUpdateModal}
                      className='allButtonsCancel'
                    >
                      {t('btn.19')}
                    </Button>
                  </Col>
                ) : (
                  <Col>
                    <Button
                      onClick={() => cancelModal()}
                      className='allButtonsCancel'
                    >
                      {t('btn.19')}
                    </Button>
                  </Col>
                )}
                {updateModal ? (
                  <Col>
                    <Button htmlType='submit' className='allButtons'>
                      {t('btn.17')}
                    </Button>
                  </Col>
                ) : (
                  <Col>
                    <Button htmlType='submit' className='allButtons'>
                      {t('btn.18')}
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </form>
      </LoadingIndicator>
    </Drawer>
  );
};
export default EventsModal;
