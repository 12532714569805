import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { history } from './Utils/history';
import { Router } from 'react-router';
import UserRoutes from './Routes';
import { tracker } from './components/openreplay';
function App() {
  const token = localStorage?.getItem('access_token');
  useEffect(() => {
    if (history.location.pathname === '/' && token) {
      history.push('/add_family');
    } else {
    }
  }, []);

  {
    process.env.NODE_ENV === 'production' && tracker.start();
  }
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <UserRoutes />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
