import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Typography, Input, message, Drawer } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  createDesignation,
  updateDesignation,
  getDesignations,
} from '../../Actions/designationsAction';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { designationSchema } from '../../Utils/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import '../../FindThePeoplePage/FindOtherPeople.css';
import '../AddFamilymember/Addfamily.css';

import { useTranslation } from 'react-i18next';

const AddDesignationModal = (props) => {
  const {
    isAddDesignationModalVisible,
    setIsAddDesignationModalVisible,
    isEditDesignationModalVisible,
    setIsEditDesignationModalVisible,
    selectedDesignation,
    editDesignationId,
    isCreateDesignationsLoading,
    isUpdateDesignationsLoading,
    setEditDesignationId,
    setCommunityId,
  } = props;
  const { t } = useTranslation();

  const [preloadedDesignationValues, setPreloadedDesignationValues] =
    useState('');
  const dispatch = useDispatch();

  const {
    handleSubmit,
    formState: { errors },
    control,

    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(designationSchema),

    defaultValues: preloadedDesignationValues,
  });
  useEffect(() => {
    setPreloadedDesignationValues(selectedDesignation);
    reset(selectedDesignation);
  }, [selectedDesignation, reset]);
  const closeAddDesignationModalMethod = () => {
    setIsAddDesignationModalVisible(false);

    setValue('designation_title', '');
    setEditDesignationId('');
    reset();
  };

  const createAddDesignationMethod = (data) => {
    data.published = true;
    data.community_id = setCommunityId;
    dispatch(createDesignation(data, successCreateDesignationMethod));
  };

  const successCreateDesignationMethod = () => {
    setIsAddDesignationModalVisible(false);
    dispatch(getDesignations(setCommunityId));
    setValue('designation_title', '');

    reset();
    setTimeout(() => {
      message.success(t('content.315'));
    }, 1000);
  };

  const closeEditDesignationModalMethod = () => {
    setIsEditDesignationModalVisible(false);
    setValue('designation_title', '');
    setEditDesignationId('');
    reset();
  };
  const updateDesignationMethod = (data) => {
    data.id = editDesignationId;
    data.community_id = setCommunityId;
    dispatch(updateDesignation(data, successUpdateDesignationMethod));
  };
  const successUpdateDesignationMethod = () => {
    setIsEditDesignationModalVisible(false);
    setValue('designation_title', '');
    reset();
    dispatch(getDesignations(setCommunityId));
    setTimeout(() => {
      message.success(t('content.316'));
    }, 1000);
  };
  useEffect(() => {
    if (isAddDesignationModalVisible) {
      setValue('designation_title', '');
    }
  }, [setValue, isAddDesignationModalVisible]);
  return (
    <Drawer
      open={
        isEditDesignationModalVisible
          ? isEditDesignationModalVisible
          : isAddDesignationModalVisible
      }
      onClose={
        isEditDesignationModalVisible
          ? closeEditDesignationModalMethod
          : closeAddDesignationModalMethod
      }
      footer={false}
      title={
        isEditDesignationModalVisible
          ? `${t('btn.27')} ${t('content.5')}`
          : t('content.162')
      }
      maskClassName='DrawerClass'
      className='DrawerBorderClass'
    >
      <LoadingIndicator
        loading={
          isEditDesignationModalVisible
            ? isUpdateDesignationsLoading
            : isCreateDesignationsLoading
        }
      >
        <form
          onSubmit={handleSubmit(
            isEditDesignationModalVisible
              ? updateDesignationMethod
              : createAddDesignationMethod
          )}
        >
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Typography className='label' style={{ paddingBottom: '4px' }}>
                {t('content.165')}
              </Typography>
              <Controller
                as={<Input className='inputLabel' />}
                name='designation_title'
                control={control}
              />
              {errors.designation_title && (
                <p style={{ color: 'red' }}>{t('content.239')}</p>
              )}
            </Col>

            <Col span={24}>
              <Row justify='end' gutter={[10, 0]}>
                <Col>
                  <Button
                    onClick={
                      isEditDesignationModalVisible
                        ? closeEditDesignationModalMethod
                        : closeAddDesignationModalMethod
                    }
                    className='allButtonsCancel'
                  >
                    {t('btn.19')}
                  </Button>
                </Col>
                <Col>
                  <Button htmlType='submit' className='allButtons'>
                    {t('btn.22')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </LoadingIndicator>
    </Drawer>
  );
};

export default AddDesignationModal;
