import React, { useState } from 'react';
import { Input, Button, Row, Col, Typography, Upload, Checkbox } from 'antd';
import '../App.css';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  createUserAction,
  loginUserAction,
} from '../Actions/authenticationAction';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../Utils/history';
import { signupSchema } from '../Utils/Schema';
import _ from 'lodash';
import { PlusOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { phoneFormatterAccordingToCountryCode } from './CommonLogics/CommonMethods';
import './../index.css';
import LoadingIndicator from './LoadingIndicator/LoadingIndicator';
import PhoneInput from 'react-phone-input-international';
import 'react-phone-input-international/lib/style.css';
import ReCAPTCHA from 'react-google-recaptcha';

const Signup = (props) => {
  const {
    allCommunities,
    setIsSignupTab,
    isCommumitiesloading,
    setIsCommunities,
  } = props;
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signupSchema),
    defaultValues: { user_type: 'Regular' },
  });
  const dispatch = useDispatch();
  const { setCommunityId } = useSelector((state) => {
    const { setCommunityId } = state.communities;
    return {
      setCommunityId,
    };
  });
  const [newFiles, setNewFiles] = useState([]);
  const [failureMessage, setFailureMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showUploadImage, setShowUploadImage] = useState(false);
  const IsEmpty = _.isEmpty(newFiles);
  const [number, setNumber] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [current, setCurrent] = useState(0);
  const [genderRequiredMessage, setGenderRequiredMessage] = useState('');
  const [selectedCommunityId, setSelectedCommunityId] = useState('');
  const [communityRequired, setCommunityRequired] = useState('');
  const [gendervalue, setGendervalue] = useState('');
  const [gender, setGender] = useState('');
  const isGenderValueEmpty = _.isEmpty(gendervalue);
  const [countryFormat, setCountryFormat] = useState('');
  const [isGoogleCaptcha, setIsGoogleCaptcha] = useState(false);
  var TextText = '';
  const createUser = (data) => {
    const Text = number;
    TextText = Text.replace(`+${countryCode}`, '');
    const checkNumberLength = TextText.toString().length;

    if (isGenderValueEmpty) {
      setGenderRequiredMessage('Gender is required');
    } else if (checkNumberLength === 10) {
      if (data.user_type === 'Regular') {
        data.user_type = 'regular';
      }
      const capitalizeFirstName = _.capitalize(data.first_name);
      const capitalizeLastName = _.capitalize(data.last_name);
      data.user_image = IsEmpty ? null : newFiles;
      data.first_name = capitalizeFirstName;
      data.last_name = capitalizeLastName;
      data.phone = TextText;
      data.country_code = `+${countryCode}`;
      data.community_id = setCommunityId;
      data.gender = gendervalue;
      data.email = '';
      dispatch(
        createUserAction(data, successCreateUser, failureCreateUser, IsEmpty)
      );
    } else {
      setErrorMessage('The number you entered must be of 10 digits only');
    }
  };
  const successCreateUser = (
    signupResponse,
    PhoneNumber,
    LoginSuccessCountryCode
  ) => {
    setNewFiles([]);
    history.push('/verify', {
      signupResponse,
      PhoneNumber,
      LoginSuccessCountryCode,
      DisplayPhoneNumber: phoneFormatterAccordingToCountryCode(
        countryFormat,
        _.drop(_.toArray(number))
      ),
    });
  };
  const failureCreateUser = (failureResponse) => {
    const data = {};
    data.phone = TextText;
    data.country_code = `+${countryCode}`;
    dispatch(loginUserAction(data, successLoginUserMethod, failureLoginMethod));
  };
  const successLoginUserMethod = (
    loginResponse,
    PhoneNumber,
    Number,
    LoginSuccessCountryCode
  ) => {
    if (loginResponse.error) {
      setErrorMessage(loginResponse.error);
    } else if (
      loginResponse.message === 'You are blocked by admin you cannot login'
    ) {
      setErrorMessage(loginResponse.message);
    } else {
      history.push('/verify', {
        loginResponse,
        PhoneNumber,
        Number,
        LoginSuccessCountryCode,
        DisplayPhoneNumber: phoneFormatterAccordingToCountryCode(
          countryFormat,
          _.drop(_.toArray(number))
        ),
      });
    }
  };
  const failureLoginMethod = (FailureResponse) => {
    setFailureMessage(FailureResponse.error);
  };
  const propsForUpload = {
    action: createUser,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles(file);
      return false;
    },
    multiple: false,
  };
  const phoneNumberMethod = (value, country, e, formattedValue) => {
    setCountryCode(country.dialCode);
    setNumber(formattedValue);
    setCountryFormat(country.format);
  };
  const handleCommunityIdMethod = () => {
    if (selectedCommunityId) {
      dispatch({
        type: 'GET_SELECTED_COMMUNITY_ID',
        payload: selectedCommunityId,
      });
      setCurrent(1);
      setIsSignupTab(true);
      setIsCommunities(false);
    } else {
      setCommunityRequired('Community is required');
    }
  };
  const hideCommunityErrorMessage = (Id) => {
    setSelectedCommunityId(Id);
    setCommunityRequired('');
  };
  const publishedCommunities = _.filter(allCommunities, (communities) => {
    return communities.published === true;
  });
  const genderValue = (e) => {
    const { checked, value } = e.target;
    setGender({
      value,
      [value]: checked,
    });
    setGendervalue(value);
    setGenderRequiredMessage('');
  };
  const communitiesHavingNatives = _.filter(
    publishedCommunities,
    (communities) => {
      return communities.community_natives.length > 0;
    }
  );
  const handleGoogleCaptchaMethod = () => {
    const myFunction = () => {
      setIsGoogleCaptcha(true);
    };
    setTimeout(myFunction, 1000);
  };
  return (
    <Col>
      <form onSubmit={handleSubmit(createUser)}>
        {current === 0 && (
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <Typography
                className='cardTitle'
                style={{ marginTop: '12px', color: '#252b42' }}
              >
                {t('welcomeText.24')}
              </Typography>
            </Col>

            <Col span={24}>
              <Col
                style={{
                  overflow: 'hidden',
                  overflowY: 'scroll',
                  height: '100px',
                }}
                className='signupScroll'
              >
                <LoadingIndicator loading={isCommumitiesloading}>
                  <Row gutter={[15, 15]} style={{ padding: '0px 10px' }}>
                    {communitiesHavingNatives &&
                      communitiesHavingNatives.length > 0 &&
                      communitiesHavingNatives?.map((val, i) => {
                        return (
                          <Button
                            className='communityRadioBtn1'
                            onClick={() => hideCommunityErrorMessage(val.id)}
                            style={{ margin: '0px 15px 0px 0px' }}
                            key={i}
                          >
                            {val?.name}
                          </Button>
                        );
                      })}
                  </Row>
                </LoadingIndicator>
              </Col>
            </Col>
          </Row>
        )}
        {current === 1 && (
          <Row gutter={[0, 15]}>
            <Col span={24}></Col>
            <Col
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={22}
              className='uploadImageSignUpInput'
            >
              <Row justify='center'>
                <Col>
                  <Upload
                    {...propsForUpload}
                    listType='picture-card'
                    maxCount={1}
                    showUploadList={showUploadImage}
                    onChange={() => setShowUploadImage(true)}
                  >
                    <PlusOutlined size={44} />
                    {t('content.154')}
                  </Upload>
                </Col>
              </Row>
            </Col>

            <Col span={24} />

            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Row gutter={[10, 0]}>
                <Col xl={12} lg={12} md={12} xs={11} sm={11}>
                  <Controller
                    as={
                      <Input
                        placeholder={t('placeholder.4')}
                        style={{ height: '40px' }}
                      />
                    }
                    name='first_name'
                    control={control}
                    className='emailInput'
                  />
                  {errors.first_name && (
                    <p style={{ color: 'red' }}>{t('content.54')}</p>
                  )}
                </Col>

                <Col xl={12} lg={12} md={12} xs={11} sm={11}>
                  <Controller
                    as={
                      <Input
                        placeholder={t('placeholder.5')}
                        style={{ height: '40px' }}
                      />
                    }
                    name='last_name'
                    control={control}
                    className='emailInput'
                  />
                  {errors.last_name && (
                    <p style={{ color: 'red' }}>{t('content.121')}</p>
                  )}
                </Col>
              </Row>
            </Col>

            <Col span={24}></Col>
            <Col xl={24} xs={22} md={24} lg={24}>
              <PhoneInput
                country={'in'}
                onChange={(value, country, e, formattedValue) =>
                  phoneNumberMethod(value, country, e, formattedValue)
                }
                containerClass='loginInputNe'
                placeholder='Enter Your Contact Number'
                autoFormat={false}
                onlyCountries={['in', 'ca', 'us', 'au', 'nz']}
                onClick={() => setErrorMessage('')}
              />
              {errors.phone && (
                <p style={{ color: 'red' }}>{t('content.99')}</p>
              )}
              {failureMessage && (
                <Typography style={{ color: 'red' }}>
                  {failureMessage}
                </Typography>
              )}

              {errorMessage ===
                'The number you entered must be of 10 digits only' && (
                <Typography style={{ color: 'red' }}>{errorMessage}</Typography>
              )}
              {errorMessage === 'You are blocked by admin you cannot login' && (
                <Typography style={{ color: 'red' }}>{errorMessage}</Typography>
              )}
            </Col>

            <Col span={24} />
            <Col span={24}>
              <Row>
                <Col className='label'>
                  <Checkbox
                    onChange={(e) => genderValue(e)}
                    value={'female'}
                    checked={gender?.female}
                  >
                    <Typography className='SignUpTerms'>
                      {t('content.83')}
                    </Typography>
                  </Checkbox>
                </Col>
                <Col className='label'>
                  <Checkbox
                    onChange={(e) => genderValue(e)}
                    value={'male'}
                    checked={gender?.male}
                  >
                    <Typography className='SignUpTerms'>
                      {t('content.82')}
                    </Typography>
                  </Checkbox>
                </Col>
              </Row>
              {genderRequiredMessage && (
                <p style={{ color: 'red', marginTop: '10px' }}>
                  {' '}
                  {genderRequiredMessage}{' '}
                </p>
              )}
            </Col>
            <Col span={24}>
              <Row>
                <Col>
                  <Typography className='SignUpTerms'>
                    <CheckCircleOutlined style={{ color: '#27AE60' }} /> By
                    signing up, you are agreeing with the terms and conditions
                    of&nbsp;
                    <Typography component='span' style={{ color: '#2accff' }}>
                      getsamaj.com
                    </Typography>
                  </Typography>
                </Col>
              </Row>
            </Col>
            {process.env.REACT_APP_ENV === 'production' && !isGoogleCaptcha && (
              <Col span={24}>
                <ReCAPTCHA
                  sitekey='6Ld6g2IlAAAAAIavE_eWDPKa-IAywqx2pvKwoiwL'
                  onChange={handleGoogleCaptchaMethod}
                />
              </Col>
            )}
          </Row>
        )}
        {current === 1 && (
          <Row gutter={[0, 30]}>
            <Col span={24}></Col>
            <Col span={12}>
              <Button
                size='large'
                className='loginBtn'
                htmlType='submit'
                disabled={
                  process.env.REACT_APP_ENV === 'production' && !isGoogleCaptcha
                }
              >
                {t('btn.10')}
              </Button>
            </Col>
          </Row>
        )}
      </form>

      {current === 0 && (
        <Row gutter={[0, 15]}>
          <Col span={24}>
            {communityRequired && (
              <Typography style={{ color: 'red' }}>
                {communityRequired}
              </Typography>
            )}
          </Col>
          <Col span={12}>
            <Button
              size='large'
              className='loginBtn'
              onClick={() => handleCommunityIdMethod()}
            >
              {t('btn.1')}
            </Button>
          </Col>
        </Row>
      )}
    </Col>
  );
};
export default Signup;
