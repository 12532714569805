import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTicketsAction,
  getTicketByIdAction,
} from '../../Actions/ticketAction';
import { Row, Col, Button, Typography, Table, Empty, Pagination } from 'antd';
import '../../FindThePeoplePage/FindOtherPeople.css';
import CreateTicketDrawer from './CreateTicketDrawer';
import ChatDrawer from './ChatDrawer';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { CustomerServiceOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { analytics } from '../../components/Mixpanel/Mixpanel';

const HelpAndSupport = () => {
  const { t } = useTranslation();

  const { allTickets, isGetTicketsLoading, isCreateTicketsLoading } =
    useSelector((state) => {
      const { allTickets, isGetTicketsLoading, isCreateTicketsLoading } =
        state.tickets;
      return {
        allTickets,
        isGetTicketsLoading,
        isCreateTicketsLoading,
      };
    });
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  useEffect(() => {
    dispatch(getTicketsAction(page));
  }, [page]);

  const [isCreateTicketVisible, setIsCreateTicketVisible] = useState(false);
  const height = window.innerHeight - 400;
  const [isChatDrawerVisible, setIsChatDrawerVisible] = useState(false);
  const [createTicketSuccessData, setCreateTicketSuccessData] = useState('');
  const [ticketByIdState, setTicketByIdState] = useState({});

  const columns = [
    {
      title: t('content.257'),
      dataIndex: 'ticket_number',
      width: '25%',
    },

    {
      title: t('content.258'),
      dataIndex: 'name',
      width: '25%',
    },
    {
      title: t('content.285'),
      dataIndex: 'ticket_status',
      width: '25%',
      render: (Status) => {
        return Status === 'Open' ? (
          <Col className='OpenStatus'>{t('content.264')}</Col>
        ) : (
          <Col className='ClosedStatus'>{t('content.265')}</Col>
        );
      },
    },

    {
      dataIndex: 'id',
      render: (id) => (
        <ActionComponent
          id={id}
          setIsChatDrawerVisible={setIsChatDrawerVisible}
          getTicketByIdAction={getTicketByIdAction}
          setCreateTicketSuccessData={setCreateTicketSuccessData}
        />
      ),
      width: '25%',
    },
  ];
  const backgroundColor = '#' + Math.floor(Math.random() * 1677).toString(14);

  return (
    <Col
      style={{
        height: height,
        overflow: 'hidden',
        overflowY: 'scroll',
      }}
      className='customLayout'
    >
      <Row justify='space-around' style={{ marginTop: '27px' }}>
        <Col span={23}>
          <Row justify='end'>
            <Col>
              <Button
                onClick={() => {
                  analytics?.track('Help&Support_AddNewTicket');
                  setIsCreateTicketVisible(true);
                }}
                type='primary'
                style={{ borderRadius: '4px', marginBottom: '27px' }}
                className='button-search'
              >
                {t('content.261')}
              </Button>
            </Col>
          </Row>
        </Col>
        <CreateTicketDrawer
          isCreateTicketVisible={isCreateTicketVisible}
          setIsCreateTicketVisible={setIsCreateTicketVisible}
          page={page}
          setIsChatDrawerVisible={setIsChatDrawerVisible}
          setCreateTicketSuccessData={setCreateTicketSuccessData}
          isCreateTicketsLoading={isCreateTicketsLoading}
          setTicketByIdState={setTicketByIdState}
        />
        <ChatDrawer
          isChatDrawerVisible={isChatDrawerVisible}
          createTicketSuccessData={createTicketSuccessData}
          setIsChatDrawerVisible={setIsChatDrawerVisible}
          page={page}
          setTicketByIdState={setTicketByIdState}
          ticketByIdState={ticketByIdState}
          backgroundColor={backgroundColor}
        />
        <Col span={23}>
          <LoadingIndicator loading={isGetTicketsLoading}>
            <Col
              style={{
                height: height,
                overflow: 'hidden',
                overflowY: 'scroll',
              }}
            >
              {allTickets?.tickets?.length > 0 ? (
                <Table
                  columns={columns}
                  dataSource={allTickets?.tickets}
                  size='middle'
                  className='table'
                  rowKey={(record) => {
                    return record.id;
                  }}
                  pagination={false}
                />
              ) : (
                !isGetTicketsLoading && (
                  <Col className='EmptyScreenCommunityCards'>
                    <Empty
                      description={
                        <Typography className='empty-details'>
                          {t('content.289')}
                        </Typography>
                      }
                      image={
                        <CustomerServiceOutlined style={{ fontSize: '50px' }} />
                      }
                    ></Empty>
                  </Col>
                )
              )}
            </Col>
          </LoadingIndicator>
        </Col>
        <Col span={23}>
          <Row justify='end' style={{ margin: '10px 0px' }}>
            <Pagination
              total={allTickets?.total_length}
              onChange={(e) => setPage(e)}
            />
          </Row>
        </Col>
      </Row>
    </Col>
  );
};
export default HelpAndSupport;

const ActionComponent = (props) => {
  const { t } = useTranslation();

  const {
    id,
    setIsChatDrawerVisible,
    getTicketByIdAction,
    setCreateTicketSuccessData,
  } = props;
  const dispatch = useDispatch();
  const viewDiscussionMethod = (Id) => {
    dispatch(getTicketByIdAction(Id));
    setIsChatDrawerVisible(true);
    setCreateTicketSuccessData('');
  };
  return (
    <Col>
      <Button
        type='primary'
        className='button-search'
        onClick={() => viewDiscussionMethod(id)}
      >
        {t('content.259')}
      </Button>
    </Col>
  );
};
