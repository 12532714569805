import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Typography, Tabs } from 'antd';
import '../App.css';
import Signup from './Signup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import GujaratiButton from '../components/GujaratiButton';
import EnglishButton from '../components/EnglishButton';
import { loginUserAction } from '../Actions/authenticationAction';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../Utils/history';
import { getCommunityById, getCommunities } from '../Actions/communitiesAction';
import PhoneInput from 'react-phone-input-international';
import 'react-phone-input-international/lib/style.css';
import _ from 'lodash';
import { phoneFormatterAccordingToCountryCode } from './CommonLogics/CommonMethods';
import GitInfo from 'react-git-info/macro';
import HindiButton from '../components/HindiButton';

const Login = (props) => {
  const { setIsSignupTab, communityData } = props;
  const {
    isCreateUserLoading,
    community,
    setCommunityId,
    allCommunities,
    isCommumitiesloading,
    key,
  } = useSelector((state) => {
    const { isLoginUserLoading, isCreateUserLoading } = state.authentication;
    const { community, setCommunityId, allCommunities, isCommumitiesloading } =
      state.communities;
    const { key } = state.footer;
    return {
      setCommunityId,
      isLoginUserLoading,
      isCreateUserLoading,
      community,
      allCommunities,
      isCommumitiesloading,
      key,
    };
  });
  const dispatch = useDispatch();
  const localStorageCommunityId = localStorage.getItem('community_id');

  useEffect(() => {
    dispatch(
      getCommunityById(
        localStorageCommunityId ? localStorageCommunityId : setCommunityId
      )
    );
    dispatch(getCommunities());
  }, [dispatch]);

  const [tabKey, setTabKey] = useState(
    history?.location?.state?.is_signup ? '2' : '1'
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [number, setNumber] = useState('');
  const [failureMessage, setFailureMessage] = useState('');
  const [countryFormat, setCountryFormat] = useState('');
  const [isCommunities, setIsCommunities] = useState(true);
  const callback = async (key) => {
    setTabKey(key);
    if (key === '1') {
      await dispatch({ type: 'RESET_APP_DATA' });
      setIsSignupTab(false);
      setIsCommunities(true);
    }
    if (key === '2') {
      dispatch(getCommunities());
    }
  };
  const { handleSubmit } = useForm({});
  const { t } = useTranslation();
  const loginUser = (data) => {
    const Text = number;
    const TextText = Text.replace(`+${countryCode}`, '');
    const checkNumberLength = TextText.toString().length;
    if (checkNumberLength === 10) {
      data.phone = TextText;
      data.country_code = `+${countryCode}`;
      dispatch(
        loginUserAction(data, successLoginUserMethod, failureLoginMethod)
      );
    } else {
      setErrorMessage('The number you entered must be of 10 digits only');
    }
  };
  const successLoginUserMethod = (
    loginResponse,
    PhoneNumber,
    Number,
    LoginSuccessCountryCode
  ) => {
    if (loginResponse.error) {
      setErrorMessage(loginResponse.error);
    } else if (
      loginResponse.message === 'You are blocked by admin you cannot login'
    ) {
      setErrorMessage(loginResponse.message);
    } else {
      history.push('/verify', {
        loginResponse,
        PhoneNumber,
        Number,
        LoginSuccessCountryCode,
        DisplayPhoneNumber: phoneFormatterAccordingToCountryCode(
          countryFormat,
          _.drop(_.toArray(number))
        ),
      });
    }
  };
  const failureLoginMethod = (FailureResponse) => {
    console.log({ FailureResponse });
    setFailureMessage(FailureResponse.error);
  };
  const phoneNumberMethod = (value, country, e, formattedValue) => {
    setCountryCode(country.dialCode);
    setNumber(formattedValue);
    setCountryFormat(country.format);
    setErrorMessage('');
  };
  const hideErrorMesssageMethod = () => {
    setErrorMessage('');
    setFailureMessage('');
  };
  const gitInfo = GitInfo();
  const items = [
    { label: t('btn.9'), key: '1' }, // remember to pass the key prop
    { label: t('btn.10'), key: '2' },
  ];
  console.log({ key });
  return (
    <Col className='loginPage'>
      <Row gutter={[0, 12]}>
        {isCommunities && (
          <Col>
            <Typography className='headerTitle'>
              {t('welcomeText.3')}{' '}
              {communityData ? communityData?.name : community?.name}{' '}
              {t('welcomeText.23')}
            </Typography>
          </Col>
        )}

        {isCommunities && (
          <Col span={24}>
            <Typography className='pageContent'>
              {community?.description ? community?.description : t('content.2')}
            </Typography>
          </Col>
        )}
        <Col>
          <Row>
            <Tabs onChange={callback} activeKey={tabKey} items={items} />
          </Row>
        </Col>
        <form onSubmit={handleSubmit(loginUser)}>
          {tabKey === '1' && (
            <Col span={24}>
              <Row gutter={[0, 10]}>
                <Col span={24}>
                  <Typography className='newTextLoginPage'>
                    {t('content.253')}
                  </Typography>
                </Col>
                <Col span={24}>
                  <PhoneInput
                    country={'in'}
                    onChange={(value, country, e, formattedValue) =>
                      phoneNumberMethod(value, country, e, formattedValue)
                    }
                    containerClass='loginInputNe'
                    placeholder='Enter Your Contact Number'
                    onClick={() => hideErrorMesssageMethod()}
                    autoFormat={false}
                    onlyCountries={['in', 'ca', 'us', 'au', 'nz']}
                    inputStyle={{ width: '100%' }}
                  />
                  {errorMessage && (
                    <Typography style={{ color: 'red' }}>
                      {errorMessage}
                    </Typography>
                  )}

                  {failureMessage && (
                    <Typography style={{ color: 'red' }}>
                      {' '}
                      {failureMessage}
                    </Typography>
                  )}
                </Col>
              </Row>
            </Col>
          )}
          {tabKey === '1' && (
            <Col>
              <Row gutter={[0, 12]}>
                <Col span={24} />
                <Col>
                  <Button size='large' className='loginBtn' htmlType='submit'>
                    {t('btn.9')}
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
        </form>
      </Row>
      {tabKey === '2' && (
        <Signup
          isCreateUserLoading={isCreateUserLoading}
          community={community}
          allCommunities={allCommunities}
          setCommunityId={setCommunityId}
          setIsSignupTab={setIsSignupTab}
          isCommumitiesloading={isCommumitiesloading}
          setIsCommunities={setIsCommunities}
        />
      )}
      <Row gutter={[0, 10]}>
        <Col span={24} />
        <Col span={24}>
          <GujaratiButton></GujaratiButton>
          <EnglishButton></EnglishButton>
          <HindiButton />
        </Col>
        <Col span={24}>
          <Row justify='end'>{gitInfo.tags[0]}</Row>
        </Col>
      </Row>
    </Col>
  );
};
export default Login;
