import React, { useState } from 'react';
import { Row, Col, Card, Avatar, Typography } from 'antd';
import { previewTemplate } from '../CommonLogics/CommonMethods';
import moment from 'moment/moment';
import Draggable from 'react-draggable';

const CommunityCongratulationsCard = (props) => {
  const { templateKey, mappedData, isCommunityCardModal } = props;
  const [titlePosition, setTitlePosition] = useState({ x: 0, y: 0 });
  const [namePosition, setNamePosition] = useState({ x: 0, y: 0 });
  const date = new Date(mappedData?.expiry_date);
  const typedDataDate = moment(date).format('MMM Do YYYY');
  const handleTitleDrag = (e, ui) => {
    setTitlePosition({ x: ui.x, y: ui.y });
  };

  const handleNameDrag = (e, ui) => {
    setNamePosition({ x: ui.x, y: ui.y });
  };
  return (
    <Col>
      <Card className='communityCard2'>
        <Row gutter={[0, 10]} justify='space-between'>
          <Col span={24}>
            <Row gutter={[0, 10]}>
              <Col span={24}>
                <Row gutter={[0, 10]}>
                  <Col span={24}>
                    <Row justify='space-between'>
                      <Col>
                        <Typography className='CommunityTextCardTitle'>
                          {mappedData?.name}
                        </Typography>
                      </Col>
                      <Col>
                        <Typography className='communityCardDate'>
                          {isCommunityCardModal
                            ? typedDataDate
                            : mappedDataDate}
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Typography className='communityCardDescription'>
                      {mappedData?.description}
                    </Typography>
                  </Col>
                </Row>
              </Col>

              <Col span={24}>
                <img
                  src={previewTemplate(templateKey)}
                  style={{
                    width: '100%',
                    height: '190px',
                    borderRadius: '4px',
                  }}
                  alt=''
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Col>
    //   <div>
    //     <Card className='communityCard2'>
    //       <Row gutter={[0, 10]} justify='space-between'>
    //         <Row>
    //           <Col span={24}>
    //             <Col span={24} justify='center' align='middle'>
    //               <img src={previewTemplate(templateKey)} />
    //               <Draggable position={titlePosition} onDrag={handleTitleDrag}>
    //                 <Typography>{mappedData?.name}</Typography>
    //               </Draggable>
    //               <Draggable position={namePosition} onDrag={handleNameDrag}>
    //                 <Typography>{mappedData?.name}</Typography>
    //               </Draggable>
    //             </Col>
    //           </Col>
    //         </Row>
    //       </Row>
    //     </Card>
    //   </div>
  );
};
export default CommunityCongratulationsCard;
