import React from 'react';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { communityMeetingSchema } from '../../Utils/Schema';
import moment from 'moment';
import {
  getCommunityMeetings,
  updateCommunityMeeting,
  createCommunityMeeting,
} from '../../Actions/communityMeetingsAction';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Button, Col, Row, DatePicker, Input, message, Drawer } from 'antd';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
const { TextArea } = Input;

const AddMeetingModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    isAddMeetingModalVisible,
    setIsAddMeetingModalVisible,
    iseditMeetingModalVisible,
    setIseditMeetingModalVisible,
    editMeetingId,
    setEditMeetingId,
    selectedMeeting,
    setCommunityId,
    setYear,
  } = props;

  const { isCreateCommunityMeetingLoading, isEditCommunityMeetingLoading } =
    useSelector((state) => {
      const { isCreateCommunityMeetingLoading, isEditCommunityMeetingLoading } =
        state.communityMeetings;
      return {
        isCreateCommunityMeetingLoading,
        isEditCommunityMeetingLoading,
      };
    });

  const newMeetingDate = moment(selectedMeeting[0]?.meeting_date, 'DD-MM-YYYY');
  const newArray = selectedMeeting.map((obj) => {
    return {
      ...obj,
      meeting_date: newMeetingDate,
      // year: Year,
    };
  });

  const [meeting, setMeeting] = useState(null);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(communityMeetingSchema),
    defaultValues: meeting,
  });

  useEffect(() => {
    setMeeting(newArray[0]);
    reset(newArray[0]);
  }, [selectedMeeting[0], reset]);

  const closeAddMeetingModal = () => {
    setIsAddMeetingModalVisible(false);
    setValue('title', '');
    setValue('place', '');
    setValue('description', '');
    setValue('meeting_date', '');
    setEditMeetingId(null);
    setMeeting(null);
    reset();
  };

  const closeEditMeetingModal = () => {
    setIseditMeetingModalVisible(false);
    setValue('title', '');
    setValue('place', '');
    setValue('number', '');
    setValue('description', '');
    setValue('year', '');
    setEditMeetingId('');
    reset();
  };

  const createAddMeetingMethod = (data) => {
    data.community_id = setCommunityId;
    data.meeting_date = moment(data.meeting_date._d).format('DD-MM-YYYY');
    data.year = data.meeting_date?.split('-')[2];
    dispatch(createCommunityMeeting(data, successCreateAddMeetingMethod));
  };

  const successCreateAddMeetingMethod = (createSuccessResponse) => {
    setIsAddMeetingModalVisible(false);
    setValue('title', '');
    setValue('place', '');
    setValue('description', '');
    setValue('meeting_date', '');
    setEditMeetingId('');
    reset({});
    setYear(
      createSuccessResponse.year +
        '-' +
        (parseInt(createSuccessResponse.year) + 1)
    );
    setTimeout(() => {
      message.success(t('content.321'));
    }, 1000);
    dispatch(getCommunityMeetings(createSuccessResponse.year));
  };
  const editMeetingMethod = (data) => {
    data.id = editMeetingId;
    data.meeting_date = moment(data.meeting_date._d).format('DD-MM-YYYY');
    data.year = data.meeting_date?.split('-')[2];
    dispatch(updateCommunityMeeting(data, successEditMethod));
  };

  const successEditMethod = (editSuccessDataResponse) => {
    setIseditMeetingModalVisible(false);
    setValue('title', '');
    setValue('place', '');
    setValue('description', '');
    setValue('meeting_date', '');
    setEditMeetingId('');
    setYear(
      editSuccessDataResponse.year +
        '-' +
        (parseInt(editSuccessDataResponse.year) + 1)
    );
    reset({});
    setTimeout(() => {
      message.success(t('content.322'));
    }, 1000);
    dispatch(getCommunityMeetings(editSuccessDataResponse.year));
  };

  useEffect(() => {
    if (!editMeetingId) {
      setValue('title', '');
      setValue('place', '');
      setValue('description', '');
      setValue('meeting_date', '');
      setEditMeetingId('');
    }
  }, [setValue, !editMeetingId]);

  return (
    <Drawer
      title={isAddMeetingModalVisible ? t('btn.43') : t('content.213')}
      open={
        isAddMeetingModalVisible
          ? isAddMeetingModalVisible
          : iseditMeetingModalVisible
      }
      onClose={
        isAddMeetingModalVisible ? closeAddMeetingModal : closeEditMeetingModal
      }
      okText='Save'
      footer={null}
      maskClassName='DrawerClass'
      className='DrawerBorderClass'
    >
      <LoadingIndicator
        loading={
          isCreateCommunityMeetingLoading
            ? isCreateCommunityMeetingLoading
            : isEditCommunityMeetingLoading
        }
      >
        <form
          onSubmit={handleSubmit(
            iseditMeetingModalVisible
              ? editMeetingMethod
              : createAddMeetingMethod
          )}
        >
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <label className='label'>{t('content.214')} : </label>
              <Controller as={<Input />} name='title' control={control} />
              {errors.title && (
                <p style={{ color: 'red' }}>{errors.title.message}</p>
              )}
            </Col>
            <Col span={24}>
              <label className='label'>{t('content.346')} : </label>
              <Controller as={<Input />} name='place' control={control} />
              {errors.place && (
                <p style={{ color: 'red' }}>{errors.place.message}</p>
              )}
            </Col>
            <Col span={24}>
              <label className='label'>{t('content.217')} : </label>
              <Controller
                as={<TextArea rows={3} />}
                name='description'
                control={control}
              />
              {errors.description && (
                <p style={{ color: 'red' }}>{errors.description.message}</p>
              )}
            </Col>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <label className='label'>{t('content.218')} : </label>
                </Col>
                <Col>
                  <Controller
                    as={
                      <DatePicker
                        onChange={(date, dateString) =>
                          console.log({ date, dateString })
                        }
                      />
                    }
                    name='meeting_date'
                    // format="YYYY"
                    control={control}
                  />
                </Col>
              </Row>
              {errors.meeting_date && (
                <p style={{ color: 'red' }}>{errors.meeting_date.message}</p>
              )}
            </Col>
          </Row>
          <Row gutter={[10, 10]} justify='end'>
            <Col>
              <Button
                onClick={() => {
                  isAddMeetingModalVisible
                    ? closeAddMeetingModal()
                    : closeEditMeetingModal();
                }}
                className='allButtonsCancel'
              >
                {t('btn.19')}
              </Button>
            </Col>
            <Col>
              <Button htmlType='submit' className='allButtons'>
                {t('btn.22')}
              </Button>
            </Col>
          </Row>
        </form>
      </LoadingIndicator>
    </Drawer>
  );
};

export default AddMeetingModal;
