import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Typography, Card } from 'antd';
import SamajIconSvg from '../../assets/SamajIconSvg.svg';
import { useBreakpoints } from '../CommonLogics/ResponsiveBreakPoints';
import OtpInput from 'react-otp-input';
import { Controller, useForm } from 'react-hook-form';
import '../../App.css';
import './OtpStyle.css';
import {
  verifyOtpAction,
  loginUserAction,
  getUsers,
} from '../../Actions/authenticationAction';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../Utils/history';
import { useTranslation } from 'react-i18next';
import GujaratiButton from '../../components/GujaratiButton';
import EnglishButton from '../../components/EnglishButton';
import { getCommunityById } from '../../Actions/communitiesAction';
import { otpSchema } from '../../Utils/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { ArrowLeftOutlined } from '@ant-design/icons';
import HindiButton from '../../components/HindiButton';
import { analytics } from '../../components/Mixpanel/Mixpanel';

const OtpAuthentication = (props) => {
  const Otpdata =
    process.env.REACT_APP_ENV === 'development'
      ? props.location.state.loginResponse.otp
      : null;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isVerifyOtpLoading, key } = useSelector((state) => {
    const { isVerifyOtpLoading } = state.authentication;
    const { community } = state.communities;
    const { key } = state.footer;
    return {
      isVerifyOtpLoading,
      community,
      key,
    };
  });

  useEffect(() => {
    if (Otpdata) {
      handleVerifyOtpMethod({ code: Otpdata });
    }
  }, [Otpdata]);

  const { IsMD, IsSM, IsXS, IsLG, IsXL, IsXXL } = useBreakpoints();

  const { handleSubmit, control, errors, setError } = useForm({
    defaultValues: { code: Otpdata },
    resolver: yupResolver(otpSchema),
  });

  const [seconds, setSeconds] = useState(60);
  const [failureMessage, setFailureMessage] = useState(false);
  const handleVerifyOtpMethod = (data) => {
    data.phone = props?.location?.state?.PhoneNumber;
    data.country_code = props?.location?.state?.LoginSuccessCountryCode;
    dispatch(verifyOtpAction(data, successVerifyOtp, failureVerifyOtp));
  };
  const onUserSuccess = (response) => {
    analytics?.identify(response?.id);
    analytics?.people.set({
      name: response?.first_name,
      lastName: response?.last_name,
      email: response?.email,
      phone: response?.phone,
      avatar: response?.image,
    });
  };
  const onUserFailure = (response) => {
    console.log(response);
  };

  const successVerifyOtp = (verifyOtpResponse) => {
    if (key === 'FOP') {
      history.push('/search');
    } else if (key === 'DOC') {
      history.push(
        `communities/${verifyOtpResponse?.user_community?.id}/informations`
      );
    } else {
      history.push('/add_family');
    }
    dispatch(getUsers(onUserSuccess, onUserFailure));
    dispatch({
      type: 'SET_FOOTER_REDIRECTION_KEY_TYPE',
      payload: null,
    });
    verifyOtpResponse?.user_community?.id &&
      dispatch({
        type: 'GET_SELECTED_COMMUNITY_ID',
        payload: verifyOtpResponse?.user_community?.id,
      });
    verifyOtpResponse?.user_community?.id &&
      dispatch(getCommunityById(verifyOtpResponse?.user_community?.id));
  };
  const failureVerifyOtp = (error) => {
    const attemptsRemaining = 2 - error.response.data.otp_attempt;
    let errorMessage;
    if (attemptsRemaining === 0) {
      errorMessage =
        'You are blocked for 24 hours. contact at hello@defx.in for help';
    } else {
      errorMessage = `Otp is invalid, attempts remaining ${attemptsRemaining}`;
    }
    setError('code', {
      type: 'custom',
      message: errorMessage,
    });
  };
  const data = {
    phone: '',
  };
  const handleResendMethod = () => {
    data.phone = props?.location?.state?.PhoneNumber;
    data.country_code = props?.location?.state?.LoginSuccessCountryCode;
    setSeconds(60);
    dispatch(loginUserAction(data));
  };
  useEffect(() => {
    const interval = setTimeout(() => {
      if (seconds >= 0) {
        setSeconds(seconds - 1);
      } else {
        setSeconds(-1);
      }
    }, 1000);
    return () => {
      clearTimeout(interval);
    };
  }, [seconds]);
  const height = window.innerHeight;
  const handleChange = (e) => {
    setFailureMessage('');
  };

  return (
    <>
      <Col
        className='OtpContainer'
        style={{
          height: IsXL || IsXXL || IsLG || IsMD || IsSM ? '100vh' : '100%',
        }}
      >
        <LoadingIndicator loading={isVerifyOtpLoading}>
          <Row gutter={[0, height / 13]}>
            {<Col span={24}></Col>}
            {(IsXS || IsSM) && (
              <Col span={24}>
                <Row style={{ marginLeft: IsXS || IsSM ? '10px' : '20px' }}>
                  <ArrowLeftOutlined
                    style={{ fontSize: '20px', cursor: 'pointer' }}
                    onClick={() => history.push('/login')}
                  />
                </Row>
              </Col>
            )}
            {IsXS && <Col span={24} />}

            {(IsXL || IsLG || IsMD || IsXXL) && (
              <Col span={24}>
                <Row style={{ marginLeft: '20px' }}>
                  <ArrowLeftOutlined
                    style={{ fontSize: '20px', cursor: 'pointer' }}
                    onClick={() => history.push('/login')}
                  />
                </Row>
              </Col>
            )}

            {(IsXL || IsXXL) && <Col span={24} />}
          </Row>
          <Row justify='center'>
            <Card
              style={{
                background: '#FFFFFF',
                width: '430px',
                paddingRight: 0,
              }}
            >
              <Row gutter={[0, 16]}>
                <Col span={24} style={{ marginBottom: '10px' }}>
                  <img src={SamajIconSvg} alt='' width={50} />
                </Col>
                <Col span={24}>
                  <Typography className='headerTitle'>
                    {t('welcomeText.4')}
                  </Typography>
                </Col>

                <Col span={24}>
                  <Typography className='pageContent'>
                    {t('content.3')}&nbsp;
                    <Typography style={{ color: '#0064ff' }} component='span'>
                      {props?.location?.state?.DisplayPhoneNumber}
                    </Typography>
                  </Typography>
                </Col>
                <Col span={22} />
                <form onSubmit={handleSubmit(handleVerifyOtpMethod)}>
                  <Col xl={20} lg={20} xs={22} md={22} sm={20}>
                    <Controller
                      as={
                        <OtpInput
                          inputStyle={{
                            width: '100%',
                            height: '56px',
                            background: '#F5F6F8',
                            border: '1px solid #DEDEDE',
                            boxSizing: 'border-box',
                            borderRadius: '4px',
                          }}
                          separator={
                            <span style={{ marginLeft: '15px' }}></span>
                          }
                          numInputs={4}
                          onChange={handleChange}
                          shouldAutoFocus={true}
                        />
                      }
                      name='code'
                      control={control}
                      defaultValue=''
                    />

                    {errors.code && (
                      <p style={{ color: 'red', marginTop: '10px' }}>
                        {errors.code.message}
                      </p>
                    )}
                    {seconds >= 0 ? (
                      <p style={{ color: 'red', marginTop: '10px' }}>
                        {t('content.100')} {seconds} s
                      </p>
                    ) : (
                      <p style={{ color: 'red', marginTop: '10px' }}>
                        {t('content.101')}
                      </p>
                    )}
                    {failureMessage && (
                      <p style={{ color: 'red', marginTop: '10px' }}>
                        {t('content.102')}
                      </p>
                    )}
                  </Col>
                  <Col span={24} style={{ marginBottom: '19px' }}>
                    <Button
                      type='link'
                      className='resendButton'
                      onClick={() => handleResendMethod()}
                      disabled={seconds !== -1}
                    >
                      {t('btn.13')}
                    </Button>
                  </Col>
                  <Col span={24}>
                    <Button className='loginBtn' htmlType='submit'>
                      {t('btn.14')}
                    </Button>
                  </Col>
                </form>
                <Col span={24}>
                  <GujaratiButton></GujaratiButton>
                  <EnglishButton></EnglishButton>
                  <HindiButton />
                </Col>
              </Row>
            </Card>
          </Row>
        </LoadingIndicator>
      </Col>
    </>
  );
};
export default OtpAuthentication;
