import { Col, Image, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { newImageUrl } from '../Screens/CommonLogics/CommonMethods';

const ImageView = ({ imageKeys, type, imageHeight, imageWidth }) => {
  const [galleryImages, setGalleryImages] = useState();
  console.log({ imageKeys, type });
  useEffect(() => {
    if (type === 'gallery') {
      const loadImages = async () => {
        const imagePromises = imageKeys?.map(async (val, i) => {
          return {
            original: await newImageUrl(val.key),
          };
        });

        const images = await Promise.all(imagePromises);
        setGalleryImages(images);
      };
      if (imageKeys) {
        loadImages();
      }
    } else {
      const loadImages = async () => {
        const imagePromises = await newImageUrl(imageKeys);
        console.log({ imagePromises });
        setGalleryImages(imagePromises);
      };
      if (imageKeys) {
        loadImages();
      }
    }
  }, [imageKeys]);
  console.log({ galleryImages });
  return (
    <Row>
      <Col span={24}>
        {galleryImages && (
          <Image src={galleryImages} height={imageHeight} width={imageWidth} />
        )}
      </Col>
    </Row>
  );
};

export default ImageView;
