import API from '../Utils/API';
import jwtDecode from 'jwt-decode';
import setAuthToken from '../Utils/setAuthToken';
import constants from '../Utils/constants';
import { history } from '../Utils/history';
export const getUsers =
  (userSuccessCallback, userFailureCallback) => (dispatch) => {
    dispatch({ type: 'GET_USERS_REQUEST' });
    API.get('api/users')
      .then((response) => {
        dispatch({ type: 'GET_USERS_SUCCESS', payload: response.data });
        userSuccessCallback && userSuccessCallback(response.data);
      })
      .catch((error) => {
        dispatch({ type: 'GET_USERS_FAILURE', payload: error.response.data });
        userFailureCallback && userFailureCallback(error.response.data);
      });
  };
export const loginUserAction =
  (data, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: 'LOGIN_USER_REQUEST' });
    API.post('api/login', data)
      .then((response) => {
        dispatch({ type: 'LOGIN_USER_SUCCESS', payload: response.data });
        successCallback &&
          successCallback(
            response.data,
            data.phone,

            data.country_code + data.phone,
            data.country_code
          );
      })
      .catch((error) => {
        dispatch({ type: 'LOGIN_USER_FAILURE', payload: error.response.data });
        failureCallback(error.response.data);
      });
  };
export const setCurrentUser = (decoded) => {
  localStorage.setItem('user_id', decoded.user_id);
  return {
    type: constants.SET_CURRENT_USER,
    payload: decoded,
  };
};
export const logoutCurrentUser = () => (dispatch) => {
  localStorage.removeItem('jwtToken');
  localStorage.clear();
  window.history.replaceState(null, null, '/');
  setAuthToken(false);
  dispatch(setCurrentUser({}));
  dispatch({ type: 'REMOVE_CURRENT_USER' });
  dispatch({ type: 'RESET_APP_DATA' });
  history.push('/login');
};
export const verifyOtpAction =
  (data, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: 'VERIFY_OTP_REQUEST', payload: data });
    API.post('api/verify', data)
      .then((response) => {
        dispatch({ type: 'VERIFY_OTP_SUCCESS', payload: response.data });

        if (response.data.error) {
          return;
        } else {
          const tokenData = response.data.token;
          localStorage.setItem('access_token', tokenData);
          localStorage.setItem(
            'community_id',
            response?.data?.user_community?.id
          );
          setAuthToken(tokenData);
          const decoded = jwtDecode(tokenData);
          dispatch(setCurrentUser(decoded));
          successCallback(response.data);
          dispatch(getUsers());
        }
      })
      .catch((error) => {
        dispatch({ type: 'VERIFY_OTP_FAILURE', payload: error });
        localStorage.clear();
        failureCallback(error);
      });
  };
export const createUserAction =
  (data, successCallback, failureCallback, IsEmpty) => (dispatch) => {
    dispatch({ type: 'CREATE_USER_REQUEST' });
    let formData = new FormData();
    IsEmpty
      ? formData.append('user_image', data.user_image)
      : formData.append('user[user_image]', data.user_image);
    // data.user_image?.map((image) => formData.append("user[user_image]", image));
    formData.append('user[first_name]', data.first_name);
    formData.append('user[last_name]', data.last_name);
    formData.append('user[email]', data.email);
    formData.append('user[phone]', data.phone);
    formData.append('user_type', data.user_type);
    formData.append('user[country_code]', data.country_code);
    formData.append('user[gender]', data.gender);

    formData.append('community_id', data.community_id);

    API.post('api/users', formData)
      .then((response) => {
        dispatch({ type: 'CREATE_USER_SUCCESS', payload: response.data });
        successCallback &&
          successCallback(response.data, data.phone, data.country_code);
      })
      .catch((error) => {
        dispatch({ type: 'CREATE_USER_FAILURE', payload: error.response.data });
        failureCallback(error.response.data);
      });
  };

export const updateUserAction =
  (data, IsEmpty, successCallback) => (dispatch) => {
    dispatch({ type: 'UPDATE_USER_REQUEST' });
    let formData = new FormData();
    IsEmpty
      ? formData.append('user_image', data.user_image)
      : formData.append('user[user_image]', data.user_image);
    // data.user_image?.map((image) => formData.append("user[user_image]", image));

    formData.append('user[first_name]', data.first_name);
    formData.append('user[last_name]', data.last_name);
    formData.append('user[email]', data.email);
    formData.append('user[phone]', data.phone);
    formData.append('user[education_id]', data.education_id);
    formData.append('user[industry_id]', data.industry_id);
    formData.append('user[birth_date]', data.birth_date);

    formData.append('user[birth_month]', data.birth_month);
    formData.append('user[birth_year]', data.birth_year);
    formData.append('user[is_poused]', data.is_poused);
    formData.append('user[gender]', data.gender);

    formData.append(
      'user[country_code]',

      data.country_code ? data.country_code : ''
    );

    API.put(`api/users/${data.id}`, formData)
      .then((response) => {
        dispatch({ type: 'UPDATE_USER_SUCCESS', payload: response.data });
        successCallback(response.data);
      })
      .catch((error) => {
        try {
          dispatch({
            type: 'UPDATE_USER_FAILURE',
            payload: error.response.data,
          });
        } catch {
          // console.log(error);
        }
      });
  };

export const getUserByIdAction = (ID) => (dispatch) => {
  dispatch({ type: 'GET_USER_BY_ID_REQUEST' });
  API.get(`api/users/${ID}`)
    .then((response) => {
      dispatch({ type: 'GET_USER_BY_ID_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_USER_BY_ID_FAILURE',
        payload: error.response.data,
      });
    });
};
