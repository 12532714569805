// import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export const loginSchema = yup.object().shape({
  phone: yup.number(10).required("Enter phone number"),
});
export const signupSchema = yup.object().shape({
  // user_image:yup.object().required('User image is required'),
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  // phone: yup.number().required("Phone number is required"),
  // user_type:yup.string().required('User type is required')
});

export const userSchema = yup.object().shape({
  last_name: yup
    .string()
    .required("Enter family name")
    .matches("^[a-z A-Z]+$", "Enter proper family name"),
  community_native_id: yup.string().required("Enter family native"),
  // familyname:yup.string().required('Family Name is required').matches('^[a-z A-Z]+$','Enter proper family name'),
  // firstname:yup.string().required('First Name is required').matches('^[a-z A-Z]+$','Enter proper first name'),
  // relationship:yup.string().required('Relationship with you is required').matches('^[a-z A-Z]+$','Enter proper relationship with you'),
  // contactnumber:yup.string().required('Contact Number is required').matches('^[7-9][0-9]{9}$','Enter proper contact number')
});

export const addFamilyMemberSchema = yup.object().shape({
  first_name: yup
    .string()
    .required("Enter your name")
    .matches("^[a-z A-Z]+$", "Enter proper name"),
  date_of_birth: yup.date().required("Enter birth date"),
  // phone: yup
  //   .number()
  //   .required("Enter phone number")
  //   .typeError("It does not look like a phone number"),
});

export const MatrimonialuserSchema = yup.object().shape({
  birth_place: yup
    .string()
    .required("Birth Place is required")
    .matches("^[a-z A-Z]+$", "Enter proper birth place"),
  height: yup.string().required("Height is required"),
  weight: yup.number().required("Weight is required"),
  description: yup.string().required("Description is required"),
  user_id: yup.string().required("Name is required"),
  current_city: yup.string().required("Current city is required"),
  marital_status: yup.string().required("Marital status is required"),
  birth_time: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .required("Birth Time is required"),
});
export const UpdateMatrimonialuserSchema = yup.object().shape({
  birth_place: yup
    .string()
    .required("Birth Place is required")
    .matches("^[a-z A-Z]+$", "Enter proper birth place"),
  height: yup.string().required("Height is required"),
  weight: yup.number().required("Weight is required"),
  description: yup.string().required("Description is required"),
  // user_id: yup.string().required("Name is required"),
  current_city: yup.string().required("Current city is required"),
  marital_status: yup.string().required("Marital status is required"),
  birth_time: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .required("Birth Time is required"),
});
export const BusinessuserSchema = yup.object().shape({
  create_user_id: yup.string().required("First Name is required"),
  company_name: yup
    .string()
    .required("Company Name is required")
    .matches("^[a-z A-Z]+$", "Enter proper company name"),
  service_provide: yup.string().required("Service Provide is required"),
  business_details: yup.string().required("Business Details is required"),
  business_location: yup.string().required("Business Location is required"),
  number_of_staff: yup.number().required("Number of staff is required"),
  working_here_since: yup.string().required("Established Year is required"),
});

export const BusinessuserUpdateSchema = yup.object().shape({
  company_name: yup
    .string()
    .required("Company Name is required")
    .matches("^[a-z A-Z]+$", "Enter proper company name"),

  service_provide: yup.string().required("Service Provide is required"),
  business_details: yup.string().required("Business Details is required"),
  business_location: yup.string().required("Business Location is required"),
  number_of_staff: yup.number().required("Number of staff is required"),
  working_here_since: yup.string().required("Established Year is required"),
});

export const JobProfileSchema = yup.object().shape({
  user_id: yup.string().required("First Name is required"),
  job_title: yup
    .string()
    .required("Job title is required")
    .matches("^[a-z A-Z]+$", "Enter proper job title"),
  working_here_since: yup.string().required("Working here since is required"),
  business_location: yup.string().required("Business Location is required"),
  experiance: yup.string().required("Experiance is required"),
  business_details: yup.string().required("Job Details is required"),

  company_name: yup.string().required("Company Name is required"),
});

export const UpdateJobProfileSchema = yup.object().shape({
  user_id: yup.string().required("First Name is required"),
  job_title: yup
    .string()
    .required("Job title is required")

    .matches("^[a-z A-Z]+$", "Enter proper job title"),
  working_here_since: yup.string().required("Working here since is required"),
  business_location: yup.string().required("Business Location is required"),
  experiance: yup.string().required("Experiance is required"),
  business_details: yup.string().required("Job Details is required"),
  company_name: yup.string().required("Company Name is required"),
});

export const eventSchema = yup.object().shape({
  event_name: yup.string().required("Enter your event name"),
  city_name: yup.string().required("Enter your city"),
  event_location: yup.string().required("Enter your event location"),
  event_detail: yup.string().required("Enter your event detail"),
  dummydate: yup.date().required("Enter your event date"),
  dummytime: yup.date().required("Enter your event time"),
});

export const trusteeSchema = yup.object().shape({
  user_id: yup.string().required("User must be selected"),
  from_year: yup.string().required("From year is required").nullable(),
  to_year: yup.string().required("To year is required").nullable(),
  designation_id: yup.string().required("Designation is required"),
});
export const trusteeEditSchema = yup.object().shape({
  designation_id: yup.string().required("Designation is required"),
});
export const familyAddressSchema = yup
  .object()
  .shape({
    // home_number:yup.number().required('Home number is required'),
    landmark: yup.string().required("Landmark is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    zip: yup.number().required("Zipcode is required"),
  })
  .required();

export const otpAuthenticationSchema = yup.object().shape({
  code: yup.number().required("OTP is required"),
});

export const updateUserSchema = yup.object().shape({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  // phone: yup.string().required("Phone is required"),

  // email: yup.string().required("email is required"),
  date_of_birth: yup.date().required("Enter birth date"),
  // education_id: yup.string().required("Enter your education"),
  // industry_id: yup.string().required("Enter your industry"),
});

export const nativeSchema = yup.object().shape({
  name: yup.string().required("Native is required"),
});

export const communityMeetingSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  place: yup.string().required("Place is required"),
  description: yup.string().required("Description is required"),
  meeting_date: yup.string().required("Year is required").nullable(),
});

export const designationSchema = yup.object().shape({
  designation_title: yup.string().required("Designation title is required"),

  // designation_parent:yup.string().required('Designation Parent is required'),
  // level:yup.string().required('Level is required')
});
export const educationSchema = yup.object().shape({
  name: yup.string().required("Education name is required"),
});

export const industrySchema = yup.object().shape({
  name: yup.string().required("Industry name is required"),
});

export const settingsSchema = yup.object().shape({
  name: yup.string().required("Community name is required"),
  description: yup
    .string()
    .required("Community description is required")
    .min(70, "Description must be atleast of 70 letters")
    .nullable("Hello"),
});
export const contactSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().required("Email is required"),
  community_name: yup.string().required("Community is required"),
  description: yup.string().required("Description is required"),
});

export const otpSchema = yup.object().shape({
  code: yup
    .string()
    .max(4, "Otp is invalid")
    .required("Enter otp code")
    .matches("^[0-9]{4}$", "Enter proper otp"),
});

export const documentSchema = yup.object().shape({
  file_name: yup.string().required("File name is required"),
});
export const communityCardSchemaNew = yup.object().shape({
  name: yup.string().required("Card name is required"),
  expiry_date: yup.string().required("Expiry date is required").nullable(),
  card_type: yup.string().required("Card type is required"),
});

export const communityCardSchema = yup.object().shape({
  name: yup.string().required("Card name is required"),
  expiry_date: yup.string().required("Expiry date is required").nullable(),
  card_type: yup.string().required("Card type is required"),
  description: yup.string().required("Description is required"),
});

export const ticketSchema = yup.object().shape({
  name: yup.string().required("Ticket name is required"),
});
