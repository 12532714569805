import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Typography,
  Avatar,
  Button,
  Modal,
  Select,
  Spin,
  message,
} from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { getUserByIdAction } from '../../Actions/authenticationAction';
import { useDispatch, useSelector } from 'react-redux';
import { updateFamilyMemberOwnershipAction } from '../../Actions/familyMemberAction';
import { Controller, useForm } from 'react-hook-form';
import './../../FindThePeoplePage/FindOtherPeople.css';
import { getFamilies } from '../../Actions/familyAction';
import { newImageUrl } from '../CommonLogics/CommonMethods';
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom.min';


const MemberCard = (props) => {
  const {
    member,
    familyId,
    onClickMethod,
    isPeopleDetailsVisible,
    isOwnershipModalVisible,
    setIsOwnershipModalVisible,
    MemberData,
    allDeletedUserFamilies,
    isOwner,
  } = props;
  const { id: memberId, user, user_image } = member;

  const backgroundColor = '#' + Math.floor(Math.random() * 1677).toString(14);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleUpdateUserByIdMethod = (UserId) => {
    dispatch(getUserByIdAction(UserId));
  };
  const { isOwnerLoading } = useSelector((state) => {
    const { allFamilies } = state.family;
    const { isOwnerLoading } = state.familyMember;
    return {
      allFamilies,
      isOwnerLoading,
    };
  });

  const userFamilies = {
    label: member.user.first_name + ' ' + member.user.last_name,
    value: member.user.id,
  };
  const userMember = _.toArray(userFamilies);
  const userOldDays =
    (user?.birth_year === null
      ? 'Edit your data'
      : moment(
          user.birth_year + '-' + user.birth_month + '-' + user.birth_date
        ).fromNow('years old')) === 'Invalid date'
      ? 'Few days'
      : user?.birth_year === null
      ? 'Edit your data'
      : moment(
          user.birth_year + '-' + user.birth_month + '-' + user.birth_date
        ).fromNow('years old');

  const [newUserImage, SetNewUserImage] = useState(null);

  useEffect(() => {
    (async function () {
      if (user_image.image) {
        const url = await newImageUrl(user_image.image);
        SetNewUserImage(url);
      }
    })();
  }, [user_image.image]);

  return (
    <>
      <Row justify='space-between'>
        <Col
          onClick={() =>
            isPeopleDetailsVisible
              ? handleUpdateUserByIdMethod(user?.id)
              : onClickMethod(memberId, familyId, user?.id)
          }
          md={isOwner ? 10 : 24}
          xl={isOwner ? 13 : 24}
          lg={isOwner ? 12 : 24}
          sm={isOwner ? 12 : 24}
          xs={15}
          style={{
            pointerEvents:
              allDeletedUserFamilies &&
              allDeletedUserFamilies.length > 0 &&
              'none',
          }}
        >
          <Row gutter={[8, 0]} style={{ cursor: 'pointer', marginTop: '10px' }}>
            <Col>
              <Avatar
                size={40}
                style={{
                  cursor: 'pointer',
                  backgroundColor: backgroundColor,
                }}
                src={newUserImage}
              >
                {user.first_name?.[0]}
              </Avatar>
            </Col>
            <Col xs={18} xl={18}>
              <Row gutter={[0, 5]}>
                <Col xs={24}>
                  <Typography
                    className='memberName'
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {user.first_name}
                    {user.gender === null ? '' : '('}
                    {user.gender === null
                      ? ''
                      : user.gender === 'male'
                      ? 'M'
                      : user.gender === 'female'
                      ? 'F'
                      : null}
                    {user.gender === null ? '' : ')'}
                  </Typography>
                </Col>
                <Col xs={24}>
                  <Typography className='memberStyle'>
                    {userOldDays}&nbsp;
                    {user?.birth_year !== null && 'old'}
                  </Typography>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        {isOwner && (
          <Col>
            <Row gutter={[0, 5]}>
              <Col span={24} />
              <Col span={24}>
                <Row justify='end'>
                  <Typography className='OwnershipTitle1'>
                    {t('content.226')}
                  </Typography>
                </Row>
              </Col>
              <Col span={24}></Col>
            </Row>
          </Col>
        )}
      </Row>
      <OwnershipModal
        isOwnershipModalVisible={isOwnershipModalVisible}
        userMember={userMember}
        setIsOwnershipModalVisible={setIsOwnershipModalVisible}
        familyId={familyId}
        dispatch={dispatch}
        updateFamilyMemberOwnershipAction={updateFamilyMemberOwnershipAction}
        isOwnerLoading={isOwnerLoading}
        getFamilies={getFamilies}
        MemberData={MemberData}
      />
    </>
  );
};
export default React.memo(MemberCard);

const OwnershipModal = (props) => {
  const { t } = useTranslation();
  const {
    isOwnershipModalVisible,
    setIsOwnershipModalVisible,
    familyId,
    dispatch,
    updateFamilyMemberOwnershipAction,
    isOwnerLoading,
    getFamilies,
    MemberData,
  } = props;
  const { handleSubmit, control } = useForm({});
  const handleOwnershipMethodForAction = (data) => {
    data.id = familyId;
    dispatch(updateFamilyMemberOwnershipAction(data, successOwnershipMethod));
  };
  const successOwnershipMethod = () => {
    dispatch(getFamilies());
    setIsOwnershipModalVisible(false);
    setTimeout(() => {
      message.success(t('content.312'));
    }, 1000);
  };
  return (
    <Modal
      open={isOwnershipModalVisible}
      title='Change Ownership'
      onCancel={() => setIsOwnershipModalVisible(false)}
      footer={null}
    >
      <Spin spinning={isOwnerLoading} size='large'>
        <form onSubmit={handleSubmit(handleOwnershipMethodForAction)}>
          <Col>
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Typography className='OwnerModalTitle'>
                  Select Family Member
                </Typography>
              </Col>
              <Col span={24}>
                <Controller
                  as={
                    <Select
                      placeholder='Select user'
                      style={{ width: '100%', borderRadius: '4px' }}
                      size='large'
                      showSearch={true}
                      options={MemberData}
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    ></Select>
                  }
                  className='inputLabel'
                  name='user_id'
                  control={control}
                />
              </Col>
              <Col span={24}>
                <Row justify='end'>
                  <Button htmlType='submit' className='allButtons'>
                    Assign Ownership
                  </Button>
                </Row>
              </Col>
            </Row>
          </Col>
        </form>
      </Spin>
    </Modal>
  );
};
