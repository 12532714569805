import API from "../Utils/API";
export const getTemplatesAction = (url) => (dispatch) => {
  dispatch({ type: "GET_TEMPLATES_REQUEST" });
  API.get(url)
    .then((response) => {
      dispatch({ type: "GET_TEMPLATES_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_TEMPLATES_FAILURE",
        payload: error.response.data,
      });
    });
};

export const createTemplatesAction =
  (data, success, failureCallback, IsEmpty) => (dispatch) => {
    dispatch({ type: "CREATE_TEMPLATES_REQUEST", payload: data });
    let formData = new FormData();
    formData.append("template[published]", data.published);
    formData.append("template[template_title]", data.template_title);
    formData.append("template[template_type]", data.template_type);
    {
      IsEmpty
        ? formData.append("template_image", data.template_image)
        : formData.append("template[template_image]", data.template_image);
    }
    API.post("api/templates", formData)
      .then((response) => {
        dispatch({
          type: "CREATE_TEMPLATES_SUCCESS",
          payload: response.data,
        });
        success();
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_TEMPLATES_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };

export const deleteTemplatesAction = (Id, successCallback) => (dispatch) => {
  dispatch({ type: "DELETE_TEMPLATES_REQUEST" });
  API.delete(`api/templates/${Id}`)
    .then((response) => {
      dispatch({
        type: "DELETE_TEMPLATES_SUCCESS",
        payload: response.data,
      });
      successCallback && successCallback();
    })
    .catch((error) => {
      dispatch({
        type: "DELETE_TEMPLATES_FAILURE",
        payload: error.response.data,
      });
    });
};

export const updateTemplatesAction =
  (data, successCallback, failureCallback, IsEmpty) => (dispatch) => {
    dispatch({ type: "UPDATE_TEMPLATES_REQUEST" });
    let formData = new FormData();

    formData.append("template[template_image]", data.template_image);
    formData.append("template[published]", data.published);
    formData.append("template[template_title]", data.template_title);

    {
      IsEmpty
        ? formData.append("template_image", data.template_image)
        : formData.append("template[template_image]", data.template_image);
    }
    // formData.append("template[template_type]", data.template_type);
    API.put(`api/templates/${data.id}`, formData)
      .then((response) => {
        dispatch({
          type: "UPDATE_TEMPLATES_SUCCESS",
          payload: response.data,
        });

        successCallback && successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_TEMPLATES_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };
export const getTemplatesById = (Id) => (dispatch) => {
  dispatch({ type: "GET_TEMPLATES_BY_ID_REQUEST" });
  API.get(`api/templates/${Id}`)
    .then((response) => {
      dispatch({ type: "GET_TEMPLATES_BY_ID_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_TEMPLATES_BY_ID_FAILURE",
        payload: error?.response.data,
      });
    });
};
