import React, { useEffect, useCallback } from 'react';
import { Row, Col, Typography } from 'antd';
import '../../Events/Events.css';
import { useDispatch, useSelector } from 'react-redux';
import UnderMaintenenceImage from '../../assets/UnderMaintenenceImage.png';
import '../../FindThePeoplePage/FindOtherPeople.css';
import moment from 'moment';
import './UnderMaintainence.css';
import { getUnderMaintenanceAction } from '../../Actions/underMaintenanceAction';
import Countdown from 'react-countdown';
const UnderMaintainence = () => {
  const dispatch = useDispatch();

  const dispatchUnderMaintenanceAction = useCallback(() => {
    dispatch(getUnderMaintenanceAction());
  }, [dispatch]);

  useEffect(() => {
    dispatchUnderMaintenanceAction();
  }, [dispatchUnderMaintenanceAction]);

  const { allUnderMaintenanceWindows } = useSelector((state) => {
    const { allUnderMaintenanceWindows } = state.underMaintenance;
    return {
      allUnderMaintenanceWindows,
    };
  });
  const current = moment.utc().format();
  var a = current; //now
  var b = moment(allUnderMaintenanceWindows[0]?.end_time);
  const milliseconds = b.diff(a, 'minutes') * 60 * 1000;

  return (
    <Col
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Row justify='center'>
        <Col span={24}>
          <Row justify='center'>
            <Col>
              <img src={UnderMaintenenceImage} width={400} alt='' />
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row justify='center'>
            <Col>
              <Typography className='MainText'>
                {allUnderMaintenanceWindows[0]?.description}
              </Typography>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row justify='center' gutter={[0, 16]}>
            <Col span={24} />
            <Col>
              <Typography className='SubText' data-testid='UnderMaintainence-1'>
                We will be back in{' '}
                <Countdown date={Date.now() + milliseconds} />
              </Typography>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};
export default UnderMaintainence;
