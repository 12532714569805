import React, { useEffect } from 'react';
import { Row, Col, Typography, Card, Image, Button } from 'antd';
import LandingPageHeader from './LandingPageHeader';
import './LandingPage.css';
import '../TermsAndConditions/TermsAndConditions.css';
import { useBreakpoints } from '../CommonLogics/ResponsiveBreakPoints';
import { CalendarOutlined, ShoppingOutlined } from '@ant-design/icons';
import { history } from '../../Utils/history';
import './Faqs.css';
import './Blogs.css';
import Footer from './Footer';
import HelpfultipsBlogs from '../../assets/HelpfultipsBlogs.png';
import newsBlogs from '../../assets/newsBlogs.png';

const Blogs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.querySelector('body').scrollTo(0, 0);
  }, []);
  const cardArray = [
    {
      name: "HOW TO's",
      image: HelpfultipsBlogs,
      url: '/howto',
    },
    {
      name: 'NEWS',
      image: newsBlogs,
      url: '/news',
    },
  ];
  const { IsMD, IsSM, IsXS, IsLG, IsXL, IsXXL } = useBreakpoints();

  return (
    <Col className='MainbackgroundColor'>
      <Col className='TermsAndConditionsImageContainer'>
        <LandingPageHeader />
        <Row
          style={{ height: '100%', padding: (IsXS || IsSM) && '100px' }}
          justify='center'
          align='middle'
        >
          <Typography className='TermsAndConditionsH1'>Blogs</Typography>
        </Row>
      </Col>
      <Col
        className='LandingPageCardResponsive'
        style={{ background: '#FFFFFF', borderRadius: '50px 50px 0 0' }}
      >
        <Row justify='center' align='middle' style={{ marginTop: '100px' }}>
          {cardArray?.map((val, i) => {
            return (
              <Col xs={20} sm={15} md={7} lg={7} xl={6} key={i}>
                <Card
                  // className='landingPageCard'
                  style={{
                    border: 'none',
                  }}
                  onClick={() => history.push(`${val?.url}`)}
                >
                  <Row gutter={[0, 10]} justify='center'>
                    <Col style={{ position: 'relative' }}>
                      <Image
                        src={val?.image}
                        preview={false}
                        className='blogsimage'
                      />
                    </Col>
                    <Col
                      className='BlogsButton'
                      style={{ position: 'relative' }}
                    >
                      <Typography
                        className='BlogsButtonText'
                        style={{
                          color: 'white',
                          textAlign: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {val.name}
                      </Typography>
                    </Col>
                  </Row>
                </Card>
              </Col>
            );
          })}
        </Row>
        <Row justify='center'>
          <Col>
            <Footer />
          </Col>
        </Row>
      </Col>
    </Col>
  );
};

export default Blogs;
