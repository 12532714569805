import constants from '../Utils/constants';

const initialState = {
  key: null,
};

export const footerRedirectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_FOOTER_REDIRECTION_KEY_TYPE:
      return {
        ...state,
        key: action.payload,
      };
    default:
      return state;
  }
};
