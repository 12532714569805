import React, { useState } from 'react';
import {
  Row,
  Col,
  Typography,
  Divider,
  Card,
  Avatar,
  Popconfirm,
  message,
  Tooltip,
} from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { getEvents } from '../Actions/eventsAction';
import EventsModal from './EventsModal';
import { useDispatch } from 'react-redux';
import './EventCard.css';
import { deleteEvents } from '../Actions/eventsAction';
import { useTranslation } from 'react-i18next';
import EventsEmptyScreen from '../assets/EventsEmptyScreen.svg';
import { newImageUrl } from '../Screens/CommonLogics/CommonMethods';
import { analytics } from '../components/Mixpanel/Mixpanel';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(advancedFormat);

const EventCard = (props) => {
  const {
    eventdate,
    eventname,
    eventlocation,
    eventdetails,
    cityname,
    dateCustomFormat,
    time,
    inNextDays,
    currentInNextDays,
    eventId,
    amPm,
    isUpdateEventsLoading,
    event,
    updateDateTime,
    currentDate,
    setCommunityId,
    dateExample1,
    difference,
    users_types,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [updateModal, setUpdateModal] = useState(false);
  const deleteEvent = () => {
    dispatch(deleteEvents(eventId, successDelete));
  };
  const successDelete = () => {
    analytics?.track('Event_Deleted');
    dispatch(getEvents(setCommunityId));
    setTimeout(() => {
      message.success(t('content.144'));
    }, 1000);
  };
  const updateModalOpen = () => {
    setUpdateModal(true);
  };
  const isUserTrustee = users_types.includes('trustee');
  const isUserAdmin = users_types.includes('admin');
  const EventDate = dayjs(dateCustomFormat).format('Do MMMM YYYY');
  return (
    <Row>
      <Col xl={24}>
        <Divider orientation='left' className='Divider'>
          {EventDate === 'Invalid Date' ? null : EventDate}
        </Divider>
        <Row>
          <Col xl={17} lg={19}>
            <Row justify='end' align='middle'>
              {EventDate === 'Invalid Date' ? null : (
                <Col className='nextbackground'>
                  {currentInNextDays ? (
                    <Typography className='innext'>Event today</Typography>
                  ) : (
                    <Typography className='innext'>
                      {currentDate ? (
                        <Col>{t('content.223')}</Col>
                      ) : (
                        <Col>
                          {difference > 0 && t('content.112')} {inNextDays}
                          {difference < 0 && t('content.224')}
                        </Col>
                      )}
                    </Typography>
                  )}
                </Col>
              )}
            </Row>
            <Card
              className='zeroPaddingCard'
              style={{
                boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.12)',
                borderRadius: '4px',
              }}
            >
              <Row gutter={[0, 14]}>
                <Col span={24}>
                  <Avatar
                    shape='square'
                    style={{
                      width: '100%',
                      height: '200px',
                      backgroundColor: '#F3F3F6',
                    }}
                    src={newImageUrl(event?.event_image_key)}
                  >
                    <img src={EventsEmptyScreen} alt=''></img>
                  </Avatar>
                </Col>
                <Col span={24} />
              </Row>

              {(isUserTrustee || isUserAdmin) && (
                <Col span={23}>
                  <Row justify='end' gutter={[30, 0]}>
                    <Col>
                      <Tooltip title={t('btn.27')}>
                        <EditOutlined onClick={() => updateModalOpen()} />
                      </Tooltip>
                      <EventsModal
                        updateModal={updateModal}
                        setUpdateModal={setUpdateModal}
                        eventId={eventId}
                        isUpdateEventsLoading={isUpdateEventsLoading}
                        eventname={eventname}
                        eventlocation={eventlocation}
                        cityname={cityname}
                        event={event}
                        updateDateTime={updateDateTime}
                        time={time}
                        setCommunityId={setCommunityId}
                        dateExample1={dateExample1}
                        AmPm={amPm}
                        eventdate={eventdate}
                      />
                    </Col>
                    <Col>
                      <Popconfirm
                        onConfirm={() => deleteEvent()}
                        title={t('btn.29')}
                        okText={t('btn.28')}
                        placement='topRight'
                        cancelText={t('btn.19')}
                      >
                        <Tooltip title={t('btn.28')}>
                          <DeleteOutlined style={{ cursor: 'pointer' }} />
                        </Tooltip>
                      </Popconfirm>
                    </Col>
                  </Row>
                </Col>
              )}
              <Row>
                <Col span={1}></Col>
                <Col span={20}>
                  <Row gutter={[0, 14]}>
                    <Col span={24}>
                      <Typography className='Eventtitle'>
                        {eventname}-{cityname}
                      </Typography>
                    </Col>
                    <Col span={24}>
                      <Row>
                        <Col>
                          <Typography className='Eventlocation'>
                            {t('content.114')} : &nbsp;
                            <Typography
                              className='Eventlocation1'
                              component='span'
                            >
                              {eventlocation}
                            </Typography>
                          </Typography>
                        </Col>
                        <Col>
                          <Typography className='Eventlocation1'>
                            ({time})
                          </Typography>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Card className='eventdetailsbackground'>
                        <Typography className='Eventlocation'>
                          {t('content.11')} : &nbsp;
                          <Typography
                            className='Eventlocation1'
                            component='span'
                          >
                            {eventdetails}
                          </Typography>
                        </Typography>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default EventCard;
