import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Typography, Tabs, Select, Modal, Empty } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import DocumentsCard from './DocumentsCard';
import { getDocuments } from '../../Actions/documentsAction';
import MeetingCard from './MeetingCard';
import { getCommunityMeetings } from '../../Actions/communityMeetingsAction';
import { getTrustees } from '../../Actions/trusteesAction';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import '../../FindThePeoplePage/FindOtherPeople.css';
import PdfViewer from './PdfViewer';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { analytics } from '../../components/Mixpanel/Mixpanel';
import Trusteecard from '../TrusteeView/Trusteecard';

const Information = () => {
  const {
    setCommunityId,
    allDocuments,
    allMeetings,
    isCommunityMeetingsLoading,
    allTrustees,
  } = useSelector((state) => {
    const { allTrustees } = state.trustees;
    const { setCommunityId } = state.communities;
    const { allDocuments } = state.documents;
    const { allMeetings, isCommunityMeetingsLoading } = state.communityMeetings;
    return {
      allTrustees,
      setCommunityId,
      allDocuments,
      allMeetings,
      isCommunityMeetingsLoading,
    };
  });

  const dispatch = useDispatch();
  const options = [];
  const d = new Date();
  const earliestYear = 2000;
  let y = d.getFullYear();
  const { t } = useTranslation();
  const [documentYear, setDocumentYear] = useState(y);
  const [tabKey, setTabkey] = useState('1');
  const [selectedDocument, setSelectedDocument] = useState({});
  const [isDocumentVisible, setIsDocumentVisible] = useState(false);

  for (let i = earliestYear; i <= y; i++) {
    options.push({
      label: i,
      value: i,
    });
  }
  const onTabClick = (key) => {
    if (key === '1') {
      analytics?.track('Info_Documents');
    } else if (key === '2') {
      analytics?.track('Info_Meetings');
    }
    setTabkey(key);
  };
  const height = window.innerHeight - 64;
  const isPublishMeeting = _.filter(allMeetings, ['published', true]);
  const sortedMeetings = _.orderBy(isPublishMeeting, 'meeting_date', ['desc']);
  const items = [
    {
      label: (
        <Button
          className={tabKey === '1' ? 'selectedButton' : 'unselectedButton'}
        >
          {t('content.233')}
        </Button>
      ),
      key: '1',
    }, // remember to pass the key prop
    {
      label: (
        <Button
          className={tabKey === '2' ? 'selectedButton' : 'unselectedButton'}
        >
          {t('content.254')}
        </Button>
      ),
      key: '2',
    },
    {
      label: (
        <Button
          className={tabKey === '3' ? 'selectedButton' : 'unselectedButton'}
        >
          {t('btn.53')}
        </Button>
      ),
      key: '3',
    },
  ];

  useEffect(() => {
    dispatch(getDocuments(documentYear));
    dispatch(getCommunityMeetings(documentYear));
    dispatch(getTrustees());
  }, [dispatch, documentYear, setCommunityId]);

  return (
    <>
      <Col
        style={{
          height: height,
          overflow: 'hidden',
          overflowY: 'scroll',
        }}
        className='customLayout'
      >
        <LoadingIndicator loading={isCommunityMeetingsLoading}>
          <Row justify='space-around'>
            <Col span={23}>
              <Row style={{ marginTop: '39px' }}>
                <Col xl={6} xxl={6} lg={6} sm={8} md={8} xs={24}>
                  <Row gutter={[0, 16]}>
                    <Col span={24}>
                      <Typography className='DocumentsTitle3'>
                        {t('content.234')}
                      </Typography>
                    </Col>
                    <Col span={24}>
                      <Select
                        placeholder='Select financial year'
                        name='yearSelector'
                        defaultValue='2022'
                        style={{ width: '100%' }}
                        onChange={(e) => {
                          analytics?.track('Info_SelectYear');
                          setDocumentYear(e);
                        }}
                        size='large'
                        className='Heading'
                        options={options}
                      />
                    </Col>
                    <Col>
                      <Tabs
                        defaultActiveKey='1'
                        onTabClick={onTabClick}
                        animated={false}
                        renderTabBar={(props, DefaultTabBar) => (
                          <DefaultTabBar {...props} className='cMTabsBckground'>
                            {(node) => (
                              <Col key={node.key} index={node.key}>
                                {node}
                              </Col>
                            )}
                          </DefaultTabBar>
                        )}
                        items={items}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              {tabKey === '1' &&
                (isCommunityMeetingsLoading ? null : allDocuments?.length >
                  0 ? (
                  <Row gutter={[24, 24]}>
                    {allDocuments?.map((Documents, i) => {
                      return (
                        <Col xl={11} lg={15} xs={24} md={16} key={i}>
                          <DocumentsCard
                            Documents={Documents}
                            setIsDocumentVisible={setIsDocumentVisible}
                            setSelectedDocument={setSelectedDocument}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <Row justify='center' className='InformationContainer'>
                    <Empty
                      description={
                        <Typography className='empty-details'>
                          {t('content.288')}
                        </Typography>
                      }
                      image={
                        <InfoCircleOutlined style={{ fontSize: '50px' }} />
                      }
                    ></Empty>
                  </Row>
                ))}
              {tabKey === '2' &&
                (sortedMeetings.length > 0 ? (
                  <Row gutter={[24, 24]}>
                    {sortedMeetings?.map((Meetings, i) => {
                      return (
                        <Col span={11} key={i}>
                          <MeetingCard Meetings={Meetings} />
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <Row justify='center' className='InformationContainer'>
                    <Empty
                      description={
                        <Typography className='empty-details'>
                          No {t('content.254')} Yet
                        </Typography>
                      }
                      image={
                        <InfoCircleOutlined style={{ fontSize: '50px' }} />
                      }
                    ></Empty>
                  </Row>
                ))}
              {tabKey === '3' &&
                (allTrustees.length > 0 ? (
                  <Row gutter={[24, 24]}>
                    <Col span={24}></Col>
                    {allTrustees?.map((trustee, i) => {
                      return (
                        <Col xl={12} lg={16} md={16} sm={20} key={i} xxl={9}>
                          <Trusteecard
                            trustee={trustee?.user}
                            trusteeArray={trustee}
                            setCommunityId={setCommunityId}
                            i={i}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <Row justify='center' className='InformationContainer'>
                    <Empty
                      description={
                        <Typography className='empty-details'>
                          {t('emptyScreen.0')}
                        </Typography>
                      }
                      image={
                        <InfoCircleOutlined style={{ fontSize: '50px' }} />
                      }
                    ></Empty>
                  </Row>
                ))}
            </Col>
          </Row>
        </LoadingIndicator>
      </Col>
      <Modal
        open={isDocumentVisible}
        onCancel={() => setIsDocumentVisible(false)}
        width={800}
        footer={null}
      >
        <Col>
          <PdfViewer
            selectedDocument={selectedDocument}
            isHelpAndSupport={false}
          />
        </Col>
      </Modal>
    </>
  );
};
export default Information;
