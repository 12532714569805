import React, { useEffect } from 'react';
import { Row, Col, Typography } from 'antd';
import LandingPageHeader from './LandingPageHeader';
import './LandingPage.css';
import '../TermsAndConditions/TermsAndConditions.css';
import './Faqs.css';
import Footer from './Footer';

const Disclaimer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.querySelector('body').scrollTo(0, 0);
  }, []);
  return (
    <Col className='MainbackgroundColor'>
      <Col className='TermsAndConditionsImageContainer'>
        <LandingPageHeader />
        <Row style={{ height: '100%' }} justify='center' align='middle'>
          <Typography className='TermsAndConditionsH1'>Disclaimer</Typography>
        </Row>
      </Col>
      <Col
        style={{
          background: '#FFFFFF',
          borderRadius: '50px 50px 0 0',
          marginTop: '100px',
        }}
      >
        <Row justify='center'>
          <Col className='ContentContainer'>
            <Row gutter={[0, 30]}>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  <b>Disclaimer for Samaj</b>
                </Typography>
              </Col>

              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  If you require any more information or have any questions
                  about our site's disclaimer, please feel free to contact us by
                  email at hello@defx.in
                </Typography>
              </Col>

              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  <b>Disclaimers for Samaj</b>
                </Typography>
              </Col>

              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  All the information on this website - getsamaj.com - is
                  published in good faith and for general information purpose
                  only. Samaj does not make any warranties about the
                  completeness, reliability and accuracy of this information.
                  Any action you take upon the information you find on this
                  website (Samaj), is strictly at your own risk. Samaj will not
                  be liable for any losses and/or damages in connection with the
                  use of our website. Our Disclaimer was generated with the help
                  of the Disclaimer Generator.
                </Typography>
              </Col>

              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  From our website, you can visit other websites by following
                  hyperlinks to such external sites. While we strive to provide
                  only quality links to useful and ethical websites, we have no
                  control over the content and nature of these sites. These
                  links to other websites do not imply a recommendation for all
                  the content found on these sites. Site owners and content may
                  change without notice and may occur before we have the
                  opportunity to remove a link which may have gone 'bad'.
                </Typography>
              </Col>

              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  Please be also aware that when you leave our website, other
                  sites may have different privacy policies and terms which are
                  beyond our control. Please be sure to check the Privacy
                  Policies of these sites as well as their "Terms of Service"
                  before engaging in any business or uploading any information.
                </Typography>
              </Col>

              <Col span={24}>
                <Typography className='TermsAndConditionsContentTitle1'>
                  <b>Consent</b>
                </Typography>
              </Col>

              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  By using our website, you hereby consent to our disclaimer and
                  agree to its terms.
                </Typography>
              </Col>

              <Col span={24}>
                <Typography className='TermsAndConditionsContentTitle1'>
                  <b>Update</b>
                </Typography>
              </Col>

              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  Should we update, amend or make any changes to this document,
                  those changes will be prominently posted here.
                </Typography>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify='center'>
          <Col>
            <Footer />
          </Col>
        </Row>
      </Col>
    </Col>
  );
};
export default Disclaimer;
