import React, { useCallback, useEffect } from 'react';
import { Button, Col, Result } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { history } from '../Utils/history';
import { getUnderMaintenanceAction } from '../Actions/underMaintenanceAction';

const EmptyScreen = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, allUnderMaintenanceWindows } = useSelector(
    (state) => {
      const { isAuthenticated } = state.authentication;
      const { allUnderMaintenanceWindows } = state.underMaintenance;

      return {
        isAuthenticated,
        allUnderMaintenanceWindows,
      };
    }
  );

  const GetUnderMaintenanceAction = useCallback(() => {
    dispatch(getUnderMaintenanceAction());
  }, [dispatch]);
  useEffect(() => {
    GetUnderMaintenanceAction();
  }, [GetUnderMaintenanceAction]);

  const HandleRouteMethod = () => {
    history.push(isAuthenticated ? '/add_Family' : '/');
  };
  const isUnderMaintenanceEnded =
    isAuthenticated && allUnderMaintenanceWindows?.length === 0
      ? false
      : allUnderMaintenanceWindows?.length === 0
      ? true
      : false;
  return (
    <Col
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Result
        status={isUnderMaintenanceEnded ? 'success' : '403'}
        title={isUnderMaintenanceEnded ? 'Samaj is live!' : '404'}
        subTitle={
          isUnderMaintenanceEnded ? 'Thank you for waiting' : 'Page not found'
        }
        extra={
          <Button type='primary' onClick={() => HandleRouteMethod()}>
            Back Home
          </Button>
        }
      />
    </Col>
  );
};

export default EmptyScreen;
