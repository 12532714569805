import React, { useEffect } from "react";
import { Row, Col, Empty, Typography } from "antd";
import "../../Events/Events.css";
import Trusteecard from "./Trusteecard";
import { getTrustees } from "../../Actions/trusteesAction";
import { useDispatch, useSelector } from "react-redux";
import TrusteeEmptyScreen from "../../assets/TrusteeEmptyScreen.svg";
import "./Trusteecard.css";
import { useTranslation } from "react-i18next";
import "../../FindThePeoplePage/FindOtherPeople.css";
import "./../AddFamilymember/Addfamily.css";
import { history } from "../../Utils/history";
import { getCommunityById } from "../../Actions/communitiesAction";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";

const Trustee = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    allTrustees,
    isGetTrusteesLoading,
    isUpdateTrusteesLoading,
    setCommunityId,
    currentUser,
    community,
  } = useSelector((state) => {
    const {
      allTrustees,
      isGetTrusteesLoading,
      isCreateTrusteesLoading,
      isUpdateTrusteesLoading,
    } = state.trustees;
    const { setCommunityId } = state.communities;
    const { currentUser } = state.authentication;
    const { community } = state.communities;
    return {
      allTrustees,
      isGetTrusteesLoading,
      isCreateTrusteesLoading,
      isUpdateTrusteesLoading,
      setCommunityId,
      currentUser,
      community,
    };
  });
  const isTrusteeVisibleByAdmin = community?.show_trustee;
  const redirectMethod = () => {
    history.push("/add_family");
  };
  const localStorageCommunityId = localStorage.getItem("community_id");
  useEffect(() => {
    dispatch(getTrustees());
    dispatch(
      getCommunityById(
        localStorageCommunityId ? localStorageCommunityId : setCommunityId
      )
    );
    if (
      currentUser?.verified === false ? true : isTrusteeVisibleByAdmin === false
    ) {
      redirectMethod();
    }
  }, [
    dispatch,
    isTrusteeVisibleByAdmin,
    setCommunityId,
    currentUser?.verified,
    localStorageCommunityId,
  ]);
  const height = window.innerHeight - 64;
  const { users_role, users_types } = currentUser || {};

  return (
    <>
      <LoadingIndicator loading={isGetTrusteesLoading}>
        <Col
          style={{
            height: height,
            overflow: "hidden",
            overflowY: "scroll",
            backgroundColor: "#FFFFFF",
          }}
          className="customLayout"
        >
          <Row gutter={[48, 40]} style={{ padding: "20px 20px" }}>
            <Col span={24}>
              <Row justify="end">
                <Col></Col>
              </Row>
            </Col>
            {!isGetTrusteesLoading && allTrustees.length <= 0 ? (
              <>
                <Col span={24} />
                <Col span={24} />
                <Col span={24} />

                <Col span={24}>
                  <Row justify="center">
                    <Empty
                      description={
                        <Typography className="empty-details">
                          {t("emptyScreen.0")}
                        </Typography>
                      }
                      image={TrusteeEmptyScreen}
                      imageStyle={{
                        height: 38,
                      }}
                    />
                  </Row>
                </Col>
              </>
            ) : (
              <>
                {allTrustees &&
                  allTrustees.map((val, i) => {
                    return (
                      <Col xl={12} lg={16} md={16} sm={20} key={i} xxl={9}>
                        <Trusteecard
                          trustee={val?.user}
                          trusteeArray={val}
                          isUpdateTrusteesLoading={isUpdateTrusteesLoading}
                          setCommunityId={setCommunityId}
                          users_role={users_role}
                          i={i}
                          users_types={users_types}
                        />
                      </Col>
                    );
                  })}
              </>
            )}
          </Row>
        </Col>
      </LoadingIndicator>
    </>
  );
};
export default Trustee;
