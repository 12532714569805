import API from "../Utils/API";

export const createMessageAction =
  (data, success, failureCallback) => (dispatch) => {
    dispatch({ type: "CREATE_MESSAGES_REQUEST", payload: data });
    let formData = new FormData();
    data.files.map((image) => {
      formData.append("message[files][]", image.originFileObj);
    });

    formData.append("message[ticket_id]", data.ticket_id);
    formData.append("message[description]", data.description);
    API.post("api/messages", formData)
      .then((response) => {
        dispatch({ type: "CREATE_MESSAGES_SUCCESS", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_MESSAGES_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };

export const deleteFileAction = (Id, SuccessCallback) => (dispatch) => {
  dispatch({ type: "DELETE_FILE_REQUEST" });
  API.delete(`api/delete-file/${Id}`)
    .then((response) => {
      dispatch({
        type: "DELETE_FILE_SUCCESS",
        payload: response.data,
      });
      SuccessCallback && SuccessCallback();
    })
    .catch((error) => {
      dispatch({
        type: "DELETE_FILE_FAILURE",
        payload: error.response.data,
      });
    });
};
