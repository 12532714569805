import React, { useEffect, useState } from 'react';
import {
  Card,
  Row,
  Col,
  Popconfirm,
  Tooltip,
  Button,
  Typography,
  Divider,
  Popover,
  message,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  UserAddOutlined,
  MoreOutlined,
  HomeOutlined,
  PlusCircleTwoTone,
} from '@ant-design/icons';
import MemberCard from './MemberCard';
import AddFamilyMember from './addFamilyMemberModal';
import {
  deleteFamily,
  getFamilies,
  getFamilyByIdAction,
} from '../../Actions/familyAction';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { deleteFamilyAddressAction } from '../../Actions/familyAddressAction';
import AddAddressModal from './AddAddressModal';
import _ from 'lodash';
import { updateDeletedFamilyMemberAction } from '../../Actions/familyMemberAction';
import './Addfamily.css';
import { useBreakpoints } from '../CommonLogics/ResponsiveBreakPoints';
import { getDeletedUserFamilies } from '../../Actions/familyAction';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { falseyValueCases } from '../CommonLogics/CommonMethods';

const ViewFamilyDetails = (props) => {
  const {
    setSelectedFamilyId,
    setSelectedMemberId,
    setIsViewMemberDetails,
    setEditFamilyId,
    setIsEditFamilyModalVisible,
    setValue,
    secondColor,
    allDeletedUserFamilies,
  } = props;

  const {
    isCreateFamilyAddressLoading,
    isUpdateFamilyAddressLoading,
    isGetFamilyByIdLoading,
    userById,
    currentUser,
    Family,
  } = useSelector((state) => {
    const { isCreateFamilyAddressLoading, isUpdateFamilyAddressLoading } =
      state.familyAddresses;
    const { Family, isGetFamilyByIdLoading } = state.family;
    const { userById, currentUser } = state.authentication;
    return {
      isCreateFamilyAddressLoading,
      isUpdateFamilyAddressLoading,
      isGetFamilyByIdLoading,
      userById,
      currentUser,
      Family,
    };
  });

  useEffect(() => {
    dispatch(getFamilyByIdAction(FamilyProfileId));
  }, [FamilyProfileId]);

  const {
    id: familyIdFromObject,
    last_name,
    community_native,
    family_addresses,
    user_families,
  } = Family || [];
  const { id: FamilyProfileId } = useParams();
  const location = useLocation();
  const PathPartsArray = location.search.split('=');
  const isPeopleDetailsVisible = !(
    PathPartsArray[PathPartsArray.length - 1] === 'families'
  );
  const { IsXS } = useBreakpoints();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isOwner = Family?.owner_id === currentUser?.id;

  const handleCloseViewFamilyDetails = () => {
    history.back();
  };

  const [isAddFamilyMemberModalVisible, setIsAddFamilyMemberModalVisible] =
    useState(false);
  const [isAddAddressModalVisible, setIsAddAddressModalVisible] =
    useState(false);
  const [editFamilyAddressId, setEditFamilyAddressId] = useState('');
  const [isEditAddressModalVisible, setIsEditAddressModalVisible] =
    useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isEditPopoverVisible, setIsEditPopoverVisible] = useState(false);
  const [idForPopover, setIdForPopover] = useState('');
  const [myObj, setMyObj] = useState({});
  const [isOwnershipModalVisible, setIsOwnershipModalVisible] = useState(false);
  const FamilyAddress = _.filter(family_addresses, (address) => {
    return address.id === editFamilyAddressId;
  });
  const handleFamilyMemberModal = () => {
    setIsAddFamilyMemberModalVisible(true);
  };
  // const showMemberDetails = (memberId, familyId, UserId) => {
  //   setSelectedMemberId(memberId);
  //   setSelectedFamilyId(familyId);
  //   setIsViewMemberDetails(true);
  //   dispatch(getUserByIdAction(UserId));
  // };
  const handleEditFamily = () => {
    // setIsEditFamilyModalVisible(true);
    // setEditFamilyId(familyIdFromObject);
    // setValue('last_name', last_name);
    // setValue('community_native_id', community_native?.name);
  };
  const handleDeleteFamily = (familyIdFromObject) => {
    dispatch(deleteFamily(familyIdFromObject, deleteFamilyCallBack));
  };
  const deleteFamilyCallBack = () => {
    setTimeout(() => {
      message.success(t('content.127'));
    }, 1000);
  };
  const openAddAddressModal = () => {
    setIsAddAddressModalVisible(true);
    setIsPopoverVisible(false);
  };
  const openEditAddressModal = (familyIdFromVal, val) => {
    setEditFamilyAddressId(familyIdFromVal);
    setIsEditAddressModalVisible(true);
    setMyObj(val);
    setIsEditPopoverVisible(false);
  };
  const deleteFamilyAddressMethod = (FamilyAddressId) => {
    dispatch(
      deleteFamilyAddressAction(FamilyAddressId, successDeleteAddressMethod)
    );
  };
  const successDeleteAddressMethod = () => {
    dispatch(getFamilies());
    setTimeout(() => {
      message.success(t('content.130'));
    }, 1000);
  };
  const openEditPopover = (Id) => {
    setIsEditPopoverVisible(true);
    setIdForPopover(Id);
  };
  const MemberATFOP = _.filter(user_families, (Family) => {
    return Family.user.id !== userById?.id;
  });
  const { family_addresses: AddressFromFOP } = Family || [];
  const handleDeletedFamilyMember = (Id) => {
    dispatch(
      updateDeletedFamilyMemberAction(
        Id,
        updateDeletedFamilyMemberActionSuccessMethod
      )
    );
  };
  const updateDeletedFamilyMemberActionSuccessMethod = () => {
    dispatch(getDeletedUserFamilies());
    dispatch(getFamilies());
    setTimeout(() => {
      message.success('You successfully joined a family');
    }, 1000);
  };

  return (
    <LoadingIndicator loading={isGetFamilyByIdLoading}>
      <Row>
        <Col xl={23} lg={23} md={22} sm={22} xs={24}>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <ArrowLeftOutlined
              style={{ paddingBottom: '20px', fontSize: '20px' }}
              onClick={() => handleCloseViewFamilyDetails()}
            />
          </Col>
          {!isGetFamilyByIdLoading ? (
            !falseyValueCases(Family) ? (
              <Card
                style={{
                  borderRadius: '10px',
                  padding: '0px',
                  boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.12)',
                }}
              >
                <Row justify='space-between'>
                  <Col>
                    <Row gutter={[11, 0]}>
                      <Col>
                        <Typography className='title'>{last_name}</Typography>
                      </Col>

                      <Col>
                        {!IsXS && (
                          <Typography className='memberStyle'>
                            {t('welcomeText.9')} {user_families?.length}{' '}
                            {t('content.75')}
                          </Typography>
                        )}
                      </Col>
                    </Row>
                  </Col>

                  <Col>
                    <Row>
                      {!isPeopleDetailsVisible && isOwner && (
                        <Col>
                          <Tooltip title={t('btn.27')}>
                            <Button
                              icon={<EditOutlined />}
                              type='text'
                              onClick={() => handleEditFamily()}
                            />
                          </Tooltip>
                        </Col>
                      )}
                      <Col>
                        {isOwner && (
                          <Tooltip title={t('btn.28')}>
                            <Popconfirm
                              title='Are you sure you want to delete your family and family member'
                              onConfirm={() =>
                                handleDeleteFamily(familyIdFromObject)
                              }
                              okText={t('btn.28')}
                              cancelText={t('btn.19')}
                            >
                              <Button icon={<DeleteOutlined />} type='text' />
                            </Popconfirm>
                          </Tooltip>
                        )}
                      </Col>
                      {!isPeopleDetailsVisible && (
                        <Col>
                          <Popover
                            trigger='click'
                            open={isPopoverVisible}
                            placement='topRight'
                            onOpenChange={(visible) =>
                              setIsPopoverVisible(visible)
                            }
                            content={
                              <>
                                <Row>
                                  <Col>
                                    <Button
                                      icon={
                                        <PlusCircleTwoTone
                                          style={{ color: '#2ACCFF' }}
                                        />
                                      }
                                      onClick={() => openAddAddressModal()}
                                      type='link'
                                    >
                                      {t('btn.39')}
                                    </Button>
                                  </Col>
                                </Row>
                                <AddAddressModal
                                  isAddAddressModalVisible={
                                    isAddAddressModalVisible
                                  }
                                  setIsAddAddressModalVisible={
                                    setIsAddAddressModalVisible
                                  }
                                  isCreateFamilyAddressLoading={
                                    isCreateFamilyAddressLoading
                                  }
                                  familyIdFromObject={familyIdFromObject}
                                />
                              </>
                            }
                          >
                            <MoreOutlined
                              style={{ paddingTop: '8px', fontSize: '15px' }}
                              onClick={() => setIsPopoverVisible(true)}
                            />
                          </Popover>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>

                <Col>
                  <Typography className='familiyNumber'>
                    {isPeopleDetailsVisible
                      ? Family?.family_number
                      : Family?.isDeleted
                      ? Family?.family_number
                      : Family?.family_number}
                  </Typography>
                </Col>

                <Divider />
                {!isPeopleDetailsVisible &&
                  family_addresses?.map((val, i) => {
                    return (
                      <Row key={i}>
                        <Col span={4}>
                          <Typography>
                            {IsXS ? (
                              <HomeOutlined />
                            ) : (
                              <b>{t('content.19')} :</b>
                            )}{' '}
                          </Typography>
                        </Col>
                        <Col span={19}>
                          <Typography className='addressLine'>
                            {val.home_number} {val.address_line_1}{' '}
                            {val.address_line_2} {val.city} {val.landmark}{' '}
                            {val.state},{val.country} {val.zip}
                          </Typography>
                        </Col>

                        <Col span={1}>
                          <Popover
                            trigger='click'
                            open={
                              idForPopover === val.id && isEditPopoverVisible
                            }
                            onOpenChange={(visible) =>
                              setIsEditPopoverVisible(visible)
                            }
                            content={
                              <>
                                <Tooltip title={t('btn.27')}>
                                  <EditOutlined
                                    onClick={() =>
                                      openEditAddressModal(val.id, val)
                                    }
                                  />
                                </Tooltip>
                                <AddAddressModal
                                  editFamilyAddressId={editFamilyAddressId}
                                  isEditAddressModalVisible={
                                    isEditAddressModalVisible
                                  }
                                  setIsEditAddressModalVisible={
                                    setIsEditAddressModalVisible
                                  }
                                  familyAddressObject={FamilyAddress[0]}
                                  familyIdFromObject={familyIdFromObject}
                                  myObj={myObj}
                                  isUpdateFamilyAddressLoading={
                                    isUpdateFamilyAddressLoading
                                  }
                                  allFamilyAddress={family_addresses}
                                />
                                <Popconfirm
                                  title={t('btn.29')}
                                  onConfirm={() =>
                                    deleteFamilyAddressMethod(val.id)
                                  }
                                  okText={t('btn.28')}
                                  cancelText={t('btn.19')}
                                  placement='topLeft'
                                >
                                  <Tooltip title={t('btn.28')}>
                                    <DeleteOutlined
                                      style={{ paddingLeft: '10px' }}
                                    />
                                  </Tooltip>
                                </Popconfirm>
                              </>
                            }
                          >
                            <MoreOutlined
                              style={{ fontSize: '15px' }}
                              onClick={() => openEditPopover(val.id)}
                            />
                          </Popover>
                        </Col>

                        <Divider />
                      </Row>
                    );
                  })}
                {isPeopleDetailsVisible &&
                  AddressFromFOP &&
                  AddressFromFOP.length > 0 &&
                  AddressFromFOP?.map((val, i) => {
                    return (
                      <Row key={i}>
                        <Col span={4}>
                          <Typography>
                            {IsXS ? (
                              <HomeOutlined />
                            ) : (
                              <b>{t('content.19')} :</b>
                            )}{' '}
                          </Typography>
                        </Col>
                        <Col span={19}>
                          <Typography className='addressLine'>
                            {val.home_number} {val.address_line_1}{' '}
                            {val.address_line_2} {val.city} {val.landmark}{' '}
                            {val.state},{val.country} {val.zip}
                          </Typography>
                        </Col>
                        <Divider />
                      </Row>
                    );
                  })}
                <Row>
                  {(isPeopleDetailsVisible ? MemberATFOP : user_families)?.map(
                    (member, i) => {
                      const MemberData = user_families?.map((val) => {
                        return {
                          label: val.user.first_name + ' ' + val.user.last_name,
                          value: val.user.id,
                        };
                      });
                      const { user } = member;
                      const isOwner = member?.Family?.user_id === user?.id;
                      return (
                        <Col
                          xl={isOwner ? 24 : 12}
                          xs={24}
                          md={isOwner ? 24 : 10}
                          lg={isOwner ? 24 : 12}
                          sm={isOwner ? 24 : 12}
                          key={i}
                        >
                          <MemberCard
                            // onClickMethod={showMemberDetails}
                            member={member}
                            familyId={Family?.id}
                            setIsViewMemberDetails={setIsViewMemberDetails}
                            secondColor={secondColor}
                            isPeopleDetailsVisible={isPeopleDetailsVisible}
                            isOwnershipModalVisible={isOwnershipModalVisible}
                            setIsOwnershipModalVisible={
                              setIsOwnershipModalVisible
                            }
                            MemberData={MemberData}
                            allDeletedUserFamilies={allDeletedUserFamilies}
                            isOwner={isOwner}
                          />
                        </Col>
                      );
                    }
                  )}
                </Row>
                {!isPeopleDetailsVisible && (
                  <Col>
                    <Row
                      justify='center'
                      style={{ backgroundColor: '#ECF6FC', marginTop: '10px' }}
                    >
                      <Col>
                        <Button
                          type='link'
                          onClick={() =>
                            Family.isDeleted
                              ? handleDeletedFamilyMember(
                                  allDeletedUserFamilies[0].id
                                )
                              : handleFamilyMemberModal()
                          }
                          icon={<UserAddOutlined />}
                        >
                          {Family?.isDeleted ? 'Rejoin Family' : t('btn.38')}
                        </Button>
                        <AddFamilyMember
                          isAddFamilyMemberModalVisible={
                            isAddFamilyMemberModalVisible
                          }
                          setIsAddFamilyMemberModalVisible={
                            setIsAddFamilyMemberModalVisible
                          }
                          familyId={Family?.id}
                        />
                      </Col>
                    </Row>
                  </Col>
                )}
              </Card>
            ) : (
              <Row justify='center' align='middle'>
                <Col>{t('content.348')}</Col>
              </Row>
            )
          ) : null}
        </Col>
      </Row>
    </LoadingIndicator>
  );
};
export default ViewFamilyDetails;
