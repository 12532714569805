import React, { useEffect } from 'react';
import { Row, Col, Card, Typography, Divider, Avatar, PageHeader } from 'antd';
import '../FindOtherPeople.css';
import { useDispatch, useSelector } from 'react-redux';
import { businessProfileById } from '../../Actions/businessAction';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOutlined } from '@ant-design/icons';
import LoadingIndicator from '../../Screens/LoadingIndicator/LoadingIndicator';
const JobProfileDetails = (props) => {
  const { t } = useTranslation();
  const {
    setViewBusinessProfileId,
    viewBusinessProfileId,

    selectedJobProfile,
    isSelectedJobProfileVisible,
  } = props;
  const { businessProfile, isBusinessLoading } = useSelector((state) => {
    const { businessProfile, isBusinessLoading } = state.business;
    return {
      businessProfile,
      isBusinessLoading,
    };
  });
  const {
    company_name,
    job_title,
    working_here_since,
    business_location,
    experiance,
    business_details,
  } = businessProfile || {};

  const handleCloseBusinessProfile = () => {
    setViewBusinessProfileId('');
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(businessProfileById(viewBusinessProfileId));
  }, [dispatch, businessProfileById]);
  const secondColor = '#' + Math.floor(Math.random() * 1677).toString(14);
  const height = window.innerHeight - 400;

  return (
    <LoadingIndicator loading={isBusinessLoading}>
      <Col style={{ height: height, overflow: 'hidden', overflowY: 'scroll' }}>
        {isBusinessLoading ? null : (
          <Row justify='space-around'>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              {!isSelectedJobProfileVisible && (
                <ArrowLeftOutlined
                  style={{ paddingBottom: '20px', fontSize: '20px' }}
                  onClick={() => handleCloseBusinessProfile()}
                />
              )}
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Card className='profileDetails-card'>
                <Row justify='space-between' gutter={[0, { sm: 7 }]}>
                  <Col xl={4} lg={4} md={4} sm={24} xs={24}>
                    <Avatar
                      shape='square'
                      size='large'
                      style={{
                        width: '127px',
                        height: '125px',
                        background: secondColor,
                      }}
                      className='BusinessProfileAvatar'
                    >
                      {isSelectedJobProfileVisible
                        ? selectedJobProfile?.company_name
                            ?.match(/\b(\w)/g)
                            .join('')
                        : company_name?.match(/\b(\w)/g).join('')}
                    </Avatar>
                  </Col>
                  <Col xl={20} lg={19} md={18} sm={18} xs={24}>
                    <Row gutter={[10, 10]}>
                      <Col xl={24} lg={24} md={10} sm={24} xs={24}>
                        <Typography className='profile-name'>
                          {isSelectedJobProfileVisible
                            ? selectedJobProfile?.job_title
                            : job_title}
                        </Typography>
                      </Col>
                      <Col>
                        <Typography className='BusinessEstablishedDate'>
                          Company Name :
                          <Typography
                            component='span'
                            className='BusinessEstablishedDateData'
                          >
                            {isSelectedJobProfileVisible
                              ? selectedJobProfile?.company_name
                              : company_name}
                          </Typography>
                          &nbsp;| Working here since:&nbsp;
                          <Typography
                            component='span'
                            className='BusinessEstablishedDateData'
                          >
                            {isSelectedJobProfileVisible
                              ? selectedJobProfile?.working_here_since
                              : working_here_since}
                          </Typography>
                        </Typography>
                      </Col>
                    </Row>
                    <Divider className='JobProfileDivider' />
                    <Col xl={18} lg={18} md={18} sm={24} xs={24}>
                      <Typography className='BusinessLocation'>
                        Company Address: &nbsp;
                        <Typography
                          component='span'
                          className='BusinessLocationData'
                        >
                          {isSelectedJobProfileVisible
                            ? selectedJobProfile?.business_location
                            : business_location}
                        </Typography>
                      </Typography>
                    </Col>
                  </Col>
                </Row>

                <Row justify='space-around' gutter={[24, 32]}>
                  <Col span={24}></Col>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <PageHeader
                      title={t('content.74')}
                      className='pageHeaderDetails'
                      style={{ height: '56px', paddingTop: 12 }}
                      extra={
                        <Typography
                          className='pageHeaderSubDetails'
                          style={{ lineHeight: '20px' }}
                        >
                          {business_details}
                        </Typography>
                      }
                    ></PageHeader>
                    <Typography
                      className='BusinessEstablishedDate'
                      style={{ padding: '21px 20px' }}
                    >
                      {t('content.73')}:&nbsp;
                      <Typography
                        component='span'
                        className='BusinessEstablishedDateData'
                      >
                        {isSelectedJobProfileVisible
                          ? selectedJobProfile?.experiance
                          : experiance}
                      </Typography>
                    </Typography>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        )}
      </Col>
    </LoadingIndicator>
  );
};
export default React.memo(JobProfileDetails);
