import API from "../Utils/API";

export const getDocuments = (Year) => (dispatch) => {
  dispatch({ type: "GET_DOCUMENT_REQUEST" });
  API.get(Year ? `/api/documents?year=${Year}` : `/api/documents`)
    .then((response) => {
      dispatch({ type: "GET_DOCUMENT_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: "GET_DOCUMENT_FAILURE", payload: error.response.data });
    });
};

export const createDocumentAction =
  (data, successCallback, IsEmpty) => (dispatch) => {
    dispatch({ type: "CREATE_DOCUMENT_REQUEST", payload: data });
    let formData = new FormData();
    formData.append("community_id", data.community_id);
    formData.append("document[file_name]", data.file_name);
    formData.append("document[year]", data.year);
    formData.append("document[document_file]", data.document_file);

    API.post("api/documents", formData)
      .then((response) => {
        dispatch({ type: "CREATE_DOCUMENT_SUCCESS", payload: response.data });
        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_DOCUMENT_FAILURE",
          payload: error.response.data,
        });
      });
  };

export const deleteDocumentAction =
  (data, successDeleteCallBack) => (dispatch) => {
    dispatch({ type: "DELETE_DOCUMENT_REQUEST" });
    API.delete(`api/documents/${data}`)
      .then((response) => {
        dispatch({ type: "DELETE_DOCUMENT_SUCCESS", payload: response.data });
        successDeleteCallBack();
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_DOCUMENT_FAILURE",
          payload: error.response.data,
        });
      });
  };
