import React, { useEffect, useState } from 'react';
import { Row, Col, Tabs, Typography, Pagination, Empty } from 'antd';
import './CommunityFeeds.css';
import CommunityTextCard from './CommunityTextCard';
import CommunityLinkCard from './CommunityLinkCard';
import CommunityImageCard from './CommunityImageCard';
import CommunityVideoCard from './CommunityVideoCard';
import CommunityTemaplateCard from './CommunityTemplateCard';
import { getCommunityCardsAction } from '../../Actions/communityCardsAction';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import FetchPolicyNumber from './FetchPolicyNumber';
import PayNow from './PayNow';
import SelectPaymentMethod from './SelectPaymentMethod';
import {
  NodeIndexOutlined,
  FontSizeOutlined,
  PlayCircleOutlined,
  PictureOutlined,
  PicRightOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { analytics } from '../../components/Mixpanel/Mixpanel';
import CommunityCongratulationsCard from './CommunityCongratulationsCard';

const CommunityFeeds = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const { allCommunityCards, isGetCommnunityCardsLoading } = useSelector(
    (state) => {
      const { allCommunityCards, isGetCommnunityCardsLoading } =
        state.communityCards;
      return {
        allCommunityCards,
        isGetCommnunityCardsLoading,
      };
    }
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCommunityCardsAction(currentPage, true));
    analytics?.track('Visited community page');
  }, [dispatch, currentPage]);

  const [stepDeals, setStepDeals] = useState(0);
  const CardsHavingImagesOnly = _.filter(
    allCommunityCards?.community_cards,
    (val) => {
      return val.card_type === 'Image';
    }
  );
  const CardsHavingVideoOnly = _.filter(
    allCommunityCards?.community_cards,
    (val) => {
      return val.card_type === 'Video';
    }
  );
  const CardsHavingTextOnly = _.filter(
    allCommunityCards?.community_cards,
    (val) => {
      return val.card_type === 'Text';
    }
  );
  const CardsHavingLinkOnly = _.filter(
    allCommunityCards?.community_cards,
    (val) => {
      return val.card_type === 'Link';
    }
  );
  const [tabkey, setTabKey] = useState('1');
  const callback = (key) => {
    if (key === 1) {
      analytics?.track('Feeds_All');
    } else if (key === 2) {
      analytics?.track('Feeds_Images');
    } else if (key === 3) {
      analytics?.track('Feeds_Videos');
    } else if (key === 4) {
      analytics?.track('Feeds_Link');
    } else if (key === 5) {
      analytics?.track('Feeds_Text');
    }
    setTabKey(key);
  };
  const height = window.innerHeight - 64;
  const items = [
    {
      label: (
        <Typography className='CommunityFeedsTabs'>
          {t('content.COMMUNITIES_ALL')}&nbsp;
          <Typography className='CommunityFeedsTabsLength' component='span'>
            {allCommunityCards?.community_cards?.length}
          </Typography>
        </Typography>
      ),
      key: '1',
      className: 'CommunityFeedsTabs',
    }, // remember to pass the key prop
    {
      label: (
        <Typography className='CommunityFeedsTabs'>
          {t('content.COMMUNITIES_IMAGE')}&nbsp;
          <Typography className='CommunityFeedsTabsLength' component='span'>
            {CardsHavingImagesOnly?.length}
          </Typography>
        </Typography>
      ),
      key: '2',
      className: 'CommunityFeedsTabs',
    },
    {
      label: (
        <Typography className='CommunityFeedsTabs'>
          {t('content.COMMUNITIES_VIDEO')}&nbsp;
          <Typography className='CommunityFeedsTabsLength' component='span'>
            {CardsHavingVideoOnly?.length}
          </Typography>
        </Typography>
      ),
      key: '3',
      className: 'CommunityFeedsTabs',
    },
    {
      label: (
        <Typography className='CommunityFeedsTabs'>
          {t('content.COMMUNITIES_LINK')}&nbsp;
          <Typography className='CommunityFeedsTabsLength' component='span'>
            {CardsHavingLinkOnly?.length}
          </Typography>
        </Typography>
      ),
      key: '4',
      className: 'CommunityFeedsTabs',
    },
    {
      label: (
        <Typography className='CommunityFeedsTabs'>
          {t('content.COMMUNITIES_TEXT')}&nbsp;
          <Typography className='CommunityFeedsTabsLength' component='span'>
            {CardsHavingTextOnly.length}
          </Typography>
        </Typography>
      ),
      key: '5',
      className: 'CommunityFeedsTabs',
    },
  ];
  return (
    <Col className='CommunityFeedsContainer'>
      <LoadingIndicator loading={isGetCommnunityCardsLoading}>
        <Col
          style={{
            height: height,
            overflow: 'hidden',
            overflowY: 'scroll',
            backgroundColor: '#FFFFFF',
          }}
          className='customLayout'
        >
          {stepDeals === 1 && (
            <FetchPolicyNumber
              setStepDeals={setStepDeals}
              stepDeals={stepDeals}
            />
          )}
          {stepDeals === 2 && (
            <PayNow setStepDeals={setStepDeals} stepDeals={stepDeals} />
          )}
          {stepDeals === 3 && (
            <SelectPaymentMethod
              setStepDeals={setStepDeals}
              stepDeals={stepDeals}
            />
          )}

          <Row justify='space-around'>
            <Col span={23}>
              <Row gutter={[0, 21]}>
                <Col span={24} />
                <Col span={24} />
              </Row>
            </Col>
            {stepDeals === 0 && (
              <Col span={23}>
                <Row>
                  <Tabs onChange={callback} activeKey={tabkey} items={items} />
                </Row>
              </Col>
            )}
            {stepDeals === 0 && (
              <Col span={23}>
                {tabkey === '1' && (
                  <Row gutter={[26, 25]}>
                    {allCommunityCards?.community_cards &&
                    allCommunityCards?.community_cards?.length > 0
                      ? allCommunityCards?.community_cards?.map((val, i) => {
                          const { card_type } = val;

                          return (
                            <Col
                              xl={6}
                              lg={8}
                              xs={24}
                              md={8}
                              sm={12}
                              xxl={6}
                              key={i}
                            >
                              {card_type === 'Image' && (
                                <CommunityImageCard mappedData={val} />
                              )}
                              {card_type === 'Text' && (
                                <CommunityTextCard mappedData={val} />
                              )}
                              {card_type === 'Video' && (
                                <CommunityVideoCard mappedData={val} />
                              )}
                              {card_type === 'Link' && (
                                <CommunityLinkCard mappedData={val} />
                              )}
                              {card_type === 'Template' && (
                                <CommunityTemaplateCard mappedData={val} />
                              )}
                            </Col>
                          );
                        })
                      : !isGetCommnunityCardsLoading && (
                          <Col className='EmptyScreenCommunityCards'>
                            <Empty
                              description={
                                <Typography className='empty-details'>
                                  {t('content.287')}
                                </Typography>
                              }
                              image={
                                <PicRightOutlined
                                  style={{ fontSize: '50px' }}
                                />
                              }
                            ></Empty>
                          </Col>
                        )}
                  </Row>
                )}
                {tabkey === '2' && (
                  <Row gutter={[26, 25]}>
                    {CardsHavingImagesOnly &&
                    CardsHavingImagesOnly?.length > 0 ? (
                      CardsHavingImagesOnly?.map((val, i) => {
                        return (
                          <Col
                            xl={6}
                            lg={8}
                            xs={24}
                            md={8}
                            sm={12}
                            xxl={6}
                            key={i}
                          >
                            <CommunityImageCard mappedData={val} />
                          </Col>
                        );
                      })
                    ) : (
                      <Col className='EmptyScreenCommunityCards'>
                        <Empty
                          description={
                            <Typography className='empty-details'>
                              {t('content.295')}
                            </Typography>
                          }
                          image={
                            <PictureOutlined style={{ fontSize: '50px' }} />
                          }
                        ></Empty>
                      </Col>
                    )}
                  </Row>
                )}
                {tabkey === '3' && (
                  <Row gutter={[26, 25]}>
                    {CardsHavingVideoOnly &&
                    CardsHavingVideoOnly?.length > 0 ? (
                      CardsHavingVideoOnly?.map((val, i) => {
                        return (
                          <Col
                            xl={6}
                            lg={8}
                            xs={24}
                            md={8}
                            sm={12}
                            xxl={6}
                            key={i}
                          >
                            <CommunityVideoCard mappedData={val} />
                          </Col>
                        );
                      })
                    ) : (
                      <Col className='EmptyScreenCommunityCards'>
                        <Empty
                          description={
                            <Typography className='empty-details'>
                              {t('content.296')}
                            </Typography>
                          }
                          image={
                            <PlayCircleOutlined style={{ fontSize: '50px' }} />
                          }
                        ></Empty>
                      </Col>
                    )}
                  </Row>
                )}
                {tabkey === '4' && (
                  <Row gutter={[26, 25]}>
                    {CardsHavingLinkOnly && CardsHavingLinkOnly?.length > 0 ? (
                      CardsHavingLinkOnly?.map((val, i) => {
                        return (
                          <Col
                            xl={6}
                            lg={8}
                            xs={24}
                            md={8}
                            sm={12}
                            xxl={6}
                            key={i}
                          >
                            <CommunityLinkCard mappedData={val} />
                          </Col>
                        );
                      })
                    ) : (
                      <Col className='EmptyScreenCommunityCards'>
                        <Empty
                          description={
                            <Typography className='empty-details'>
                              {t('content.297')}
                            </Typography>
                          }
                          image={
                            <NodeIndexOutlined style={{ fontSize: '50px' }} />
                          }
                        ></Empty>
                      </Col>
                    )}
                  </Row>
                )}
                {tabkey === '5' && (
                  <Row gutter={[26, 25]}>
                    {CardsHavingTextOnly && CardsHavingTextOnly?.length > 0 ? (
                      CardsHavingTextOnly?.map((val, i) => {
                        return (
                          <Col
                            xl={6}
                            lg={8}
                            xs={24}
                            md={8}
                            sm={12}
                            xxl={6}
                            key={i}
                          >
                            <CommunityTextCard mappedData={val} />
                          </Col>
                        );
                      })
                    ) : (
                      <Col className='EmptyScreenCommunityCards'>
                        <Empty
                          description={
                            <Typography className='empty-details'>
                              {t('content.286')}
                            </Typography>
                          }
                          image={
                            <FontSizeOutlined style={{ fontSize: '50px' }} />
                          }
                        ></Empty>
                      </Col>
                    )}
                  </Row>
                )}
              </Col>
            )}
            {stepDeals === 0 && (
              <Col span={23}>
                <Row justify='end' style={{ margin: '10px 0px' }}>
                  <Pagination
                    total={allCommunityCards?.total_length}
                    onChange={(e) => setCurrentPage(e)}
                  />
                </Row>
              </Col>
            )}
          </Row>
        </Col>
      </LoadingIndicator>
    </Col>
  );
};
export default CommunityFeeds;
