import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Typography,
  Input,
  Divider,
  Checkbox,
  Upload,
  message,
} from 'antd';
import { Controller, useForm } from 'react-hook-form';
import './Admin.css';
import '../AddFamilymember/Addfamily.css';
import {
  updateCommunitiesAction,
  getCommunityById,
} from '../../Actions/communitiesAction';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { settingsSchema } from '../../Utils/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { analytics } from '../../components/Mixpanel/Mixpanel';
const Settings = () => {
  const { t } = useTranslation();

  const { community, isUpdateCommunityLoading, setCommunityId } = useSelector(
    (state) => {
      const { community, isUpdateCommunityLoading, setCommunityId } =
        state.communities;
      return {
        community,
        isUpdateCommunityLoading,
        setCommunityId,
      };
    }
  );
  const dispatch = useDispatch();
  const localStorageCommunityId = localStorage.getItem('community_id');

  useEffect(() => {
    dispatch(
      getCommunityById(
        localStorageCommunityId ? localStorageCommunityId : setCommunityId
      )
    );
  }, [dispatch, setCommunityId]);
  const { handleSubmit, control, errors } = useForm({
    resolver: yupResolver(settingsSchema),
    defaultValues: community,
  });

  const [isTrusteeChecked, setIsTrusteeChecked] = useState(
    community?.show_trustee
  );
  const [isEventsChecked, setIsEventsChecked] = useState(community?.show_event);
  const [isFindOtherPeopleChecked, setIsFindOtherPeopleChecked] = useState(
    community?.show_find_other_people
  );
  const [isMatrimonialChecked, setIsMatrimonialChecked] = useState(
    community?.show_matrimonial
  );

  const [isBusinessProfileChecked, setIsBusinessProfileChecked] = useState(
    community?.show_business_profile
  );
  const [newFiles, setNewFiles] = useState([]);
  const [isDefaultImageVisible, setIsDefaultImageVisible] = useState(true);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const IsEmpty = _.isEmpty(newFiles);
  const editCommunityMethod = (data) => {
    data.id = setCommunityId;

    data.show_trustee = isTrusteeChecked;
    data.show_event = isEventsChecked;
    data.community_logo = IsEmpty ? null : newFiles;
    data.show_find_other_people = isFindOtherPeopleChecked;
    data.show_matrimonial = isMatrimonialChecked;
    data.show_business_profile = isBusinessProfileChecked;
    dispatch(
      updateCommunitiesAction(data, successEditCommunityMethod, IsEmpty)
    );
  };

  const successEditCommunityMethod = () => {
    analytics?.track('Admin_Settings_Save');
    setTimeout(() => {
      message.success(t('content.340'));
    }, 1000);
    dispatch(
      getCommunityById(
        localStorageCommunityId ? localStorageCommunityId : setCommunityId
      )
    );
  };
  const trusteeCheckboxMethod = (e) => {
    setIsTrusteeChecked(e);
  };

  const eventsCheckedMethod = (e) => {
    setIsEventsChecked(e);
  };
  const findOtherPeopleCheckedMethod = (e) => {
    setIsFindOtherPeopleChecked(e);
  };
  const matrimonialCheckedMethod = (e) => {
    setIsMatrimonialChecked(e);
  };
  const businessProfileCheckedMethod = (e) => {
    setIsBusinessProfileChecked(e);
  };
  const propsForUpload = {
    action: editCommunityMethod,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles(file);
      return false;
    },
    multiple: false,
  };
  const handleUploadMethod = () => {
    setShowUploadImage(true);
    setIsDefaultImageVisible(false);
  };
  return (
    <Col>
      <form onSubmit={handleSubmit(editCommunityMethod)}>
        <LoadingIndicator loading={isUpdateCommunityLoading}>
          <Row gutter={[10, 22]}>
            <Col>
              <Row justify='center'>
                <Col>
                  {isDefaultImageVisible && community?.community_logo && (
                    <Upload
                      listType='picture-card'
                      openFileDialogOnClick={false}
                    >
                      <img
                        src={community?.community_logo}
                        width={102}
                        alt=''
                      ></img>
                    </Upload>
                  )}
                </Col>
                <Col>
                  <Upload
                    {...propsForUpload}
                    listType='picture-card'
                    maxCount={1}
                    showUploadList={showUploadImage}
                    onChange={() => handleUploadMethod()}
                  >
                    <PlusOutlined size={44} />
                    {community?.community_logo
                      ? 'Edit Image'
                      : t('content.154')}
                  </Upload>
                </Col>
              </Row>
            </Col>

            <Col xl={6}>
              <Typography
                className='SettingsInputLabel'
                style={{ paddingBottom: '7px' }}
              >
                {t('content.208')}
              </Typography>
              <Controller
                as={<Input className='inputLabel' size='large' />}
                name='name'
                control={control}
              />

              {errors.name && (
                <p style={{ color: 'red' }}>{errors.name.message}</p>
              )}
            </Col>
            <Col span={24}>
              <Typography
                className='SettingsInputLabel'
                style={{ paddingBottom: '7px' }}
              >
                {t('content.209')}
              </Typography>
              <Controller
                as={
                  <Input.TextArea
                    size='large'
                    maxLength={100}
                    style={{ height: 120 }}
                  />
                }
                name='description'
                control={control}
              />
              {errors.description && (
                <p style={{ color: 'red' }}>{errors.description.message}</p>
              )}
            </Col>
            <Divider className='SettingsBorderLine' />
          </Row>
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <Typography className='SettingsAccessTitle'>
                {t('content.210')}
              </Typography>
            </Col>
            <Col span={24} />
          </Row>
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <Row gutter={[17, 0]}>
                <Col>
                  <Checkbox
                    value={'Find Other People'}
                    checked={isFindOtherPeopleChecked}
                    className='SettingsCheckboxTitle'
                    onChange={(e) =>
                      findOtherPeopleCheckedMethod(e.target.checked)
                    }
                  >
                    {t('navigationHeader.16')}
                  </Checkbox>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[17, 0]}>
                <Col>
                  <Checkbox
                    value={isEventsChecked ? true : false}
                    checked={isEventsChecked}
                    onChange={(e) => eventsCheckedMethod(e.target.checked)}
                    className='SettingsCheckboxTitle'
                  >
                    {t('navigationHeader.13')}
                  </Checkbox>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[17, 0]}>
                <Col span={24}>
                  <Checkbox
                    value={'Trustee'}
                    checked={isTrusteeChecked}
                    onChange={(e) => trusteeCheckboxMethod(e.target.checked)}
                    className='SettingsCheckboxTitle'
                  >
                    {t('navigationHeader.12')}
                  </Checkbox>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[17, 0]}>
                <Col>
                  <Checkbox
                    value={'Matrimonials'}
                    checked={isMatrimonialChecked}
                    className='SettingsCheckboxTitle'
                    onChange={(e) => matrimonialCheckedMethod(e.target.checked)}
                  >
                    {t('navigationHeader.14')}
                  </Checkbox>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[17, 0]}>
                <Col>
                  <Checkbox
                    value={'Business profiles'}
                    checked={isBusinessProfileChecked}
                    className='SettingsCheckboxTitle'
                    onChange={(e) =>
                      businessProfileCheckedMethod(e.target.checked)
                    }
                  >
                    {t('navigationHeader.15')}
                  </Checkbox>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[10, 37]}>
            <Col span={24} />
            <Col>
              <Button
                type='primary'
                htmlType='submit'
                className='SettingsButton'
              >
                {t('btn.22')}
              </Button>
            </Col>

            <Col>
              <Button className='SettingsCancelButton'>{t('btn.19')}</Button>
            </Col>
          </Row>
        </LoadingIndicator>
      </form>
    </Col>
  );
};
export default Settings;
