import constants from '../Utils/constants';

const initialState = {
  allDocuments: [],
  isDocumentLoading: false,
  isCreateDocumentLoading: false,
  isDeleteDocumentLoading: false,
};

export const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_DOCUMENT_REQUEST:
      return {
        ...state,
        isDocumentLoading: true,
      };
    case constants.GET_DOCUMENT_SUCCESS:
      return {
        ...state,
        isDocumentLoading: false,
        allDocuments: action.payload,
      };
    case constants.GET_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
      };
    case constants.CREATE_DOCUMENT_REQUEST:
      return {
        ...state,
        isCreateDocumentLoading: true,
      };
    case constants.CREATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        isCreateDocumentLoading: false,
      };
    case constants.CREATE_DOCUMENT_FAILURE:
      return {
        ...state,
        isCreateDocumentLoading: false,
      };

    case constants.DELETE_DOCUMENT_REQUEST:
      return {
        ...state,
        isDeleteDocumentLoading: true,
      };
    case constants.DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        isDeleteDocumentLoading: false,
      };
    case constants.DELETE_DOCUMENT_FAILURE:
      return {
        ...state,
        isDeleteDocumentLoading: false,
      };

    default:
      return state;
  }
};
