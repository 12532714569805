import React, { useEffect } from 'react';
import { Row, Col, Typography } from 'antd';
import LandingPageHeader from '../LandingPage/LandingPageHeader';
import '../LandingPage/LandingPage.css';
import './TermsAndConditions.css';

import Footer from '../LandingPage/Footer';
const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.querySelector('body').scrollTo(0, 0);
  }, []);
  return (
    <Col className='MainbackgroundColor'>
      <Col className='TermsAndConditionsImageContainer' id='heading'>
        <LandingPageHeader />
        <Row style={{ height: '100%' }} justify='center' align='middle'>
          <Typography className='TermsAndConditionsH1'>
            Terms and Conditions
          </Typography>
        </Row>
      </Col>
      <Col
        style={{
          background: '#FFFFFF',
          borderRadius: '50px 50px 0 0',
          marginTop: '100px',
        }}
      >
        <Row justify='center'>
          <Col className='ContentContainer'>
            <Row gutter={[0, 30]}>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  <b>Welcome to Samaj!</b>
                </Typography>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  These terms and conditions outline the rules and regulations
                  for the use of Samaj's Website, located at getsamaj.com.
                </Typography>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  By accessing this website we assume you accept these terms and
                  conditions. Do not continue to use Samaj if you do not agree
                  to take all of the terms and conditions stated on this page.
                </Typography>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  The following terminology applies to these Terms and
                  Conditions, Privacy Statement and Disclaimer Notice and all
                  Agreements: "Client", "You" and "Your" refers to you, the
                  person log on this website and compliant to the Company’s
                  terms and conditions. "The Company", "Ourselves", "We", "Our"
                  and "Us", refers to our Company. "Party", "Parties", or "Us",
                  refers to both the Client and ourselves. All terms refer to
                  the offer, acceptance and consideration of payment necessary
                  to undertake the process of our assistance to the Client in
                  the most appropriate manner for the express purpose of meeting
                  the Client’s needs in respect of provision of the Company’s
                  stated services, in accordance with and subject to, prevailing
                  law of Netherlands. Any use of the above terminology or other
                  words in the singular, plural, capitalization and/or he/she or
                  they, are taken as interchangeable and therefore as referring
                  to same. Our Terms and Conditions were created with the help
                  of the Terms & Conditions Generator.
                </Typography>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  <b>Cookies</b>
                </Typography>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  We employ the use of cookies. By accessing Samaj, you agreed
                  to use cookies in agreement with the Samaj's Privacy Policy.
                </Typography>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  Most interactive websites use cookies to let us retrieve the
                  user’s details for each visit. Cookies are used by our website
                  to enable the functionality of certain areas to make it easier
                  for people visiting our website. Some of our
                  affiliate/advertising partners may also use cookies.
                </Typography>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  <b>License</b>
                </Typography>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  Unless otherwise stated, Samaj and/or its licensors own the
                  intellectual property rights for all material on Samaj. All
                  intellectual property rights are reserved. You may access this
                  from Samaj for your own personal use subjected to restrictions
                  set in these terms and conditions.
                </Typography>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  You must not:
                </Typography>
              </Col>
              <Col>
                <Row justify='space-around'>
                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>Republish material from Samaj</li>
                    </Typography>
                  </Col>

                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>Sell, rent or sub-license material from Samaj</li>
                    </Typography>
                  </Col>
                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>Reproduce, duplicate or copy material from Samaj</li>
                    </Typography>
                  </Col>
                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>Redistribute content from Samaj</li>
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  This Agreement shall begin on the date hereof.
                </Typography>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  Parts of this website offer an opportunity for users to post
                  and exchange opinions and information in certain areas of the
                  website. Samaj does not filter, edit, publish or review
                  Comments prior to their presence on the website. Comments do
                  not reflect the views and opinions of Samaj,its agents and/or
                  affiliates. Comments reflect the views and opinions of the
                  person who post their views and opinions. To the extent
                  permitted by applicable laws, Samaj shall not be liable for
                  the Comments or for any liability, damages or expenses caused
                  and/or suffered as a result of any use of and/or posting of
                  and/or appearance of the Comments on this website.
                </Typography>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  Samaj reserves the right to monitor all Comments and to remove
                  any Comments which can be considered inappropriate, offensive
                  or causes breach of these Terms and Conditions.
                </Typography>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  You warrant and represent that:
                </Typography>
              </Col>
              <Col>
                <Row justify='space-around'>
                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>
                        You are entitled to post the Comments on our website and
                        have all necessary licenses and consents to do so;
                      </li>
                    </Typography>
                  </Col>

                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>
                        The Comments do not invade any intellectual property
                        right, including without limitation copyright, patent or
                        trademark of any third party;
                      </li>
                    </Typography>
                  </Col>
                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>
                        The Comments do not contain any defamatory, libelous,
                        offensive, indecent or otherwise unlawful material which
                        is an invasion of privacy
                      </li>
                    </Typography>
                  </Col>
                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>
                        The Comments will not be used to solicit or promote
                        business or custom or present commercial activities or
                        unlawful activity.
                      </li>
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  You hereby grant Samaj a non-exclusive license to use,
                  reproduce, edit and authorize others to use, reproduce and
                  edit any of your Comments in any and all forms, formats or
                  media.
                </Typography>
              </Col>
              <Col span={24}>
                <Typography className='TermsAndConditionsContentTitle1'>
                  <b>Hyperlinking to our Content</b>
                </Typography>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  The following organizations may link to our Website without
                  prior written approval:
                </Typography>
              </Col>
              <Col>
                <Row justify='space-around'>
                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>Government agencies;</li>
                    </Typography>
                  </Col>

                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>Search engines;</li>
                    </Typography>
                  </Col>
                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>News organizations;</li>
                    </Typography>
                  </Col>
                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>
                        Online directory distributors may link to our Website in
                        the same manner as they hyperlink to the Websites of
                        other listed businesses; and
                      </li>
                    </Typography>
                  </Col>
                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>
                        System wide Accredited Businesses except soliciting
                        non-profit organizations, charity shopping malls, and
                        charity fundraising groups which may not hyperlink to
                        our Web site.
                      </li>
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  These organizations may link to our home page, to publications
                  or to other Website information so long as the link: (a) is
                  not in any way deceptive; (b) does not falsely imply
                  sponsorship, endorsement or approval of the linking party and
                  its products and/or services; and (c) fits within the context
                  of the linking party’s site.
                </Typography>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  We may consider and approve other link requests from the
                  following types of organizations:
                </Typography>
              </Col>
              <Col>
                <Row justify='space-around'>
                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>
                        commonly-known consumer and/or business information
                        sources;
                      </li>
                    </Typography>
                  </Col>

                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>dot.com community sites;</li>
                    </Typography>
                  </Col>
                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>
                        associations or other groups representing charities;
                      </li>
                    </Typography>
                  </Col>
                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>online directory distributors;</li>
                    </Typography>
                  </Col>
                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>internet portals;</li>
                    </Typography>
                  </Col>
                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>accounting, law and consulting firms; and</li>
                    </Typography>
                  </Col>
                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>educational institutions and trade associations.</li>
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  We will approve link requests from these organizations if we
                  decide that: (a) the link would not make us look unfavorably
                  to ourselves or to our accredited businesses; (b) the
                  organization does not have any negative records with us; (c)
                  the benefit to us from the visibility of the hyperlink
                  compensates the absence of Samaj; and (d) the link is in the
                  context of general resource information.
                </Typography>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  These organizations may link to our home page so long as the
                  link: (a) is not in any way deceptive; (b) does not falsely
                  imply sponsorship, endorsement or approval of the linking
                  party and its products or services; and (c) fits within the
                  context of the linking party’s site.
                </Typography>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  If you are one of the organizations listed in paragraph 2
                  above and are interested in linking to our website, you must
                  inform us by sending an e-mail to Samaj. Please include your
                  name, your organization name, contact information as well as
                  the URL of your site, a list of any URLs from which you intend
                  to link to our Website, and a list of the URLs on our site to
                  which you would like to link. Wait 2-3 weeks for a response.
                </Typography>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  Approved organizations may hyperlink to our Website as
                  follows:
                </Typography>
              </Col>
              <Col>
                <Row justify='space-around'>
                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>By use of our corporate name; or</li>
                    </Typography>
                  </Col>

                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>
                        By use of the uniform resource locator being linked to;
                        or
                      </li>
                    </Typography>
                  </Col>
                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>
                        By use of any other description of our Website being
                        linked to that makes sense within the context and format
                        of content on the linking party’s site.
                      </li>
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  No use of Samaj's logo or other artwork will be allowed for
                  linking absent a trademark license agreement.
                </Typography>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  <b>iFrames</b>
                </Typography>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  Without prior approval and written permission, you may not
                  create frames around our Webpages that alter in any way the
                  visual presentation or appearance of our Website.
                </Typography>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  <b>Content Liability</b>
                </Typography>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  We shall not be hold responsible for any content that appears
                  on your Website. You agree to protect and defend us against
                  all claims that is rising on your Website. No link(s) should
                  appear on any Website that may be interpreted as libelous,
                  obscene or criminal, or which infringes, otherwise violates,
                  or advocates the infringement or other violation of, any third
                  party rights.
                </Typography>
              </Col>

              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  <b>Reservation of Rights</b>
                </Typography>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  We reserve the right to request that you remove all links or
                  any particular link to our Website. You approve to immediately
                  remove all links to our Website upon request. We also reserve
                  the right to amen these terms and conditions and it’s linking
                  policy at any time. By continuously linking to our Website,
                  you agree to be bound to and follow these linking terms and
                  conditions.
                </Typography>
              </Col>

              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  <b>Removal of links from our website</b>
                </Typography>
              </Col>

              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  If you find any link on our Website that is offensive for any
                  reason, you are free to contact and inform us any moment. We
                  will consider requests to remove links but we are not
                  obligated to or so or to respond to you directly.
                </Typography>
              </Col>

              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  We do not ensure that the information on this website is
                  correct, we do not warrant its completeness or accuracy; nor
                  do we promise to ensure that the website remains available or
                  that the material on the website is kept up to date.
                </Typography>
              </Col>

              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  <b>Disclaimer</b>
                </Typography>
              </Col>

              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  To the maximum extent permitted by applicable law, we exclude
                  all representations, warranties and conditions relating to our
                  website and the use of this website. Nothing in this
                  disclaimer will:
                </Typography>
              </Col>

              <Col>
                <Row justify='space-around'>
                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>
                        limit or exclude our or your liability for death or
                        personal injury;
                      </li>
                    </Typography>
                  </Col>

                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>
                        limit or exclude our or your liability for fraud or
                        fraudulent misrepresentation;
                      </li>
                    </Typography>
                  </Col>
                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>
                        limit any of our or your liabilities in any way that is
                        not permitted under applicable law; or
                      </li>
                    </Typography>
                  </Col>
                  <Col span={23}>
                    <Typography className='TermsAndConditionsContentTitle1'>
                      <li>
                        exclude any of our or your liabilities that may not be
                        excluded under applicable law.
                      </li>
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  The limitations and prohibitions of liability set in this
                  Section and elsewhere in this disclaimer: (a) are subject to
                  the preceding paragraph; and (b) govern all liabilities
                  arising under the disclaimer, including liabilities arising in
                  contract, in tort and for breach of statutory duty.
                </Typography>
              </Col>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  As long as the website and the information and services on the
                  website are provided free of charge, we will not be liable for
                  any loss or damage of any nature.
                </Typography>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify='center'>
          <Col>
            <Footer />
          </Col>
        </Row>
      </Col>
    </Col>
  );
};

export default TermsAndConditions;
