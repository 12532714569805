import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import {
  Input,
  Button,
  Typography,
  Row,
  Col,
  message,
  Select,
  Checkbox,
  Drawer,
} from 'antd';
import { Controller, useForm } from 'react-hook-form';
import {
  createBusiness,
  getBusiness,
  updateBusiness,
} from '../../Actions/businessAction';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import {
  BusinessuserSchema,
  BusinessuserUpdateSchema,
} from '../../Utils/Schema';
import { useTranslation } from 'react-i18next';
import { getSearchUser } from '../../Actions/searchUserAction';
import { getFamilies } from '../../Actions/familyAction';
import LoadingIndicator from '../../Screens/LoadingIndicator/LoadingIndicator';
const BusinessProfileModalForm = (props) => {
  const { t } = useTranslation();
  const { visible, setVisible, editId, setEditId, setSelectedUserId } = props;
  const dispatch = useDispatch();
  const {
    allBusiness,
    allFamilies,
    isCreateBusinessLoading,
    isUpdateBusinessLoading,
    setCommunityId,
  } = useSelector((state) => {
    const {
      allBusiness,
      isBusinessLoading,
      isCreateBusinessLoading,
      isUpdateBusinessLoading,
    } = state.business;
    const { allFamilies } = state.family;
    const { setCommunityId } = state.communities;

    return {
      allBusiness,
      isBusinessLoading,
      allFamilies,
      isCreateBusinessLoading,
      isUpdateBusinessLoading,
      setCommunityId,
    };
  });

  const the_business = _.filter(allBusiness, function (o) {
    return o.id === editId;
  });
  const { user_families } = allFamilies || [];
  const userName =
    the_business &&
    the_business.length > 0 &&
    the_business[0]?.user?.first_name;
  const userId =
    the_business && the_business.length > 0 && the_business[0]?.user?.id;
  const userLastName =
    the_business && the_business.length > 0 && the_business[0]?.user?.last_name;
  const userFullName = userName + ' ' + userLastName;

  const [preloadedBusinessProfileValue, setPreloadedBusinessProfileValue] =
    useState(null);
  const [wholesaleRetailRequired, setWholesaleRetailRequired] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: preloadedBusinessProfileValue,
    resolver: yupResolver(
      editId ? BusinessuserUpdateSchema : BusinessuserSchema
    ),
  });
  setValue(
    'user_id',
    editId &&
      the_business[0]?.user?.first_name + ' ' + the_business[0]?.user?.last_name
  );
  useEffect(() => {
    setPreloadedBusinessProfileValue(the_business[0]);
    reset(the_business[0]);
    dispatch(getSearchUser(`api/user_search?community_id=${setCommunityId}`));

    dispatch(getFamilies());
  }, [the_business[0], reset]);
  const [checkedValue, setCheckedValue] = useState(
    the_business[0]?.is_wholesale ? true : false
  );

  const handleCreateBusiness = (data) => {
    data.user_id = data.create_user_id;
    data.is_business = true;
    data.is_retail = checkedValue === 'Retail' ? true : false;
    data.is_wholesale = checkedValue === 'Wholesale' ? true : false;
    if (checkedValue) {
      dispatch(
        createBusiness(data, successCreateBusiness, failureCreateBusiness)
      );
    } else {
      setWholesaleRetailRequired(true);
    }
  };
  const successCreateBusiness = () => {
    reset();
    setVisible(false);
    setSelectedUserId('');
    setTimeout(() => {
      message.success(t('content.301'));
    }, 1000);
    dispatch(getBusiness());
    setCheckedValue('');
  };
  const failureCreateBusiness = () => {};

  const hanleEditBusiness = (data) => {
    data.id = editId;
    data.user_id = userId;
    data.is_wholesale =
      checkedValue === 'Wholesale'
        ? true
        : checkedValue === 'Retail'
        ? false
        : the_business[0]?.is_wholesale;
    data.is_retail =
      checkedValue === 'Retail'
        ? true
        : checkedValue === 'Wholesale'
        ? false
        : the_business[0]?.is_retail;

    dispatch(updateBusiness(data, UpdateCallBack));
  };
  const UpdateCallBack = () => {
    reset();

    setVisible(false);
    setEditId('');

    setSelectedUserId('');
    setTimeout(() => {
      message.success(t('content.302'));
    }, 1000);
    dispatch(getBusiness());
    setCheckedValue('');
  };
  const handleCancel = () => {
    reset({});
    setVisible(false);
    setEditId('');
    setSelectedUserId('');
    setCheckedValue('');
    clearErrors();
    setValue('company_name', '');
    setValue('service_provide', '');
    setValue('business_details', '');
    setValue('business_location', '');
    setValue('number_of_staff', '');
    setValue('working_here_since', '');
  };

  const handledChecked = (event) => {
    const { value } = event.target;
    setCheckedValue(value);
    setWholesaleRetailRequired(false);
  };
  const servicesOption = [
    { label: 'Developer', value: 'Developer' },
    { label: 'Engineer', value: 'Engineer' },
  ];
  useEffect(() => {
    if (!editId) {
      setValue('company_name', '');
      setValue('service_provide', '');
      setValue('business_details', '');
      setValue('business_location', '');
      setValue('number_of_staff', '');
      setEditId('');
      setValue('working_here_since', '');
    }
  }, [setValue, !editId]);
  const userFamilies = allFamilies?.map((val, i) => {
    const { user_families } = val;
    const userFirstName = user_families?.map((val) => {
      return {
        label: val.user.first_name + ' ' + val.user.last_name,
        value: val.user.id,
      };
    });
    return userFirstName;
  });

  const userMember = _.flatten(userFamilies);
  const uniqueMembers = _.uniqBy(userMember, (users) => {
    return users?.value;
  });

  return (
    <>
      <Drawer
        title={
          editId
            ? `Edit Business Profile For ${userFullName}`
            : t('welcomeText.15')
        }
        open={visible}
        onClose={() => handleCancel()}
        footer={null}
        className='matrimonialProfile DrawerBorderClass'
        maskClassName='DrawerClass'
      >
        <form
          onSubmit={handleSubmit(
            editId ? hanleEditBusiness : handleCreateBusiness
          )}
        >
          <LoadingIndicator
            loading={editId ? isUpdateBusinessLoading : isCreateBusinessLoading}
          >
            <Row gutter={[8, 8]}>
              {!editId && (
                <Col span={12} className='formRows'>
                  <Typography className='details' style={{ paddingBottom: 9 }}>
                    {t('content.15')}
                  </Typography>
                  {editId ? (
                    <Controller
                      as={
                        <Select
                          placeholder={t('content.4')}
                          style={{ width: '100%', borderRadius: '4px' }}
                          size='large'
                          showSearch={true}
                          options={
                            user_families?.user &&
                            user_families?.user.length > 0 &&
                            user_families?.user.map((val, i) => {
                              return {
                                label: val.first_name + ' ' + val.last_name,
                                value: val.id,
                              };
                            })
                          }
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        ></Select>
                      }
                      className='inputLabel'
                      name='user_id'
                      control={control}
                    />
                  ) : (
                    <Controller
                      as={
                        <Select
                          placeholder={t('content.4')}
                          style={{ width: '100%', borderRadius: '4px' }}
                          size='large'
                          showSearch={true}
                          options={uniqueMembers}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        ></Select>
                      }
                      className='inputLabel'
                      name='create_user_id'
                      control={control}
                    />
                  )}
                  {errors.create_user_id && (
                    <p style={{ color: 'red' }}>{t('content.54')}</p>
                  )}
                </Col>
              )}
              <Col span={editId ? 24 : 12} className='formRows'>
                <Typography className='details' style={{ paddingBottom: 9 }}>
                  {t('content.231')}
                </Typography>
                <Controller
                  as={<Input size='large' />}
                  name='working_here_since'
                  control={control}
                  className='inputLabel'
                />
                {errors.working_here_since && (
                  <p style={{ color: 'red' }}>{t('content.232')}</p>
                )}
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col span={12} className='formRows'>
                <Typography className='details' style={{ paddingBottom: 9 }}>
                  {t('content.48')}
                </Typography>
                <Controller
                  as={<Input size='large' />}
                  name='company_name'
                  control={control}
                  className='inputLabel'
                />
                {errors.company_name && (
                  <p style={{ color: 'red' }}>{t('content.55')}</p>
                )}
              </Col>
              <Col span={12} className='formRows'>
                <Typography className='details' style={{ paddingBottom: 9 }}>
                  {t('content.49')}
                </Typography>
                <Controller
                  as={<Input size='large' />}
                  name='service_provide'
                  control={control}
                  className='inputLabel'
                />
                {errors.service_provide && (
                  <p style={{ color: 'red' }}>{t('content.56')}</p>
                )}
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col span={24} className='formRows'>
                <Typography className='details' style={{ paddingBottom: 9 }}>
                  {t('content.50')}
                </Typography>
                <Controller
                  as={<Input size='large' />}
                  name='business_details'
                  control={control}
                  className='inputLabel'
                />
                {errors.business_details && (
                  <p style={{ color: 'red' }}>{t('content.57')}</p>
                )}
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col span={24} className='formRows'>
                <Typography className='details' style={{ paddingBottom: 9 }}>
                  {t('content.51')}
                </Typography>
                <Controller
                  as={<Input size='large' />}
                  name='business_location'
                  control={control}
                  className='inputLabel'
                />
                {errors.business_location && (
                  <p style={{ color: 'red' }}>{t('content.58')}</p>
                )}
              </Col>
              <Col span={12} className='formRows'>
                <Typography className='details' style={{ paddingBottom: 9 }}>
                  {t('content.52')}
                </Typography>
                <Controller
                  as={<Input size='large' />}
                  name='number_of_staff'
                  control={control}
                  className='inputLabel'
                />
                {errors.number_of_staff && (
                  <p style={{ color: 'red' }}>{t('content.59')}</p>
                )}
              </Col>
            </Row>
            <Row gutter={[8, 10]}>
              <Col span={24} />
              <Col>
                <Checkbox
                  onChange={handledChecked}
                  checked={
                    checkedValue
                      ? checkedValue === 'Wholesale' && true
                      : the_business[0]?.is_wholesale === true && true
                  }
                  value={'Wholesale'}
                >
                  {t('content.229')}
                </Checkbox>
              </Col>
              <Col>
                <Checkbox
                  onChange={handledChecked}
                  checked={
                    checkedValue
                      ? checkedValue === 'Retail' && true
                      : the_business[0]?.is_retail === true && true
                  }
                  value={'Retail'}
                >
                  {t('content.230')}
                </Checkbox>
                {wholesaleRetailRequired && (
                  <p style={{ color: 'red' }}>Any one must be checked</p>
                )}
              </Col>
            </Row>
            <Row gutter={[8, 8]} justify='end'>
              <Col className='formRows'>
                <Button
                  style={{ margin: '0 8px' }}
                  className='modal-buttonCancel'
                  onClick={() => handleCancel()}
                >
                  {t('btn.19')}
                </Button>
                {editId ? (
                  <Button htmlType='submit' className='modal-buttonAdd'>
                    {t('btn.17')}
                  </Button>
                ) : (
                  <Button htmlType='submit' className='modal-buttonAdd'>
                    {t('btn.24')}
                  </Button>
                )}
              </Col>
            </Row>
          </LoadingIndicator>
        </form>
      </Drawer>
    </>
  );
};
export default BusinessProfileModalForm;
