import API from "../Utils/API";

export const getCommunityMeetings = (year) => (dispatch) => {
  dispatch({ type: "GET_COMMUNITY_MEETINGS_REQUEST" });

  API.get(`/api/community_meetings?year=${year}`)
    .then((response) => {
      dispatch({
        type: "GET_COMMUNITY_MEETINGS_SUCCESS",
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: "GET_COMMUNITY_MEETINGS_FAILURE",
        payload: error.response.data,
      });
    });
};

export const createCommunityMeeting = (data, successCallback) => (dispatch) => {
  dispatch({ type: "CREATE_COMMUNITY_MEETING_REQUEST", payload: data });

  API.post("/api/community_meetings", data)
    .then((response) => {
      dispatch({
        type: "CREATE_COMMUNITY_MEETING_SUCCESS",
        payload: response.data,
      });
      successCallback(response.data);
    })
    .catch((error) => {
      dispatch({
        type: "CREATE_COMMUNITY_MEETING_FAILURE",
        payload: error.response.data,
      });
    });
};

export const updatePublishCommunityMeeting =
  (id, successCallback) => (dispatch) => {
    dispatch({ type: "UPDATE_PUBLISH_COMMUNITY_MEETING_REQUEST" });

    API.put(`/api/published_community_meeting/${id}`)

      .then((response) => {
        dispatch({
          type: "UPDATE_PUBLISH_COMMUNITY_MEETING_SUCCESS",
          payload: response.data,
        });
        successCallback(response.data);
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_PUBLISH_COMMUNITY_MEETING_FAILURE",
          payload: error.response.data,
        });
        successCallback();
      });
  };

export const updateCommunityMeeting = (data, successCallback) => (dispatch) => {
  dispatch({ type: "UPDATE_COMMUNITY_MEETING_REQUEST" });

  API.put(`/api/community_meetings/${data.id}`, data)
    .then((response) => {
      dispatch({
        type: "UPDATE_COMMUNITY_MEETING_SUCCESS",
        payload: response.data,
      });
      successCallback(response.data);
    })
    .catch((error) => {
      dispatch({
        type: "UPDATE_COMMUNITY_MEETING_FAILURE",
        payload: error.response.data,
      });
    });
};

export const deleteCommunityMeeting =
  (meetingId, successCallback) => (dispatch) => {
    dispatch({ type: "DELETE_COMMUNITY_MEETING_REQUEST" });

    API.delete(`/api/community_meetings/${meetingId}`)
      .then((response) => {
        dispatch({
          type: "DELETE_COMMUNITY_MEETING_SUCCESS",
          payload: response.data,
        });
        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_COMMUNITY_MEETING_FAILURE",
          payload: error.response.data,
        });
      });
  };
