import constants from "../Utils/constants";
const initialState = {
  isUnderMaintenanceLoading: false,
  allUnderMaintenanceWindows: [],
};

export const windowMaintenanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_MAINTENANCE_WINDOWS_REQUEST:
      return {
        ...state,
        isUnderMaintenanceLoading: true,
      };
    case constants.GET_MAINTENANCE_WINDOWS_SUCCESS:
      return {
        ...state,
        isUnderMaintenanceLoading: false,
        allUnderMaintenanceWindows: action.payload,
      };
    case constants.GET_MAINTENANCE_WINDOWS_FAILURE:
      return {
        ...state,
        isUnderMaintenanceLoading: false,
      };

    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
