import React from 'react';
import { Row, Table, Col, Button, message } from 'antd';
import '../../FindThePeoplePage/FindOtherPeople.css';
import {
  getEducationsAction,
  updateEducationAction,
} from '../../Actions/educationAction';
import './Admin.css';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const EducationTable = (props) => {
  const { t } = useTranslation();
  const {
    allEducations,
    isEducationsLoading,
    setIsEditEducationModalVisible,
    setEditEducationId,
  } = props;
  const columns = [
    {
      title: `${t('content.18')} (${allEducations?.length})`,

      dataIndex: 'name',
      width: '80%',

      render: (name, allEducationsList) => {
        return allEducationsList.published ? (
          name
        ) : (
          <Col className='hidedRow'>{name}</Col>
        );
      },
    },

    {
      dataIndex: 'id',
      render: (id, allEducationList) => (
        <ActionComponent
          id={id}
          setEditEducationId={setEditEducationId}
          setIsEditEducationModalVisible={setIsEditEducationModalVisible}
          allEducationList={allEducationList}
        />
      ),
      width: '15em',
    },
  ];
  return (
    <LoadingIndicator laoding={isEducationsLoading}>
      <Col className='AdminTable'>
        <Table
          columns={columns}
          dataSource={allEducations}
          size='middle'
          className='table'
          rowKey={(record) => {
            return record.id;
          }}
        />
      </Col>
    </LoadingIndicator>
  );
};

export default EducationTable;

const ActionComponent = (props) => {
  const {
    id,
    setEditEducationId,
    setIsEditEducationModalVisible,
    allEducationList,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let UnpublishData = {};
  let PublishData = {};
  const openEditEducationMethod = (EditId) => {
    setEditEducationId(EditId);
    setIsEditEducationModalVisible(true);
  };
  const handlePublishEducationMethod = (EditId) => {
    PublishData.id = EditId;
    PublishData.published = true;
    dispatch(updateEducationAction(PublishData, successUpdatePublishMethod));
  };
  const handleUnpublishEducationMethod = (EditId) => {
    UnpublishData.id = EditId;
    UnpublishData.published = false;
    dispatch(
      updateEducationAction(UnpublishData, successUpdateUnpublishMethod)
    );
  };

  const successUpdateUnpublishMethod = () => {
    setTimeout(() => {
      message.success(t('content.330'));
    }, 1000);
    dispatch(getEducationsAction());
  };
  const successUpdatePublishMethod = () => {
    setTimeout(() => {
      message.success(t('content.331'));
    }, 1000);
    dispatch(getEducationsAction());
  };
  return (
    <Row>
      <Col span={10}>
        <Button
          type='primary'
          className='editDesignationButton'
          onClick={() => openEditEducationMethod(id)}
        >
          {t('btn.27')}
        </Button>
      </Col>
      <Col>
        {allEducationList?.published ? (
          <Button
            type='primary'
            className='unPublishButton'
            onClick={() => handleUnpublishEducationMethod(id)}
          >
            {t('content.164')}
          </Button>
        ) : (
          <Button
            type='primary'
            className='publishButton'
            onClick={() => handlePublishEducationMethod(id)}
          >
            {t('content.163')}
          </Button>
        )}
      </Col>
    </Row>
  );
};
