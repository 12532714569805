import React, { useEffect } from 'react';
import { Row, Col, Typography, Card } from 'antd';
import LandingPageHeader from './LandingPageHeader';
import './LandingPage.css';
import '../TermsAndConditions/TermsAndConditions.css';
import { useBreakpoints } from '../CommonLogics/ResponsiveBreakPoints';
import { CalendarOutlined, ShoppingOutlined } from '@ant-design/icons';
import './Faqs.css';
import Footer from './Footer';
import SamajIconSvg from '../../assets/SamajIconSvg.svg';
import Watermark from '../../assets/Watermark.svg';
const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.querySelector('body').scrollTo(0, 0);
  }, []);
  const cardArray = [
    {
      name: 'Vision',
      Description:
        'Our vision is to establish an inclusive online platform that serves as the heart of diverse communities, fostering meaningful connections and empowering members to thrive together. Through innovative digital solutions and seamless networking opportunities, we aim to create a vibrant ecosystem where individuals can collaborate, share experiences, and support one another towards collective growth and prosperity.',
    },
    {
      name: 'Mission',
      Description:
        'Empowering communities through digitalization and online networking tools, Samaj aims to simplify everyday life for its members by providing easy access to contact details, business information, and community activities. Through fostering meaningful connections and facilitating communication, Samaj seeks to promote community development and enhance the overall well-being of its members.',
    },
  ];
  const { IsMD, IsSM, IsXS, IsLG, IsXL, IsXXL } = useBreakpoints();

  return (
    <Col className='MainbackgroundColor'>
      <Col className='TermsAndConditionsImageContainer'>
        <LandingPageHeader />

        <Row
          style={{ height: '100%', padding: (IsXS || IsSM) && '100px' }}
          justify='center'
          align='middle'
        >
          <Typography
            className='TermsAndConditionsH1'
            style={{ fontSize: IsXS && '30px' }}
          >
            About Us
          </Typography>
        </Row>
      </Col>

      <Col
        style={{
          background: '#FFFFFF',
          borderRadius: '50px 50px 0 0',
          marginTop: '100px',
        }}
      >
        <Row justify='center' style={{ paddingTop: IsXS ? '50px' : '100px' }}>
          <Col style={{ width: '940px' }}>
            <Row justify='space-around' gutter={[0, 40]}>
              <Col align='middle'>
                <img
                  src={SamajIconSvg}
                  style={{
                    width: '173px',
                    height: '173px',
                    borderRadius: '24px',
                  }}
                  alt=''
                ></img>
              </Col>
              <Col>
                <Typography className='AboutUsTitle'>
                  Samaj is a social platform designed to empower communities and
                  foster connections among members. With a focus on kindness,
                  laughter, and support, Samaj aims to create a vibrant
                  environment where people can come together to connect,
                  network, and meet family members. The platform facilitates
                  interaction between local businesses and customers,
                  encouraging community support. Additionally, Samaj offers
                  features for event management, directory management, and
                  matchmaking within the community. Through digitalization
                  efforts, Samaj strives to simplify everyday life for its
                  members by providing easy access to contact details, business
                  information, and community activities. Ultimately, Samaj seeks
                  to promote community development and enhance the overall
                  well-being of its members.
                </Typography>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify='center'>
          <Col
            style={{
              marginTop: '100px',
              backgroundImage: `url(${Watermark})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          >
            <Row justify='center'>
              <Col style={{ width: '940px' }}>
                <Row
                  gutter={[10, 20]}
                  justify={IsXS ? 'center' : 'space-between'}
                >
                  {cardArray?.map((val, i) => {
                    return (
                      <Col key={i}>
                        <Card
                          className='landingPageCard'
                          style={{
                            borderRadius: '24px',
                            background:
                              val?.name === 'Vision'
                                ? 'linear-gradient(to right, #2DCDFF, #4593EF)'
                                : 'linear-gradient(to right, #EE5C9F, #F19C51)',
                            border: 'none',
                          }}
                        >
                          <Row gutter={[0, 10]} justify='center'>
                            <Col span={24}>{val.Icon}</Col>
                            <Col span={24} align='center'>
                              <Typography
                                className='cardTitle'
                                style={{ color: '#F5F5F7' }}
                              >
                                {val.name}
                              </Typography>
                            </Col>
                            <Col>
                              <Typography
                                className='cardTitle2'
                                style={{ color: '#F5F5F7' }}
                              >
                                {val.Description}
                              </Typography>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify='center'>
          <Col style={{ marginTop: '100px' }}>
            <Footer />
          </Col>
        </Row>
      </Col>
    </Col>
  );
};

export default AboutUs;
