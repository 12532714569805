import API from "../Utils/API";
export const getTicketsAction = (Page) => (dispatch) => {
  dispatch({ type: "GET_TICKETS_REQUEST" });
  API.get(`api/tickets?page=${Page}`)
    .then((response) => {
      dispatch({ type: "GET_TICKETS_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_TICKETS_FAILURE",
        payload: error.response.data,
      });
    });
};

export const createTicketAction =
  (data, success, failureCallback) => (dispatch) => {
    dispatch({ type: "CREATE_TICKETS_REQUEST", payload: data });
    API.post("api/tickets", data)
      .then((response) => {
        dispatch({ type: "CREATE_TICKETS_SUCCESS", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_TICKETS_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };

export const getTicketByIdAction = (Id, SuccessCallback) => (dispatch) => {
  dispatch({ type: "GET_TICKET_BY_ID_REQUEST" });
  API.get(`api/tickets/${Id}`)
    .then((response) => {
      dispatch({ type: "GET_TICKET_BY_ID_SUCCESS", payload: response.data });
      SuccessCallback && SuccessCallback(response.data);
    })
    .catch((error) => {
      dispatch({
        type: "GET_TICKET_BY_ID_FAILURE",
        payload: error.response.data,
      });
    });
};
