import API from "../Utils/API";
export const getEducationsAction = () => (dispatch) => {
  dispatch({ type: "GET_EDUCATIONS_REQUEST" });
  API.get("api/educations")
    .then((response) => {
      dispatch({ type: "GET_EDUCATIONS_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_EDUCATIONS_FAILURE",
        payload: error.response.data,
      });
    });
};
export const createEducationAction = (data, successCallback) => (dispatch) => {
  dispatch({ type: "CREATE_EDUCATIONS_REQUEST", payload: data });

  API.post("api/educations", data)
    .then((response) => {
      dispatch({ type: "CREATE_EDUCATIONS_SUCCESS", payload: response.data });
      successCallback();
    })
    .catch((error) => {
      dispatch({
        type: "CREATE_EDUCATIONS_FAILURE",
        payload: error.response.data,
      });
    });
};

export const updateEducationAction = (data, successCallback) => (dispatch) => {
  dispatch({ type: "UPDATE_EDUCATIONS_REQUEST" });
  API.put(`api/educations/${data.id}`, data)
    .then((response) => {
      dispatch({ type: "UPDATE_EDUCATIONS_SUCCESS", payload: response.data });
      successCallback();
    })
    .catch((error) => {
      dispatch({
        type: "UPDATE_EDUCATIONS_FAILURE",
        payload: error.response.data,
      });
    });
};

export const deleteUserEducationAction =
  
(data, successCallback) => (dispatch) => {
    dispatch({ type: "DELETE_USER_EDUCATIONS_REQUEST" });
    API.delete(`api/remove_user_education/${data}`)
      .then((response) => {
        dispatch({
          type: "DELETE_USER_EDUCATIONS_SUCCESS",
          payload: response.data,
        });
        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_USER_EDUCATIONS_FAILURE",
          payload: error.response.data,
        });
      });
  };
