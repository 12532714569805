import API from "../Utils/API";
export const getSearchUser = (url, SuccessCallback) => (dispatch) => {
  dispatch({ type: "GET_SEARCH_USER_REQUEST" });
  API.get(url)
    .then((response) => {
      dispatch({ type: "GET_SEARCH_USER_SUCCESS", payload: response.data });
      SuccessCallback && SuccessCallback(response.data.users);
    })
    .catch((error) => {
      dispatch({
        type: "GET_SEARCH_USER_FAILURE",
        payload: error?.response?.data,
      });
    });
};

export const updateVerifyUserAction = (Id, SuccessCallback) => (dispatch) => {
  dispatch({ type: "UPDATE_VERIFY_USERS_REQUEST" });
  API.put(`api/verified_user/${Id}`)
    .then((response) => {
      dispatch({ type: "UPDATE_VERIFY_USERS_SUCCESS", payload: response.data });
      SuccessCallback && SuccessCallback();
    })
    .catch((error) => {
      dispatch({
        type: "UPDATE_VERIFY_USERS_FAILURE",
        payload: error.response.data,
      });
    });
};
