import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Tabs } from 'antd';
import './Admin.css';
import AddNativeModal from './AddNativeModal';
import Nativetable from './NativeTable';
import AddDesignationModal from './AddDesignationModal';
import DesignationTable from './DesignationTable';
import { useDispatch, useSelector } from 'react-redux';
import { getDesignations } from '../../Actions/designationsAction';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import '../../FindThePeoplePage/FindOtherPeople.css';
import { getCommunitiesNative } from '../../Actions/communitiesNativeAction';
import AddCommunityCardModal from './AddCommunityCardModal';
import Settings from './Settings';
import AddEducationModal from './AddEducationModal';
import EducationTable from './EducationTable';
import { getEducationsAction } from '../../Actions/educationAction';
import AddIndustryModal from './AddIndustryModal';
import { getIndustriesAction } from '../../Actions/industryAction';
import IndustryTable from './IndustryTable';
import VolunteerTable from './VolunteerTable';
import { history } from '../../Utils/history';
import { useTranslation } from 'react-i18next';
import MeetingTable from './MeetingTable';
import AddMeetingModal from './AddMeetingModal';
import { getCommunityMeetings } from '../../Actions/communityMeetingsAction';
import DocumnetTable from './DocumentTable';
import HandleUsers from './HandleUsers';
import UploadDocument from './UploadDocument';
import { getDocuments } from '../../Actions/documentsAction';
import '../../Screens/LoadingIndicator/LoadingIndicator.css';
import CommunityCardsTable from './CommunityCardsTable';
import { getCommunityCardsAction } from '../../Actions/communityCardsAction';
import VerifyUsers from './VerifyUsers';
import ManageTrustee from './ManageTrustee';
import { analytics } from '../../components/Mixpanel/Mixpanel';
const Admin = () => {
  const { t } = useTranslation();
  const [editDesignationId, setEditDesignationId] = useState('');
  const {
    allDesignations,
    isCreateDesignationsLoading,
    isGetDesignationsLoading,
    isUpdateDesignationsLoading,
    allCommunitiesNative,
    isCommunitiesNativeLoading,
    allEducations,
    isEducationsLoading,
    isCreateEducationLoading,
    isUpdateEducationLoading,
    allIndustries,
    isCreateIndustryLoading,
    isUpdateIndustryLoading,
    currentUser,
    allCommunityCards,
    allMeetings,
    isCommunityMeetingsLoading,
    allDocuments,
    setCommunityId,
    isCreateDocumentLoading,
    isDocumentLoading,
    isDeleteDocumentLoading,
    isCreateCommunityCardsLoading,
    isUpdateCommunityCardsLoading,
  } = useSelector((state) => {
    const {
      allDesignations,
      isCreateDesignationsLoading,
      isUpdateDesignationsLoading,
      isGetDesignationsLoading,
    } = state.designations;

    const { allCommunitiesNative, isCommunitiesNativeLoading } =
      state.communitiesNative;
    const {
      allEducations,
      isEducationsLoading,
      isCreateEducationLoading,
      isUpdateEducationLoading,
    } = state.educations;
    const { allIndustries, isCreateIndustryLoading, isUpdateIndustryLoading } =
      state.industry;
    const { currentUser } = state.authentication;
    const { allMeetings, isCommunityMeetingsLoading, editMeetingId } =
      state.communityMeetings;
    const {
      allDocuments,
      isCreateDocumentLoading,
      isDocumentLoading,
      isDeleteDocumentLoading,
    } = state.documents;
    const {
      allCommunityCards,
      isCreateCommunityCardsLoading,
      isUpdateCommunityCardsLoading,
    } = state.communityCards;
    const { setCommunityId } = state.communities;

    return {
      allDesignations,
      isCreateDesignationsLoading,
      isUpdateDesignationsLoading,
      isGetDesignationsLoading,
      allCommunitiesNative,
      isCommunitiesNativeLoading,
      allEducations,
      isEducationsLoading,
      isCreateEducationLoading,
      isUpdateEducationLoading,
      allIndustries,
      isCreateIndustryLoading,
      isUpdateIndustryLoading,
      currentUser,
      allMeetings,
      isCommunityMeetingsLoading,
      editMeetingId,
      allDocuments,
      setCommunityId,
      isCreateDocumentLoading,
      isDocumentLoading,
      isDeleteDocumentLoading,
      allCommunityCards,
      isCreateCommunityCardsLoading,
      isUpdateCommunityCardsLoading,
    };
  });

  const dispatch = useDispatch();
  const { setValue } = useForm({});
  const redirectMethod = () => {
    history.push('/add_family');
  };
  const d = new Date();
  let y = d.getFullYear();
  const [year, setYear] = useState(y);
  const { users_types } = currentUser || {};
  const isUserAdmin = users_types?.includes('admin');
  const [documentYear, setDocumentYear] = useState(y);
  const [communityCardPage, setCommunityCardPage] = useState(1);
  const [isCommunityCardPublished, setIsCommunityCardPublished] =
    useState(false);
  useEffect(() => {
    dispatch(getDesignations(setCommunityId));
    dispatch(getCommunitiesNative(setCommunityId));
    dispatch(getEducationsAction());
    dispatch(getIndustriesAction());
    dispatch(getCommunityMeetings(year));
    dispatch(getDocuments(documentYear));
    if (!isUserAdmin) {
      redirectMethod();
    }
  }, [dispatch]);
  useEffect(() => {
    dispatch(getCommunityCardsAction(communityCardPage));
  }, [communityCardPage]);
  const [tabKey, setTabKey] = useState('1');
  const [isAddNativeModalVisible, setIsAddNativeModalVisible] = useState(false);
  const [isAddDesignationModalVisible, setIsAddDesignationModalVisible] =
    useState(false);
  const [editNativeId, setEditNativeId] = useState('');
  const [isEditDesignationModalVisible, setIsEditDesignationModalVisible] =
    useState(false);
  const [isEditNativeModalVisible, setIsEditNativeModalVisible] =
    useState(false);
  const [isAddEducationModalVisible, setIsAddEducationModalVisible] =
    useState(false);
  const [isEditEducationModalVisible, setIsEditEducationModalVisible] =
    useState(false);
  const [editEducationId, setEditEducationId] = useState('');
  const [isAddIndustryModalVisible, setIsAddIndustryModalVisible] =
    useState(false);
  const [isEditIndustryModalVisible, setIsEditIndustryModalVisible] =
    useState(false);
  const [editIndustryId, setEditIndustryId] = useState('');
  const [isAddMeetingModalVisible, setIsAddMeetingModalVisible] =
    useState(false);
  const [editMeetingId, setEditMeetingId] = useState('');
  const [iseditMeetingModalVisible, setIseditMeetingModalVisible] =
    useState(false);
  const [isPublishMeeting, setIsPublishMeeting] = useState(false);
  const [isUploadDocsVisible, setIsUploadDocsVisible] = useState(false);
  const [editDocumentId, setEditDocumentId] = useState('');
  const [isDocumentVisible, setIsDocumentVisible] = useState(false);
  const callback = (key) => {
    if (key === '1') {
      analytics?.track('Admin_TrusteeDesignation');
    } else if (key === '2') {
      analytics?.track('Admin_Native');
    } else if (key === '6') {
      analytics?.track('Admin_Volunteer');
    } else if (key === '8') {
      analytics?.track('Admin_CommunityMetting');
    } else if (key === '9') {
      analytics?.track('Admin_Documents');
    } else if (key === '7') {
      analytics?.track('Admin_Users');
    } else if (key === '11') {
      analytics?.track('Admin_VerifyUsers');
    } else if (key === '10') {
      analytics?.track('Admin_CommunityCards');
    } else if (key === '12') {
      analytics?.track('Admin_ManageTrustee');
    } else if (key === '5') {
      analytics?.track('Admin_Settings');
    }
    setTabKey(key);
  };
  const [isCreateCommunityCardVisible, setIsCreateCommunityCardVisible] =
    useState(false);
  const [isEditCommunityCardVisible, setIsEditCommunityCardVisible] =
    useState(false);
  const [editCommunityCardId, setEditCommunityCardId] = useState('');

  const openAddNativeMethod = () => {
    setValue('name', '');
    setIsAddNativeModalVisible(true);
  };
  const openAddDesignationMethod = () => {
    analytics?.track('Admin_AddDesigination');
    setIsAddDesignationModalVisible(true);
  };
  const openAddEducationMethod = () => {
    setIsAddEducationModalVisible(true);
  };

  const openAddIndustryMethod = () => {
    setIsAddIndustryModalVisible(true);
  };
  const openAddMeetingMethod = () => {
    setIsAddMeetingModalVisible(true);
  };

  const openUploadDocsMethod = () => {
    setIsUploadDocsVisible(true);
  };
  const openCommunityCardsMethod = () => {
    setIsCreateCommunityCardVisible(true);
  };
  const selectedDesignation = _.filter(allDesignations, (designations) => {
    return designations.id === editDesignationId;
  });
  const selectedNative = _.filter(allCommunitiesNative, (natives) => {
    return natives.id === editNativeId;
  });
  const selectedEducation = _.filter(allEducations, (educations) => {
    return educations.id === editEducationId;
  });

  const selectedIndustry = _.filter(allIndustries, (industries) => {
    return industries.id === editIndustryId;
  });
  const selectedMeeting = _.filter(allMeetings, (meetings) => {
    return meetings.id === editMeetingId;
  });
  const height = window.innerHeight - 64;
  const selectedDocument = _.filter(allDocuments, (document) => {
    return document.id === editDocumentId;
  });
  const selectedCommunityCard = _.filter(
    allCommunityCards.community_cards,
    (Cards) => {
      return Cards.id === editCommunityCardId;
    }
  );
  const items = [
    {
      label: (
        <Button
          className={tabKey === '1' ? 'selectedButton' : 'unselectedButton'}
        >
          {t('content.277')}
        </Button>
      ),
      key: '1',
    }, // remember to pass the key prop
    {
      label: (
        <Button
          className={tabKey === '2' ? 'selectedButton' : 'unselectedButton'}
        >
          {t('content.151')}
        </Button>
      ),
      key: '2',
    },
    {
      label: (
        <Button
          className={tabKey === '6' ? 'selectedButton' : 'unselectedButton'}
        >
          {t('content.205')}
        </Button>
      ),
      key: '6',
    },
    {
      label: (
        <Button
          className={tabKey === '8' ? 'selectedButton' : 'unselectedButton'}
        >
          {t('content.211')}
        </Button>
      ),
      key: '8',
    },
    {
      label: (
        <Button
          className={tabKey === '9' ? 'selectedButton' : 'unselectedButton'}
        >
          {t('content.233')}
        </Button>
      ),
      key: '9',
    },
    {
      label: (
        <Button
          className={tabKey === '7' ? 'selectedButton' : 'unselectedButton'}
        >
          {t('content.206')}
        </Button>
      ),
      key: '7',
    },
    {
      label: (
        <Button
          className={tabKey === '11' ? 'selectedButton' : 'unselectedButton'}
        >
          {t('content.278')}
        </Button>
      ),
      key: '11',
    },
    {
      label: (
        <Button
          className={tabKey === '10' ? 'selectedButton' : 'unselectedButton'}
        >
          {t('content.279')}
        </Button>
      ),
      key: '10',
    },
    {
      label: (
        <Button
          className={tabKey === '12' ? 'selectedButton' : 'unselectedButton'}
        >
          {t('content.280')}
        </Button>
      ),
      key: '12',
    },
    {
      label: (
        <Button
          className={tabKey === '5' ? 'selectedButton' : 'unselectedButton'}
        >
          {t('content.207')}
        </Button>
      ),
      key: '5',
    },
  ];
  return (
    <Col
      style={{
        height: height,
        overflow: 'hidden',
        overflowY: 'scroll',
      }}
    >
      <Col>
        <Row style={{ padding: '24px' }}>
          <Col span={24}>
            <Row justify='space-between'>
              <Col>
                <Col className='Tabs'>
                  <Tabs
                    defaultActiveKey='1'
                    animated={false}
                    renderTabBar={(props, DefaultTabBar) => (
                      <DefaultTabBar {...props} className='cMTabsBckground'>
                        {(node) => (
                          <Col key={node.key} index={node.key}>
                            {node}
                          </Col>
                        )}
                      </DefaultTabBar>
                    )}
                    onChange={(e) => callback(e)}
                    moreIcon={false}
                    tabBarExtraContent={false}
                    className='hideBottomLine'
                    tabPosition={'top'}
                    items={items}
                  />
                </Col>
              </Col>
              <Col span={24}>
                <Row justify='end'>
                  {tabKey === '1' ? (
                    <Button
                      type='primary'
                      onClick={() => {
                        analytics?.track('Admin_AddDesigination');
                        openAddDesignationMethod();
                      }}
                      className='button-search'
                    >
                      {t('content.162')}
                    </Button>
                  ) : tabKey === '2' ? (
                    <Button
                      type='primary'
                      onClick={() => {
                        analytics?.track('Admin_AddNative');
                        openAddNativeMethod();
                      }}
                      className='button-search'
                    >
                      {t('content.166')}
                    </Button>
                  ) : tabKey === '3' ? (
                    <Button
                      type='primary'
                      className='button-search'
                      onClick={() => {
                        analytics?.track('Admin_AddEducation');
                        openAddEducationMethod();
                      }}
                    >
                      {t('content.169')}
                    </Button>
                  ) : tabKey === '4' ? (
                    <Button
                      type='primary'
                      className='button-search'
                      onClick={() => {
                        analytics?.track('Admin_AddIndustry');
                        openAddIndustryMethod();
                      }}
                    >
                      {t('content.202')}
                    </Button>
                  ) : tabKey === '8' ? (
                    <Button
                      type='primary'
                      className='button-search'
                      onClick={() => {
                        analytics?.track('Admin_AddMeeting');
                        openAddMeetingMethod();
                      }}
                    >
                      {t('btn.43')}
                    </Button>
                  ) : tabKey === '9' ? (
                    <Button
                      type='primary'
                      className='editButton'
                      onClick={() => {
                        analytics?.track('Admin_UploadDocs');
                        openUploadDocsMethod();
                      }}
                    >
                      {t('content.241')}
                    </Button>
                  ) : tabKey === '10' ? (
                    <Button
                      type='primary'
                      className='editButton'
                      onClick={() => {
                        analytics?.track('Admin_AddCommunityCard');
                        openCommunityCardsMethod();
                      }}
                    >
                      {t('content.283')}
                      {/* Add Community Cards */}
                    </Button>
                  ) : (
                    ''
                  )}

                  <AddNativeModal
                    isAddNativeModalVisible={isAddNativeModalVisible}
                    setIsAddNativeModalVisible={setIsAddNativeModalVisible}
                    isEditNativeModalVisible={isEditNativeModalVisible}
                    setIsEditNativeModalVisible={setIsEditNativeModalVisible}
                    editNativeId={editNativeId}
                    selectedNative={selectedNative?.[0]}
                    setEditNativeId={setEditNativeId}
                    setCommunityId={setCommunityId}
                  />

                  <AddMeetingModal
                    isAddMeetingModalVisible={isAddMeetingModalVisible}
                    setIsAddMeetingModalVisible={setIsAddMeetingModalVisible}
                    editMeetingId={editMeetingId}
                    setEditMeetingId={setEditMeetingId}
                    iseditMeetingModalVisible={iseditMeetingModalVisible}
                    setIseditMeetingModalVisible={setIseditMeetingModalVisible}
                    selectedMeeting={selectedMeeting}
                    allMeetings={allMeetings}
                    setCommunityId={setCommunityId}
                    setYear={setYear}
                  />

                  <UploadDocument
                    isUploadDocsVisible={isUploadDocsVisible}
                    setIsUploadDocsVisible={setIsUploadDocsVisible}
                    documentYear={documentYear}
                    setCommunityId={setCommunityId}
                    isCreateDocumentLoading={isCreateDocumentLoading}
                  />

                  <AddDesignationModal
                    isAddDesignationModalVisible={isAddDesignationModalVisible}
                    setIsAddDesignationModalVisible={
                      setIsAddDesignationModalVisible
                    }
                    isEditDesignationModalVisible={
                      isEditDesignationModalVisible
                    }
                    setIsEditDesignationModalVisible={
                      setIsEditDesignationModalVisible
                    }
                    selectedDesignation={selectedDesignation?.[0]}
                    editDesignationId={editDesignationId}
                    isCreateDesignationsLoading={isCreateDesignationsLoading}
                    isUpdateDesignationsLoading={isUpdateDesignationsLoading}
                    setEditDesignationId={setEditDesignationId}
                    setCommunityId={setCommunityId}
                  />
                  <AddEducationModal
                    isAddEducationModalVisible={isAddEducationModalVisible}
                    setIsAddEducationModalVisible={
                      setIsAddEducationModalVisible
                    }
                    isCreateEducationLoading={isCreateEducationLoading}
                    isEditEducationModalVisible={isEditEducationModalVisible}
                    editEducationId={editEducationId}
                    setIsEditEducationModalVisible={
                      setIsEditEducationModalVisible
                    }
                    selectedEducation={selectedEducation[0]}
                    setEditEducationId={setEditEducationId}
                    isUpdateEducationLoading={isUpdateEducationLoading}
                  />
                  <AddIndustryModal
                    isEditIndustryModalVisible={isEditIndustryModalVisible}
                    isAddIndustryModalVisible={isAddIndustryModalVisible}
                    setIsAddIndustryModalVisible={setIsAddIndustryModalVisible}
                    setIsEditIndustryModalVisible={
                      setIsEditIndustryModalVisible
                    }
                    editIndustryId={editIndustryId}
                    selectedIndustry={selectedIndustry[0]}
                    setEditIndustryId={setEditIndustryId}
                    isCreateIndustryLoading={isCreateIndustryLoading}
                    isUpdateIndustryLoading={isUpdateIndustryLoading}
                  />
                  <AddCommunityCardModal
                    isCreateCommunityCardVisible={isCreateCommunityCardVisible}
                    setIsCreateCommunityCardVisible={
                      setIsCreateCommunityCardVisible
                    }
                    selectedCommunityCard={selectedCommunityCard}
                    isEditCommunityCardVisible={isEditCommunityCardVisible}
                    setIsEditCommunityCardVisible={
                      setIsEditCommunityCardVisible
                    }
                    editCommunityCardId={editCommunityCardId}
                    setEditCommunityCardId={setEditCommunityCardId}
                    communityCardPage={communityCardPage}
                    isUpdateCommunityCardsLoading={
                      isUpdateCommunityCardsLoading
                    }
                    isCreateCommunityCardsLoading={
                      isCreateCommunityCardsLoading
                    }
                  />
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {tabKey === '1' && (
          <Row justify='space-around'>
            <Col span={23}>
              <DesignationTable
                setIsEditDesignationModalVisible={
                  setIsEditDesignationModalVisible
                }
                setEditDesignationId={setEditDesignationId}
                editDesignationId={editDesignationId}
                allDesignations={allDesignations}
                isGetDesignationsLoading={isGetDesignationsLoading}
                setCommunityId={setCommunityId}
              />
            </Col>
          </Row>
        )}
        {tabKey === '2' && (
          <Row justify='space-around'>
            <Col span={23}>
              <Nativetable
                setEditNativeId={setEditNativeId}
                setIsEditNativeModalVisible={setIsEditNativeModalVisible}
                allCommunitiesNative={allCommunitiesNative}
                isCommunitiesNativeLoading={isCommunitiesNativeLoading}
                setCommunityId={setCommunityId}
              />
            </Col>
          </Row>
        )}
        {tabKey === '3' && (
          <Row justify='space-around'>
            <Col span={23}>
              <EducationTable
                allEducations={allEducations}
                isEducationsLoading={isEducationsLoading}
                isEditEducationModalVisible={isEditEducationModalVisible}
                setIsEditEducationModalVisible={setIsEditEducationModalVisible}
                setEditEducationId={setEditEducationId}
              />
            </Col>
          </Row>
        )}
        {tabKey === '4' && (
          <Row justify='space-around'>
            <Col span={23}>
              <IndustryTable
                allIndustries={allIndustries}
                setEditIndustryId={setEditIndustryId}
                setIsEditIndustryModalVisible={setIsEditIndustryModalVisible}
              />
            </Col>
          </Row>
        )}
        {tabKey === '5' && (
          <Row justify='space-around'>
            <Col span={23}>
              <Settings setCommunityId={setCommunityId} />
            </Col>
          </Row>
        )}
        {tabKey === '6' && (
          <Row justify='space-around'>
            <Col span={23}>
              <VolunteerTable
                setTabKey={setTabKey}
                setCommunityId={setCommunityId}
              />
            </Col>
          </Row>
        )}
        {tabKey === '8' && (
          <Row justify='space-around'>
            <Col span={23}>
              <MeetingTable
                setEditMeetingId={setEditMeetingId}
                setIseditMeetingModalVisible={setIseditMeetingModalVisible}
                allMeetings={allMeetings}
                isCommunityMeetingsLoading={isCommunityMeetingsLoading}
                isPublishMeeting={isPublishMeeting}
                setIsPublishMeeting={setIsPublishMeeting}
                setCommunityId={setCommunityId}
                setYear={setYear}
                year={year}
              />
            </Col>
          </Row>
        )}

        {tabKey === '9' && (
          <Row justify='space-around'>
            <Col span={23}>
              <DocumnetTable
                allDocuments={allDocuments}
                setDocumentYear={setDocumentYear}
                setEditDocumentId={setEditDocumentId}
                isDocumentVisible={isDocumentVisible}
                setIsDocumentVisible={setIsDocumentVisible}
                selectedDocument={selectedDocument}
                setCommunityId={setCommunityId}
                documentYear={documentYear}
                isDocumentLoading={isDocumentLoading}
                isDeleteDocumentLoading={isDeleteDocumentLoading}
              />
            </Col>
          </Row>
        )}

        {tabKey === '7' && <HandleUsers />}
        {tabKey === '10' && (
          <Row justify='space-around'>
            <Col span={23}>
              <CommunityCardsTable
                allCommunityCards={allCommunityCards}
                setCommunityCardPage={setCommunityCardPage}
                communityCardPage={communityCardPage}
                setEditCommunityCardId={setEditCommunityCardId}
                // editCommunityCardId={editCommunityCardId}
                setIsEditCommunityCardVisible={setIsEditCommunityCardVisible}
              />
            </Col>
          </Row>
        )}
        {tabKey === '11' && <VerifyUsers />}
        {tabKey === '12' && (
          <Row justify='space-around'>
            <Col span={23}>
              <ManageTrustee />
            </Col>
          </Row>
        )}
      </Col>
    </Col>
  );
};

export default Admin;
