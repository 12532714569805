import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Typography, Divider, Avatar, PageHeader } from 'antd';
import '../FindOtherPeople.css';
import { useDispatch, useSelector } from 'react-redux';
import { businessProfileById } from '../../Actions/businessAction';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOutlined } from '@ant-design/icons';
import LoadingIndicator from '../../Screens/LoadingIndicator/LoadingIndicator';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { falseyValueCases } from '../../Screens/CommonLogics/CommonMethods';

const BusinessProfileDetails = (props) => {
  // const {
  //   setViewBusinessProfileId,
  //   viewBusinessProfileId,
  //   selectedBusiness,
  //   isSelectedBusinessProfileVisible,
  // } = props;

  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const { businessProfile } = useSelector((state) => {
    const { businessProfile } = state.business;
    return {
      businessProfile,
    };
  });

  const [isBusinessLoading, setIsBusinessLoading] = useState(true);

  const {
    company_name,
    business_location,
    is_retail,
    // number_of_staff,
    // business_details,
    // service_provide,
    // working_here_since,
  } = businessProfile || {};

  useEffect(() => {
    dispatch(
      businessProfileById(id, onBusinessProfileSucess, onBusinessProfileFailure)
    );
  }, [dispatch, id]);

  const handleCloseBusinessProfile = () => {
    // setViewBusinessProfileId('');
    history.back();
  };
  const onBusinessProfileSucess = () => {
    setIsBusinessLoading(false);
  };
  const onBusinessProfileFailure = () => {
    setIsBusinessLoading(false);
  };

  const secondColor = '#' + Math.floor(Math.random() * 1677).toString(14);
  const height = window.innerHeight - 400;

  return (
    <LoadingIndicator loading={isBusinessLoading}>
      <Col style={{ height: height, overflow: 'hidden', overflowY: 'scroll' }}>
        {isBusinessLoading ? null : (
          <Row justify='space-around'>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              {
                <ArrowLeftOutlined
                  style={{ paddingBottom: '20px', fontSize: '20px' }}
                  onClick={() => handleCloseBusinessProfile()}
                />
              }
            </Col>
            {!falseyValueCases(businessProfile) ? (
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Card className='profileDetails-card'>
                  <Row justify='space-between'>
                    <Col xl={4} lg={4} md={4} sm={4} xs={24}>
                      <Avatar
                        shape='square'
                        size='large'
                        style={{
                          width: '127px',
                          height: '125px',
                          background: secondColor,
                        }}
                        className='BusinessProfileAvatar'
                      >
                        {company_name[0]}
                        {/* {isSelectedBusinessProfileVisible
                        ? selectedBusiness?.company_name
                            ?.match(/\b(\w)/g)
                            .join('')
                        : company_name?.match(/\b(\w)/g).join('')} */}
                      </Avatar>
                    </Col>

                    <Col xl={20} lg={19} md={18} sm={17} xs={24}>
                      <Row gutter={[0, 10]}>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <Row gutter={[0, 10]}>
                            <Col xs={24} />
                            <Col>
                              <Typography className='profile-name'>
                                {company_name}
                                {/* {isSelectedBusinessProfileVisible
                                ? selectedBusiness?.company_name
                                : company_name} */}
                              </Typography>
                            </Col>
                            <Col>
                              <Typography
                                className='profile-address'
                                style={{ marginTop: '3px' }}
                              >
                                {businessProfile?.is_retail
                                  ? '(Retail Business)'
                                  : businessProfile?.is_wholesale
                                  ? '(Wholesale Business)'
                                  : is_retail
                                  ? '(Retail Business)'
                                  : '(Wholesale Business)'}
                              </Typography>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Typography className='BusinessEstablishedDate'>
                            {t('content.53')}:{' '}
                            <Typography
                              component='span'
                              className='BusinessEstablishedDateData'
                            >
                              {businessProfile?.working_here_since}
                            </Typography>
                          </Typography>
                        </Col>
                      </Row>
                      <Divider className='JobProfileDivider' />
                      <Col xl={18} lg={18} md={18} sm={24} xs={24}>
                        <Typography className='BusinessLocation'>
                          {t('content.51')}: &nbsp;
                          <Typography
                            component='span'
                            className='BusinessLocationData'
                          >
                            {business_location || ''}
                          </Typography>
                        </Typography>
                      </Col>
                    </Col>
                  </Row>

                  <Row justify='space-around' gutter={[24, 32]}>
                    <Col span={24}></Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <PageHeader
                        title={t('content.60')}
                        className='pageHeaderDetails'
                        extra={[
                          <Typography className='pageHeaderSubDetails'>
                            {t('content.61')}{' '}
                            <Typography
                              className='BusinessLocation'
                              component='span'
                            >
                              {businessProfile.number_of_staff}
                              {/* {isSelectedBusinessProfileVisible
                              ? selectedBusiness?.number_of_staff
                              : number_of_staff} */}
                            </Typography>{' '}
                            {t('content.62')}
                          </Typography>,
                        ]}
                      ></PageHeader>
                      <Typography
                        className='pageHeaderSubDetails'
                        style={{ padding: '2px 20px' }}
                      >
                        {businessProfile?.business_details}
                        {/* {isSelectedBusinessProfileVisible
                        ? selectedBusiness?.business_details
                        : business_details} */}
                      </Typography>
                    </Col>
                  </Row>

                  <Row justify='space-around' gutter={[24, 16]}>
                    <Col span={24}></Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <PageHeader
                        title={t('content.63')}
                        className='pageHeaderDetails'
                        extra={[
                          <Typography className='pageHeaderSubDetails'>
                            {businessProfile?.service_provide}
                            {/* {isSelectedBusinessProfileVisible
                            ? selectedBusiness?.service_provide
                            : service_provide} */}
                          </Typography>,
                        ]}
                      ></PageHeader>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ) : (
              <Row justify='center' align='middle'>
                <Col>{t('content.347')}</Col>
              </Row>
            )}
          </Row>
        )}
      </Col>
    </LoadingIndicator>
  );
};
export default React.memo(BusinessProfileDetails);
