import React, { useEffect, useState } from 'react';
import { Card, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import './App.css';
import Login from './Screens/Login';
import { useDispatch, useSelector } from 'react-redux';
import { getCommunityById } from './Actions/communitiesAction';
import LoadingIndicator from './Screens/LoadingIndicator/LoadingIndicator';
import SamajIconSvg from './assets/SamajIconSvg.svg';
function Main() {
  const history = useHistory();
  const communityData = history?.location?.state
    ? history?.location?.state.data
    : null;

  const {
    isLoginUserLoading,
    isCreateUserLoading,
    isCommunityByIdLoading,
    setCommunityId,
  } = useSelector((state) => {
    const { isCommunityByIdLoading, setCommunityId } = state.communities;
    const { isLoginUserLoading, isCreateUserLoading } = state.authentication;

    return {
      isLoginUserLoading,
      isCreateUserLoading,
      isCommunityByIdLoading,
      setCommunityId,
    };
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCommunityById(setCommunityId));
  }, [dispatch]);
  const [is_signupTab, setIsSignupTab] = useState(false);

  return (
    <>
      <Col style={{ background: '#F3F7FD' }} className='MainLoadingIndicator'>
        <LoadingIndicator
          loading={
            isLoginUserLoading || isCreateUserLoading || isCommunityByIdLoading
          }
        >
          <Col
            style={{
              padding: '20px 0px 0px 20px',
              position: 'absolute',
            }}
          >
            <img src={SamajIconSvg} alt='samajlogo' data-testid='logo-1' />
          </Col>
          <Col
            style={{
              height: '100vh',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Card style={{ background: '#FFFFFF', width: '430px' }}>
              <Login
                setIsSignupTab={setIsSignupTab}
                is_signupTab={is_signupTab}
                communityData={communityData}
              />
            </Card>
          </Col>
        </LoadingIndicator>
      </Col>
    </>
  );
}
export default Main;
