import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  Typography,
  Divider,
  Empty,
  Button,
  Select,
} from 'antd';
import '../FindOtherPeople.css';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOutlined, UserOutlined } from '@ant-design/icons';
import _, { find } from 'lodash';
import LoadingIndicator from '../../Screens/LoadingIndicator/LoadingIndicator';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import { getMatrimonialMatchesAction } from '../../Actions/matrimonialAction';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { newImageUrl } from '../../Screens/CommonLogics/CommonMethods';

dayjs.extend(advancedFormat);

const ViewMyMatches = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    setViewMatrimonialProfileId,
    setIsViewMyMatchesVisible,
    currentMatchesPage,
    selectedUserId,
  } = props;
  const { matrimonialMatches, isMatrimonialMatchesLoading } = useSelector(
    (state) => {
      const {
        allMatrimonial,
        isMatrimonialLoading,
        matrimonialMatches,
        isMatrimonialMatchesLoading,
      } = state.matrimonial;
      return {
        allMatrimonial,
        isMatrimonialLoading,
        matrimonialMatches,
        isMatrimonialMatchesLoading,
      };
    }
  );
  useEffect(() => {
    dispatch(getMatrimonialMatchesAction(selectedUserId, currentMatchesPage));
  }, [currentMatchesPage]);
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({});
  const [filteredMatchesRecords, setFilteredMatchesRecords] = useState([]);
  const [isFilterSearchRecords, setIsFilterSearchRecords] = useState(false);
  const handleClick = (matrimonialProfileId) => {
    setViewMatrimonialProfileId(matrimonialProfileId);
    setIsViewMyMatchesVisible(false);
  };
  const handleCloseMatrimonialProfile = () => {
    setViewMatrimonialProfileId('');
    setFilteredMatchesRecords([]);
  };
  const DropdownList = [
    { label: 'Married', value: 'married' },
    { label: 'Unmarried', value: 'unmarried' },
    { label: 'Widow', value: 'widow' },
    { label: 'Widower', value: 'widower' },
  ];
  const DropdownListLocation = [
    { label: 'Ahmedabad', value: 'Ahmedabad' },
    { label: 'Delhi', value: 'Delhi' },
  ];
  const DropdownListWork = [
    { label: 'Business', value: 'Business' },
    { label: 'Job', value: 'Job' },
  ];
  const handleSearchMethod = (data) => {
    const filterMethods2 = (item) => {
      const marital_exist = data?.Marital_Status
        ? item.marital_status === data?.Marital_Status
        : true;
      const location_exist = data?.Location
        ? item.current_city === data?.Location
        : true;
      const work_exist = data?.Work
        ? find(item.user.business_profiles, (profile) => {
            return (
              (profile?.is_business && data?.Work === 'Business') ||
              (!profile?.is_business && data?.Work === 'Job')
            );
          })
        : true;

      return [marital_exist && location_exist && work_exist];
    };
    const filteredArray2 = matrimonialMatches?.my_matches.filter((matches) => {
      return find(filterMethods2(matches), (method) => {
        return method;
      });
    });
    setIsFilterSearchRecords(true);
    setFilteredMatchesRecords(filteredArray2);
  };

  const ImageGalleryComponent = ({ imageKeys }) => {
    const [galleryImages, setGalleryImages] = useState([]);

    useEffect(() => {
      const loadImages = async () => {
        const imagePromises = imageKeys?.map(async (val, i) => {
          return {
            original: await newImageUrl(val),
          };
        });

        const images = await Promise.all(imagePromises);
        setGalleryImages(images);
      };

      if (imageKeys?.length > 0) {
        loadImages();
      }
    }, [imageKeys]);

    return (
      <Row>
        <Col span={24}>
          {galleryImages.length > 0 && (
            <ImageGallery
              items={galleryImages}
              additionalClass='ImageViewMatrimonial'
              showNav={false}
              showBullets={true}
              showThumbnails={false}
              originalClass='ImageViewMatrimonial'
            />
          )}
        </Col>
      </Row>
    );
  };
  return (
    <>
      <Row justify='space-between' gutter={[0, 8]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <ArrowLeftOutlined
            style={{ paddingBottom: '20px', fontSize: '20px' }}
            onClick={() => handleCloseMatrimonialProfile()}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={20} xxl={20} lg={24} md={24} sm={24} xs={24}>
          <Row>
            <form onSubmit={handleSubmit(handleSearchMethod)}>
              <Col xl={22} xxl={22} md={24} sm={24} xs={24}>
                <Row gutter={[32, 16]}>
                  <Col xl={5} xxl={5} lg={5} md={8} sm={8} xs={12}>
                    <Row gutter={[0, 16]}>
                      <Col span={24}>
                        <Typography className='MatrimonialWorkTitle'>
                          Work
                        </Typography>
                      </Col>
                      <Col span={24}>
                        <Controller
                          name='Work'
                          as={
                            <Select
                              style={{ width: '100%' }}
                              placeholder='Select Work'
                              size='large'
                              className='MatrimonialViewMyMatchesSelect'
                              options={DropdownListWork}
                            />
                          }
                          control={control}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={5} xxl={5} lg={5} md={8} sm={8} xs={12}>
                    <Row gutter={[0, 16]}>
                      <Col span={24}>
                        <Typography className='MatrimonialWorkTitle'>
                          Salary
                        </Typography>
                      </Col>
                      <Col span={24}>
                        <Controller
                          name='Range'
                          control={control}
                          as={
                            <Select
                              style={{ width: '100%' }}
                              placeholder='Select Range'
                              size='large'
                              className='MatrimonialViewMyMatchesSelect'
                            />
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={5} xxl={5} lg={5} md={8} sm={8} xs={12}>
                    <Row gutter={[0, 16]}>
                      <Col span={24}>
                        <Typography className='MatrimonialWorkTitle'>
                          Location
                        </Typography>
                      </Col>
                      <Col span={24}>
                        <Controller
                          as={
                            <Select
                              style={{ width: '100%' }}
                              placeholder='Select Location'
                              size='large'
                              className='MatrimonialViewMyMatchesSelect'
                              options={DropdownListLocation}
                            />
                          }
                          name='Location'
                          control={control}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={5} xxl={5} lg={5} md={8} sm={8} xs={12}>
                    <Row gutter={[0, 16]}>
                      <Col span={24}>
                        <Typography className='MatrimonialWorkTitle'>
                          Marrital Status
                        </Typography>
                      </Col>
                      <Col span={24}>
                        <Controller
                          as={
                            <Select
                              style={{ width: '100%' }}
                              options={DropdownList}
                              placeholder='Select Status'
                              size='large'
                              className='MatrimonialViewMyMatchesSelect'
                            />
                          }
                          name='Marital_Status'
                          control={control}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={4} xxl={4} lg={24}>
                    <Row gutter={[0, 16]}>
                      <Col span={24} />
                      <Col span={24} />
                      <Col span={24}>
                        <Button
                          className='button-search'
                          size='large'
                          type='primary'
                          htmlType='submit'
                        >
                          Search
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </form>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[0, 8]}>
        <Col span={24}></Col>
        <Col span={24}>
          <Typography className='matches'>{t('welcomeText.18')}</Typography>
        </Col>
        <Col span={24}>
          <Typography className='matchesDetail'>
            Total{' '}
            {
              (isFilterSearchRecords
                ? filteredMatchesRecords
                : matrimonialMatches?.my_matches
              )?.length
            }{' '}
            Profile matches with your biographic
          </Typography>
        </Col>
      </Row>
      <LoadingIndicator loading={isMatrimonialMatchesLoading}>
        {(isFilterSearchRecords
          ? filteredMatchesRecords
          : matrimonialMatches?.my_matches
        )?.length > 0 ? (
          <Row gutter={[32, 32]} style={{ marginTop: '30px' }}>
            {(isFilterSearchRecords
              ? filteredMatchesRecords
              : matrimonialMatches?.my_matches
            )?.map((val, i) => {
              const noData1 = _.flatten(
                val?.matrimonial?.user?.user_addresses?.filter((address, i) => {
                  return address?.length &&
                    val?.matrimonial?.user?.user_addresses[i]?.length === 0
                    ? 0
                    : 1;
                })
              );
              return (
                <Col xl={8} lg={12} md={12} sm={24} xs={24} key={i}>
                  <Card className='MatchesCard'>
                    {val?.matrimonial?.matrimonial_user_images?.length > 0 && (
                      <Row>
                        <Col span={24}>
                          <ImageGalleryComponent
                            imageKeys={
                              val?.matrimonial?.matrimonial_user_image_keys
                            }
                          />
                        </Col>
                      </Row>
                    )}
                    <Col style={{ padding: '24px' }}>
                      <Row gutter={[0, 16]}>
                        <Col span={24}>
                          <Typography
                            className='profile-name'
                            style={{
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {val?.matrimonial?.user?.first_name}&nbsp;
                            {val?.matrimonial?.user?.last_name}
                          </Typography>
                        </Col>
                        <Col span={24}>
                          <Row justify='space-between'>
                            <Col>
                              <Typography className='profile-birthdate'>
                                {dayjs(
                                  val?.matrimonial?.user?.birth_month +
                                    '/' +
                                    val?.matrimonial?.user?.birth_date +
                                    '/' +
                                    val?.matrimonial?.user?.birth_year
                                ).format('Do MMMM YYYY')}
                              </Typography>
                            </Col>
                            <Col>
                              <Row justify='end' style={{ textAlign: 'end' }}>
                                <Col span={24}>
                                  <Typography className='View-matchPercentage'>
                                    {val?.matching_percentage}
                                  </Typography>
                                </Col>
                                <Col span={24}>
                                  <Typography className='View-matches'>
                                    Matches
                                  </Typography>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row gutter={[0, 16]}>
                        <Divider />
                      </Row>
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <Typography className='profile-address'>
                            {noData1.length > 0 &&
                              noData1.map((Address, i) => {
                                return (
                                  <Row key={i}>
                                    <Col span={24}>
                                      {Address?.address_line_1 +
                                        ' ' +
                                        Address?.home_number +
                                        ' ' +
                                        Address?.address_line_2 +
                                        ' ' +
                                        Address?.city +
                                        ',' +
                                        Address?.zip}
                                    </Col>
                                  </Row>
                                );
                              })}
                          </Typography>
                        </Col>
                        <Col
                          xl={24}
                          lg={24}
                          md={24}
                          sm={24}
                          xs={24}
                          style={{ textAlign: 'end' }}
                        >
                          <Button
                            className=' View-fullProfile'
                            onClick={() => handleClick(val.id)}
                          >
                            {t('btn.26')}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Card>
                </Col>
              );
            })}
          </Row>
        ) : (
          !isMatrimonialMatchesLoading && (
            <Row justify='center'>
              <Empty
                description={
                  <Typography className='empty-details'>
                    {t('content.293')}
                  </Typography>
                }
                image={
                  <UserOutlined
                    style={{ fontSize: '38px', color: '#828282' }}
                  />
                }
                imageStyle={{
                  height: 38,
                }}
              />
            </Row>
          )
        )}
      </LoadingIndicator>
    </>
  );
};
export default ViewMyMatches;
