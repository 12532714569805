import { Row, Col, Button } from 'antd';
import React, { useState } from 'react';
import { Tabs } from 'antd';
import '../../Screens/Admin/Admin.css';
import BusinessProfileModalForm from './BusinessProfileModalForm';
import BusinessProfileDetails from './BusinessProfileDetails';
import ViewBusinessProfile from '../BusinessProfiles/ViewBusinessProfile';
import JobProfileDetails from '../JobProfile/JobProfileDetails';
import ViewJobProfile from '../JobProfile/ViewJobProfile';
import JobProfileModalForm from '../JobProfile/JobProfileModalForm';
import { useTranslation } from 'react-i18next';
import { analytics } from '../../components/Mixpanel/Mixpanel';
const BusinessProfile = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [viewBusinessProfileId, setViewBusinessProfileId] = useState('');
  const [editId, setEditId] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('');
  const { TabPane } = Tabs;
  const [tabKey, setTabkey] = useState('1');

  const onTabClick = (key) => {
    if (key === '1') {
      analytics?.track('BuisnessProfiles_');
    } else if (key === '2') {
      analytics?.track('BuisnessProfile_JobProfile');
    }
    setTabkey(key);
  };
  const height = window.innerHeight * 0.95;
  return (
    <Col
      style={{
        height: height,
        overflow: 'hidden',
        overflowY: 'scroll',
      }}
      className='customLayout'
    >
      <Row justify='space-between' style={{ padding: '24px' }}>
        <Col>
          {!viewBusinessProfileId && (
            <Col>
              <Tabs
                defaultActiveKey='1'
                onTabClick={onTabClick}
                animated={false}
                renderTabBar={(props, DefaultTabBar) => (
                  <DefaultTabBar {...props} className='cMTabsBckground'>
                    {(node) => (
                      <Col key={node.key} index={node.key}>
                        {node}
                      </Col>
                    )}
                  </DefaultTabBar>
                )}
              >
                <TabPane
                  tab={
                    <Button
                      className={
                        tabKey === '1' ? 'selectedButton' : 'unselectedButton'
                      }
                    >
                      {t('btn.41')}
                    </Button>
                  }
                  key='1'
                ></TabPane>

                <TabPane
                  tab={
                    <Button
                      className={
                        tabKey === '2' ? 'selectedButton' : 'unselectedButton'
                      }
                    >
                      {t('btn.42')}
                    </Button>
                  }
                  key='2'
                ></TabPane>
              </Tabs>
            </Col>
          )}
        </Col>
        <Col style={{ textAlign: 'end' }}>
          {tabKey === '1' ? (
            <>
              {!viewBusinessProfileId && (
                <Button
                  onClick={() => {
                    analytics?.track('AddBuisnessProfile');
                    setVisible(true);
                  }}
                  className='button-search'
                  type='primary'
                  style={{ width: '100%' }}
                >
                  {t('btn.33')}
                </Button>
              )}
              <BusinessProfileModalForm
                visible={visible}
                setVisible={setVisible}
                editId={editId}
                setEditId={setEditId}
                selectedUserId={selectedUserId}
                setSelectedUserId={setSelectedUserId}
              />
            </>
          ) : (
            <>
              {!viewBusinessProfileId && (
                <Button
                  type='primary'
                  onClick={() => setVisible(true)}
                  className='button-search'
                >
                  {t('btn.34')}
                </Button>
              )}
              <JobProfileModalForm
                visible={visible}
                setVisible={setVisible}
                editId={editId}
                setEditId={setEditId}
                selectedUserId={selectedUserId}
                setSelectedUserId={setSelectedUserId}
              />
            </>
          )}
        </Col>
      </Row>
      <Row style={{ padding: 20 }}>
        <Col span={24}>
          {tabKey === '1' ? (
            viewBusinessProfileId ? (
              <BusinessProfileDetails
                editId={editId}
                setEditId={setEditId}
                visible={visible}
                setVisible={setVisible}
                viewBusinessProfileId={viewBusinessProfileId}
                setViewBusinessProfileId={setViewBusinessProfileId}
              />
            ) : (
              <ViewBusinessProfile
                editId={editId}
                setEditId={setEditId}
                visible={visible}
                setVisible={setVisible}
                viewBusinessProfileId={viewBusinessProfileId}
                setViewBusinessProfileId={setViewBusinessProfileId}
              />
            )
          ) : null}
          {tabKey === '2' ? (
            viewBusinessProfileId ? (
              <JobProfileDetails
                editId={editId}
                setEditId={setEditId}
                visible={visible}
                setVisible={setVisible}
                viewBusinessProfileId={viewBusinessProfileId}
                setViewBusinessProfileId={setViewBusinessProfileId}
              />
            ) : (
              <ViewJobProfile
                editId={editId}
                setEditId={setEditId}
                visible={visible}
                setVisible={setVisible}
                viewBusinessProfileId={viewBusinessProfileId}
                setViewBusinessProfileId={setViewBusinessProfileId}
              />
            )
          ) : null}
        </Col>
      </Row>
    </Col>
  );
};
export default BusinessProfile;
