import constants from "../Utils/constants";
const initialState = {
  isSearchUserLoading: false,
  searchUsers: [],
  isUpdateVerifyUserLoading: false,
};

export const searchUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_SEARCH_USER_REQUEST:
      return {
        ...state,
        isSearchUserLoading: true,
        searchUsers: [],
      };
    case constants.GET_SEARCH_USER_SUCCESS:
      return {
        ...state,
        isSearchUserLoading: false,
        searchUsers: action.payload,
      };
    case constants.GET_SEARCH_USER_FAILURE:
      return {
        ...state,
        isSearchUserLoading: false,
      };
    case constants.UPDATE_VERIFY_USERS_REQUEST:
      return {
        ...state,
        isUpdateVerifyUserLoading: true,
      };
    case constants.UPDATE_VERIFY_USERS_SUCCESS:
      return {
        ...state,
        isUpdateVerifyUserLoading: false,
      };
    case constants.UPDATE_VERIFY_USERS_FAILURE:
      return {
        ...state,
        isUpdateVerifyUserLoading: false,
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
