import React from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import "./components.css";
function GujaratiButton() {
  const { t, i18n } = useTranslation();
  function handleclick(lang) {
    i18n.changeLanguage(lang);
  }
  return (
    <Button
      type="link"
      style={{ color: "#2ACCFF" }}
      htmlType="submit"
      onClick={() => handleclick("guj")}
      className={i18n.language === "guj" && "BoldButton"}
    >
      {t("btn.12")}
    </Button>
  );
}
export default GujaratiButton;
