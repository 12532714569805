import React, { useEffect } from 'react';
import { Row, Col, Typography, Breadcrumb, Collapse, Image } from 'antd';
import LandingPageHeader from './LandingPageHeader';
import './LandingPage.css';
import '../TermsAndConditions/TermsAndConditions.css';
import { useBreakpoints } from '../CommonLogics/ResponsiveBreakPoints';
import { HomeOutlined } from '@ant-design/icons';
import './Faqs.css';
import Footer from './Footer';
import { getJourneyById } from '../../Actions/JourneyAction';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { newImageUrl } from '../CommonLogics/CommonMethods';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

const HowtoDetails = () => {
  const { Panel } = Collapse;

  useEffect(() => {
    window.scrollTo(0, 0);
    document.querySelector('body').scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();
  const params = useParams();
  const journeyId = params.id;

  useEffect(() => {
    dispatch(getJourneyById(journeyId));
  }, [journeyId]);

  const { journey, isjourneyloading } = useSelector((state) => {
    const { journey, isjourneyloading } = state.journey;
    return {
      journey,
      isjourneyloading,
    };
  });

  const { IsMD, IsSM, IsXS, IsLG, IsXL, IsXXL } = useBreakpoints();
  return (
    <Col style={{ backgroundColor: '#FAFAFA' }}>
      <Col className='TermsAndConditionsImageContainerTest'>
        <LandingPageHeader />
        <Row
          justify='center'
          align='middle'
          gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 32]}
        >
          <Col span={24}></Col>
          <Col span={24}></Col>
          <Col span={24}></Col>
          <Col span={24}></Col>
          <Col>
            <Row>
              <Typography className='TermsAndConditionsH1'>'How To'</Typography>
            </Row>
          </Col>
          <Col span={24}></Col>
          <Col span={24}></Col>
        </Row>
      </Col>
      <Row gutter={[16, 32]}>
        <Col span={24}></Col>
        <Col span={2}></Col>
        <Col span={16}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/'>
                <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/blogs'>Blogs</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/howto'>How to</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item> {journey?.title}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Col
        className='LandingPageCardResponsive'
        style={{
          margin: (IsMD || IsLG || IsXL || IsXXL || IsSM || IsXS) && '5% 10%',
        }}
      >
        <Row justify='center'>
          <Col span={24}>
            <Typography className='cardTitle' style={{ color: '#303134' }}>
              {journey?.title}
            </Typography>
          </Col>
          <Col span={24}>
            <Typography
              className='cardTitle2'
              style={{ color: '#303134', marginLeft: '10px' }}
            >
              {journey?.description}
            </Typography>
          </Col>
        </Row>
        <LoadingIndicator loading={isjourneyloading}>
          <Collapse bordered={false}>
            {journey?.journey_routes?.map((val, i) => {
              return (
                <Panel
                  header={`Step${val.position}`}
                  className='cardTitle'
                  key={i}
                >
                  <Typography
                    className='cardTitle2'
                    style={{ color: '#000000' }}
                  >
                    {val.description}
                  </Typography>
                  {val.journey_route_image_key === null ? (
                    ''
                  ) : (
                    <Col className='blogImage'>
                      {' '}
                      <Image
                        width={526}
                        height={325}
                        src={
                          val.journey_route_image_key &&
                          newImageUrl(val.journey_route_image_key)
                        }
                      />
                    </Col>
                  )}
                </Panel>
              );
            })}
          </Collapse>
        </LoadingIndicator>
      </Col>
      <Footer />
    </Col>
  );
};

export default HowtoDetails;
