import React, { useEffect } from 'react';
import { Row, Col, Typography, Card, Breadcrumb, Button, Image } from 'antd';
import LandingPageHeader from './LandingPageHeader';
import './LandingPage.css';
import '../TermsAndConditions/TermsAndConditions.css';
import { useBreakpoints } from '../CommonLogics/ResponsiveBreakPoints';
import { HomeOutlined } from '@ant-design/icons';
import { history } from '../../Utils/history';
import moment from 'moment';
import './Faqs.css';
import './Blogs.css';
import Footer from './Footer';
import { getJourney } from '../../Actions/JourneyAction';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import CommunityHowtos from '../../assets/CommunityHowtos.png';
import FindMembersCommunity from '../../assets/FindMembersCommunity.png';
import JoinUsHowtos from '../../assets/JoinUsHowtos.png';
import { newImageUrl } from '../CommonLogics/CommonMethods';
import ImageView from '../../components/ImageView';

const Howto = () => {
  const { allJourney } = useSelector((state) => {
    const { allJourney } = state.journey;
    return {
      allJourney,
    };
  });
  const dispatch = useDispatch();
  const { IsMD, IsSM, IsXS, IsLG, IsXL, IsXXL } = useBreakpoints();
  useEffect(() => {
    dispatch(getJourney(`api/journeys`));
  }, []);

  const handleOpenJouney = (Id) => {
    const id = Id.id;
    history.push(`/howtodetails/${id}`);
    dispatch({
      type: 'GET_SELECTED_JOURNEY_ID',
      payload: Id,
    });
  };

  return (
    <Col className='MainbackgroundColor'>
      <Col className='TermsAndConditionsImageContainer'>
        <LandingPageHeader />
        <Row
          style={{ height: '100%', padding: (IsXS || IsSM) && '100px' }}
          justify='center'
          align='middle'
        >
          <Typography className='TermsAndConditionsH1'>How To's</Typography>
        </Row>
      </Col>
      <Col
        className='CurvMainCard'
        style={{ background: '#FFFFFF', borderRadius: '50px 50px 0 0' }}
      >
        <Row
          justify='center'
          style={{
            marginTop: '50px',
          }}
        >
          <Col className='ContentContainer'>
            <Row>
              <Col
                span={24}
                style={{ padding: IsXS ? '0px 25px 25px 25px' : 0 }}
              >
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to='/'>
                      <HomeOutlined />
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to='/blogs'>Blogs</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>How to</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col span={24} style={{ marginTop: '50px' }}>
                <Row gutter={[20, 20]} style={{ padding: IsXS ? '25px' : 0 }}>
                  {allJourney?.map((val, i) => {
                    return (
                      <>
                        <Col
                          xs={24}
                          sm={24}
                          md={4}
                          xl={6}
                          lg={8}
                          style={{ borderRadius: '20px' }}
                        >
                          {/* <Image src={newImageUrl(val?.journey_image_key)} /> */}
                          <ImageView
                            imageKeys={val?.journey_image_key}
                            imageWidth='226px'
                            imageHeight='197px'
                          />
                        </Col>
                        <Col xs={24} sm={24} md={20} xl={18} lg={16}>
                          <Row gutter={[0, 30]} style={{ marginTop: '2px' }}>
                            <Col span={24}>
                              <Typography className='HowtosCardTitle'>
                                {val?.title}
                              </Typography>
                            </Col>
                            <Col span={24}>
                              <Typography className='HowtosCardDiscription'>
                                {val?.description}
                              </Typography>
                            </Col>
                            <Col
                              style={{
                                display: 'flex',
                                justifyContent: 'end',
                              }}
                              span={24}
                            >
                              <Button
                                className='HowtosCheckoutButton'
                                onClick={() =>
                                  history.push(`${val?.url}/${val?.id}`)
                                }
                              >
                                Check Out
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify='center'>
          <Col style={{ marginTop: '100px' }}>
            <Footer />
          </Col>
        </Row>
      </Col>
    </Col>
  );
};

export default Howto;
