import API from "../Utils/API";
export const getUnderMaintenanceAction = (SuccessCallback) => (dispatch) => {
  dispatch({ type: "GET_MAINTENANCE_WINDOWS_REQUEST" });
  API.get("api/maintenance_windows/")
    .then((response) => {
      dispatch({
        type: "GET_MAINTENANCE_WINDOWS_SUCCESS",
        payload: response.data,
      });
      SuccessCallback && SuccessCallback(response.data);
    })
    .catch((error) => {
      dispatch({
        type: "GET_MAINTENANCE_WINDOWS_FAILURE",
        payload: error.response?.data,
      });
    });
};
