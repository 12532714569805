import constants from "../Utils/constants";
const initialState = {
  allIndustries: [],
  isIndustriesLoading: false,
  isCreateIndustryLoading: false,
  isUpdateIndustryLoading: false,
};

export const industryReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_INDUSTRIES_REQUEST:
      return {
        ...state,
        isIndustriesLoading: true,
      };

    case constants.GET_INDUSTRIES_SUCCESS:
      return {
        ...state,
        isIndustriesLoading: false,
        allIndustries: action.payload,
      };
    case constants.GET_INDUSTRIES_FAILURE:
      return {
        ...state,
        isIndustriesLoading: false,
      };

    case constants.CREATE_INDUSTRIES_REQUEST:
      return {
        ...state,
        isCreateIndustryLoading: true,
      };
    case constants.CREATE_INDUSTRIES_SUCCESS:
      return {
        ...state,
        isCreateIndustryLoading: false,
      };
    case constants.CREATE_INDUSTRIES_FAILURE:
      return {
        ...state,
        isCreateIndustryLoading: false,
      };

    case constants.UPDATE_INDUSTRIES_REQUEST:
      return {
        ...state,
        isUpdateIndustryLoading: true,
      };
    case constants.UPDATE_INDUSTRIES_SUCCESS:
      return {
        ...state,
        isUpdateIndustryLoading: false,
      };
    case constants.UPDATE_INDUSTRIES_FAILURE:
      return {
        ...state,
        isUpdateIndustryLoading: false,
      };

    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
