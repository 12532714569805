import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Typography,
  Input,
  message,
  Select,
  DatePicker,
  Upload,
  Drawer,
} from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import '../AddFamilymember/Addfamily.css';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import _, { compact } from 'lodash';
import { getTemplatesAction } from '../../Actions/TemplatesAction';
import {
  getTemplatesElementsAction,
  createTemplatesElementsAction,
} from '../../Actions/TemplateElementsAction';
import moment from 'moment';
import {
  createCommunityCardsAction,
  updateCommunityCardsAction,
  getCommunityCardsAction,
} from '../../Actions/communityCardsAction';
import { PlusOutlined } from '@ant-design/icons';
import {
  communityCardSchema,
  communityCardSchemaNew,
} from '../../Utils/Schema';
import CommunityTextCard from '../CommunityFeeds/CommunityTextCard';
import CommunityLinkCard from '../CommunityFeeds/CommunityLinkCard';
import CommunityImageCard from '../CommunityFeeds/CommunityImageCard';
import CommunityVideoCard from '../CommunityFeeds/CommunityVideoCard';
import CommunityCongratulationsCard from '../CommunityFeeds/CommunityCongratulationsCard';
import CommunityMarriageCard from '../CommunityFeeds/CommunityMarrigeCard';
import { newImageUrl } from '../CommonLogics/CommonMethods';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
const AddCommunityCardModal = (props) => {
  const { t } = useTranslation();
  const [preloadedCommunityCardsValues, setPreloadedCommunityCardsValues] =
    useState('');

  const {
    isCreateCommunityCardVisible,
    setIsCreateCommunityCardVisible,
    isEditCommunityCardVisible,
    setIsEditCommunityCardVisible,
    selectedCommunityCard,
    editCommunityCardId,
    setEditCommunityCardId,
    communityCardPage,
    isUpdateCommunityCardsLoading,
    isCreateCommunityCardsLoading,
    isCommunityCardPublished,
  } = props;
  const newCommunityExpiryDate = moment(
    selectedCommunityCard[0]?.expiry_date,
    'YYYY-MM-DD'
  );
  const newArray = selectedCommunityCard.map((obj) => {
    return {
      ...obj,
      expiry_date: newCommunityExpiryDate,
    };
  });
  const [isDefaultImageVisible, setIsDefaultImageVisible] = useState(true);
  const [OptionSelected, setOptionSelected] = useState(
    selectedCommunityCard[0]?.card_type
  );
  const [OptiontemplateSelected, setOptiontemplateSelected] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
    watch,
  } = useForm({
    resolver: yupResolver(
      OptionSelected === 'Video' ? communityCardSchemaNew : communityCardSchema
    ),
    defaultValues: preloadedCommunityCardsValues,
  });
  const watching = watch();
  const { setTempleteElementId, setTempleteId, allTemplates } = useSelector(
    (state) => {
      const { setTempleteId, allTemplates } = state.templates;
      const { setTempleteElementId } = state.templatesElements;
      return {
        setTempleteElementId,
        setTempleteId,
        allTemplates,
      };
    }
  );
  useEffect(() => {
    setPreloadedCommunityCardsValues(newArray[0]);
    reset(newArray[0]);
    setOptionSelected(selectedCommunityCard[0]?.card_type);
    setIsDefaultImageVisible(true);
    setImgUrl(selectedCommunityCard[0]?.card_file_key);
  }, [selectedCommunityCard[0], reset]);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [newFiles, setNewFiles] = useState([]);
  const [imageRequired, setImageRequired] = useState('');
  const [videoLinkRequired, setVideoLinkRequired] = useState('');
  const [isPreview, setIsPreview] = useState(false);
  const [templatekey, setTemplatekey] = useState();
  const [TypedData, setTypedData] = useState({});
  const [imgUrl, setImgUrl] = useState(selectedCommunityCard[0]?.card_file_key);
  const [isValidUrl, setIsValidUrl] = useState('');
  const dispatch = useDispatch();
  const IsEmpty = _.isEmpty(newFiles);
  const isValidHttpUrl = (string) => {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  };

  const handleCreateCommunityCardsMethod = (data) => {
    setIsValidUrl(isValidHttpUrl(data?.url));
    const Date = moment(data?.expiry_date);
    if (OptionSelected === 'Image' && IsEmpty) {
      setImageRequired('Image is required');
    } else if (
      (OptionSelected === 'Video' || OptionSelected === 'Link') &&
      data?.url === ''
    ) {
      setVideoLinkRequired('Url is required');
    } else {
      data.expiry_date = moment(Date._d).format('yyyy/MM/DD');
      data.community_card_file = newFiles;
      setTypedData(data);
      dispatch(
        createCommunityCardsAction(
          data,
          createCommunitySuccessCallBackMethod,
          createCommunityFailureCallBackMethod,
          IsEmpty
        )
      );
    }
    setIsCreateCommunityCardVisible(false);
  };
  const createCommunitySuccessCallBackMethod = () => {
    setValue('name', '');
    setValue('url', '');
    setValue('card_type', '');
    setValue('expiry_date', '');
    setValue('description', '');
    setShowUploadImage(false);
    clearErrors();
    setIsPreview(false);
    setOptionSelected('');
    setTimeout(() => {
      message.success(t('content.313'));
    }, 1000);
  };
  const createCommunityFailureCallBackMethod = () => {};
  const cardArray = [
    { label: 'Link', value: 'Link' },
    { label: 'Text', value: 'Text' },
    { label: 'Image', value: 'Image' },
    { label: 'Video', value: 'Video' },
    { label: 'Template', value: 'Template' },
  ];
  const templaeArray = [
    { label: 'Congratulations', value: 'Congratulations' },
    { label: 'Marriage', value: 'Marriage' },
    { label: 'Achievements', value: 'Achievements' },
    { label: 'New Births', value: 'New Births' },
    { label: 'Death', value: 'Death' },
  ];
  const closeCreateCommunityCardMethod = () => {
    setIsCreateCommunityCardVisible(false);
    setValue('name', '');
    setValue('url', '');
    setValue('card_type', '');
    setValue('expiry_date', '');
    setValue('description', '');
    setEditCommunityCardId('');
    clearErrors();
    setShowUploadImage(false);
    setIsPreview(false);
    setOptionSelected('');
    setIsValidUrl('');
  };
  const closeEditCommunityCardMethod = () => {
    setIsEditCommunityCardVisible(false);
    setNewFiles([]);
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
    setValue('name', '');
    setValue('url', '');
    setValue('card_type', '');
    setValue('expiry_date', '');
    setValue('description', '');
    setEditCommunityCardId('');
    clearErrors();
    setIsPreview(false);
    setIsValidUrl('');
    reset();
  };

  const propsForUpload = {
    action: handleCreateCommunityCardsMethod || handleEditCommunityCardsMethod,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles(file);
      return false;
    },
    multiple: false,
  };
  const handleUploadMethod = (e) => {
    setShowUploadImage(true);
    setIsDefaultImageVisible(false);
    setImageRequired('');
  };
  const handleEditCommunityCardsMethod = (data) => {
    setIsValidUrl(isValidHttpUrl(data?.url));
    if (
      (OptionSelected === 'Video' || OptionSelected === 'Link') &&
      data?.url === ''
    ) {
      setVideoLinkRequired('Url is required');
    } else {
      const Date = moment(data.expiry_date);
      data.community_card_file = IsEmpty ? null : newFiles;
      data.expiry_date = moment(Date._d).format('yyyy/MM/DD');
      data.id = editCommunityCardId;
      data.description = data.description
        ? data.description
        : selectedCommunityCard[0]?.description;
      dispatch(
        updateCommunityCardsAction(
          data,
          updateCommunityCardsSuccessCallback,
          IsEmpty
        )
      );
    }
  };
  const updateCommunityCardsSuccessCallback = () => {
    setNewFiles([]);
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
    setValue('name', '');
    setValue('url', '');
    setValue('card_type', '');
    setValue('expiry_date', '');
    setValue('description', '');
    setIsEditCommunityCardVisible(false);
    setEditCommunityCardId('');
    setIsPreview(false);
    setIsValidUrl('');
    setTimeout(() => {
      message.success(t('content.314'));
    }, 1000);
    clearErrors();
  };
  useEffect(() => {
    if (!editCommunityCardId) {
      setValue('name', '');
      setValue('url', '');
      setValue('card_type', '');
      setValue('expiry_date', '');
      setValue('description', '');
      setEditCommunityCardId('');
    }
  }, [setValue, !editCommunityCardId]);
  const handleClearErrorMethod = () => {
    setVideoLinkRequired('');
    setIsValidUrl('');
  };
  const handleCreateCommunityTemplateCardsMethod = (data) => {
    data.expiry_date = moment(Date._d).format('yyyy/MM/DD');
    dispatch(
      createTemplatesElementsAction(
        data,
        createCommunityTemplateSuccessCallBackMethod,
        createCommunityTemplateFailureCallBackMethod
      )
    );
  };
  const createCommunityTemplateSuccessCallBackMethod = () => {
    setCurrentPage(1);
    setIsCreateCommunityCardVisible(false);
    setEditCommunityCardId('');
    reset();
    setValue('title', '');
    setValue('date', '');
    setValue('element_name', '');
    setValue('element_type', '');
    setValue('description', '');
    dispatch(
      getTemplatesElementsAction(
        `api/elements?template_id=${setTempleteElementId}&page=${currentPage}`
      )
    );
    clearErrors();
    setOptionSelected('');
    setTimeout(() => {
      message.success(t('content.313'));
    }, 1000);
  };
  const setTemplateImagekey = (key) => {
    setTemplatekey(key);
  };

  useEffect(async () => {
    await dispatch(
      getTemplatesAction(
        `api/templates?published=true&template_type=${OptiontemplateSelected}`
      )
    );
  }, [OptiontemplateSelected]);

  const createCommunityTemplateFailureCallBackMethod = () => {};

  const TemplateGalleryComponent = ({ imageKeys }) => {
    const [galleryImages, setGalleryImages] = useState([]);

    useEffect(() => {
      const loadImages = async () => {
        const imagePromises = compact(imageKeys)?.map(async (val, i) => {
          return {
            key: val,
            original: await newImageUrl(val),
          };
        });

        const images = await Promise.all(imagePromises);
        setGalleryImages(images);
      };

      if (imageKeys?.length > 0) {
        loadImages();
      }
    }, [imageKeys]);
    return (
      <Row>
        <CarouselProvider
          naturalSlideWidth={1}
          naturalSlideHeight={2}
          totalSlides={galleryImages.length}
        >
          <Slider style={{ width: '216px', height: '125px' }}>
            {galleryImages?.map((image, i) => {
              return (
                <Slide index={i} key={i}>
                  <img
                    src={image?.original}
                    style={{ width: '216px', height: '125px' }}
                    onClick={() => {
                      setTemplateImagekey(image.key);
                    }}
                  />
                </Slide>
              );
            })}
          </Slider>
          <ButtonBack>Back</ButtonBack>
          <ButtonNext>Next</ButtonNext>
        </CarouselProvider>
      </Row>
    );
  };

  const imageKeysArray = allTemplates?.templates?.map(
    (val) => val?.template_image
  );
  return (
    <Drawer
      open={
        isEditCommunityCardVisible
          ? isEditCommunityCardVisible
          : isCreateCommunityCardVisible
      }
      onClose={
        isEditCommunityCardVisible
          ? closeEditCommunityCardMethod
          : closeCreateCommunityCardMethod
      }
      footer={false}
      title={
        isEditCommunityCardVisible
          ? 'Edit Community Card'
          : 'Create Community Card'
      }
      centered={true}
      width={800}
      maskClassName='DrawerClass'
      className='DrawerBorderClass'
    >
      <Row gutter={[10, 0]} align='top'>
        <Col span={11}>
          <LoadingIndicator
            loading={
              isEditCommunityCardVisible
                ? isUpdateCommunityCardsLoading
                : isCreateCommunityCardsLoading
            }
          >
            {isPreview && OptionSelected === 'Text' ? (
              <CommunityTextCard
                mappedData={TypedData}
                isCommunityCardModal={true}
              />
            ) : isPreview && OptionSelected === 'Link' ? (
              <CommunityLinkCard
                mappedData={TypedData}
                isCommunityCardModal={true}
              />
            ) : isPreview && OptionSelected === 'Image' ? (
              <CommunityImageCard
                mappedData={TypedData}
                isCommunityCardModal={true}
                imgUrl={imgUrl}
              />
            ) : isPreview && OptionSelected === 'Video' ? (
              <CommunityVideoCard
                mappedData={TypedData}
                isCommunityCardModal={true}
              />
            ) : isPreview && OptionSelected === 'Template' ? (
              <>
                {OptiontemplateSelected === 'Congratulations' && (
                  <CommunityCongratulationsCard
                    mappedData={TypedData}
                    isCommunityCardModal={true}
                  />
                )}
                {OptiontemplateSelected === 'Marriage' && (
                  <CommunityMarriageCard
                    mappedData={TypedData}
                    isCommunityCardModal={true}
                  />
                )}
              </>
            ) : (
              <form
                onSubmit={handleSubmit(
                  OptionSelected === 'Templete'
                    ? isEditCommunityCardVisible
                      ? handleEditCommunityCardsMethod
                      : handleCreateCommunityTemplateCardsMethod
                    : isEditCommunityCardVisible
                    ? handleEditCommunityCardsMethod
                    : handleCreateCommunityCardsMethod
                )}
              >
                <Row gutter={[0, 10]}>
                  <Col span={24}>
                    <Row justify='center'>
                      <Col>
                        {isDefaultImageVisible &&
                          newArray[0]?.card_file &&
                          OptionSelected === 'Image' && (
                            <Upload
                              listType='picture-card'
                              openFileDialogOnClick={false}
                            >
                              <img
                                src={newArray[0]?.card_file}
                                width={102}
                                alt=''
                              ></img>
                            </Upload>
                          )}
                      </Col>

                      {OptionSelected === 'Image' && (
                        <Col>
                          <Upload
                            {...propsForUpload}
                            listType='picture-card'
                            maxCount={1}
                            showUploadList={showUploadImage}
                            onChange={(e) => handleUploadMethod(e)}
                            isImageUrl={(Image) => {
                              setImgUrl(Image?.thumbUrl);
                              return (
                                <img width={102} src={Image?.thumbUrl} alt='' />
                              );
                            }}
                          >
                            <PlusOutlined size={44} />
                            {newArray[0]?.card_file
                              ? 'Edit Image'
                              : t('content.154')}
                          </Upload>
                        </Col>
                      )}
                      {imageRequired && (
                        <Col span={24}>
                          <Row justify='center'>
                            <p style={{ color: 'red' }}>{imageRequired}</p>
                          </Row>
                        </Col>
                      )}
                    </Row>
                  </Col>

                  <Col span={24}>
                    <Typography
                      className='label'
                      style={{ paddingBottom: '4px' }}
                    >
                      Card Type
                    </Typography>
                    <Controller
                      as={
                        <Select
                          className='inputLabel'
                          options={cardArray}
                          onSelect={(e) => setOptionSelected(e)}
                        />
                      }
                      name='card_type'
                      control={control}
                      style={{ width: '100%' }}
                    />
                    {errors.card_type && (
                      <p style={{ color: 'red' }}>{errors.card_type.message}</p>
                    )}
                  </Col>

                  <Col></Col>
                  {OptionSelected === 'Template' && (
                    <>
                      <Col span={24}>
                        <Typography
                          className='label'
                          style={{ paddingBottom: '4px' }}
                        >
                          Template Type
                        </Typography>
                        <Select
                          className='inputLabel'
                          options={templaeArray}
                          onSelect={(e) => setOptiontemplateSelected(e)}
                          style={{ width: '100%' }}
                        />
                      </Col>
                      {(OptiontemplateSelected === 'Congratulations' ||
                        OptiontemplateSelected === 'Marriage' ||
                        OptiontemplateSelected === 'Achievements' ||
                        OptiontemplateSelected === 'New Births' ||
                        OptiontemplateSelected === 'Death') && (
                        <>
                          <TemplateGalleryComponent
                            imageKeys={imageKeysArray}
                          />
                        </>
                      )}
                      {OptiontemplateSelected === 'Marriage' && (
                        <Row>
                          <Col span={10}>
                            <Typography
                              className='label'
                              style={{ paddingBottom: '4px' }}
                            >
                              Name 1
                            </Typography>
                            <Controller
                              as={<Input className='inputLabel' />}
                              name='name'
                              control={control}
                            />
                          </Col>
                          <Col>
                            <Typography
                              className='label'
                              style={{ padding: '5px', marginTop: '20px' }}
                            >
                              Weds
                            </Typography>
                          </Col>
                          <Col span={10}>
                            <Typography
                              className='label'
                              style={{ paddingBottom: '4px' }}
                            >
                              Name 2
                            </Typography>
                            <Controller
                              as={<Input className='inputLabel' />}
                              name='name'
                              control={control}
                            />
                          </Col>
                        </Row>
                      )}
                      {OptiontemplateSelected === 'New Births' && (
                        <Row>
                          <Col span={11}>
                            <Typography
                              className='label'
                              style={{ paddingBottom: '4px' }}
                            >
                              Name 1
                            </Typography>
                            <Controller
                              as={<Input className='inputLabel' />}
                              name='name1'
                              control={control}
                            />
                          </Col>
                          <Col span={2}></Col>
                          <Col span={11}>
                            <Typography
                              className='label'
                              style={{ paddingBottom: '4px' }}
                            >
                              Name 2
                            </Typography>
                            <Controller
                              as={<Input className='inputLabel' />}
                              name='name2'
                              control={control}
                            />
                          </Col>
                        </Row>
                      )}
                    </>
                  )}

                  <Col></Col>

                  <Col span={24}>
                    <Row gutter={[10, 0]}>
                      {OptiontemplateSelected === 'Marriage' ||
                        OptiontemplateSelected === 'New Births' || (
                          <Col
                            span={
                              OptionSelected === 'Video' ||
                              OptionSelected === 'Link'
                                ? 12
                                : 24
                            }
                          >
                            <Typography
                              className='label'
                              style={{ paddingBottom: '4px' }}
                            >
                              Card Name
                            </Typography>
                            <Controller
                              as={<Input className='inputLabel' />}
                              name='name'
                              control={control}
                            />
                            {errors.name && (
                              <p style={{ color: 'red' }}>
                                {errors.name.message}
                              </p>
                            )}
                          </Col>
                        )}
                      {(OptionSelected === 'Video' ||
                        OptionSelected === 'Link') && (
                        <Col span={12}>
                          <Typography
                            className='label'
                            style={{ paddingBottom: '4px' }}
                          >
                            Card Url
                          </Typography>
                          <Controller
                            as={
                              <Input
                                className='inputLabel'
                                onClick={() => handleClearErrorMethod()}
                              />
                            }
                            name='url'
                            control={control}
                          />
                          {videoLinkRequired && (
                            <p style={{ color: 'red' }}>{videoLinkRequired}</p>
                          )}
                          {(OptionSelected === 'Video' ||
                            OptionSelected === 'Link') &&
                            isValidUrl === false && (
                              <p style={{ color: 'red' }}>Link is not valid</p>
                            )}
                        </Col>
                      )}
                    </Row>
                  </Col>

                  <Col span={24}>
                    <Typography
                      className='label'
                      style={{ paddingBottom: '4px' }}
                    >
                      Card Expiry Date
                    </Typography>
                    <Controller
                      as={
                        <DatePicker
                          placeholder='Select Date'
                          className='inputLabel'
                          disabledDate={(current) => {
                            let customDate = moment().format('YYYY-MM-DD');
                            return (
                              current &&
                              current < moment(customDate, 'YYYY-MM-DD')
                            );
                          }}
                        />
                      }
                      name='expiry_date'
                      control={control}
                      style={{ width: '100%' }}
                    />
                    {errors.expiry_date && (
                      <p style={{ color: 'red' }}>
                        {errors.expiry_date.message}
                      </p>
                    )}
                  </Col>
                  {OptionSelected === 'Template' && <Col span={24}></Col>}
                  {!(OptionSelected === 'Video') && (
                    <Col span={24}>
                      <Typography
                        className='label'
                        style={{ paddingBottom: '4px' }}
                      >
                        Community Card Description
                      </Typography>
                      <Controller
                        as={<Input.TextArea className='inputLabel' />}
                        name='description'
                        control={control}
                      />
                      {errors.description && (
                        <p style={{ color: 'red' }}>
                          {errors.description.message}
                        </p>
                      )}
                    </Col>
                  )}

                  <Col span={24}>
                    <Row justify='end' gutter={[10, 0]}>
                      <Col>
                        <Button
                          onClick={
                            isEditCommunityCardVisible
                              ? closeEditCommunityCardMethod
                              : closeCreateCommunityCardMethod
                          }
                          className='allButtonsCancel'
                        >
                          {t('btn.19')}
                        </Button>
                      </Col>
                      <Col>
                        <Button htmlType='submit' className='allButtons'>
                          {isEditCommunityCardVisible ? 'Edit' : 'Create'}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </form>
            )}
          </LoadingIndicator>
        </Col>
        <Col span={3} />
        <Col span={10}>
          <h2>Preview of community card </h2>
          {OptionSelected === 'Text' ? (
            <CommunityTextCard
              mappedData={watching}
              isCommunityCardModal={true}
            />
          ) : OptionSelected === 'Link' ? (
            <CommunityLinkCard
              mappedData={watching}
              isCommunityCardModal={true}
            />
          ) : OptionSelected === 'Image' ? (
            <CommunityImageCard
              mappedData={watching}
              isCommunityCardModal={true}
              imgUrl={imgUrl}
            />
          ) : OptionSelected === 'Video' ? (
            <CommunityVideoCard
              mappedData={watching}
              isCommunityCardModal={true}
            />
          ) : OptionSelected === 'Template' ? (
            <>
              {OptiontemplateSelected === 'Congratulations' && (
                <CommunityCongratulationsCard
                  mappedData={watching}
                  isCommunityCardModal={true}
                  templateKey={templatekey}
                />
              )}
              {/* {OptiontemplateSelected === 'Marriage' && (
                <CommunityCongratulationsCard
                  mappedData={watching}
                  isCommunityCardModal={true}
                  templateKey={templatekey}
                />
              )} */}
            </>

          ) : null}
        </Col>
      </Row>
    </Drawer>
  );
};
export default AddCommunityCardModal;
