import API from "../Utils/API";
export const createFamily = (data, successCallback) => (dispatch) => {
  dispatch({ type: "CREATE_FAMILY_REQUEST" });
  API.post("api/families", data)
    .then((response) => {
      dispatch({ type: "CREATE_FAMILY_SUCCESS", payload: response.data });
      successCallback(response.data);
    })
    .catch((error) => {
      dispatch({ type: "CREATE_FAMILY_FAILURE", payload: error.response.data });
    });
};
export const getFamilies = () => (dispatch) => {
  dispatch({ type: "GET_FAMILIES_REQUEST" });
  API.get("api/families")
    .then((response) => {
      dispatch({ type: "GET_FAMILIES_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: "GET_FAMILIES_FAILURE", payload: error.response.data });
    });
};

export const deleteFamily = (familyId, successCallback) => (dispatch) => {
  dispatch({ type: "DELETE_FAMILIES_REQUEST" });

  API.delete(`api/families/${familyId}`)
    .then((response) => {
      dispatch({ type: "DELETE_FAMILIES_SUCCESS", payload: response.data });
      dispatch(getFamilies());
      successCallback();
    })
    .catch((error) => {
      dispatch({
        type: "DELETE_FAMILIES_FAILURE",
        payload: error.response.data,
      });
    });
};

export const updateFamily = (data, successUpdateCallBack) => (dispatch) => {
  dispatch({ type: "UPDATE_FAMILIES_REQUEST" });
  API.put(`api/families/${data.id}`, data)
    .then((response) => {
      dispatch({ type: "UPDATE_FAMILIES_SUCCESS", payload: response.data });
      successUpdateCallBack();
    })
    .catch((error) => {
      dispatch({
        type: "UPDATE_FAMILIES_FAILURE",
        payload: error.response.data,
      });
    });
};

export const getDeletedUserFamilies = () => (dispatch) => {
  dispatch({ type: "GET_DELETED_USER_FAMILIES_REQUEST" });
  API.get("api/deleted_user_families")
    .then((response) => {
      dispatch({
        type: "GET_DELETED_USER_FAMILIES_SUCCESS",
        payload: response.data,
      });
      response.data.length > 0 &&
        dispatch(getFamilyByIdAction(response?.data[0]?.family?.id));
    })
    .catch((error) => {
      dispatch({
        type: "GET_DELETED_USER_FAMILIES_FAILURE",
        payload: error.response.data,
      });
    });
};

export const getFamilyByIdAction = (Id) => (dispatch) => {
  dispatch({ type: "GET_FAMILY_BY_ID_REQUEST" });
  API.get(`api/families/${Id}`)
    .then((response) => {
      dispatch({
        type: "GET_FAMILY_BY_ID_SUCCESS",
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: "GET_FAMILY_BY_ID_FAILURE",
        payload: error.response.data,
      });
    });
};

export const createJoinFamilyAction =
  (data, successCallback, faliureCallback) => (dispatch) => {
    dispatch({ type: "CREATE_JOIN_FAMILY_REQUEST" });
    API.post("api/add_member", data)
      .then((response) => {
        dispatch({
          type: "CREATE_JOIN_FAMILY_SUCCESS",
          payload: response.data,
        });
        successCallback && successCallback(response.data);
        !response.data.error && dispatch(getFamilies());
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_JOIN_FAMILY_FAILURE",
          payload: error.response.data,
        });
        faliureCallback && faliureCallback(error.response.data);
      });
  };
