import React, { useEffect, useState } from 'react';
import { Col } from 'antd';
import LightBlueLogo from '../../assets/LightBlueLogo.png';
import DarkBlueLogo from '../../assets/DarkBlueLogo.png';
import PinkLogo from '../../assets/PinkLogo.png';
import YellowLogo from '../../assets/YellowLogo.png';
import '../Admin/Admin.css';
import './LoadingIndicator.css';

const LoadingIndicator = (props) => {
  const { loading, isIntroLoader } = props;
  const [animationType, setAnimationType] = useState('darkBlueAnimation');
  const [count, setCount] = useState(0);

  const runAnimation = () => {
    if (count > 3) {
      setCount(0);
    } else {
      setCount(count + 1);
    }
    setAnimationType(
      count === 0
        ? 'darkBlueAnimation'
        : count === 1
        ? 'lightBlueAnimation'
        : count === 2
        ? 'yellowAnimation'
        : count === 3
        ? 'pinkAnimation'
        : 'darkBlueAnimation'
    );
  };

  useEffect(() => {
    const Interval = setTimeout(
      () => {
        runAnimation();
      },
      isIntroLoader ? 1000 : 400
    );

    return () => {
      clearTimeout(Interval);
    };
  }, [count]);

  return (
    <>
      {loading && (
        <Col
          className='LoadingIndicatorContainer'
          id='loading-indicator-container-id'
        >
          <Col className='loading-indicator-sub-container'>
            <img
              src={DarkBlueLogo}
              className={`LogoImage logohorizontal ${
                animationType === 'darkBlueAnimation' && 'dark-blue-logo'
              }`}
              alt=''
            ></img>
            <img
              src={LightBlueLogo}
              className={`LogoImage logovertical light-blue-logo ${
                animationType === 'lightBlueAnimation' && 'light-blue-animation'
              }`}
              alt=''
            ></img>
            <img
              src={YellowLogo}
              className={`LogoImage logohorizontal yellow-logo ${
                animationType === 'yellowAnimation' && 'yellow-logo-animation'
              }`}
              alt=''
            ></img>
            <img
              src={PinkLogo}
              className={`LogoImage logovertical pink-logo ${
                animationType === 'pinkAnimation' && 'pink-animation'
              }`}
              alt=''
            ></img>
          </Col>
        </Col>
      )}
      {isIntroLoader && (
        <Col
          className='LoadingIndicatorContainer IntroLoadingContainer'
          id='loading-indicator-container-id'
        >
          <Col className='IntroLoaderCircle'>
            <Col className='loading-indicator-sub-container'>
              <img
                src={DarkBlueLogo}
                className={`LoaderIntroImage LoaderIntroLogoHorizontal Loader-Intro-dark-blue-logo ${
                  animationType === 'darkBlueAnimation' &&
                  'Loader-Intro-Dark-Blue-Animation'
                }`}
                alt=''
              ></img>
              <img
                src={LightBlueLogo}
                className={`LoaderIntroImage logovertical Loader-Intro-light-blue-logo ${
                  animationType === 'lightBlueAnimation' &&
                  'Loader-Intro-Light-Blue-Animation'
                }`}
                alt=''
              ></img>
              <img
                src={YellowLogo}
                className={`LoaderIntroImage LoaderIntroLogoHorizontal Loader-Intro-yellow-logo ${
                  animationType === 'yellowAnimation' &&
                  'Loader-Intro-Yellow-Animation'
                }`}
                alt=''
              ></img>
              <img
                src={PinkLogo}
                className={`LoaderIntroImage logovertical Loader-Intro-pink-logo ${
                  animationType === 'pinkAnimation' &&
                  'Loader-Intro-Pink-Animation'
                }`}
                alt=''
              ></img>
            </Col>
          </Col>
        </Col>
      )}
      {!isIntroLoader && props.children}
    </>
  );
};
export default LoadingIndicator;
