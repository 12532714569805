import React from "react";
import "antd/dist/antd.css";
import "./FindOtherPeople.css";
import PeopleTable from "./Table";
const FindOtherPeople = () => {
  return (
    <>
      {/* <Form2></Form2> */}
      <PeopleTable />
    </>
  );
};

export default FindOtherPeople;
