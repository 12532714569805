import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Typography,
  Divider,
  Button,
  Empty,
  Tooltip,
  Popconfirm,
  message,
} from 'antd';
import '../FindOtherPeople.css';
import LoadingIndicator from '../../Screens/LoadingIndicator/LoadingIndicator';
import { useDispatch, useSelector } from 'react-redux';
import { deleteBusiness, getBusiness } from '../../Actions/businessAction';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import BusinessEmpty from '../../assets/BusinessEmpty.svg';
import { useTranslation } from 'react-i18next';
import { analytics } from '../../components/Mixpanel/Mixpanel';
const ViewJobProfile = (props) => {
  const { t } = useTranslation();
  const {
    setEditId,
    setVisible,
    setViewBusinessProfileId,
    business_profiles,
    setSelectedJobProfileId,
    isPeopleDetailsVisible,
    setIsSelectedJobProfileVisible,
  } = props;
  const { allBusiness, isBusinessLoading, setCommunityId, currentUser } =
    useSelector((state) => {
      const { allBusiness, isBusinessLoading } = state.business;
      const { setCommunityId } = state.communities;
      const { currentUser } = state.authentication;
      return {
        allBusiness,
        isBusinessLoading,
        setCommunityId,
        currentUser,
      };
    });
  const jobProfileData =
    allBusiness &&
    allBusiness.length > 0 &&
    allBusiness?.filter((o) => {
      return o.is_business === false;
    });
  const handleEdit = (val) => {
    setEditId(val);
    setVisible(true);
  };
  const handleDeleteJobProfileMethod = (businessId) => {
    dispatch(deleteBusiness(businessId, successDeleteJobProfileMethod));
  };
  const successDeleteJobProfileMethod = () => {
    setTimeout(() => {
      message.success(t('content.306'));
    }, 1000);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBusiness(setCommunityId));
  }, [dispatch, setCommunityId]);
  const handleClick = (businessProfileId) => {
    if (isPeopleDetailsVisible) {
      setSelectedJobProfileId(businessProfileId);
      setIsSelectedJobProfileVisible(true);
    } else {
      setViewBusinessProfileId(businessProfileId);
    }
  };
  const height = window.innerHeight - 400;

  return (
    <LoadingIndicator
      loading={
        !isPeopleDetailsVisible &&
        jobProfileData &&
        jobProfileData.length > 0 &&
        isBusinessLoading
      }
    >
      <Col style={{ height: height, overflow: 'hidden', overflowY: 'scroll' }}>
        {isBusinessLoading ? null : jobProfileData &&
          jobProfileData.length > 0 ? (
          <Row gutter={[32, 32]}>
            {isPeopleDetailsVisible
              ? business_profiles?.map((val, i) => {
                  return (
                    <Col
                      xl={12}
                      lg={17}
                      md={16}
                      sm={24}
                      xs={24}
                      key={i}
                      xxl={11}
                    >
                      <Card className='BusinessCard'>
                        <Row gutter={[0, 16]}>
                          <Col xl={20} lg={20} md={18} sm={18} xs={18}>
                            <Row gutter={[16, 16]}>
                              <Col span={24}>
                                <Typography className='profile-name'>
                                  {val.job_title}
                                </Typography>
                              </Col>
                              <Col>
                                <Typography className='BusinessEstablishedDate'>
                                  {t('content.66')}:{' '}
                                  <Typography
                                    component='span'
                                    className='BusinessEstablishedDateData'
                                  >
                                    {val?.working_here_since}
                                  </Typography>
                                </Typography>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row gutter={[0, 16]}>
                          <Divider className='JobProfileCardDivider' />
                        </Row>
                        <Row>
                          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Typography className='profile-address'>
                              {val.business_location}
                            </Typography>
                          </Col>
                          <Col
                            xl={24}
                            lg={24}
                            md={24}
                            sm={24}
                            xs={24}
                            style={{ textAlign: 'end' }}
                          >
                            <Button
                              className=' View-fullProfile'
                              onClick={() => {
                                analytics?.track('');
                                handleClick(val.id);
                              }}
                            >
                              {t('btn.37')}{' '}
                            </Button>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  );
                })
              : jobProfileData?.map((val, i) => {
                  return (
                    <Col
                      xl={12}
                      lg={12}
                      md={18}
                      sm={19}
                      xs={24}
                      key={i}
                      xxl={11}
                    >
                      <Card className='BusinessCard'>
                        <Row gutter={[0, 16]}>
                          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Row justify='space-between'>
                              <Col>
                                <Typography className='profile-name'>
                                  {val.job_title}
                                </Typography>
                              </Col>
                              {currentUser?.id === val?.user?.id && (
                                <Col style={{ textAlign: 'end' }}>
                                  <Tooltip title={t('btn.27')}>
                                    <Button
                                      icon={<EditOutlined />}
                                      type='text'
                                      onClick={() => handleEdit(val.id)}
                                    />
                                  </Tooltip>
                                  <Tooltip title={t('btn.28')}>
                                    <Popconfirm
                                      title={t('btn.29')}
                                      onConfirm={() =>
                                        handleDeleteJobProfileMethod(val.id)
                                      }
                                      okText={t('btn.28')}
                                      cancelText={t('btn.19')}
                                    >
                                      <Button
                                        icon={<DeleteOutlined />}
                                        type='text'
                                      />
                                    </Popconfirm>
                                  </Tooltip>
                                </Col>
                              )}
                            </Row>
                          </Col>
                          <Row>
                            <Col span={24}>
                              <Typography className='BusinessEstablishedDate'>
                                {t('content.66')}:{' '}
                                <Typography
                                  component='span'
                                  className='BusinessEstablishedDateData'
                                >
                                  {val.working_here_since}
                                </Typography>
                              </Typography>
                            </Col>
                          </Row>
                        </Row>
                        <Row gutter={[0, 16]}>
                          <Divider className='JobProfileCardDivider' />
                        </Row>
                        <Row>
                          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Typography className='profile-address'>
                              {val.business_location}
                            </Typography>
                          </Col>
                          <Col
                            xl={24}
                            lg={24}
                            md={24}
                            sm={24}
                            xs={24}
                            style={{ textAlign: 'end' }}
                          >
                            <Button
                              className=' View-fullProfile'
                              onClick={() => {
                                analytics?.track('Buisness_JobInfo');
                                handleClick(val.id);
                              }}
                            >
                              {t('btn.37')}{' '}
                            </Button>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  );
                })}
          </Row>
        ) : (
          <Row justify='center' style={{ paddingTop: '150px' }}>
            <Col>
              <Empty
                image={BusinessEmpty}
                imageStyle={{
                  height: 30,
                }}
                description={
                  <span className='empty-details'>{t('emptyScreen.5')}</span>
                }
              ></Empty>
            </Col>
          </Row>
        )}
      </Col>
    </LoadingIndicator>
  );
};
export default ViewJobProfile;
