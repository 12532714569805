import React, { useEffect, useState } from "react";
import { Row, Col, Button, Typography, Modal, Input, message } from "antd";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { educationSchema } from "../../Utils/Schema";
import "../../FindThePeoplePage/FindOtherPeople.css";
import { yupResolver } from "@hookform/resolvers/yup";
import "../AddFamilymember/Addfamily.css";
import {
  createEducationAction,
  getEducationsAction,
  updateEducationAction,
} from "../../Actions/educationAction";
import { useTranslation } from "react-i18next";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";

const AddEducationModal = (props) => {
  const {
    isAddEducationModalVisible,
    setIsAddEducationModalVisible,
    isCreateEducationLoading,
    isEditEducationModalVisible,
    editEducationId,
    setIsEditEducationModalVisible,
    selectedEducation,
    setEditEducationId,
    isUpdateEducationLoading,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [preloadedEducationValues, setPreloadedEucationValues] = useState("");
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(educationSchema),
    defaultValues: preloadedEducationValues,
  });
  useEffect(() => {
    setPreloadedEucationValues(selectedEducation);
    reset(selectedEducation);
  }, [selectedEducation, reset]);
  const handleCreateEducationMethod = (data) => {
    data.published = true;

    dispatch(createEducationAction(data, successCreateEducationMethod));
  };
  const successCreateEducationMethod = () => {
    setIsAddEducationModalVisible(false);
    setTimeout(() => {
      message.success(t("content.317"));
    }, 1000);
    setValue("name", "");
    dispatch(getEducationsAction());
    reset();
  };

  const closeAddEducationMethod = () => {
    setIsAddEducationModalVisible(false);

    setValue("name", "");
    setEditEducationId("");
    reset();
  };
  const handleEditEducationMethod = (data) => {
    data.id = editEducationId;
    dispatch(updateEducationAction(data, successUpdateEditEducationMethod));
  };

  const successUpdateEditEducationMethod = () => {
    setIsEditEducationModalVisible(false);
    setTimeout(() => {
      message.success(t("content.318"));
    }, 1000);
    setValue("name", "");
    reset();
    dispatch(getEducationsAction());
  };
  const closeEditEducationMethod = () => {
    setIsEditEducationModalVisible(false);
    setValue("name", "");
    setEditEducationId("");

    reset();
  };
  useEffect(() => {
    if (isAddEducationModalVisible) {
      setValue("name", "");
    }
  }, [setValue, isAddEducationModalVisible]);
  return (
    <Modal
      open={
        isEditEducationModalVisible
          ? isEditEducationModalVisible
          : isAddEducationModalVisible
      }
      title={isEditEducationModalVisible ? t("content.201") : t("content.169")}
      footer={null}
      width={400}
      onCancel={
        isEditEducationModalVisible
          ? closeEditEducationMethod
          : closeAddEducationMethod
      }
    >
      <form
        onSubmit={handleSubmit(
          isEditEducationModalVisible
            ? handleEditEducationMethod
            : handleCreateEducationMethod
        )}
      >
        <LoadingIndicator
          loading={
            isEditEducationModalVisible
              ? isUpdateEducationLoading
              : isCreateEducationLoading
          }
        >
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Typography className="label" style={{ paddingBottom: "4px" }}>
                {t("content.200")}
              </Typography>

              <Controller
                as={<Input className="inputLabel" />}
                name="name"
                control={control}
              />
              {errors.name && (
                <p style={{ color: "red" }}>{t("content.149")}</p>
              )}
            </Col>
            <Col span={24}>
              <Row justify="end" gutter={[10, 0]}>
                <Col>
                  <Button
                    className="allButtonsCancel"
                    onClick={
                      isEditEducationModalVisible
                        ? closeEditEducationMethod
                        : closeAddEducationMethod
                    }
                  >
                    {t("btn.19")}
                  </Button>
                </Col>

                <Col>
                  <Button htmlType="submit" className="allButtons">
                    {isEditEducationModalVisible ? t("btn.27") : t("btn.22")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </LoadingIndicator>
      </form>
    </Modal>
  );
};
export default AddEducationModal;
