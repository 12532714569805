import API from "../Utils/API";
export const getTrustees = () => (dispatch) => {
  dispatch({ type: "GET_TRUSTEES_REQUEST" });
  API.get("api/community_trustees")
    .then((response) => {
      dispatch({ type: "GET_TRUSTEES_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: "GET_TRUSTEES_FAILURE", payload: error.response.data });
    });
};

export const createTrustee = (data, success, failureCallback) => (dispatch) => {
  dispatch({ type: "CREATE_TRUSTEES_REQUEST", payload: data });
  API.post("api/community_trustees", data)
    .then((response) => {
      dispatch({ type: "CREATE_TRUSTEES_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "CREATE_TRUSTEES_FAILURE",
        payload: error.response.data,
      });
      failureCallback && failureCallback(error.response.data);
    });
};

export const deleteTrustee = (data, successCallback) => (dispatch) => {
  dispatch({ type: "DELETE_TRUSTEES_REQUEST" });
  API.delete(`api/community_trustees/${data}`)
    .then((response) => {
      dispatch({ type: "DELETE_TRUSTEES_SUCCESS", payload: response.data });
      successCallback();
    })
    .catch((error) => {
      dispatch({
        type: "DELETE_TRUSTEES_FAILURE",
        payload: error.response.data,
      });
    });
};

export const updateTrustee = (data, successCallback) => (dispatch) => {
  dispatch({ type: "UPDATE_TRUSTEES_REQUEST" });
  API.put(`api/community_trustees/${data.id}`, data)
    .then((response) => {
      dispatch({ type: "UPDATE_TRUSTEES_SUCCESS", payload: response.data });

      successCallback();
    })
    .catch((error) => {
      dispatch({
        type: "UPDATE_TRUSTEES_FAILURE",
        payload: error.response.data,
      });
    });
};
