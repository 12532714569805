import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Typography, Drawer, Input, message } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import {
  createCommunitiesNative,
  getCommunitiesNative,
  updateCommunitiesNativeAction,
} from '../../Actions/communitiesNativeAction';
import { useDispatch, useSelector } from 'react-redux';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { nativeSchema } from '../../Utils/Schema';
import '../../FindThePeoplePage/FindOtherPeople.css';
import { yupResolver } from '@hookform/resolvers/yup';
import '../AddFamilymember/Addfamily.css';
import { useTranslation } from 'react-i18next';

const AddNativeModal = (props) => {
  const { t } = useTranslation();
  const {
    isAddNativeModalVisible,
    setIsAddNativeModalVisible,
    isEditNativeModalVisible,
    setIsEditNativeModalVisible,
    editNativeId,
    selectedNative,
    setEditNativeId,
    setCommunityId,
  } = props;
  const { isCreateCommunitiesNativeLoading, isUpdateCommunitiesNativeLoading } =
    useSelector((state) => {
      const {
        isCreateCommunitiesNativeLoading,
        isUpdateCommunitiesNativeLoading,
      } = state.communitiesNative;

      return {
        isCreateCommunitiesNativeLoading,
        isUpdateCommunitiesNativeLoading,
      };
    });

  const [native, setNative] = useState(null);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(nativeSchema),
    defaultValues: native,
  });
  useEffect(() => {
    setNative(selectedNative);
    reset(selectedNative);
  }, [selectedNative, reset]);
  const dispatch = useDispatch();
  const closeOpenAddNativeMethod = () => {
    setIsAddNativeModalVisible(false);
    setValue('name', '');
    setEditNativeId('');
    reset();
  };
  const createAddNativeMethod = (data) => {
    data.is_published = true;
    data.community_id = setCommunityId;
    dispatch(createCommunitiesNative(data, successCreateAddNativeMethod));
  };
  const successCreateAddNativeMethod = () => {
    setIsAddNativeModalVisible(false);
    setTimeout(() => {
      message.success(t('content.323'));
    }, 1000);
    dispatch(getCommunitiesNative(setCommunityId));
    setValue('name', '');
    reset();
  };
  const closeEditNativeMethod = () => {
    setIsEditNativeModalVisible(false);
    setValue('name', '');
    setEditNativeId('');
    setNative('');
    reset();
  };
  const editNativeMethod = (data) => {
    data.id = editNativeId;
    dispatch(updateCommunitiesNativeAction(data, successEditNativeMethod));
  };
  const successEditNativeMethod = () => {
    setIsEditNativeModalVisible(false);
    setValue('name', '');
    reset();
    setTimeout(() => {
      message.success(t('content.325'));
    }, 1000);
    dispatch(getCommunitiesNative(setCommunityId));
  };

  useEffect(() => {
    if (isAddNativeModalVisible) {
      setValue('name', '');
    }
  }, [setValue, isAddNativeModalVisible]);
  return (
    <Drawer
      title={isEditNativeModalVisible ? t('content.168') : t('content.166')}
      open={
        isEditNativeModalVisible
          ? isEditNativeModalVisible
          : isAddNativeModalVisible
      }
      onClose={
        isEditNativeModalVisible
          ? closeEditNativeMethod
          : closeOpenAddNativeMethod
      }
      footer={false}
      width={400}
    >
      <LoadingIndicator
        loading={
          isEditNativeModalVisible
            ? isUpdateCommunitiesNativeLoading
            : isCreateCommunitiesNativeLoading
        }
        maskClassName='DrawerClass'
        className='DrawerBorderClass'
      >
        <form
          onSubmit={handleSubmit(
            isEditNativeModalVisible ? editNativeMethod : createAddNativeMethod
          )}
        >
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Typography className='label' style={{ paddingBottom: '4px' }}>
                {t('content.167')}
              </Typography>
              <Controller
                as={<Input className='inputLabel' />}
                name='name'
                control={control}
              />
              {errors.name && (
                <p style={{ color: 'red' }}>{t('content.240')}</p>
              )}
            </Col>
            <Col span={24}>
              <Row justify='end' gutter={[10, 0]}>
                <Col>
                  <Button
                    onClick={
                      isEditNativeModalVisible
                        ? closeEditNativeMethod
                        : closeOpenAddNativeMethod
                    }
                    className='allButtonsCancel'
                  >
                    {t('btn.19')}
                  </Button>
                </Col>
                <Col>
                  <Button htmlType='submit' className='allButtons'>
                    {isEditNativeModalVisible ? t('btn.17') : t('btn.22')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </LoadingIndicator>
    </Drawer>
  );
};

export default AddNativeModal;
