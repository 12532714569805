import { falseyValueCases } from '../Screens/CommonLogics/CommonMethods';
import DisplayImageModel from '../Screens/HelpAndSupport/DisplayImageModel';
import API from '../Utils/API';
export const createFamilyMember =
  (data, successCreateMember, IsEmpty, failureCallback) => (dispatch) => {
    dispatch({ type: 'CREATE_FAMILY_MEMBER_REQUEST', payload: data });
    let formData = new FormData();
    IsEmpty
      ? formData.append('user_image', data.user_image)
      : formData.append('user[user_image]', data.user_image);

    formData.append('user[first_name]', data.first_name);
    formData.append('user[birth_date]', data.birth_date);
    formData.append('user[birth_month]', data.birth_month);
    formData.append('user[birth_year]', data.birth_year);

    formData.append('user[education_id]', data.education_id);
    formData.append('user[industry_id]', data.industry_id);

    formData.append('user[gender]', data.gender);
    formData.append('family_id', data.family_id);
    formData.append('user[last_name]', data.last_name);
    data.phone && formData.append('user[phone]', data.phone);
    // formData.append("user[email]", data.email);
    if (!falseyValueCases(data.email)) {
      formData.append('user[email]', data.email);
    }
    data.country_code &&
      formData.append('user[country_code]', data.country_code);
    API.post('api/members', formData)

      .then((response) => {
        dispatch({
          type: 'CREATE_FAMILY_MEMBER_SUCCESS',
          payload: response.data,
        });
        successCreateMember();
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_FAMILY_MEMBER_FAILURE',
          payload: error.response.data,
        });
        failureCallback(error.response.data);
      });
  };

export const getFamilyMemberById = (data) => (dispatch) => {
  dispatch({ type: 'GET_FAMILY_MEMBER_BY_ID_REQUEST' });
  API.get(`api/members/${data}`)
    .then((response) => {
      dispatch({
        type: 'GET_FAMILY_MEMBER_BY_ID_SUCESS',
        payload: response.data,
      });
    })
    .catch((error) => {
      {
        dispatch({
          type: 'GET_FAMILY_MEMBER_BY_ID_FAILURE',
          payload: error.response.data,
        });
      }
    });
};
export const deleteFamilyMember = (data, deleteCall) => (dispatch) => {
  dispatch({ type: 'DELETE_FAMILY_MEMBER_REQUEST' });
  API.delete(`api/members/${data}`)
    .then((response) => {
      dispatch({ type: 'DELETE_FAMILY_MEMBER_SUCCESS' });
      deleteCall();
    })
    .catch((error) => {
      dispatch({ type: 'DELETE_FAMILY_MEMBER_FAILURE' });
    });
};
export const updateFamilyMember =
  (data, successUpdateMember, IsEmpty, failureUpdateCallback) => (dispatch) => {
    dispatch({ type: 'UPDATE_FAMILY_MEMBER_REQUEST' });
    let formData = new FormData();

    IsEmpty
      ? formData.append('user_image', data.user_image)
      : formData.append('user[user_image]', data.user_image);

    formData.append('user[first_name]', data.first_name);
    formData.append('user[birth_date]', data.birth_date);
    formData.append('user[birth_month]', data.birth_month);
    formData.append('user[birth_year]', data.birth_year);
    formData.append('user[education_id]', data.education_id);
    formData.append('user[industry_id]', data.industry_id);
    formData.append('user[gender]', data.gender);
    formData.append('family_id', data.family_id);
    formData.append('user[last_name]', data.last_name);
    data.phone && formData.append('user[phone]', data.phone);
    // formData.append("user[email]", data.email);
    if (!falseyValueCases(data.email)) {
      formData.append('user[email]', data.email);
    }
    data.country_code &&
      formData.append('user[country_code]', data.country_code);

    formData.append('user[deceased]', data.deceased);
    API.put(`api/members/${data.id}`, formData)
      .then((response) => {
        dispatch({
          type: 'UPDATE_FAMILY_MEMBER_SUCCESS',
          payload: response.data,
        });
        successUpdateMember && successUpdateMember(response.data);
      })
      .catch((error) => {
        dispatch({ type: 'UPDATE_FAMILY_MEMBER_FAILURE' });
        failureUpdateCallback(error.response?.data);
      });
  };
export const updateFamilyMemberOwnershipAction =
  (data, successCallback) => (dispatch) => {
    dispatch({ type: 'UPDATE_OWNERSHIP_REQUEST' });
    API.put(`api/ownership/${data.id}`, data)
      .then((response) => {
        dispatch({ type: 'UPDATE_OWNERSHIP_SUCCESS', payload: response.data });
        successCallback && successCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_OWNERSHIP_FAILURE',
          payload: error.response.data,
        });
      });
  };

export const updateDeletedFamilyMemberAction =
  (data, SuccessCallback) => (dispatch) => {
    dispatch({ type: 'UPDATE_DELETED_FAMILY_MEMBER_REQUEST' });
    API.put(`api/user_families/${data}`)
      .then((response) => {
        dispatch({
          type: 'UPDATE_DELETED_FAMILY_MEMBER_SUCCESS',
          payload: response.data,
        });
        SuccessCallback && SuccessCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_DELETED_FAMILY_MEMBER_FAILURE',
          payload: error.response.data,
        });
      });
  };
