import React from 'react';
import { Row, Col, Typography, Card } from 'antd';
import './Information.css';
import { useTranslation } from 'react-i18next';
const MeetingCard = (props) => {
  const { t } = useTranslation();
  const { Meetings } = props;
  return (
    <Col>
      <Card className='DocumentCard'>
        <Row gutter={[0, 15]}>
          <Col span={24}>
            <Typography className='MeetingTitle'>{Meetings?.title}</Typography>
          </Col>
          <Col span={24}>
            <Typography className='MeetingTitle1'>
              {t('content.216')} :{' '}
              <Typography className='MeetingTitle2' component='span'>
                {Meetings?.place}
              </Typography>
            </Typography>
          </Col>
          <Col span={24}>
            <Card style={{ background: '#F5F6F8' }}>
              <Typography className='MeetingTitle1'>
                {t('content.217')}:{' '}
                <Typography className='MeetingTitle2' component='span'>
                  {Meetings?.description}
                </Typography>
              </Typography>
            </Card>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};
export default MeetingCard;
