import React from 'react';
import { Card, Typography, Row, Col } from 'antd';
import './Admin.css';
import './ViewMeetings.css';
import { useTranslation } from 'react-i18next';
import '../../Events/EventCard.css';
function ViewMeetings(props) {
  const { t } = useTranslation();
  const { selectedMeeting } = props;
  return (
    <Card
      title={<Row justify='center'>{selectedMeeting?.title}</Row>}
      style={{
        boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
      }}
    >
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <Typography className='Eventlocation'>
            {t('content.346')}&nbsp;:&nbsp;
            <Typography component='span' className='Eventlocation1'>
              {selectedMeeting?.place}
            </Typography>
          </Typography>
        </Col>
        <Col span={24}>
          <Typography className='Eventlocation'>
            {t('content.217')}&nbsp;:&nbsp;
            <Typography component='span' className='Eventlocation1'>
              {selectedMeeting?.description}
            </Typography>
          </Typography>
        </Col>
        <Col span={24}>
          <Typography className='Eventlocation'>
            {t('content.225')}&nbsp;:&nbsp;
            <Typography component='span' className='Eventlocation1'>
              {selectedMeeting?.year}
            </Typography>
          </Typography>
        </Col>
      </Row>
    </Card>
  );
}

export default ViewMeetings;
