import React from 'react';
import { Row, Table, Col, Button, message } from 'antd';
import '../../FindThePeoplePage/FindOtherPeople.css';
import {
  getIndustriesAction,
  updateIndustryAction,
} from '../../Actions/industryAction';
import './Admin.css';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
const IndustryTable = (props) => {
  const { t } = useTranslation();
  const { allIndustries, setEditIndustryId, setIsEditIndustryModalVisible } =
    props;

  const columns = [
    {
      title: `${t('content.80')} (${allIndustries?.length})`,
      dataIndex: 'name',
      width: '80%',
      render: (name, allIndustriesList) => {
        return allIndustriesList.published ? (
          name
        ) : (
          <Col className='hidedRow'>{name}</Col>
        );
      },
    },
    {
      dataIndex: 'id',
      render: (id, allIndustriesList) => (
        <IndustryActionComponent
          id={id}
          setEditIndustryId={setEditIndustryId}
          setIsEditIndustryModalVisible={setIsEditIndustryModalVisible}
          allIndustriesList={allIndustriesList}
        />
      ),
      width: '15em',
    },
  ];
  return (
    <Col className='AdminTable'>
      <Table
        columns={columns}
        dataSource={allIndustries}
        size='middle'
        className='table'
        rowKey={(record) => {
          return record.id;
        }}
      />
    </Col>
  );
};

export default IndustryTable;

const IndustryActionComponent = (props) => {
  const {
    id,
    setEditIndustryId,
    setIsEditIndustryModalVisible,
    allIndustriesList,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  let UnpublishData = {};
  let PublishData = {};
  const openEditIndustryMethod = (EditId) => {
    setEditIndustryId(EditId);

    setIsEditIndustryModalVisible(true);
  };
  const handlePublishIndustryMethod = (EditId) => {
    PublishData.id = EditId;
    PublishData.published = true;
    dispatch(updateIndustryAction(PublishData, successUpdatePublishMethod));
  };
  const handleUnpublishIndustryMethod = (EditId) => {
    UnpublishData.id = EditId;

    UnpublishData.published = false;
    dispatch(updateIndustryAction(UnpublishData, successUpdateUnpublishMethod));
  };

  const successUpdateUnpublishMethod = () => {
    setTimeout(() => {
      message.success(t('content.334'));
    }, 1000);
    dispatch(getIndustriesAction());
  };

  const successUpdatePublishMethod = () => {
    setTimeout(() => {
      message.success(t('content.335'));
    }, 1000);
    dispatch(getIndustriesAction());
  };
  return (
    <Row>
      <Col span={10}>
        <Button
          type='primary'
          className='editDesignationButton'
          onClick={() => openEditIndustryMethod(id)}
        >
          {t('btn.27')}
        </Button>
      </Col>

      <Col>
        {allIndustriesList?.published ? (
          <Button
            type='primary'
            className='unPublishButton'
            onClick={() => handleUnpublishIndustryMethod(id)}
          >
            {t('content.164')}
          </Button>
        ) : (
          <Button
            type='primary'
            className='publishButton'
            onClick={() => handlePublishIndustryMethod(id)}
          >
            {t('content.163')}
          </Button>
        )}
      </Col>
    </Row>
  );
};
