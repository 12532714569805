import React, { useCallback, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Typography,
  Divider,
  PageHeader,
  Descriptions,
} from 'antd';
import '../FindOtherPeople.css';
import { useDispatch, useSelector } from 'react-redux';
import { matrimonialById } from '../../Actions/matrimonialAction';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOutlined } from '@ant-design/icons';
import LoadingIndicator from '../../Screens/LoadingIndicator/LoadingIndicator';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import _ from 'lodash';
import {
  CountryViseFormat,
  falseyValueCases,
  newImageUrl,
} from '../../Screens/CommonLogics/CommonMethods';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
dayjs.extend(advancedFormat);

const MatrimonialProfileDetails = (props) => {
  // const { setViewMatrimonialProfileId, viewMatrimonialProfileId } = props;
  const { matrimonialProfile, isMatrimonialByIdLoading } = useSelector(
    (state) => {
      const { matrimonialProfile, isMatrimonialByIdLoading } =
        state.matrimonial;
      return {
        matrimonialProfile,
        isMatrimonialByIdLoading,
      };
    }
  );

  const { id: matrimonialProfielId } = useParams();
  const { address, description, height, weight, user } =
    matrimonialProfile || {};
  const { t } = useTranslation();
  const handleCloseMatrimonialProfile = () => {
    history.back();
    dispatch({ type: 'RESET_MATRIMONIAL_BY_ID_DATA' });
  };
  const dispatch = useDispatch();
  const MatrimonialById = useCallback(() => {
    dispatch(matrimonialById(matrimonialProfielId));
  }, [dispatch]);
  useEffect(() => {
    MatrimonialById();
  }, [MatrimonialById]);

  const isPhonePresent = _.isEmpty(user?.phone);
  const isEmailPresent = _.isEmpty(user?.email);
  const IsEmpty =
    user?.phone_with_country_code === 'null'
      ? true
      : user?.phone_with_country_code === 'undefined'
      ? true
      : _.isEmpty(user?.phone_with_country_code);

  const ImageGalleryComponent = ({ imageKeys }) => {
    const [galleryImages, setGalleryImages] = React.useState([]);

    React.useEffect(() => {
      const loadImages = async () => {
        const imagePromises = imageKeys?.map(async (val, i) => {
          return {
            original: await newImageUrl(val.key),
          };
        });

        const images = await Promise.all(imagePromises);
        setGalleryImages(images);
      };

      if (imageKeys?.length > 0) {
        loadImages();
      }
    }, [imageKeys]);

    return (
      <Row>
        <Col span={24}>
          {galleryImages.length > 0 && (
            <ImageGallery
              items={galleryImages}
              additionalClass='ImageViewMatrimonial'
              showNav={false}
              showBullets={true}
              showThumbnails={false}
              originalClass='ImageViewMatrimonial'
            />
          )}
        </Col>
      </Row>
    );
  };

  return (
    <LoadingIndicator loading={isMatrimonialByIdLoading}>
      <Row justify='space-around'>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <ArrowLeftOutlined
            style={{ paddingBottom: '20px', fontSize: '20px' }}
            onClick={() => handleCloseMatrimonialProfile()}
          />
        </Col>

        {isMatrimonialByIdLoading ? null : !falseyValueCases(
            matrimonialProfile
          ) ? (
          <Col span={24}>
            <Card className='profileDetails-card'>
              <Row justify='end'></Row>
              <Row gutter={[20, { sm: 20, xl: 0, xxl: 0, md: 0, xs: 20 }]}>
                {matrimonialProfile?.matrimonial_user_image_keys?.length >
                  0 && (
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <ImageGalleryComponent
                      imageKeys={
                        matrimonialProfile?.matrimonial_user_image_keys
                      }
                    />
                  </Col>
                )}
                <Col
                  xl={
                    matrimonialProfile?.matrimonial_user_image_keys?.length > 0
                      ? 12
                      : 24
                  }
                  xxl={
                    matrimonialProfile?.matrimonial_user_image_keys?.length > 0
                      ? 12
                      : 24
                  }
                  lg={
                    matrimonialProfile?.matrimonial_user_image_keys?.length > 0
                      ? 12
                      : 24
                  }
                  md={
                    matrimonialProfile?.matrimonial_user_image_keys?.length > 0
                      ? 12
                      : 24
                  }
                  sm={24}
                  xs={24}
                >
                  <Row gutter={[0, 17]} align='middle'>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Typography className='profile-name'>
                        {user?.first_name}&nbsp;{user?.last_name}
                      </Typography>
                    </Col>
                    <Col span={24}>
                      <Row justify='space-between'>
                        <Col>
                          <Typography className='profile-birthdate'>
                            {dayjs(
                              user?.birth_month +
                                '/' +
                                user?.birth_date +
                                '/' +
                                user?.birth_year
                            ).format('Do MMMM YYYY')}
                          </Typography>
                        </Col>
                        <Col>
                          <Typography className='profile-caste'>
                            {t('content.22')}:&nbsp;Hindu
                          </Typography>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Col xl={14} lg={14} md={14} sm={24} xs={24}>
                    <Typography className='profile-address'>
                      {address}
                    </Typography>
                  </Col>
                </Col>
              </Row>
              <Divider />
              <Row justify='space-around' gutter={[24, 32]}>
                <Col span={24}></Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <PageHeader
                    title={t('content.28')}
                    className='pageHeaderDetails'
                    extra={[
                      <Typography className='pageHeaderSubDetails'>
                        Total 4 Family Member
                      </Typography>,
                    ]}
                  ></PageHeader>
                  <Descriptions column={1} style={{ padding: '2px 20px' }}>
                    <Descriptions.Item
                      label={
                        <Typography className='descriptionDetails'>
                          {t('content.31')}
                        </Typography>
                      }
                    >
                      <Typography className='pageHeaderSubDetails'>
                        Chandresh Shah
                      </Typography>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography className='descriptionDetails'>
                          {t('content.32')}
                        </Typography>
                      }
                    >
                      <Typography className='pageHeaderSubDetails'>
                        Seema Shah
                      </Typography>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography className='descriptionDetails'>
                          {t('content.33')}
                        </Typography>
                      }
                    >
                      <Typography className='pageHeaderSubDetails'>
                        {' '}
                        Shreyansh Shah
                      </Typography>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  {!(isEmailPresent && isPhonePresent) && (
                    <PageHeader
                      title={t('content.29')}
                      className='pageHeaderDetails'
                      extra={[
                        <Typography className='pageHeaderSubDetails'></Typography>,
                      ]}
                      style={{ padding: '7px 20px' }}
                    ></PageHeader>
                  )}
                  <Descriptions column={1} style={{ padding: '2px 20px' }}>
                    {!isPhonePresent && (
                      <Descriptions.Item
                        label={
                          <Typography className='descriptionDetails'>
                            {t('content.34')}
                          </Typography>
                        }
                      >
                        <Typography className='pageHeaderSubDetails'>
                          {IsEmpty === false &&
                            user?.phone_with_country_code &&
                            CountryViseFormat(user?.phone_with_country_code)}
                        </Typography>
                      </Descriptions.Item>
                    )}
                    {!isEmailPresent && (
                      <Descriptions.Item
                        label={
                          <Typography className='descriptionDetails'>
                            {t('content.35')}
                          </Typography>
                        }
                      >
                        <Typography className='pageHeaderSubDetails'>
                          {user?.email}
                        </Typography>
                      </Descriptions.Item>
                    )}
                  </Descriptions>
                </Col>
              </Row>

              <Row justify='space-around' gutter={[24, 16]}>
                <Col span={24}></Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <PageHeader
                    title={t('content.30')}
                    className='pageHeaderDetails'
                    style={{ padding: '7px 20px' }}
                  ></PageHeader>
                  <Descriptions column={1} style={{ padding: '2px 20px' }}>
                    <Descriptions.Item
                      label={
                        <Typography className='descriptionDetails'>
                          {t('content.36')}
                        </Typography>
                      }
                    >
                      <Typography className='pageHeaderSubDetails'>
                        {height}&nbsp;cm
                      </Typography>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography className='descriptionDetails'>
                          {t('content.37')}
                        </Typography>
                      }
                    >
                      <Typography className='pageHeaderSubDetails'>
                        {weight}&nbsp;Kg
                      </Typography>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Typography className='descriptionDetails'>
                          {t('content.38')}
                        </Typography>
                      }
                    >
                      <Typography
                        className='pageHeaderSubDetails'
                        style={{ textAlign: 'justify' }}
                      >
                        {description}
                      </Typography>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}></Col>
              </Row>
            </Card>
          </Col>
        ) : (
          <Row justify='center' align='middle'>
            <Col>
              <Typography>{t('content.350')}</Typography>
            </Col>
          </Row>
        )}
      </Row>
    </LoadingIndicator>
  );
};
export default MatrimonialProfileDetails;
