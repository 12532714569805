import {
  Input,
  Button,
  Row,
  Col,
  Typography,
  DatePicker,
  Checkbox,
  message,
  Upload,
  Select,
  Drawer,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import {
  createFamilyMember,
  updateFamilyMember,
} from '../../Actions/familyMemberAction';
import { useDispatch, useSelector } from 'react-redux';
import { getFamilies } from '../../Actions/familyAction';
import { addFamilyMemberSchema } from '../../Utils/Schema';
import { useTranslation } from 'react-i18next';
import './Addfamily.css';
import './../../FindThePeoplePage/FindOtherPeople.css';
import _ from 'lodash';
import { PlusOutlined } from '@ant-design/icons';
import { getUsers } from '../../Actions/usersAction';
import PhoneInput from 'react-phone-input-international';
import 'react-phone-input-international/lib/style.css';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { falseyValueCases, newImageUrl } from '../CommonLogics/CommonMethods';
import { getUserByIdAction } from '../../Actions/authenticationAction';

const AddFamilyMember = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    isAddFamilyMemberModalVisible,
    setIsAddFamilyMemberModalVisible,
    familyId,
    isUpdateMemberModalVisible,
    setIsUpdateMemberModalVisible,
    the_member,
  } = props;
  const {
    isCreateFamilyMemberLoading,
    isUpdateFamilyMemberLoading,
    allEducations,
    allIndustries,
    allFamilies,
    userById,
  } = useSelector((state) => {
    const { isCreateFamilyMemberLoading, isUpdateFamilyMemberLoading } =
      state.familyMember;
    const { allEducations } = state.educations;
    const { allIndustries } = state.industry;
    const { allFamilies } = state.family;
    const { userById } = state.authentication;

    return {
      isCreateFamilyMemberLoading,
      isUpdateFamilyMemberLoading,
      allEducations,
      allIndustries,
      allFamilies,
      userById,
    };
  });
  const LastNameForCreateFamilyMember = _.filter(allFamilies, (Families) => {
    return Families.id === familyId;
  });
  const onlyPublishedEducations = allEducations.filter(
    (Educations) => Educations.published === true
  );
  const onlyPublishedIndustries = allIndustries.filter(
    (Industries) => Industries.published === true
  );
  const { user, user_image } = the_member || {};
  const { id: memberUniqueId } = user || {};
  const [preloadedFamilyMemberValues, setPreloadedFamilyMemberValues] =
    useState('');
  const date = isUpdateMemberModalVisible
    ? moment(
        `${userById?.birth_month}-${userById?.birth_date}-${userById?.birth_year}`,
        'MM-DD-YYYY'
      )
    : '';
  if (isUpdateMemberModalVisible) {
    if (!falseyValueCases(userById?.first_name)) {
      userById.date_of_birth = date ? date : moment();
      userById.education_id = userById?.education?.name;

      userById.industry_id = userById?.industry?.name;
      userById.email = userById.email === 'undefined' ? '' : userById.email;
    }
  }
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues:
      userById?.birth_date === null
        ? {
            first_name: preloadedFamilyMemberValues?.first_name,
            last_name: preloadedFamilyMemberValues?.last_name,
            email: preloadedFamilyMemberValues?.email,

            phone: preloadedFamilyMemberValues?.phone,
            education_type: preloadedFamilyMemberValues?.education_type,
            industry: preloadedFamilyMemberValues?.industry,
          }
        : preloadedFamilyMemberValues,

    resolver: yupResolver(addFamilyMemberSchema),
  });
  useEffect(() => {
    setPreloadedFamilyMemberValues(userById);
    reset(userById);
  }, [userById, reset]);
  const [DateCustom, setDateCustom] = useState('');
  const [gender, setGender] = useState('');
  const [gendervalue, setGendervalue] = useState('');
  const [newFiles, setNewFiles] = useState([]);
  const IsEmpty = _.isEmpty(newFiles);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [isDeceased, setIsDeceased] = useState(user?.deceased);
  const [isDefaultImageVisible, setIsDefaultImageVisible] = useState(true);
  const [genderRequiredMessage, setGenderRequiredMessage] = useState('');
  const [phoneRequiredMessage, setPhoneRequiredMessage] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [number, setNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [failureMessage, setFailureMessage] = useState('');
  const onDateSelection = (date) => {
    const Date = moment(date);
    setDateCustom(moment(Date._d).calendar());
  };
  const monthOfBirth = DateCustom.toString().slice(0, 2);
  const dateOfBirth = DateCustom.toString().slice(3, 5);
  const yearOfBirth = DateCustom.toString().slice(6, 10);
  const handleCancel = () => {
    setIsAddFamilyMemberModalVisible(false);
    reset();
    setGender({});
    setGendervalue('');
    setShowUploadImage(false);
    setNewFiles([]);
  };
  const isGenderValueEmpty = _.isEmpty(gendervalue);

  const createMem = (data) => {
    const Text = number;
    const TextText = Text.replace(`+${countryCode}`, '');
    if (isGenderValueEmpty) {
      setGenderRequiredMessage('Gender is required');
    } else {
      const capitalizeFirstName = _.capitalize(data.first_name);
      data.first_name = capitalizeFirstName;
      data.family_id = familyId;
      data.birth_date = dateOfBirth;
      data.birth_month = monthOfBirth;
      data.birth_year = yearOfBirth;
      data.gender = gendervalue;
      data.user_image = IsEmpty ? null : newFiles;
      data.phone = TextText ? TextText : '';
      data.country_code = TextText ? `+${countryCode}` : '';
      data.last_name = LastNameForCreateFamilyMember[0].last_name;
      dispatch(
        createFamilyMember(
          data,
          successCreateMember,
          IsEmpty,
          failureCreateMethod
        )
      );
    }
  };
  const successCreateMember = () => {
    setValue('first_name', '');
    setValue('email', '');
    setValue('phone', '');
    setValue('education_type', '');
    setValue('industry', '');
    setValue('date_of_birth', '');
    reset();
    setGender({});
    setGendervalue('');
    setIsAddFamilyMemberModalVisible(false);
    setTimeout(() => {
      message.success(t('content.131'));
    }, 1000);
    dispatch(getFamilies());
    setNewFiles([]);
    setShowUploadImage(false);
  };
  const failureCreateMethod = (failureResponse) => {
    setFailureMessage(failureResponse?.phone?.[0]);
  };
  const containsNumber = (str) => {
    return /\d/.test(str);
  };
  const updateMem = (data) => {
    const Text = number;
    const TextText = Text.replace(`+${countryCode}`, '');
    const capitalizeFirstName = _.capitalize(data.first_name);
    data.first_name = capitalizeFirstName;
    data.id = memberUniqueId;
    data.birth_date = dateOfBirth ? dateOfBirth : userById.birth_date;
    data.birth_month = monthOfBirth ? monthOfBirth : userById.birth_month;
    data.birth_year = yearOfBirth ? yearOfBirth : userById.birth_year;
    data.gender = gendervalue ? gendervalue : userById.gender;
    data.user_image = IsEmpty ? null : newFiles;
    data.education_id = containsNumber(data?.education_id)
      ? data?.education_id
      : userById?.education?.id;
    data.industry_id = containsNumber(data?.industry_id)
      ? data?.industry_id
      : userById?.industry?.id;
    data.phone = TextText ? TextText : userById?.phone ? userById?.phone : '';
    data.country_code = TextText
      ? `+${countryCode}`
      : userById?.country_code
      ? userById?.country_code
      : '';
    data.deceased = isDeceased ? isDeceased : user?.deceased;
    data.last_name = LastNameForCreateFamilyMember[0].last_name;
    dispatch(
      updateFamilyMember(
        data,
        successUpdateMember,
        IsEmpty,
        failureUpdateMethod
      )
    );
  };

  const successUpdateMember = (SuccessResponse) => {
    dispatch(getFamilies());
    setIsUpdateMemberModalVisible(false);
    dispatch(getUsers());
    setTimeout(() => {
      message.success(t('content.132'));
    }, 1000);
    setNewFiles([]);
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
    dispatch(getUserByIdAction(userById?.id));
  };
  const failureUpdateMethod = (failureResponse) => {
    setFailureMessage(failureResponse?.phone?.[0]);
  };
  const CloseMemberModal = () => {
    setIsUpdateMemberModalVisible(false);
    setNewFiles([]);
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
    reset({
      first_name: userById?.first_name,
      last_name: userById?.last_name,
      email: userById?.email,
      phone: userById?.phone,
      education_id: userById?.education?.name,
      industry_id: userById?.industry?.name,
      date_of_birth: date ? date : moment(),
    });
    clearErrors();
  };
  const genderValue = (e) => {
    const { checked, value } = e.target;
    setGender({
      value,
      [value]: checked,
    });
    setGendervalue(value);
    setGenderRequiredMessage('');
  };

  if (isUpdateMemberModalVisible && gender?.female && user) {
    user.gender = 'female';
  } else if (isUpdateMemberModalVisible && gender?.male && user) {
    user.gender = 'male';
  }
  const propsForUpload = {
    action: createMem,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles(file);
      return false;
    },
    multiple: false,
  };
  const handleUploadMethod = () => {
    setShowUploadImage(true);
    setIsDefaultImageVisible(false);
  };
  const phoneNumberMethod = (value, country, e, formattedValue) => {
    setCountryCode(country.dialCode);
    setNumber(formattedValue);
  };
  const isPhoneNumberAbsent =
    preloadedFamilyMemberValues?.phone_with_country_code === 'null'
      ? false
      : preloadedFamilyMemberValues?.phone_with_country_code === 'undefined'
      ? false
      : _.isEmpty(preloadedFamilyMemberValues?.phone_with_country_code)
      ? false
      : true;

  useEffect(() => {
    if (isAddFamilyMemberModalVisible) {
      setValue('first_name', '');
      setValue('last_name', '');
      setValue('email', '');
      setValue('phone', '');
      setValue('education_id', '');
      setValue('industry_id', '');
      setValue('date_of_birth', '');
    }
  }, [setValue, isAddFamilyMemberModalVisible]);

  return (
    <Col>
      {isAddFamilyMemberModalVisible ? (
        <Drawer
          open={isAddFamilyMemberModalVisible}
          onClose={handleCancel}
          footer={null}
          title={t('btn.38')}
          maskClassName='DrawerClass'
          className='DrawerBorderClass'
        >
          <LoadingIndicator loading={isCreateFamilyMemberLoading}>
            <form onSubmit={handleSubmit(createMem)}>
              <Row justify='center'>
                <Col>
                  <Upload
                    {...propsForUpload}
                    listType='picture-card'
                    maxCount={1}
                    showUploadList={showUploadImage}
                    onChange={() => setShowUploadImage(true)}
                  >
                    <PlusOutlined size={44} />
                    {t('content.154')}
                  </Upload>
                </Col>
              </Row>
              <Row gutter={[10, 0]}>
                <Col
                  xl={12}
                  md={12}
                  sm={12}
                  xs={24}
                  style={{ paddingTop: '10px' }}
                >
                  <Typography className='label' style={{ paddingBottom: 9 }}>
                    {t('placeholder.4')}*
                  </Typography>
                  <Controller
                    as={<Input className='inputLabel' />}
                    name='first_name'
                    control={control}
                  />
                  {errors.first_name && (
                    <p style={{ color: 'red' }}>{t('content.39')}</p>
                  )}
                </Col>

                <Col
                  xl={12}
                  xs={24}
                  sm={12}
                  md={12}
                  style={{ paddingTop: '10px' }}
                >
                  <Typography style={{ paddingBottom: 9 }} className='label'>
                    {t('content.124')}*
                  </Typography>
                  <Controller
                    as={
                      <DatePicker
                        placeholder={t('placeholder.10')}
                        onSelect={(date, dateString) =>
                          onDateSelection(date, dateString)
                        }
                        className='inputLabel'
                      />
                    }
                    name='date_of_birth'
                    control={control}
                    style={{ width: '100%' }}
                  />
                  {errors.date_of_birth && (
                    <p style={{ color: 'red' }}>{t('content.118')}</p>
                  )}
                </Col>
              </Row>
              <Row gutter={[10, 0]}>
                <Col
                  xl={12}
                  md={12}
                  sm={12}
                  xs={24}
                  style={{ paddingTop: '10px' }}
                >
                  <Typography style={{ paddingBottom: 8 }} className='label'>
                    {t('content.17')}
                  </Typography>
                  <PhoneInput
                    country={'in'}
                    onChange={(value, country, e, formattedValue) =>
                      phoneNumberMethod(value, country, e, formattedValue)
                    }
                    placeholder='Enter Your Contact Number'
                    onClick={() => setPhoneRequiredMessage('')}
                    inputStyle={{ width: '100%' }}
                    autoFormat={false}
                    onlyCountries={['in', 'ca', 'us', 'au', 'nz']}
                  />
                  {errors.phone && (
                    <p style={{ color: 'red' }}>{t('content.99')}</p>
                  )}
                  {phoneRequiredMessage && (
                    <p style={{ color: 'red' }}>{phoneRequiredMessage}</p>
                  )}
                  {failureMessage && (
                    <p style={{ color: 'red' }}>{failureMessage}</p>
                  )}
                </Col>

                <Col
                  xl={12}
                  md={12}
                  sm={12}
                  xs={24}
                  style={{ paddingTop: '10px' }}
                >
                  <Typography style={{ paddingBottom: 8 }} className='label'>
                    {t('placeholder.6')}
                  </Typography>
                  <Controller
                    as={<Input className='inputLabel' />}
                    name='email'
                    control={control}
                  />
                  {errors.email && (
                    <p style={{ color: 'red' }}>{t('content.122')}</p>
                  )}
                </Col>
              </Row>
              <Row gutter={[10, 0]}>
                <Col
                  xl={12}
                  md={12}
                  sm={12}
                  xs={24}
                  style={{ paddingTop: '10px' }}
                >
                  <Typography style={{ paddingBottom: 8 }} className='label'>
                    {t('content.79')}
                  </Typography>
                  <Controller
                    name='education_id'
                    as={
                      <Select
                        placeholder={t('content.159')}
                        style={{ width: '100%', borderRadius: '4px' }}
                        className='inputLabel'
                        showSearch={true}
                        options={
                          onlyPublishedEducations &&
                          onlyPublishedEducations.length > 0 &&
                          onlyPublishedEducations.map((val, i) => {
                            return {
                              label: val.name,
                              value: val.id,
                            };
                          })
                        }
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      ></Select>
                    }
                    control={control}
                  />
                  {errors.education_id && (
                    <p style={{ color: 'red' }}>{t('content.119')}</p>
                  )}
                </Col>
                <Col
                  xl={12}
                  md={12}
                  sm={12}
                  xs={24}
                  style={{ paddingTop: '10px' }}
                >
                  <Typography style={{ paddingBottom: 8 }} className='label'>
                    {t('content.80')}
                  </Typography>
                  <Controller
                    as={
                      <Select
                        placeholder={t('content.160')}
                        style={{ width: '100%', borderRadius: '4px' }}
                        className='inputLabel'
                        showSearch={true}
                        options={
                          onlyPublishedIndustries &&
                          onlyPublishedIndustries.length > 0 &&
                          onlyPublishedIndustries.map((val, i) => {
                            return {
                              label: val.name,
                              value: val.id,
                            };
                          })
                        }
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      ></Select>
                    }
                    name='industry_id'
                    control={control}
                  />
                  {errors.industry_id && (
                    <p style={{ color: 'red' }}>{t('content.120')}</p>
                  )}
                </Col>
              </Row>
              <Row style={{ paddingTop: '10px' }} gutter={[0, 10]}>
                <Col span={24}>
                  <Typography className='label'>{t('content.81')}*</Typography>
                </Col>
                <Col>
                  <Row>
                    <Col className='label'>
                      <Checkbox
                        onChange={(e) => genderValue(e)}
                        value={'female'}
                        checked={gender?.female}
                      >
                        {t('content.83')}
                      </Checkbox>
                    </Col>
                    <Col className='label'>
                      <Checkbox
                        onChange={(e) => genderValue(e)}
                        value={'male'}
                        checked={gender?.male}
                      >
                        {t('content.82')}
                      </Checkbox>
                    </Col>
                  </Row>
                  {genderRequiredMessage && (
                    <p style={{ color: 'red', marginTop: '10px' }}>
                      {' '}
                      {genderRequiredMessage}{' '}
                    </p>
                  )}
                </Col>
              </Row>

              <Row
                gutter={[10, 0]}
                style={{ paddingTop: '10px' }}
                justify='end'
              >
                <Col>
                  <Button onClick={handleCancel} className='allButtonsCancel'>
                    {t('btn.19')}
                  </Button>
                </Col>
                <Col>
                  <Button htmlType='submit' className='allButtons'>
                    {t('btn.24')}
                  </Button>
                </Col>
              </Row>
            </form>
          </LoadingIndicator>
        </Drawer>
      ) : (
        <Drawer
          open={isUpdateMemberModalVisible}
          onClose={CloseMemberModal}
          footer={null}
          title={t('btn.40')}
          maskClassName='DrawerClass'
          className='DrawerBorderClass'
        >
          <LoadingIndicator loading={isUpdateFamilyMemberLoading}>
            <form onSubmit={handleSubmit(updateMem)}>
              <Col span={24}>
                <Row justify='center'>
                  <Col>
                    {isUpdateMemberModalVisible &&
                      isDefaultImageVisible &&
                      user_image?.image && (
                        <Upload
                          listType='picture-card'
                          openFileDialogOnClick={false}
                        >
                          <img
                            src={newImageUrl(user_image?.image)}
                            width={102}
                            alt=''
                          ></img>
                        </Upload>
                      )}
                  </Col>
                  <Col>
                    <Upload
                      {...propsForUpload}
                      listType='picture-card'
                      maxCount={1}
                      showUploadList={showUploadImage}
                      onChange={() => handleUploadMethod()}
                    >
                      <PlusOutlined size={44} />
                      {user?.image ? 'Edit Image' : 'Upload Image'}
                    </Upload>
                  </Col>
                </Row>
              </Col>
              <Row gutter={[10, 0]}>
                <Col
                  xl={12}
                  md={12}
                  sm={12}
                  xs={24}
                  style={{ paddingTop: '10px' }}
                >
                  <Typography className='label' style={{ paddingBottom: 9 }}>
                    {t('placeholder.4')}*
                  </Typography>
                  <Controller
                    as={<Input className='inputLabel' />}
                    name='first_name'
                    control={control}
                  />
                  {errors.first_name && (
                    <p style={{ color: 'red' }}>{errors.first_name.message}</p>
                  )}
                </Col>
                <Col
                  xl={12}
                  md={12}
                  sm={12}
                  xs={24}
                  style={{ paddingTop: '10px' }}
                >
                  <Typography style={{ paddingBottom: 8 }} className='label'>
                    {t('placeholder.6')}
                  </Typography>
                  <Controller
                    as={<Input className='inputLabel' />}
                    name='email'
                    control={control}
                  />
                </Col>
              </Row>
              <Row gutter={[10, 0]}>
                <Col
                  xl={12}
                  md={12}
                  sm={12}
                  xs={24}
                  style={{ paddingTop: '10px' }}
                >
                  <Typography style={{ paddingBottom: 8 }} className='label'>
                    {t('content.17')}
                  </Typography>

                  <PhoneInput
                    country={'in'}
                    onChange={(value, country, e, formattedValue) =>
                      phoneNumberMethod(value, country, e, formattedValue)
                    }
                    placeholder='Enter Your Contact Number'
                    onClick={() => setErrorMessage('')}
                    inputStyle={{ width: '100%' }}
                    value={preloadedFamilyMemberValues?.phone_with_country_code}
                    autoFormat={false}
                    onlyCountries={['in', 'ca', 'us', 'au', 'nz']}
                    disabled={isPhoneNumberAbsent}
                  />
                  {errorMessage && (
                    <p style={{ color: 'red' }}>{errorMessage}</p>
                  )}
                </Col>
                <Col
                  xl={12}
                  md={12}
                  sm={12}
                  xs={24}
                  style={{ paddingTop: '10px' }}
                >
                  <Typography style={{ paddingBottom: 8 }} className='label'>
                    {t('content.79')}
                  </Typography>
                  <Controller
                    name='education_id'
                    as={
                      <Select
                        placeholder='Select Education'
                        style={{ width: '100%', borderRadius: '4px' }}
                        className='inputLabel'
                        showSearch={true}
                        options={
                          onlyPublishedEducations &&
                          onlyPublishedEducations.length > 0 &&
                          onlyPublishedEducations.map((val, i) => {
                            return {
                              label: val.name,
                              value: val.id,
                            };
                          })
                        }
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      ></Select>
                    }
                    control={control}
                  />
                  {errors.education_id && (
                    <p style={{ color: 'red' }}>
                      {errors.education_id.message}
                    </p>
                  )}
                </Col>
              </Row>
              <Row gutter={[10, 0]}>
                <Col
                  xl={12}
                  md={12}
                  sm={12}
                  xs={24}
                  style={{ paddingTop: '10px' }}
                >
                  <Typography style={{ paddingBottom: 8 }} className='label'>
                    {t('content.80')}
                  </Typography>
                  <Controller
                    as={
                      <Select
                        placeholder='Select Industry'
                        style={{ width: '100%', borderRadius: '4px' }}
                        className='inputLabel'
                        showSearch={true}
                        options={
                          onlyPublishedIndustries &&
                          onlyPublishedIndustries.length > 0 &&
                          onlyPublishedIndustries.map((val, i) => {
                            return {
                              label: val.name,
                              value: val.id,
                            };
                          })
                        }
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      ></Select>
                    }
                    name='industry_id'
                    control={control}
                  />
                  {errors.industry_id && (
                    <p style={{ color: 'red' }}>{errors.industry_id.message}</p>
                  )}
                </Col>
                <Col
                  xl={12}
                  xs={24}
                  sm={12}
                  md={12}
                  style={{ paddingTop: '10px' }}
                >
                  <Typography style={{ paddingBottom: 9 }} className='label'>
                    {t('content.124')}*
                  </Typography>
                  <Controller
                    as={
                      <DatePicker
                        onSelect={(date, dateString) =>
                          onDateSelection(date, dateString)
                        }
                        className='inputLabel'
                      />
                    }
                    name='date_of_birth'
                    control={control}
                    style={{ width: '100%' }}
                  />
                  {errors.date_of_birth && (
                    <p style={{ color: 'red' }}>
                      {errors.date_of_birth.message}
                    </p>
                  )}
                </Col>
              </Row>
              <Row
                style={{ paddingTop: '10px' }}
                gutter={[0, 10]}
                justify='space-between'
              >
                <Col span={12}>
                  <Row>
                    <Col span={24}>
                      <Typography className='label'>
                        {t('content.81')}*
                      </Typography>
                    </Col>
                    <Col>
                      <Row>
                        <Col className='label'>
                          <Checkbox
                            onChange={(e) => genderValue(e)}
                            value={'female'}
                            checked={user?.gender === 'female'}
                          >
                            {t('content.83')}
                          </Checkbox>
                        </Col>
                        <Col className='label'>
                          <Checkbox
                            onChange={(e) => genderValue(e)}
                            value={'male'}
                            checked={user?.gender === 'male'}
                          >
                            {t('content.82')}
                          </Checkbox>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row gutter={[0, 18]}>
                    <Col span={24} />
                    <Col className='label'>
                      <Checkbox
                        value={'deceased'}
                        checked={isDeceased}
                        onChange={(e) => setIsDeceased(e.target.checked)}
                      >
                        {t('content.227')}
                      </Checkbox>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row
                gutter={[10, 0]}
                style={{ paddingTop: '10px' }}
                justify='end'
              >
                <Col>
                  <Button
                    onClick={CloseMemberModal}
                    className='allButtonsCancel'
                  >
                    {t('btn.19')}
                  </Button>
                </Col>
                <Col>
                  <Button htmlType='submit' className='allButtons'>
                    {t('btn.17')}
                  </Button>
                </Col>
              </Row>
            </form>
          </LoadingIndicator>
        </Drawer>
      )}
    </Col>
  );
};

export default AddFamilyMember;
