import React, { useEffect } from 'react';
import { Row, Col, Typography, Collapse, Divider } from 'antd';
import LandingPageHeader from './LandingPageHeader';
import './LandingPage.css';
import '../TermsAndConditions/TermsAndConditions.css';
import {
  PlusOutlined,
  MinusOutlined,
  CaretRightOutlined,
} from '@ant-design/icons';

import './Faqs.css';
import Footer from './Footer';
import { useBreakpoints } from '../CommonLogics/ResponsiveBreakPoints';
const Faqs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.querySelector('body').scrollTo(0, 0);
  }, []);
  const { Panel } = Collapse;
  const { IsXS, IsSM } = useBreakpoints();
  return (
    <Col className='MainbackgroundColor'>
      <Col className='TermsAndConditionsImageContainer'>
        <LandingPageHeader />
        <Row
          style={{
            height: '100%',
          }}
        >
          <Col span={24}>
            <Typography className='TermsAndConditionsH1'>FAQs</Typography>
            <Typography className='TermsAndConditionsH1_1'>
              Frequently Asked Questions
            </Typography>
          </Col>
        </Row>
      </Col>
      <Col
        style={{
          background: '#FFFFFF',
          borderRadius: '50px 50px 0 0',
          marginTop: '100px',
        }}
      >
        <Row justify='center'>
          <Col className='ContentContainer'>
            <Row gutter={[0, 32]}>
              <Col span={24}>
                <Collapse
                  bordered={false}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  accordion
                  style={{ padding: IsXS ? '0 20px 0 20px' : '0' }}
                >
                  <Panel
                    header={
                      <Typography
                        className='faqsTitle1'
                        style={{ marginTop: '20px' }}
                      >
                        Can I access Samaj on my mobile phone?
                      </Typography>
                    }
                    style={{
                      marginTop: '20px',
                      background: '#F7F7F7',
                      borderRadius: '25px',
                    }}
                  >
                    <Divider style={{ marginTop: '-20px' }} />
                    <Typography className='faqsTitle2'>
                      Yes, Samaj is accessible on mobile phones through the web
                      browser or by downloading the mobile app from the App
                      Store or Google Play Store.
                    </Typography>
                  </Panel>
                  <Panel
                    header={
                      <Typography
                        className='faqsTitle1'
                        style={{ marginTop: '20px' }}
                      >
                        What should I do if I encounter technical issues while
                        using Samaj?
                      </Typography>
                    }
                    style={{
                      marginTop: '20px',
                      background: '#F7F7F7',
                      borderRadius: '25px',
                    }}
                  >
                    <Divider style={{ marginTop: '-20px' }} />
                    <Typography className='faqsTitle2'>
                      Have you lost photos, work documents, assignments, or any
                      other important files? We quickly recover data from
                      phones, laptops, tablets, computers and hard drives.
                    </Typography>
                  </Panel>
                  <Panel
                    header={
                      <Typography
                        className='faqsTitle1'
                        style={{ marginTop: '20px' }}
                      >
                        How do I update my profile information on Samaj?
                      </Typography>
                    }
                    style={{
                      marginTop: '20px',
                      background: '#F7F7F7',
                      borderRadius: '25px',
                    }}
                  >
                    <Divider style={{ marginTop: '-20px' }} />
                    <Typography className='faqsTitle2'>
                      Have you lost photos, work documents, assignments, or any
                      other important files? We quickly recover data from
                      phones, laptops, tablets, computers and hard drives.
                    </Typography>
                  </Panel>
                  <Panel
                    header={
                      <Typography
                        className='faqsTitle1'
                        style={{ marginTop: '20px' }}
                      >
                        How does Samaj handle user authentication and security?
                      </Typography>
                    }
                    style={{
                      marginTop: '20px',
                      background: '#F7F7F7',
                      borderRadius: '25px',
                    }}
                  >
                    <Divider style={{ marginTop: '-20px' }} />
                    <Typography className='faqsTitle2'>
                      Have you lost photos, work documents, assignments, or any
                      other important files? We quickly recover data from
                      phones, laptops, tablets, computers and hard drives.
                    </Typography>
                  </Panel>
                  <Panel
                    header={
                      <Typography
                        className='faqsTitle1'
                        style={{ marginTop: '20px' }}
                      >
                        Can I contribute to the development of my community
                        through Samaj?
                      </Typography>
                    }
                    style={{
                      marginTop: '20px',
                      background: '#F7F7F7',
                      borderRadius: '25px',
                    }}
                  >
                    <Divider style={{ marginTop: '-20px' }} />
                    <Typography className='faqsTitle2'>
                      Have you lost photos, work documents, assignments, or any
                      other important files? We quickly recover data from
                      phones, laptops, tablets, computers and hard drives.
                    </Typography>
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify='center'>
          <Col>
            <Footer />
          </Col>
        </Row>
      </Col>
    </Col>
  );
};

export default Faqs;
