import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import {
  Drawer,
  Input,
  Button,
  Typography,
  Row,
  Col,
  message,
  Select,
} from 'antd';
import { Controller, useForm } from 'react-hook-form';
import {
  createBusiness,
  getBusiness,
  updateBusiness,
} from '../../Actions/businessAction';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { JobProfileSchema, UpdateJobProfileSchema } from '../../Utils/Schema';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../../Screens/LoadingIndicator/LoadingIndicator';
const JobProfileModalForm = (props) => {
  const { t } = useTranslation();
  const { visible, setVisible, editId, setEditId } = props;
  const dispatch = useDispatch();
  const {
    allBusiness,
    isCreateBusinessLoading,
    isUpdateBusinessLoading,
    allFamilies,
  } = useSelector((state) => {
    const {
      allBusiness,
      isBusinessLoading,
      isCreateBusinessLoading,
      isUpdateBusinessLoading,
    } = state.business;
    const { searchUsers } = state.searchUser;
    const { allFamilies } = state.family;
    return {
      allBusiness,
      isBusinessLoading,
      searchUsers,
      isCreateBusinessLoading,
      isUpdateBusinessLoading,
      allFamilies,
    };
  });
  const the_business = _.filter(allBusiness, function (o) {
    return o.id === editId;
  });
  const [preloadedBusinessValue, setPreloadedBusinessValue] = useState(null);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: preloadedBusinessValue,
    resolver: yupResolver(editId ? UpdateJobProfileSchema : JobProfileSchema),
  });

  useEffect(() => {
    setPreloadedBusinessValue(the_business[0]);
    reset(the_business[0]);
  }, [the_business[0], reset]);
  const userName =
    the_business &&
    the_business.length > 0 &&
    the_business[0]?.user?.first_name;
  const userId =
    the_business && the_business.length > 0 && the_business[0]?.user?.id;
  const userLastName =
    the_business && the_business.length > 0 && the_business[0]?.user?.last_name;
  const userFullName = userName + ' ' + userLastName;
  if (editId) {
    setValue('user_id', userFullName);
  }
  const handleCancel = () => {
    setVisible(false);
    setEditId('');
    setValue('job_title', '');
    setValue('service_provide', '');
    setValue('working_here_since', '');
    setValue('business_location', '');
    setValue('experiance', '');
    setValue('business_details', '');
    setValue('company_name', '');
    clearErrors();
  };
  const handleCreateBusiness = (data) => {
    data.is_business = false;

    dispatch(
      createBusiness(data, successCreateBusiness, failureCreateBusiness)
    );
  };
  const successCreateBusiness = () => {
    setVisible(false);
    setValue('user_id', '');
    setValue('job_title', '');
    setValue('service_provide', '');
    setValue('working_here_since', '');
    setValue('business_location', '');
    setValue('experiance', '');
    setValue('business_details', '');
    setValue('company_name', '');
    setEditId('');
    dispatch(getBusiness());
    setTimeout(() => {
      message.success(t('content.304'));
    }, 1000);
  };
  const failureCreateBusiness = () => {};
  const handleEditBusiness = (data) => {
    data.id = editId;
    data.user_id = userId;
    dispatch(updateBusiness(data, UpdateCallBack));
  };
  const UpdateCallBack = () => {
    setVisible(false);

    setEditId('');
    reset();

    dispatch(getBusiness());
    setTimeout(() => {
      message.success(t('content.305'));
    }, 1000);
  };

  useEffect(() => {
    if (!editId) {
      setValue('job_title', '');
      setValue('working_here_since', '');
      setValue('business_details', '');
      setValue('business_location', '');
      setValue('experiance', '');
      setValue('company_name', '');
      setEditId('');
    }
  }, [setValue, !editId]);
  const userFamilies = allFamilies?.map((val, i) => {
    const { user_families } = val;
    const userFirstName = user_families?.map((val) => {
      return {
        label: val.user.first_name + ' ' + val.user.last_name,
        value: val.user.id,
      };
    });
    return userFirstName;
  });

  const userMember = _.flatten(userFamilies);
  return (
    <>
      <Drawer
        title={
          editId ? `Edit Job Profile For ${userFullName}` : t('welcomeText.22')
        }
        centered
        open={visible}
        onClose={handleCancel}
        footer={null}
        className='matrimonialProfile DrawerBorderClass'
        maskClassName='DrawerClass'
      >
        <form
          onSubmit={handleSubmit(
            editId ? handleEditBusiness : handleCreateBusiness
          )}
        >
          <LoadingIndicator
            loading={editId ? isUpdateBusinessLoading : isCreateBusinessLoading}
          >
            <Row gutter={[8, 8]}>
              {!editId && (
                <Col span={12} className='formRows'>
                  <Typography className='details' style={{ paddingBottom: 9 }}>
                    {t('content.15')}
                  </Typography>
                  <Controller
                    as={
                      <Select
                        placeholder={t('content.4')}
                        style={{ width: '100%', borderRadius: '4px' }}
                        size='large'
                        showSearch={true}
                        options={userMember}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      ></Select>
                    }
                    name='user_id'
                    control={control}
                  />
                  {errors.user_id && (
                    <p style={{ color: 'red' }}>{errors.user_id.message}</p>
                  )}
                </Col>
              )}
              <Col span={editId ? 24 : 12} className='formRows'>
                <Typography className='details' style={{ paddingBottom: 9 }}>
                  {t('content.228')}
                </Typography>
                <Controller
                  as={<Input size='large' />}
                  name='job_title'
                  control={control}
                  className='inputLabel'
                />
                {errors.job_title && (
                  <p style={{ color: 'red' }}>{errors.job_title.message}</p>
                )}
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={24} className='formRows'>
                <Typography className='details' style={{ paddingBottom: 9 }}>
                  {t('content.48')}
                </Typography>
                <Controller
                  as={<Input size='large' />}
                  name='company_name'
                  control={control}
                  className='inputLabel'
                />
                {errors.company_name && (
                  <p style={{ color: 'red' }}>{errors.company_name.message}</p>
                )}
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={24} className='formRows'>
                <Typography className='details' style={{ paddingBottom: 9 }}>
                  {t('content.66')}
                </Typography>
                <Controller
                  as={<Input size='large' />}
                  name='working_here_since'
                  control={control}
                  className='inputLabel'
                />
                {errors.working_here_since && (
                  <p style={{ color: 'red' }}>
                    {errors.working_here_since.message}
                  </p>
                )}
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={24} className='formRows'>
                <Typography className='details' style={{ paddingBottom: 9 }}>
                  {t('content.74')}
                </Typography>
                <Controller
                  as={<Input size='large' />}
                  name='business_details'
                  control={control}
                  className='inputLabel'
                />
                {errors.business_details && (
                  <p style={{ color: 'red' }}>
                    {errors.business_details.message}
                  </p>
                )}
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col span={24} className='formRows'>
                <Typography className='details' style={{ paddingBottom: 9 }}>
                  {t('content.68')}
                </Typography>
                <Controller
                  as={<Input size='large' />}
                  name='business_location'
                  control={control}
                  className='inputLabel'
                />
                {errors.business_location && (
                  <p style={{ color: 'red' }}>
                    {errors.business_location.message}
                  </p>
                )}
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col span={24} className='formRows'>
                <Typography className='details' style={{ paddingBottom: 9 }}>
                  {t('content.69')}
                </Typography>
                <Controller
                  as={<Input size='large' />}
                  name='experiance'
                  control={control}
                  className='inputLabel'
                />
                {errors.experiance && (
                  <p style={{ color: 'red' }}>{errors.experiance.message}</p>
                )}
              </Col>
            </Row>

            <Row gutter={[8, 8]} justify='end'>
              <Col className='formRows'>
                {editId ? (
                  <Button htmlType='submit' className='modal-buttonAdd'>
                    {t('btn.17')}
                  </Button>
                ) : (
                  <Button htmlType='submit' className='modal-buttonAdd'>
                    {t('btn.24')}
                  </Button>
                )}
                <Button
                  className='modal-buttonCancel'
                  onClick={handleCancel}
                  style={{ margin: '0 8px' }}
                >
                  {t('btn.19')}
                </Button>
              </Col>
            </Row>
          </LoadingIndicator>
        </form>
      </Drawer>
    </>
  );
};
export default JobProfileModalForm;
