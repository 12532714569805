import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Typography, Empty } from 'antd';
import { getEvents } from '../Actions/eventsAction';
import { useDispatch, useSelector } from 'react-redux';
import EventsEmptyScreen from '../assets/EventsEmptyScreen.svg';
import './Events.css';
import EventsModal from './EventsModal';
import EventCard from './EventCard';
import '../Screens/TrusteeView/Trusteecard.css';
import { useTranslation } from 'react-i18next';
import '../Screens/AddFamilymember/Addfamily.css';
import './../FindThePeoplePage/FindOtherPeople.css';
import { history } from '../Utils/history';
import { getCommunityById } from '../Actions/communitiesAction';
import LoadingIndicator from '../Screens/LoadingIndicator/LoadingIndicator';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);

const Event = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    allEvents,
    isCreateEventsLoading,
    isGetEventsLoading,
    isUpdateEventsLoading,
    setCommunityId,
    currentUser,
    community,
  } = useSelector((state) => {
    const {
      allEvents,
      isCreateEventsLoading,
      isGetEventsLoading,
      isUpdateEventsLoading,
    } = state.events;
    const { setCommunityId } = state.communities;
    const { currentUser } = state.authentication;
    const { community } = state.communities;
    return {
      allEvents,
      isCreateEventsLoading,
      isGetEventsLoading,
      isUpdateEventsLoading,
      setCommunityId,
      currentUser,
      community,
    };
  });
  const localStorageCommunityId = localStorage.getItem('community_id');
  const isEventsVisibleByAdmin = community?.show_event;
  const redirectMethod = () => {
    history.push('/add_family');
  };
  useEffect(() => {
    dispatch(getEvents(setCommunityId));
    dispatch(
      getCommunityById(
        localStorageCommunityId ? localStorageCommunityId : setCommunityId
      )
    );
    if (isEventsVisibleByAdmin === false) {
      redirectMethod();
    }
  }, [dispatch, isEventsVisibleByAdmin]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUploadImageVisible, setIsUploadImageVisible] = useState(true);
  const openCreateEventModal = () => {
    setIsModalVisible(true);
  };
  const height = window.innerHeight - 64;
  const { users_role, users_types } = currentUser || {};
  const isUserTrustee = users_types.includes('trustee');
  const isUserAdmin = users_types.includes('admin');
  return (
    <>
      <LoadingIndicator loading={isGetEventsLoading}>
        <Col
          style={{
            height: height,
            overflow: 'hidden',
            overflowY: 'scroll',
            backgroundColor: '#FFFFFF',
          }}
          className='customLayout'
        >
          <Row style={{ padding: '20px' }}>
            <Col span={24}>
              <Row gutter={[0, { xs: 20 }]} justify='space-between'>
                <Col>
                  <Typography className='firstrowevents'>
                    {t('navigationHeader.13')}&nbsp;({allEvents?.length})
                  </Typography>
                </Col>
                <Col>
                  {(isUserTrustee || isUserAdmin) && (
                    <Button
                      type='primary'
                      onClick={() => openCreateEventModal()}
                      style={{ borderRadius: '4px', width: '100%' }}
                      className='button-search'
                    >
                      {t('btn.20')}
                    </Button>
                  )}
                  <EventsModal
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    isCreateEventsLoading={isCreateEventsLoading}
                    setCommunityId={setCommunityId}
                    isUploadImageVisible={isUploadImageVisible}
                    setIsUploadImageVisible={setIsUploadImageVisible}
                  ></EventsModal>
                </Col>
              </Row>
            </Col>
            {!isGetEventsLoading && allEvents <= 0 ? (
              <Col span={24}>
                <Row
                  justify='center'
                  gutter={[0, 44]}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    width: '100%',
                    height: '70vh',
                    margin: 'auto',
                  }}
                >
                  <Empty
                    description={
                      <Typography className='empty-details'>
                        {t('emptyScreen.1')}
                      </Typography>
                    }
                    image={EventsEmptyScreen}
                    imageStyle={{
                      height: 38,
                    }}
                  />
                </Row>
              </Col>
            ) : (
              <Col xl={23} md={20} xxl={20}>
                <Row>
                  {allEvents.length > 0 &&
                    allEvents?.map((val, i) => {
                      const { event_datetime } = val;
                      const eventId = val.id;
                      const DateTime = event_datetime?.split('_');
                      const date = DateTime[0];
                      const time = DateTime[1];
                      const day = dayjs(date).format('dddd');
                      const dateCustomFormat = date;
                      const inNextDays = dayjs(date).fromNow(
                        new Date().toTimeString()
                      );
                      const currentInNextDays =
                        inNextDays === new Date().toLocaleDateString();
                      const amPm = DateTime[1]?.toString().slice(5, 7);
                      const updateDateTime = date;
                      const currentDate =
                        dateCustomFormat === dayjs().format('Do MMMM YYYY');
                      const startDate = dayjs(date).format('YYYY-MM-DD');
                      const endDate = dayjs().format('YYYY-MM-DD');
                      const difference = dayjs(startDate).diff(endDate, 'days');
                      const dateExample1 = event_datetime;
                      return (
                        <Col xl={24} key={i}>
                          <EventCard
                            key={i}
                            eventdate={date}
                            eventname={val.event_name}
                            eventlocation={val.event_location}
                            eventdetails={val.event_detail}
                            cityname={val.city_name}
                            dateCustomFormat={dateCustomFormat}
                            time={time}
                            inNextDays={inNextDays}
                            currentInNextDays={currentInNextDays}
                            eventId={eventId}
                            amPm={amPm}
                            isCreateEventsLoading={isCreateEventsLoading}
                            isUpdateEventsLoading={isUpdateEventsLoading}
                            day={day}
                            event={val}
                            updateDateTime={updateDateTime}
                            currentDate={currentDate}
                            setCommunityId={setCommunityId}
                            difference={difference}
                            dateExample1={dateExample1}
                            users_role={users_role}
                            users_types={users_types}
                          ></EventCard>
                        </Col>
                      );
                    })}
                </Row>
              </Col>
            )}
          </Row>
        </Col>
      </LoadingIndicator>
    </>
  );
};
export default Event;
