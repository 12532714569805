import React from 'react';
import { Row, Col, Button, Table, message, Empty, Typography } from 'antd';
import '../../FindThePeoplePage/FindOtherPeople.css';
import './Admin.css';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import {
  updateDesignation,
  getDesignations,
} from '../../Actions/designationsAction';
import DesignationEmptyScreen from '../../assets/DesignationEmptyScreen.svg';
import { useBreakpoints } from '../CommonLogics/ResponsiveBreakPoints';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { analytics } from '../../components/Mixpanel/Mixpanel';
const DesignationTable = (props) => {
  const { t } = useTranslation();

  const { IsSM, IsXS } = useBreakpoints();

  const {
    setIsEditDesignationModalVisible,
    setEditDesignationId,
    allDesignations,
    isGetDesignationsLoading,
    setCommunityId,
  } = props;
  const AllDesignations = _.isArray(allDesignations)
    ? allDesignations
    : Object.keys(allDesignations);
  const columns = [
    {
      title: `${t('content.5')} (${AllDesignations?.length})`,
      dataIndex: 'designation_title',
      width: IsXS || IsSM ? '50%' : '80%',
      render: (designation_title, allDesignationsList) => {
        return allDesignationsList.published ? (
          designation_title
        ) : (
          <Col className='hidedRow'>{designation_title}</Col>
        );
      },
    },
    {
      dataIndex: 'id',
      render: (id, designation_title) => (
        <ActionComponent
          id={id}
          setIsEditDesignationModalVisible={setIsEditDesignationModalVisible}
          setEditDesignationId={setEditDesignationId}
          designation_title={designation_title}
          setCommunityId={setCommunityId}
        />
      ),
      width: '15em',
    },
  ];
  const height = window.innerHeight - 400;
  return (
    <>
      <LoadingIndicator loading={isGetDesignationsLoading}>
        <Col
          style={{ height: height, overflow: 'hidden', overflowY: 'scroll' }}
        >
          {allDesignations.length > 0 ? (
            <Col className='AdminTable'>
              <Table
                columns={columns}
                dataSource={AllDesignations}
                size='middle'
                className='table'
                rowKey={(record) => {
                  return record.id;
                }}
              />
            </Col>
          ) : (
            !isGetDesignationsLoading && (
              <Row
                justify='center'
                gutter={[0, 10]}
                className='emptyScreenAdminCenter'
              >
                <Empty
                  description={
                    <Typography className='emptyScreenAdmin'>
                      {t('content.290')}
                    </Typography>
                  }
                  image={DesignationEmptyScreen}
                  imageStyle={{
                    height: 38,
                  }}
                />
              </Row>
            )
          )}
        </Col>
      </LoadingIndicator>
    </>
  );
};

export default DesignationTable;

const ActionComponent = (props) => {
  const dispatch = useDispatch();
  const {
    id,
    setIsEditDesignationModalVisible,
    setEditDesignationId,
    designation_title,
    setCommunityId,
  } = props;
  let UnpublishData = {};
  let PublishData = {};
  const handleEditDesignationMethod = (id) => {
    setIsEditDesignationModalVisible(true);
    setEditDesignationId(id);
  };
  const handlePublishDesignationMethod = (EditId) => {
    PublishData.id = EditId;
    PublishData.published = true;
    dispatch(updateDesignation(PublishData, successUpdatePublishMethod));
  };
  const handleUnpublishDesignationMethod = (EditId) => {
    UnpublishData.id = EditId;
    UnpublishData.published = false;
    dispatch(updateDesignation(UnpublishData, successUpdateUnpublishMethod));
  };
  const successUpdateUnpublishMethod = () => {
    setTimeout(() => {
      message.success(t('content.327'));
    }, 1000);
    dispatch(getDesignations(setCommunityId));
  };
  const successUpdatePublishMethod = () => {
    setTimeout(() => {
      message.success(t('content.328'));
    }, 1000);
    dispatch(getDesignations(setCommunityId));
  };
  const { t } = useTranslation();

  return (
    <>
      <Row gutter={[40, 0]}>
        <Col span={10}>
          <Button
            onClick={() => {
              analytics?.track('Admin_Desigination_Edit');
              handleEditDesignationMethod(id);
            }}
            type='primary'
            className='editDesignationButton'
          >
            {t('btn.27')}
          </Button>
        </Col>

        <Col span={10}>
          {designation_title.published ? (
            <Button
              type='primary'
              className='unPublishButton'
              onClick={() => {
                analytics?.track('Admin_Desigination_Unpublish');
                handleUnpublishDesignationMethod(id);
              }}
            >
              {t('content.164')}
            </Button>
          ) : (
            <Button
              type='primary'
              className='publishButton'
              onClick={() => {
                analytics?.track('Admin_Desigination_Publish');
                handlePublishDesignationMethod(id);
              }}
            >
              {t('content.163')}
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};
