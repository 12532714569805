import constants from "../Utils/constants";
const initialState = {
        isContactLoading:false
};

export const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_CONTACT_REQUEST:
      return {
        ...state,
        isContactLoading: true,
      };
    case constants.CREATE_CONTACT_SUCCESS:
      return {
        ...state,
        isContactLoading: false,
      };
    case constants.CREATE_CONTACT_FAILURE:
      return {
        ...state,
        isContactLoading: false,
      };
    ;
    
      case constants.RESET_APP_DATA:
        return {
          ...initialState
        }
        default:
      return state;
  }
};
export default contactReducer;