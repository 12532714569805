import React, { useState, useEffect } from 'react';
import {
  Input,
  Button,
  Typography,
  Row,
  Col,
  Select,
  Divider,
  message,
  Steps,
  Upload,
  TimePicker,
  Checkbox,
  DatePicker,
  Drawer,
} from 'antd';
import { Controller, useForm } from 'react-hook-form';
import {
  createMatrimonial,
  getMatrimonial,
  matrimonialById,
  updateMatrimonial,
} from '../../Actions/matrimonialAction';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import {
  MatrimonialuserSchema,
  UpdateMatrimonialuserSchema,
} from '../../Utils/Schema';
import { deleteFileAction } from '../../Actions/messageAction';
import { useTranslation } from 'react-i18next';
import {
  PlusOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import LoadingIndicator from '../../Screens/LoadingIndicator/LoadingIndicator';
import { getFamilies } from '../../Actions/familyAction';
import { newImageUrl } from '../../Screens/CommonLogics/CommonMethods';
import { analytics } from '../../components/Mixpanel/Mixpanel';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

const MyProfileModalForm = (props) => {
  const { visible, setVisible, editId, setEditId, currentPage } = props;
  const { t } = useTranslation();
  const [current, setCurrent] = React.useState(0);
  const next = () => {
    const {
      birth_place,
      birth_time,
      height,
      weight,
      description,
      user_id,
      current_city,
      marital_status,
    } = getValues([
      'birth_time',
      'birth_place',
      'user_id',
      'height',
      'weight',
      'description',
      'current_city',
      'marital_status',
    ]);
    if (
      current === 0 &&
      (!birth_time || (!editId && !user_id) || !birth_place || !current_city)
    ) {
      trigger(['user_id', 'birth_time', 'birth_place', 'current_city']);
    } else if (
      current === 1 &&
      (!height || !weight || !description || !marital_status)
    ) {
      trigger(['height', 'weight', 'description', 'marital_status']);
    } else {
      setCurrent(current + 1);
    }
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const [newFiles, setNewFiles] = useState([]);
  const [isDefaultImageVisible, setIsDefaultImageVisible] = useState(true);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [failureMessageError, setFailureMessageError] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const IsEmpty = _.isEmpty(newFiles);
  const dispatch = useDispatch();
  const {
    allMatrimonial,
    allFamilies,
    isDeleteFileLoading,
    isCreateMatrimonialLoading,
    isUpdateMatrimonialLoading,
    matrimonialProfile,
  } = useSelector((state) => {
    const {
      allMatrimonial,
      isMatrimonialLoading,
      isCreateMatrimonialLoading,
      isUpdateMatrimonialLoading,
      matrimonialProfile,
    } = state.matrimonial;
    const { allFamilies } = state.family;
    const { searchUsers } = state.searchUser;
    const { isDeleteFileLoading } = state.message;
    return {
      allMatrimonial,
      isMatrimonialLoading,
      allFamilies,
      searchUsers,
      isDeleteFileLoading,
      isCreateMatrimonialLoading,
      isUpdateMatrimonialLoading,
      matrimonialProfile,
    };
  });
  useEffect(() => {
    dispatch(getFamilies());
  }, []);
  const the_matrimonial = _.filter(allMatrimonial.matrimonials, function (o) {
    return o.id === editId;
  });
  const Time = dayjs(the_matrimonial[0]?.birth_time, 'HH:mm:ss');
  const newArray = the_matrimonial.map((obj) => {
    return {
      ...obj,
      birth_time: Time,
    };
  });
  const [preloadedMatrimonialValues, setPreloadedMatrimonialValues] =
    useState('');
  const [customTime, setCustomTime] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [isGenderAbsent, setIsGenderAbsent] = useState(false);
  const [gender, setGender] = useState('');
  const [gendervalue, setGendervalue] = useState('');
  const [genderRequiredMessage, setGenderRequiredMessage] = useState('');
  const [isBirthYearAbsent, setIsBirthYearAbsent] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [birthYearRequiredMessage, setBirthYearRequiredMessage] = useState('');
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    trigger,
    getValues,
    clearErrors,
  } = useForm({
    resolver: yupResolver(
      editId ? UpdateMatrimonialuserSchema : MatrimonialuserSchema
    ),
    defaultValues: preloadedMatrimonialValues,
  });
  useEffect(() => {
    setPreloadedMatrimonialValues(newArray[0]);
    reset(newArray[0]);
  }, [the_matrimonial[0], reset]);
  const onDateSelection = (date) => {
    const selectDate = dayjs(date);
    setSelectedDate(dayjs(selectDate._d).format('yyyy/MM/DD'));
    setBirthYearRequiredMessage('');
  };
  const genderValue = (e) => {
    const { checked, value } = e.target;
    setGender({
      value,
      [value]: checked,
    });
    setGendervalue(value);
    setGenderRequiredMessage('');
  };
  const handleCreateMatrimonial = (data) => {
    const BirthDate = selectedDate.split('/');
    const selectedMember = _.filter(uniqueMembers, (val) => {
      return val.value === selectedId;
    });
    data.files = newFiles;
    data.birth_time = customTime;
    data.gender = isGenderAbsent
      ? gendervalue === 'Male'
        ? 'male'
        : 'female'
      : selectedMember[0].gender;
    data.birth_year =
      BirthDate.length > 1 ? BirthDate[0] : selectedMember[0].year;
    data.birth_month =
      BirthDate.length > 1 ? BirthDate[1] : selectedMember[0].month;
    data.birth_date =
      BirthDate.length > 1 ? BirthDate[2] : selectedMember[0].date;
    if (isGenderAbsent && _.isEmpty(gendervalue)) {
      setGenderRequiredMessage('Gender is required');
    } else if (isBirthYearAbsent && BirthDate.length <= 1) {
      setBirthYearRequiredMessage('Birth Year is required');
    } else {
      dispatch(
        createMatrimonial(
          data,
          successCreateMatrimonial,
          failureCreateMatrimonial,
          IsEmpty
        )
      );
    }
  };
  const successCreateMatrimonial = () => {
    analytics?.track('Matrimonial_CreateProfile');
    reset();
    setCurrent(0);
    setVisible(false);
    setSelectedDate('');
    setEditId('');
    setIsDefaultImageVisible(false);
    setShowUploadImage(false);
    setTimeout(() => {
      message.success(t('content.307'));
    }, 1000);
    dispatch(getMatrimonial(currentPage));
    setNewFiles([]);
    setPreloadedMatrimonialValues('');
    setEditId('');
    setValue('first_name', '');
    setValue('birth_time', '');
    setValue('current_city', '');
    setValue('education', '');
    setValue('address', '');
    setValue('user_id', '');
    setValue('birth_place', '');
    setValue('cast', '');
    setValue('region', '');
    setValue('height', '');
    setValue('weight', '');
    setValue('gender', '');
    setValue('description', '');
    setFailureMessageError('');
  };
  const failureCreateMatrimonial = (FailureResponse) => {
    setFailureMessageError(FailureResponse?.user_id[0]);
  };
  const hanleEditMatrimonial = (data) => {
    data.id = editId;
    data.files = IsEmpty ? null : newFiles;
    data.birth_time = customTime
      ? customTime
      : the_matrimonial[0].birth_time._i;
    dispatch(updateMatrimonial(data, UpdateCallBack, IsEmpty));
  };
  const UpdateCallBack = (data) => {
    reset(data);
    setCurrent(0);
    setVisible(false);
    setEditId('');
    dispatch(getMatrimonial(currentPage));
    setSelectedDate('');
    setShowUploadImage(false);
    setCustomTime('');
    setFailureMessageError('');
    setNewFiles([]);
    setTimeout(() => {
      message.success(t('content.308'));
    }, 1000);
  };
  const handleCancel = () => {
    setNewFiles([]);
    setCurrent(0);
    setVisible(false);
    setEditId('');
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
    setSelectedDate('');
    clearErrors();
    setFailureMessageError('');
    reset();
    setValue('first_name', '');
    setValue('birth_time', '');
    setValue('current_city', '');
    setValue('education', '');
    setValue('address', '');
    setValue('birth_place', '');
    setValue('cast', '');
    setValue('region', '');
    setValue('height', '');
    setValue('weight', '');
    setValue('gender', '');
    setValue('description', '');
    setValue('user_id', '');
    editId && dispatch({ type: 'RESET_MATRIMONIAL_BY_ID_DATA' });
  };
  const propsForUpload = {
    action: handleCreateMatrimonial || hanleEditMatrimonial,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      return false;
    },
    multiple: true,
  };
  const handleUploadMethod = (Event) => {
    setShowUploadImage(true);
    setNewFiles(Event.fileList);
  };
  const { TextArea } = Input;
  const { Step } = Steps;
  const steps = [
    {
      title: t('welcomeText.12'),
    },
    {
      title: t('welcomeText.13'),
    },
  ];
  const onTimeSelection = (time) => {
    const timeSplit = dayjs(time).format('HH.mm');
    setCustomTime(timeSplit > 11 ? timeSplit + 'PM' : timeSplit + 'AM');
  };
  useEffect(() => {
    if (!editId) {
      setEditId('');
      setValue('birth_place', '');
      setValue('height', '');
      setValue('weight', '');
      setValue('gender', '');
      setValue('description', '');
      setValue('current_city', '');
      setValue('marital_status', '');
      setValue('birth_time', '');
      setValue('user_id', '');
    }
  }, [setValue, !editId]);
  const DropdownList = [
    { label: 'Married', value: 'married' },
    { label: 'Unmarried', value: 'unmarried' },
    { label: 'Widow', value: 'widow' },
    { label: 'Widower', value: 'widower' },
  ];
  const userFamilies = allFamilies?.map((val, i) => {
    const { user_families } = val;
    const userFirstName = user_families?.map((val) => {
      return {
        label: val.user.first_name + ' ' + val.user.last_name,
        value: val.user.id,

        gender: val.user.gender,
        year: val.user.birth_year,
        month: val.user.birth_month,
        date: val.user.birth_date,
      };
    });
    return userFirstName;
  });
  const userMember = _.flatten(userFamilies);
  const uniqueMembers = _.uniqBy(userMember, (users) => {
    return users?.value;
  });
  const DeleteImageMethod = (Id) => {
    setDeleteId(Id);
    dispatch(deleteFileAction(Id, SuccessDeleteFileCallback));
  };
  const SuccessDeleteFileCallback = () => {
    dispatch(matrimonialById(editId));
  };
  const handleMemberSelectedMethod = (SelectedMemberId) => {
    setSelectedId(SelectedMemberId);
  };

  return (
    <>
      <Drawer
        title={
          editId
            ? t('welcomeText.11') +
              ' ' +
              'For' +
              ' ' +
              preloadedMatrimonialValues?.user?.first_name +
              ' ' +
              preloadedMatrimonialValues?.user?.last_name
            : t('welcomeText.12')
        }
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onClose={() => handleCancel()}
        footer={null}
        className='matrimonialProfile DrawerBorderClass'
        maskClassName='DrawerClass'
      >
        <Steps direction='vertical' current={current} className='details'>
          {steps?.map((item, i) => (
            <Step title={item.title} key={i} />
          ))}
        </Steps>
        <Divider />
        <LoadingIndicator
          loading={
            editId ? isUpdateMatrimonialLoading : isCreateMatrimonialLoading
          }
        >
          <form
            onSubmit={handleSubmit(
              editId ? hanleEditMatrimonial : handleCreateMatrimonial
            )}
          >
            <Row>
              <Col span={24}>
                <Row justify='center' gutter={[10, 0]}>
                  {editId &&
                    isDefaultImageVisible &&
                    matrimonialProfile?.matrimonial_user_image_keys &&
                    matrimonialProfile?.matrimonial_user_image_keys?.map(
                      (val, i) => {
                        return (
                          <Col key={i}>
                            <Row>
                              <Col>
                                <Upload
                                  listType='picture-card'
                                  openFileDialogOnClick={false}
                                  className='matrimonialUpload'
                                >
                                  <img
                                    src={newImageUrl(val?.key)}
                                    width={102}
                                  ></img>
                                </Upload>
                              </Col>
                              <Col
                                style={{
                                  display: 'flex',
                                  alignItems: 'end',
                                  paddingBottom: '10px',
                                }}
                              >
                                {deleteId === val?.id && isDeleteFileLoading ? (
                                  <LoadingOutlined
                                    style={{
                                      color: '#2accff',
                                    }}
                                  />
                                ) : (
                                  <DeleteOutlined
                                    onClick={() => DeleteImageMethod(val?.id)}
                                  />
                                )}
                              </Col>
                            </Row>
                          </Col>
                        );
                      }
                    )}

                  <Col>
                    <Upload
                      {...propsForUpload}
                      listType='picture-card'
                      showUploadList={showUploadImage}
                      onChange={(e) => handleUploadMethod(e)}
                      multiple={true}
                      className='matrimonialUpload'
                      fileList={newFiles}
                    >
                      <PlusOutlined size={44} />
                      Upload Image
                    </Upload>
                  </Col>
                </Row>
              </Col>
            </Row>

            {!editId && (
              <Col
                span={24}
                style={{ display: current === 0 ? 'block' : 'none' }}
                className='formRows'
              >
                <Typography className='details'>
                  Select your family member
                </Typography>

                <Controller
                  style={{ display: current === 0 ? 'block' : 'none' }}
                  as={
                    <Select
                      placeholder={t('content.4')}
                      style={{ width: '100%', borderRadius: '4px' }}
                      className='inputLabel'
                      showSearch={true}
                      options={uniqueMembers}
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSelect={(e) => handleMemberSelectedMethod(e)}
                    ></Select>
                  }
                  name='user_id'
                  control={control}
                  className='inputLabel'
                  onClick={() => clearErrors('user_id')}
                />
                {errors.user_id && (
                  <p style={{ color: 'red' }}>{errors.user_id.message}</p>
                )}
              </Col>
            )}
            <Col
              span={24}
              className='formRows'
              style={{ display: current === 0 ? 'block' : 'none' }}
            >
              <Typography className='details'>City</Typography>
              <Controller
                as={<Input />}
                control={control}
                name='current_city'
                className='inputLabel'
                onClick={() => clearErrors('current_city')}
              />
              {errors.current_city && (
                <p style={{ color: 'red' }}>{errors.current_city.message}</p>
              )}
            </Col>

            <Col
              span={24}
              style={{ display: current === 0 ? 'block' : 'none' }}
              className='formRows'
            >
              <Typography className='details'>Birth Time</Typography>
              <Controller
                as={
                  <TimePicker
                    use12Hours
                    format='h:mm a'
                    onSelect={(time) => onTimeSelection(time)}
                    className='inputLabel'
                  />
                }
                name='birth_time'
                control={control}
                className='inputLabel'
                style={{ width: '100%' }}
                placeholder={t('placeholder.11')}
                onClick={() => clearErrors('birth_time')}
              />
              {errors.birth_time && (
                <p style={{ color: 'red' }}>{errors.birth_time.message}</p>
              )}
            </Col>
            <Col
              span={24}
              style={{ display: current === 0 ? 'block' : 'none' }}
              className='formRows'
            >
              <Typography className='details'>{t('content.21')}</Typography>
              <Controller
                as={<Input />}
                name='birth_place'
                control={control}
                className='inputLabel'
                onClick={() => clearErrors('birth_place')}
              />
              {errors.birth_place && (
                <p style={{ color: 'red' }}>{t('content.42')}</p>
              )}
            </Col>

            <Row gutter={[16, 8]}>
              <Col
                xl={24}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                style={{
                  textAlign: 'end',
                  display: current === 0 ? 'block' : 'none',
                }}
                className='formRows'
              >
                {current === 0 && (
                  <Button className='modal-buttonCancel' onClick={next}>
                    {t('btn.1')}
                  </Button>
                )}
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col
                span={24}
                style={{ display: current === 1 ? 'block' : 'none' }}
                className='formRows'
              >
                <Typography className='details'>{t('content.24')}</Typography>
                <Controller
                  as={<Input />}
                  name='height'
                  control={control}
                  className='inputLabel'
                  onClick={() => clearErrors('height')}
                />
                {errors.height && (
                  <p style={{ color: 'red' }}>{t('content.44')}</p>
                )}
              </Col>
              <Col
                span={24}
                style={{ display: current === 1 ? 'block' : 'none' }}
                className='formRows'
              >
                <Typography className='details'>{t('content.25')}</Typography>
                <Controller
                  as={<Input />}
                  name='weight'
                  control={control}
                  className='inputLabel'
                  onClick={() => clearErrors('weight')}
                />
                {errors.weight && (
                  <p style={{ color: 'red' }}>{t('content.45')}</p>
                )}
              </Col>
            </Row>

            <Row gutter={[16, 8]}>
              <Col
                span={24}
                style={{ display: current === 1 ? 'block' : 'none' }}
                className='formRows'
              >
                <Typography className='details'>Marital Status</Typography>
                <Controller
                  as={
                    <Select options={DropdownList} style={{ width: '100%' }} />
                  }
                  name='marital_status'
                  control={control}
                  className='inputLabel'
                  onClick={() => clearErrors('marital_status')}
                />
                {errors.marital_status && (
                  <p style={{ color: 'red' }}>
                    {errors.marital_status.message}
                  </p>
                )}
              </Col>

              {!editId && isBirthYearAbsent && (
                <Col
                  xl={12}
                  xs={24}
                  sm={12}
                  md={12}
                  style={{
                    paddingTop: '10px',
                    display: current === 1 ? 'block' : 'none',
                  }}
                >
                  <Typography style={{ paddingBottom: 9 }} className='label'>
                    {t('content.124')}*
                  </Typography>
                  <Controller
                    as={
                      <DatePicker
                        placeholder={t('placeholder.10')}
                        onSelect={(date, dateString) =>
                          onDateSelection(date, dateString)
                        }
                        className='inputLabel'
                      />
                    }
                    name='date_of_birth'
                    control={control}
                    style={{ width: '100%' }}
                  />
                  {errors.date_of_birth && (
                    <p style={{ color: 'red' }}>{t('content.118')}</p>
                  )}
                </Col>
              )}
            </Row>

            <Row gutter={[16, 8]}>
              <Col
                span={24}
                style={{ display: current === 1 ? 'block' : 'none' }}
                className='formRows'
              >
                <Typography className='details'>{t('content.27')}</Typography>
                <Controller
                  as={<TextArea maxLength={100} style={{ height: 120 }} />}
                  name='description'
                  control={control}
                  className='inputLabel'
                  onClick={() => clearErrors('description')}
                />
                {errors.description && (
                  <p style={{ color: 'red' }}>Description is required</p>
                )}
              </Col>
            </Row>

            {!editId && isGenderAbsent && (
              <Row gutter={[16, 8]}>
                <Col
                  className='label'
                  style={{ display: current === 1 ? 'block' : 'none' }}
                >
                  <Checkbox
                    onChange={(e) => genderValue(e)}
                    value={'Female'}
                    checked={gender?.Female}
                  >
                    {t('content.83')}
                  </Checkbox>
                </Col>
                <Col
                  className='label'
                  style={{ display: current === 1 ? 'block' : 'none' }}
                >
                  <Checkbox
                    onChange={(e) => genderValue(e)}
                    value={'Male'}
                    checked={gender?.Male}
                  >
                    {t('content.82')}
                  </Checkbox>
                </Col>
              </Row>
            )}
            <Row gutter={[16, 8]}>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={6}
                xs={6}
                style={{ display: current === 1 ? 'block' : 'none' }}
                className='formRows'
              >
                {current > 0 && (
                  <Button
                    style={{ margin: '0 8px' }}
                    className='modal-buttonCancel'
                    onClick={() => prev()}
                  >
                    {t('btn.23')}
                  </Button>
                )}
              </Col>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={18}
                xs={18}
                style={{
                  textAlign: 'end',
                  display: current === 1 ? 'block' : 'none',
                }}
                className='formRows'
              >
                {editId ? (
                  <Button htmlType='submit' className='modal-buttonAdd'>
                    {t('btn.17')}
                  </Button>
                ) : (
                  <Button htmlType='submit' className='modal-buttonAdd'>
                    {t('btn.24')}
                  </Button>
                )}
              </Col>
              <Col
                style={{
                  display: current === 1 ? 'block' : 'none',
                }}
              >
                <Button
                  className='modal-buttonCancel'
                  onClick={() => handleCancel()}
                  style={{ marginLeft: '10%' }}
                >
                  {t('btn.19')}
                </Button>
              </Col>
              {failureMessageError && (
                <p
                  style={{
                    color: 'red',
                    display: current === 1 ? 'block' : 'none',
                  }}
                >
                  {failureMessageError}
                </p>
              )}
              {genderRequiredMessage && (
                <p
                  style={{
                    color: 'red',
                    display: current === 1 ? 'block' : 'none',
                  }}
                >
                  {genderRequiredMessage}
                </p>
              )}
              {birthYearRequiredMessage && (
                <p
                  style={{
                    color: 'red',
                    display: current === 1 ? 'block' : 'none',
                  }}
                >
                  {birthYearRequiredMessage}
                </p>
              )}
            </Row>
          </form>
        </LoadingIndicator>
      </Drawer>
    </>
  );
};
export default MyProfileModalForm;
