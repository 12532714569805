import API from "../Utils/API";
import constants from "../Utils/constants";

export const getUsers = () => (dispatch) => {
  dispatch({ type: "GET_USERS_REQUEST" });
  API.get("api/users")
    .then((response) => {
      dispatch({ type: "GET_USERS_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: "GET_USERS_FAILURE", payload: error.response.data });
    });
};

export const getCommunityBySlug =
  (community_name, slugSuccessCallback, slugFailureCallback) => (dispatch) => {
    dispatch({ type: constants.GET_COMMUNITY_BY_SLUG_REQUEST });
    API.get(`api/get_community_by_slug?slug=${community_name}`)
      .then((response) => {
        dispatch({
          type: constants.GET_COMMUNITY_BY_SLUG_SUCCESS,
          payload: response.data,
        });
        slugSuccessCallback && slugSuccessCallback(response.data);
      })
      .catch((error) => {
        dispatch({
          type: constants.GET_COMMUNITY_BY_SLUG_REQUEST,
          payload: error,
        });
        slugFailureCallback && slugFailureCallback(error);
      });
  };
