import API from "../Utils/API";
export const createContact = (data , successCallback) => (dispatch) => {
    dispatch({ type: "CREATE_CONTACT_REQUEST", payload: data });
    API.post("api/contacts", data)
      .then((response) => {
        dispatch({ type: "CREATE_CONTACT_SUCCESS", payload: response.data });
        // const sCT = success ? success() : null;
        // console.log({ sCT });
        // success();
        successCallback()
    })
      .catch((error) => {
        dispatch({
          type: "CREATE_CONTACT_FAILURE",
         
         
         
          payload: error.response.data,
       
        });
      });
  }