import API from "../Utils/API";
export const getTemplatesElementsAction = (url) => (dispatch) => {
  dispatch({ type: "GET_TEMPLATES_ELEMENTS_REQUEST" });
  API.get(url)
    .then((response) => {
      dispatch({
        type: "GET_TEMPLATES_ELEMENTS_SUCCESS",
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: "GET_TEMPLATES_ELEMENTS_FAILURE",
        payload: error.response.data,
      });
    });
};

export const createTemplatesElementsAction =
  (data, success, failureCallback) => (dispatch) => {
    dispatch({ type: "CREATE_TEMPLATES_ELEMENTS_REQUEST", payload: data });
    API.post("api/elements", data)
      .then((response) => {
        dispatch({
          type: "CREATE_TEMPLATES_ELEMENTS_SUCCESS",
          payload: response.data,
        });
        // const sCT = success ? success() : null;
        success();
      })
      .catch((error) => {
       
        dispatch({
          type: "CREATE_TEMPLATES_ELEMENTS_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
        // console.log({error})
      });
  };

export const deleteTemplatesElementsAction =
  (Id, successCallback) => (dispatch) => {
    dispatch({ type: "DELETE_TEMPLATES_ELEMENTS_REQUEST" });
    API.delete(`api/elements/${Id}`)
      .then((response) => {
        dispatch({
          type: "DELETE_TEMPLATES_ELEMENTS_SUCCESS",
          payload: response.data,
        });
        successCallback && successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_TEMPLATES_ELEMENTS_FAILURE",
          payload: error.response.data,
        });
      });
  };

export const updateTemplatesElementsAction =
  (data, successCallback, IsEmpty) => (dispatch) => {
    dispatch({ type: "UPDATE_TEMPLATES_ELEMENTS_REQUEST" });
    let formData = new FormData();

    IsEmpty
      ? formData.append("community_card_file", data?.community_card_file)
      : formData.append(
          "community_card[community_card_file]",
          data?.community_card_file
        );
    formData.append("community_id", data.community_id);
    formData.append("community_card[name]", data.name);
    formData.append("community_card[description]", data.description);
    formData.append("community_card[url]", data.url);
    formData.append("community_card[expiry_date]", data.expiry_date);
    formData.append("community_card[card_type]", data.card_type);
    formData.append("community_card[published]", data.published);
    API.put(`api/elements/${data.id}`, formData)
      .then((response) => {
        dispatch({
          type: "UPDATE_TEMPLATES_ELEMENTS_SUCCESS",
          payload: response.data,
        });

        successCallback && successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_TEMPLATES_ELEMENTS_FAILURE",
          payload: error.response.data,
        });
        // failureCallback && failureCallback(error.response.data);
      });
  };

  export const getTemplateElementsById = (Id) => (dispatch) => {
    dispatch({ type: "GET_TEMPLATES_ELEMENTS_BY_ID_REQUEST" });
    API.get(`api/elements/${Id}`)
      .then((response) => {
        dispatch({ type: "GET_TEMPLATES_ELEMENTS_BY_ID_SUCCESS", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_TEMPLATES_ELEMENTS_BY_ID_FAILURE",
          payload: error?.response.data,
        });
      });
  };
