import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Empty,
  Table,
  message,
  Typography,
  AutoComplete,
  Checkbox,
} from 'antd';
import '../../FindThePeoplePage/FindOtherPeople.css';
import './Admin.css';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  CountryViseFormat,
  falseyValueCases,
} from '../CommonLogics/CommonMethods';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchUser } from '../../Actions/searchUserAction';
import { updateUserAction } from '../../Actions/authenticationAction';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import PhoneInput from 'react-phone-input-international';
import 'react-phone-input-international/lib/style.css';
import { SearchOutlined } from '@ant-design/icons';
import { analytics } from '../../components/Mixpanel/Mixpanel';

const HandleUsers = () => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState(' ');
  const [countryCode, setCountryCode] = useState('');
  const [number, setNumber] = useState(' ');
  const [currentPage, setCurrentPage] = useState(1);
  const [isVerified, setIsVerified] = useState(false);
  const { t } = useTranslation();
  const { searchUsers, isSearchUserLoading, setCommunityId } = useSelector(
    (state) => {
      const { searchUsers, isSearchUserLoading } = state.searchUser;
      const { setCommunityId } = state.communities;

      return {
        searchUsers,
        isSearchUserLoading,
        setCommunityId,
      };
    }
  );
  useEffect(() => {
    if (falseyValueCases(firstName)) {
      dispatch(
        getSearchUser(
          `api/user_search?page=${currentPage}&verified=${isVerified}`
        )
      );
    }
  }, [falseyValueCases(firstName)]);

  const columns = [
    {
      title: t('content.15'),
      dataIndex: 'first_name',
      width: '20em',
      render: (first_name, allUsersList) => {
        return allUsersList?.is_poused ? (
          <Col className='hidedRow'>{first_name}</Col>
        ) : (
          first_name
        );
      },
    },

    {
      title: t('placeholder.5'),
      dataIndex: 'last_name',
      width: '20em',
      render: (last_name, allUsersList) => {
        return allUsersList?.is_poused ? (
          <Col className='hidedRow'>{last_name}</Col>
        ) : (
          last_name
        );
      },
    },
    {
      title: t('content.17'),
      dataIndex: 'phone_with_country_code',

      render: (phone_with_country_code, allUsersList) => {
        const IsEmpty =
          phone_with_country_code === 'null'
            ? true
            : phone_with_country_code === 'undefined'
            ? true
            : _.isEmpty(phone_with_country_code);
        return (
          IsEmpty === false &&
          (allUsersList?.is_poused ? (
            <Col className='hidedRow'>
              {phone_with_country_code &&
                CountryViseFormat(phone_with_country_code)}
            </Col>
          ) : (
            phone_with_country_code &&
            CountryViseFormat(phone_with_country_code)
          ))
        );
      },
    },

    {
      dataIndex: 'id',
      render: (id, allUsersList) => (
        <ActionComponent
          id={id}
          searchUsers={searchUsers}
          allUsersList={allUsersList}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setCommunityId={setCommunityId}
          isVerified={isVerified}
          currentPage={currentPage}
        />
      ),
    },
  ];
  const HandleNameMethod = (Name) => {
    const Text = Name.split(' ');
    setFirstName(Text[0]);

    setLastName(Text[1]);
  };
  const Firstname = _.capitalize(firstName && firstName);
  const Lastname = _.capitalize(lastName && lastName);
  const searchUserMethod = () => {
    let url = `api/user_search?page=${currentPage}&verified=${isVerified}`;
    const Text = number;
    const TextText = Text.replace(`+${countryCode}`, '');
    if (firstName && lastName && number) {
      url = `api/user_search?first_name=${Firstname}&last_name=${Lastname}&phone=${TextText}&page=${currentPage}&verified=${isVerified}`;
    } else if (firstName && lastName) {
      url = `api/user_search?first_name=${Firstname}&last_name=${Lastname}&page=${currentPage}&verified=${isVerified}`;
    } else if (firstName) {
      url = `api/user_search?first_name=${Firstname}&page=${currentPage}&verified=${isVerified}`;
    } else if (lastName) {
      url = `api/user_search?last_name=${Lastname}&page=${currentPage}&verified=${isVerified}`;
    } else if (number) {
      url = `api/user_search?phone=${TextText}&page=${currentPage}&verified=${isVerified}`;
    } else {
      url = `api/user_search?page=${currentPage}&verified=${isVerified}`;
    }
    dispatch(getSearchUser(url));
  };
  const SearchUsersByFirstName = _.uniqBy(searchUsers.users, (users) => {
    return users.first_name;
  });
  const SearchFirstName = SearchUsersByFirstName.map((val) => {
    return { value: val.first_name };
  });
  const phoneNumberMethod = (value, country, e, formattedValue) => {
    setCountryCode(country.dialCode);
    setNumber(formattedValue);
  };
  const height = window.innerHeight - 400;
  return (
    <>
      <Row justify='space-around' align='middle' gutter={[0, 10]}></Row>
      <Row justify='space-around'>
        <Col span={23}>
          <Col span={24} />
          <Row gutter={[10, 0]}>
            <Col xl={6} lg={8} md={12} sm={22} xs={22}>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Typography className='firstname'>
                    {t('content.246')}
                  </Typography>
                </Col>
                <Col span={24}>
                  <AutoComplete
                    style={{ width: '100%', borderRadius: '5px' }}
                    options={SearchFirstName}
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onChange={(e) => HandleNameMethod(e)}
                  />
                </Col>
              </Row>
            </Col>
            <Col xl={6} lg={8} md={12} sm={22} xs={22}>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Typography className='firstname'>
                    {t('content.247')}
                  </Typography>
                </Col>
                <Col span={24}>
                  <PhoneInput
                    country={'in'}
                    onChange={(value, country, e, formattedValue) =>
                      phoneNumberMethod(value, country, e, formattedValue)
                    }
                    placeholder='Enter Your Contact Number'
                    inputStyle={{ width: '100%', height: '32px' }}
                    autoFormat={false}
                    onlyCountries={['in', 'ca', 'us', 'au', 'nz']}
                  />
                </Col>
              </Row>
            </Col>

            <Col xl={6} lg={6} md={6} sm={22} xs={22}>
              <Row gutter={[10, 27]}>
                <Col span={24}></Col>
                <Col span={24}>
                  <Button
                    type='primary'
                    className='button-search'
                    onClick={() => {
                      analytics?.track('Admin_User_Search');
                      searchUserMethod();
                    }}
                  >
                    {t('btn.35')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[0, 10]}>
            <Col span={24} />
            <Col span={24}>
              <Checkbox
                className='firstname'
                onChange={(e) => setIsVerified(e.target.checked)}
              >
                {t('content.281')}?
              </Checkbox>
            </Col>
          </Row>
          <LoadingIndicator loading={isSearchUserLoading}>
            <Col
              className='AdminTable'
              style={{
                height: height,
                overflow: 'hidden',
                overflowY: 'scroll',
              }}
            >
              {searchUsers?.users && searchUsers?.users.length > 0 ? (
                <Table
                  columns={columns}
                  dataSource={searchUsers?.users}
                  className='table'
                  size='middle'
                  style={{ marginTop: '20px' }}
                  rowKey={(record) => {
                    return record.id;
                  }}
                  onChange={(e) => setCurrentPage(e?.current)}
                  pagination={{
                    total: searchUsers?.community_users_length,
                  }}
                />
              ) : (
                !isSearchUserLoading && (
                  <Row justify='center' className='InformationContainer'>
                    <Empty
                      description={
                        <Typography className='empty-details'>
                          {t('content.282')}
                        </Typography>
                      }
                      image={<SearchOutlined style={{ fontSize: '50px' }} />}
                    ></Empty>
                  </Row>
                )
              )}
            </Col>
          </LoadingIndicator>
        </Col>
      </Row>
    </>
  );
};

export default HandleUsers;

const ActionComponent = (props) => {
  const {
    id,
    searchUsers,
    allUsersList,
    setFirstName,
    setLastName,
    setPhoneNumber,
    isVerified,
    currentPage,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const unblockUserMethod = (Id) => {
    const selectedUser = searchUsers.users.filter((Users) => {
      return Users.id === Id;
    });
    selectedUser[0].is_poused = false;
    selectedUser[0].education_id = selectedUser[0]?.education?.id;

    selectedUser[0].industry_id = selectedUser[0]?.industry?.id;

    dispatch(updateUserAction(selectedUser[0], true, successUnblockMethod));
  };

  const successUnblockMethod = () => {
    dispatch(
      getSearchUser(
        `api/user_search?page=${currentPage}&verified=${isVerified}`
      )
    );
    setFirstName('');
    setLastName('');

    setPhoneNumber('');
    setTimeout(() => {
      message.success(t('content.332'));
    }, 1000);
  };
  const blockUserMethod = (Id) => {
    const selectedUser = searchUsers.users.filter((Users) => {
      return Users.id === Id;
    });
    selectedUser[0].is_poused = true;
    selectedUser[0].education_id = selectedUser[0]?.education?.id;
    selectedUser[0].industry_id = selectedUser[0]?.industry?.id;
    dispatch(updateUserAction(selectedUser[0], true, successBlockMethod));
  };

  const successBlockMethod = () => {
    dispatch(
      getSearchUser(
        `api/user_search?page=${currentPage}&verified=${isVerified}`
      )
    );
    setFirstName('');

    setLastName('');
    setPhoneNumber('');
    setTimeout(() => {
      message.success(t('content.333'));
    }, 1000);
  };
  return (
    <Row justify='space-around'>
      {allUsersList.is_poused ? (
        <Col span={10}>
          <Button
            onClick={() => unblockUserMethod(id)}
            className='editDesignationButton'
            type='primary'
          >
            {t('btn.50')}
          </Button>
        </Col>
      ) : (
        <Col span={10}>
          <Button
            onClick={() => blockUserMethod(id)}
            className='unPublishButton'
            type='primary'
          >
            {t('btn.49')}
          </Button>
        </Col>
      )}
    </Row>
  );
};
