import React from "react";
import { Button } from "antd";

import { useTranslation } from "react-i18next";
import "./components.css";

function EnglishButton() {
  const { t, i18n } = useTranslation();
  function handleclick(lang) {
    i18n.changeLanguage(lang);
  }
  return (
    <Button
      type="link"
      style={{ color: "#2ACCFF" }}
      htmlType="submit"
      onClick={() => handleclick("en")}
      className={
        i18n.language === "en-US"
          ? "BoldButton"
          : i18n.language === "en"
          ? "BoldButton"
          : ""
      }
    >
      {t("btn.11")}
    </Button>
  );
}
export default EnglishButton;
