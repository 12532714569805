import constants from "../Utils/constants";
const initialState = {
  isGetUsersLoading: false,
  isGetCommunityBySlugLoading: false,
  allUsers: [],
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_USERS_REQUEST:
      return {
        ...state,
        isGetUsersLoading: true,
      };
    case constants.GET_USERS_SUCCESS:
      return {
        ...state,
        isGetUsersLoading: false,
        allUsers: action.payload,
      };
    case constants.GET_USERS_FAILURE:
      return {
        ...state,
        isGetUsersLoading: false,
      };
    case constants.GET_COMMUNITY_BY_SLUG_REQUEST:
      return {
        ...state,
        isGetCommunityBySlugLoading: true,
      };
    case constants.GET_COMMUNITY_BY_SLUG_SUCCESS:
      return {
        ...state,
        isGetCommunityBySlugLoading: false,
        communitSlugData: action.payload,
      };
    case constants.GET_COMMUNITY_BY_SLUG_FAILURE:
      return {
        ...state,
        isGetCommunityBySlugLoading: false,
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
