import React from 'react';
import { Row, Col, Typography, Modal, Avatar } from 'antd';
import './IdCard.css';
import _ from 'lodash';
const IdCard = (props) => {
  const { isIdCardVisible, SelectedMember, setIsIdCardVisible } = props;
  const hideIdCardMethod = () => {
    setIsIdCardVisible(false);
  };
  const UserNatives = _.uniq(SelectedMember?.native, (Name) => {
    return Name;
  }).join(',');
  const backgroundColor = '#' + Math.floor(Math.random() * 1677).toString(14);

  return (
    <Col className='IdCardMainContainer'>
      <Modal
        open={isIdCardVisible}
        footer={null}
        width={400}
        onCancel={hideIdCardMethod}
        style={{ borderRadius: '4px' }}
        className='IdCardMainContainer'
      >
        <Col className='IdCard'>
          <Col className='centerDiv'>
            <Col className='NameTitle'>{SelectedMember?.last_name}</Col>
            <Col className='NameTitle1'>{SelectedMember?.user_number}</Col>

            <Avatar
              size={200}
              style={{
                cursor: 'pointer',
                backgroundColor: !SelectedMember?.image && backgroundColor,
              }}
              src={SelectedMember?.image}
            >
              <Typography style={{ fontSize: '70px', color: '#FFFFFF' }}>
                {' '}
                {SelectedMember?.first_name?.[0]}
              </Typography>
            </Avatar>
          </Col>
          <Row>
            <Col span={24}>
              <Typography className='NameTitle2'>
                {SelectedMember?.first_name}&nbsp;{SelectedMember?.last_name}
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className='NameTitle3'>
                {_.isEmpty(UserNatives) ? 'Edit your data' : UserNatives}
              </Typography>
            </Col>
          </Row>
          <Row gutter={[0, 7]}>
            <Col span={24}>
              <Typography className='NameTitle4'>Birthdate</Typography>
            </Col>
            <Col span={24}>
              <Typography className='NameTitle5'>
                {SelectedMember?.birth_date}/{SelectedMember?.birth_month}/
                {SelectedMember?.birth_year}
              </Typography>
            </Col>
          </Row>

          <Col className='PhoneNumberTitleContainer'>
            <Typography className='PhoneNumberTitle'>
              {_.isEmpty(SelectedMember?.phone_with_country_code)
                ? 'Edit your data'
                : SelectedMember?.phone_with_country_code}
            </Typography>
          </Col>
        </Col>
      </Modal>
    </Col>
  );
};
export default IdCard;
