import React from 'react';
import { Row, Col, Modal } from 'antd';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import './HelpAndSupport.css';
import '../../index.css';
import { CloseOutlined } from '@ant-design/icons';
const DisplayImageModel = (props) => {
  const {
    isDisplayImageVisible,
    setIsDisplayImageVisible,
    selectedImagesState,
  } = props;
  const handleCloseModalMethod = () => {
    setIsDisplayImageVisible(false);
  };
  return (
    <Col style={{ position: 'relative' }}>
      <Modal
        open={isDisplayImageVisible}
        footer={false}
        onCancel={handleCloseModalMethod}
        className='zeroPaddingModal'
        closeIcon={
          <CloseOutlined
            size={8}
            style={{
              borderRadius: 20,
              background: '#FFFFFF',
              padding: 5,
              color: '#2accff',
            }}
          />
        }
      >
        <Row>
          <Col span={24}>
            <ImageGallery
              items={selectedImagesState}
              additionalClass='ImageViewMatrimonial'
              showNav={false}
              showBullets={true}
              showThumbnails={false}
              originalClass='ImageViewMatrimonial'
            />
          </Col>
        </Row>
      </Modal>
    </Col>
  );
};
export default DisplayImageModel;
