import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Card,
  Input,
  message,
  Carousel,
  Form,
  Space,
} from 'antd';
import LandingPageHeader from './LandingPageHeader';
import './LandingPage.css';
import {
  CalendarOutlined,
  ShoppingOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import DocumentsImage from '../../assets/DocumentsImage.svg';
import LandingPageFamilyImage from '../../assets/LandingPageFamilyImage.svg';
import { contactSchema } from '../../Utils/Schema';
import LandingPagePeopleImage from '../../assets/LandingPagePeopleImage.svg';
import AppleiPhone11ProGold2 from '../../assets/AppleiPhone11ProGold2.png';
import MessageLogo from '../../assets/MessageLogo.png';
import BusinessSamajaMainMobile from '../../assets/BusinessSamajaMainMobile.png';
import MainPhotoCarousel1 from '../../assets/MainPhotoCarousel1.png';
import MainPhotoCarousel2 from '../../assets/MainPhotoCarousel2.png';
import MainPhotoCarousel3 from '../../assets/MainPhotoCarousel3.png';
import MainPhotoCarousel4 from '../../assets/MainPhotoCarousel4.png';
import handshakeIcon from '../../assets/handshakeIcon.svg';
import DirectoryIcon from '../../assets/DirectoryIcon.svg';
import MatrimonialIcon from '../../assets/MatrimonialIcon.svg';
import BusinessesCardImage from '../../assets/BusinessesCardImage.png';
import DirectroyCardImage from '../../assets/DirectroyCardImage.png';
import EventCardImage from '../../assets/EventCardImage.png';
import MatrimonialCardImage from '../../assets/MatrimonialCardImage.png';
import CommunityEventBackgroundImage from '../../assets/CommunityEventBackgroundImage.jpg';
import CommunityBusinessBackgroundImage from '../../assets/CommunityBusinessBackgroundImage.jpg';
import CommunityEventsLogo from '../../assets/CommunityEventsLogo.png';
import CommunitBussinessLogo from '../../assets/CommunitBussinessLogo.png';
import CommunitBusinessMainImage from '../../assets/CommunitBusinessMainImage.png';
import CommunityMatrimonialLogo from '../../assets/CommunityMatrimonialLogo.png';
import CommunityDirectoryLogo from '../../assets/CommunityDirectoryLogo.png';
import CommunityMatrimonial from '../../assets/CommunityMatrimonial.png';
import CommunityMatrimonialBackgroundImage from '../../assets/CommunityMatrimonialBackgroundImage.png';
import CommunityDirectoryBackgroundImage from '../../assets/CommunityDirectoryBackgroundImage.png';
import CommunityDirectoryMainImage from '../../assets/CommunityDirectoryMainImage.png';
import AppStoreLogo from '../../assets/AppStoreLogo.png';
import GooglePlayLogo from '../../assets/GooglePlayLogo.png';
import HelpAndSuportLogo from '../../assets/HelpAndSuportLogo.png';
import HelpandSupportMainIMainImage from '../../assets/HelpandSupportMainIMainImage.png';
import HelpAndSupportBackgroundImage from '../../assets/HelpAndSupportBackgroundImage.jpg';
import stack from '../../assets/stack.png';
import CommunityManagementPhoto from '../../assets/CommunitymanagementPhoto.png';
import HelpAndSupportPhoto from '../../assets/HelpAndSupportPhoto.png';
import CommunityEvents from '../../assets/CommunityEvents.png';
import { history } from '../../Utils/history';
import Footer from './Footer';
import PhoneInput from 'react-phone-input-international';
import 'react-phone-input-international/lib/style.css';
import { Controller, useForm } from 'react-hook-form';
import '../../App.css';
import '../../index.css';
import { yupResolver } from '@hookform/resolvers/yup';
import { createContact } from '../../Actions/contactAction';
import { useBreakpoints } from '../CommonLogics/ResponsiveBreakPoints';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';

const LandingPage = () => {
  const dispatch = useDispatch();
  const { IsMD, IsSM, IsXS, IsLG, IsXL, IsXXL } = useBreakpoints();
  const cardArray = [
    {
      name: 'Events',
      Icon: <CalendarOutlined style={{ fontSize: '27px', color: '#F5F5F7' }} />,
      image: EventCardImage,
      Description: 'Create Events for your community and enjoy together',
    },

    {
      name: 'Business',

      Icon: (
        <img
          src={handshakeIcon}
          style={{ fontSize: '27px', color: '#F5F5F7' }}
        />
      ),
      image: BusinessesCardImage,
      Description: 'Know each other business or job and reach out to them',
    },

    {
      name: 'Matrimonials',
      Icon: (
        <img
          src={MatrimonialIcon}
          style={{ fontSize: '27px', color: '#F5F5F7' }}
        />
      ),
      image: MatrimonialCardImage,
      Description: 'Find the best life partner and bond relation to eachother',
    },
    {
      name: 'Directory',
      Icon: (
        <img
          src={DirectoryIcon}
          style={{ fontSize: '27px', color: '#F5F5F7' }}
        />
      ),
      image: DirectroyCardImage,
      Description: 'Get along with your nearby community people',
    },
  ];
  const cardArray1 = [
    {
      image: DocumentsImage,
      name: 'Documents',
      Description: 'You can check expeses or any other related Document',
    },

    {
      image: LandingPageFamilyImage,
      name: 'Create Your Family',
      Description: 'Create and add your family member ',
    },
    {
      image: LandingPagePeopleImage,
      name: 'Find Other People',
      Description: 'You can find Other people from your community',
    },
  ];

  const [countryCode, setCountryCode] = useState('');
  const [number, setNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState('');
  const [isGoogleCaptcha, setIsGoogleCaptcha] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(contactSchema),
  });
  const phoneNumberMethod = (value, country, e, formattedValue) => {
    setCountryCode(country.dialCode);
    setNumber(formattedValue);
    setPhoneNumber(value);
  };

  const createContactMethod = (data) => {
    data.description = 'abcsrecs';
    data.country_code = countryCode;
    console.log({ data });
    dispatch(createContact(data, successCreateContactMethod));
  };
  const successCreateContactMethod = () => {
    setValue('name', '');
    setValue('community_name', '');
    setValue('email', '');
    setValue('description', '');
    setPhoneNumber('+91');
    setNumber('');
    setTimeout(() => {
      message.success('Your request was received successfully to us');
    }, 1000);
    form.resetFields();
  };

  const handleGoogleCaptchaMethod = () => {
    const myFunction = () => {
      setIsGoogleCaptcha(true);
    };
    setTimeout(myFunction, 1000);
  };
  const [form] = Form.useForm();
  return (
    <Col>
      <Col className='LandingPageImageContainer'>
        <LandingPageHeader />
        <Row
          className='LandingPageHeadingAndImageContainer'
          style={{ width: '940px' }}
          justify='space-between'
        >
          <Col xs={24} sm={24} md={18} lg={17}>
            <Typography className='Title1'>
              Empowering communities to create a better world, together.
            </Typography>
            <Typography className='subTitle1' data-testid='data-1'>
              Together, we can build a stronger, more vibrant community where
              kindness, laughter, and support are our guiding principles and
              <br />
              <strong>Download our Application</strong>
            </Typography>
            <Col span={24}>
              <Row gutter={[10, 10]} className='FindAndRegisterCommunity'>
                <Col>
                  <Button
                    className='subTitleLoginButton'
                    onClick={() => history.push('/login')}
                    data-testid='Login-clicked'
                  >
                    Find Your Community
                  </Button>
                </Col>
                <Col className='communityContainer'>
                  <a
                    className='subTitleLearnMoreButton'
                    onClick={() => history.push('/communities')}
                    href='#community'
                  >
                    Register your community
                    <ArrowRightOutlined />
                  </a>
                </Col>
              </Row>
            </Col>
          </Col>

          <Col
            xs={24}
            sm={24}
            md={6}
            lg={7}
            className='LandingPageCarouselMainContainer'
          >
            <Carousel autoplay className='LandingPageCarouselContainer'>
              <Col>
                <img
                  src={BusinessSamajaMainMobile}
                  className='LandingPageCorousel'
                />
              </Col>
              <Col>
                <img
                  src={BusinessSamajaMainMobile}
                  className='LandingPageCorousel'
                />
              </Col>
              <Col>
                <img
                  src={BusinessSamajaMainMobile}
                  className='LandingPageCorousel'
                />
              </Col>
              <Col>
                <img
                  src={BusinessSamajaMainMobile}
                  className='LandingPageCorousel'
                />
              </Col>
              <Col>
                <img
                  src={BusinessSamajaMainMobile}
                  className='LandingPageCorousel'
                />
              </Col>
            </Carousel>
          </Col>
        </Row>
      </Col>

      <Row justify='center'>
        {(IsXS || IsSM) && (
          <LandingPageCard cardArray={cardArray} isMobile={false} />
        )}
        {(IsXL || IsLG || IsMD || IsXXL) && (
          <LandingPageCard cardArray={cardArray} isMobile={true} />
        )}
      </Row>

      {/* Community Event */}
      <Col
        span={24}
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundImage: `url(${CommunityEventBackgroundImage})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          marginTop: '100px',
        }}
        id='events'
      >
        <Row
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            width: '100%',
          }}
          justify='center'
        >
          <Row
            justify='space-between'
            className='CommunityEvents'
            align='middle'
          >
            <Col xs={24} sm={24} md={24} lg={12}>
              <Row gutter={[0, 10]} className='SectionInformationContainer'>
                <Col>
                  <img src={CommunityEventsLogo} alt='loading' />
                </Col>
                <Col span={24} />
                <Col>
                  <Typography className='SectionHeading'>
                    Community <br /> Events
                  </Typography>
                </Col>
                <Col span={24} />
                <Col>
                  <Typography className='SectionDiscription'>
                    The community members can check and manage all the events
                    and its details here.
                  </Typography>
                </Col>
                <Col span={24} />
                <Col>
                  <Button className='SectionCommunityEventsButton'>
                    Check all Feeds
                    <ArrowRightOutlined />
                  </Button>
                </Col>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              className='SectionMainImageContainer'
            >
              <img
                src={CommunityEvents}
                alt='loading'
                id='CommunityManagementPhoto'
              />
            </Col>
          </Row>
        </Row>
      </Col>

      {/* Community Business */}

      <Col
        span={24}
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundImage: `url(${CommunityBusinessBackgroundImage})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          marginTop: '100px',
        }}
        id='business'
      >
        <Row
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            width: '100%',
          }}
          justify='center'
        >
          <Row
            justify='space-between'
            className='CommunityBussiness'
            align='middle'
          >
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              className='SectionMainImageContainer'
            >
              <img
                src={CommunitBusinessMainImage}
                alt='loading'
                id='CommunitBusinessMainImage'
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              className='CommunityBusinessFirstColumn'
            >
              <Row gutter={[0, 10]} className='SectionInformationContainer'>
                <Col>
                  <img src={CommunitBussinessLogo} alt='loading' />
                </Col>
                <Col span={24} />
                <Col>
                  <Typography className='SectionHeading'>
                    Community <br /> Business
                  </Typography>
                </Col>
                <Col span={24} />
                <Col>
                  <Typography className='SectionDiscription'>
                    This is designed to connect local businesses with customers
                    and other businesses, creating a vibrant community that
                    supports one another.
                  </Typography>
                </Col>
                <Col span={24} />
                <Col>
                  <Button className='SectionCommunityBusinessButton'>
                    Check All Businesses
                    <ArrowRightOutlined />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </Col>

      {/* Community Matrimonial */}

      <Col
        span={24}
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundImage: `url(${CommunityMatrimonialBackgroundImage})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          marginTop: '100px',
        }}
        id='matrimonial'
      >
        <Row
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.4)',
            width: '100%',
          }}
          justify='center'
        >
          <Row
            justify='space-between'
            className='CommunityEvents'
            align='middle'
          >
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              className='CommunityMatrimoinalFirstColumn'
            >
              <Row gutter={[0, 10]} className='SectionInformationContainer'>
                <Col>
                  <img src={CommunityMatrimonialLogo} alt='loading' />
                </Col>
                <Col span={24} />
                <Col>
                  <Typography className='SectionHeading'>
                    Community <br /> Matrimonial
                  </Typography>
                </Col>
                <Col span={24} />
                <Col>
                  <Typography className='SectionDiscription'>
                    Find your life partner in your own community. Establish
                    enduring companionships and foster chances for significant
                    connections.
                  </Typography>
                </Col>
                <Col span={24} />
                <Col>
                  <Button className='SectionCommunityMatrimonialButton'>
                    Find your match
                    <ArrowRightOutlined />
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              className='SectionMainImageContainer'
            >
              <img
                src={CommunityMatrimonial}
                alt='loading'
                id='CommunityMatrimonialPhoto'
              />
            </Col>
          </Row>
        </Row>
      </Col>

      {/* Community Directory */}
      <Col
        span={24}
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundImage: `url(${CommunityDirectoryBackgroundImage})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          marginTop: '100px',
        }}
      >
        <Row
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            width: '100%',
          }}
          justify='center'
        >
          <Row
            justify='space-between'
            className='CommunityBussiness'
            align='middle'
          >
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              className='SectionMainImageContainer'
            >
              <img
                src={CommunityDirectoryMainImage}
                alt='loading'
                id='CommunitBusinessMainImage'
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              className='CommunityBusinessFirstColumn'
            >
              <Row gutter={[0, 10]} className='SectionInformationContainer'>
                <Col>
                  <img src={CommunityDirectoryLogo} alt='loading' />
                </Col>
                <Col span={24} />
                <Col>
                  <Typography className='SectionHeading'>
                    Community <br /> Directory
                  </Typography>
                </Col>
                <Col span={24} />
                <Col>
                  <Typography className='SectionDiscription'>
                    Get along with your nearby community people
                  </Typography>
                </Col>
                <Col span={24} />
                <Col>
                  <Button className='SectionCommunityDirectoryButton'>
                    Check All Businesses
                    <ArrowRightOutlined />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </Col>

      {/* Help and Support */}
      <Col
        span={24}
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundImage: `url(${HelpAndSupportBackgroundImage})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          marginTop: '100px',
        }}
      >
        <Row
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            width: '100%',
          }}
          justify='center'
        >
          <Row
            justify='space-between'
            className='CommunityEvents'
            align='middle'
          >
            <Col xs={24} sm={24} md={24} lg={12}>
              <Row gutter={[0, 10]} className='SectionInformationContainer'>
                <Col>
                  <img src={HelpAndSuportLogo} alt='loading' />
                </Col>
                <Col span={24} />
                <Col>
                  <Typography className='SectionHeading'>
                    Help and <br /> Support
                  </Typography>
                </Col>
                <Col span={24} />
                <Col>
                  <Typography className='SectionDiscription'>
                    Bring all people together with happiness and smile
                  </Typography>
                </Col>
                <Col span={24} />
                <Col>
                  <Button className='SectionHelpAndSupportButton'>
                    Find your match
                    <ArrowRightOutlined />
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              className='SectionMainImageContainer'
            >
              <img
                src={HelpandSupportMainIMainImage}
                alt='loading'
                id='CommunityMatrimonialPhoto'
              />
            </Col>
          </Row>
        </Row>
      </Col>

      {/* Download our Mobile Application */}
      <Col
        span={24}
        className='DownloadMobileApplicationContainer'
        id='contactus'
      >
        <Row
          style={{ width: '940px', padding: '20px' }}
          justify='space-between'
          gutter={[50, 0]}
        >
          <Col xs={24} sm={24} md={24} lg={8}>
            <Row justify='center'>
              <Col>
                <Typography className='DownloadMobileApplicationHeading'>
                  Download <br />
                  Our Mobile Application
                </Typography>
              </Col>
              <Col span={24} />
              <Col style={{ paddingTop: '20px' }}>
                <Row gutter={[0, 20]} className='DownloadMobileApplicationLogo'>
                  <Col style={{ display: 'flex', justifyContent: 'center' }}>
                    <a
                      href='https://apps.apple.com/us/app/samaj-app/id1640436240?platform=iphone'
                      target='blank'
                    >
                      <img src={AppStoreLogo} alt='App Store' />
                    </a>
                  </Col>
                  <Col span={24} />
                  <Col style={{ display: 'flex', justifyContent: 'center' }}>
                    <a
                      href='https://play.google.com/store/apps/details?id=com.samaj.app'
                      target='blank'
                    >
                      <img src={GooglePlayLogo} alt='Google play' />
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col
            xs={24}
            sm={24}
            md={24}
            lg={16}
            className='ContactUsFormContainer'
          >
            <Row justify='center'>
              <Col>
                <Typography className='DownloadMobileApplicationHeading'>
                  Contact Us
                </Typography>
              </Col>
              {IsMD && <Col span={24} />}
              <Col>
                <Form
                  layout='vertical'
                  onFinish={createContactMethod}
                  form={form}
                >
                  <Row gutter={[10, 0]} justify='center'>
                    <Col>
                      <Form.Item
                        label={
                          <label style={{ color: 'white' }}>Username</label>
                        }
                        name='name'
                        rules={[
                          {
                            required: true,
                            message: 'Please enter your name!',
                          },
                        ]}
                        labelCol={{ style: { color: 'red' } }}
                      >
                        <Input
                          placeholder='Enter your name'
                          className='ContactusFormInputField'
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        label={
                          <label style={{ color: 'white' }}>
                            Phone Number *
                          </label>
                        }
                        name='phone'
                        rules={[
                          {
                            required: true,
                            message: 'Please enter your phone number!',
                          },
                        ]}
                      >
                        <PhoneInput
                          country={'in'}
                          onChange={(value, country, e, formattedValue) =>
                            phoneNumberMethod(value, country, e, formattedValue)
                          }
                          placeholder='Enter Your Contact Number'
                          onClick={() => setPhoneNumberErrorMessage('')}
                          autoFormat={false}
                          onlyCountries={['in', 'ca', 'us', 'au', 'nz']}
                          inputStyle={{
                            background: 'transparent',
                            width: '267px',
                            borderRadius: '1px solid #fffff',
                          }}
                          value={phoneNumber}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[10, 0]} justify='center'>
                    <Col>
                      <Form.Item
                        label={
                          <label style={{ color: 'white' }}>
                            Email Address
                          </label>
                        }
                        name='email'
                        rules={[
                          {
                            required: true,
                            message: 'Please enter email address!',
                          },
                        ]}
                      >
                        <Input
                          placeholder='Enter your email address'
                          className='ContactusFormInputField'
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        label={
                          <label style={{ color: 'white' }}>
                            Community Name
                          </label>
                        }
                        name='community_name'
                        rules={[
                          {
                            required: true,
                            message: 'Please enter your Community name!',
                          },
                        ]}
                      >
                        <Input
                          placeholder='Enter your Community name'
                          className='ContactusFormInputField'
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify='center' justify='center'>
                    <Col>
                      <Form.Item>
                        <Space>
                          <Button
                            htmlType='submit'
                            className='ContactusFormSubmitButton'
                          >
                            Submit
                          </Button>
                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Footer />
    </Col>
  );
};

export default LandingPage;

const LandingPageCard = (props) => {
  const { cardArray, isMobile } = props;
  const [height, setHeight] = useState(0);
  const ElementHeight = useRef(null);
  useEffect(() => {
    setHeight(ElementHeight.current.clientHeight);
  }, [ElementHeight]);

  return (
    <Col className='LandingPageCardResponsive'>
      <Row
        justify='center'
        gutter={[30, { xs: 50, sm: 50, md: 0, lg: 0, xl: 0, xxl: 0 }]}
      >
        <Col span={24} />

        {cardArray?.map((val, i) => {
          return (
            <Col
              xs={20}
              xl={6}
              lg={7}
              md={7}
              sm={15}
              key={i}
              ref={ElementHeight}
              style={{
                marginTop: isMobile ? -(height / 2) : 0,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Card
                className='landingPageCard'
                id='features'
                style={{
                  background:
                    val.name === 'Matrimonials'
                      ? '#ee5c9f'
                      : val.name === 'Business'
                      ? '#4593EF'
                      : val.name === 'Directory'
                      ? '#2DCDFF'
                      : '#F19C51',
                  border: 'none',
                  backgroundImage: `url(${val.image})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                }}
              >
                <Row gutter={[0, 10]}>
                  <Col span={24}>{val.Icon}</Col>
                  <Col span={24}>
                    <Typography
                      className='cardTitle'
                      style={{ color: '#F5F5F7' }}
                    >
                      {val.name}
                    </Typography>
                  </Col>
                  <Col>
                    <Typography
                      className='cardTitle2'
                      style={{ color: '#F5F5F7' }}
                    >
                      {val.Description}
                    </Typography>
                  </Col>
                </Row>
              </Card>
            </Col>
          );
        })}
        <Col span={24} />
      </Row>
    </Col>
  );
};
