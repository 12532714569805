import { Input, Button, Row, Col, Typography, message, Drawer } from 'antd';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { createJoinFamilyAction } from '../../Actions/familyAction';
import { useDispatch, useSelector } from 'react-redux';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import './Addfamily.css';
import { useTranslation } from 'react-i18next';
import { analytics } from '../../components/Mixpanel/Mixpanel';

const JoinFamilyModal = (props) => {
  const { isJoinFamilyModalVisible, setIsJoinFamilyModalVisible } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({});
  const { allDeletedUserFamilies, isCreateJoinFamilyLoading } = useSelector(
    (state) => {
      const { allDeletedUserFamilies, isCreateJoinFamilyLoading } =
        state.family;
      return {
        allDeletedUserFamilies,
        isCreateJoinFamilyLoading,
      };
    }
  );
  const [errorMessage, setErrorMessage] = useState('');
  const hideJoinFamilyModal = () => {
    setIsJoinFamilyModalVisible(false);
    setErrorMessage('');
    reset();
  };
  const handleJoinFamilyMethod = (data) => {
    const checkLength = data.family_number;
    data.family_number = `F#${data.family_number}`;
    if (
      allDeletedUserFamilies &&
      allDeletedUserFamilies.length > 0 &&
      allDeletedUserFamilies[0]?.family?.family_number === data.family_number
    ) {
      setErrorMessage(
        'You were part of this family so check the rejoin section in your family'
      );
    } else if (checkLength === undefined || checkLength?.length < 5) {
      setErrorMessage('Enter valid family id');
    } else {
      dispatch(
        createJoinFamilyAction(data, SuccessJoinFamilyCallback, FailureCallBack)
      );
    }
  };
  const SuccessJoinFamilyCallback = (SuccessResponse) => {
    if (SuccessResponse.error) {
      setErrorMessage(SuccessResponse.error);
    } else {
      analytics?.track('Families_JoinedFamaily');
      setIsJoinFamilyModalVisible(false);
      setErrorMessage('');
      setTimeout(() => {
        message.success(t('content.311'));
      });
      reset();
      setValue('family_number', '');
    }
  };
  const FailureCallBack = (FailureResponse) => {
    setErrorMessage(FailureResponse.family_id[0]);
  };

  return (
    <Drawer
      open={isJoinFamilyModalVisible}
      title='Join Family'
      footer={null}
      onClose={hideJoinFamilyModal}
      maskClassName='DrawerClass'
      className='DrawerBorderClass'
    >
      <LoadingIndicator loading={isCreateJoinFamilyLoading}>
        <form onSubmit={handleSubmit(handleJoinFamilyMethod)}>
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Typography className='label' style={{ paddingBottom: 9 }}>
                Enter Family ID
              </Typography>
              <Controller
                as={
                  <Input
                    className='inputLabel'
                    prefix='F#'
                    size='large'
                    maxLength={5}
                    onClick={() => setErrorMessage('')}
                    style={{ padding: '2px 8px' }}
                  />
                }
                name='family_number'
                control={control}
              />
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            </Col>
            <Col span={24}>
              <Row gutter={[10, 0]} justify='end'>
                <Col>
                  <Button
                    onClick={() => hideJoinFamilyModal()}
                    className='allButtonsCancel'
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button htmlType='submit' className='allButtons'>
                    Join
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </LoadingIndicator>
    </Drawer>
  );
};
export default JoinFamilyModal;
