import constants from "../Utils/constants";
const initialState = {
  allEducations: [],
  isEducationsLoading: false,
  isCreateEducationLoading: false,
  isUpdateEducationLoading: false,
  isDeleteUserEducationLoading: false,
};

export const educationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_EDUCATIONS_REQUEST:
      return {
        ...state,
        isEducationsLoading: true,
      };

    case constants.GET_EDUCATIONS_SUCCESS:
      return {
        ...state,
        isEducationsLoading: false,
        allEducations: action.payload,
      };
    case constants.GET_EDUCATIONS_FAILURE:
      return {
        ...state,
        isEducationsLoading: false,
      };

    case constants.CREATE_EDUCATIONS_REQUEST:
      return {
        ...state,
        isCreateEducationLoading: true,
      };
    case constants.CREATE_EDUCATIONS_SUCCESS:
      return {
        ...state,
        isCreateEducationLoading: false,
      };
    case constants.CREATE_EDUCATIONS_FAILURE:
      return {
        ...state,
        isCreateEducationLoading: false,
      };

    case constants.UPDATE_EDUCATIONS_REQUEST:
      return {
        ...state,
        isUpdateEducationLoading: true,
      };
    case constants.UPDATE_EDUCATIONS_SUCCESS:
      return {
        ...state,
        isUpdateEducationLoading: false,
      };
    case constants.UPDATE_EDUCATIONS_FAILURE:
      return {
        ...state,
        isUpdateEducationLoading: false,
      };
    case constants.DELETE_USER_EDUCATIONS_REQUEST:
      return {
        ...state,
        isDeleteUserEducationLoading: true,
      };
    case constants.DELETE_USER_EDUCATIONS_SUCCESS:
      return {
        ...state,
        isDeleteUserEducationLoading: false,
      };
    case constants.DELETE_USER_EDUCATIONS_FAILURE:
      return {
        ...state,
        isDeleteUserEducationLoading: false,
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
