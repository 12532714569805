import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Pagination,
  Table,
  message,
  Typography,
  AutoComplete,
  Empty,
} from 'antd';
import '../../FindThePeoplePage/FindOtherPeople.css';
import './Admin.css';

import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { CountryViseFormat } from '../CommonLogics/CommonMethods';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSearchUser,
  updateVerifyUserAction,
} from '../../Actions/searchUserAction';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import PhoneInput from 'react-phone-input-international';
import 'react-phone-input-international/lib/style.css';
import { SearchOutlined } from '@ant-design/icons';
import { analytics } from '../../components/Mixpanel/Mixpanel';

const VerifyUsers = () => {
  const dispatch = useDispatch();

  const { searchUsers, isSearchUserLoading } = useSelector((state) => {
    const { searchUsers, isSearchUserLoading } = state.searchUser;

    return {
      searchUsers,
      isSearchUserLoading,
    };
  });
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [number, setNumber] = useState('');
  const [countryCode, setCountryCode] = useState('');

  const searchDependancy = (firstName || lastName || number) === '';
  useEffect(() => {
    dispatch(
      getSearchUser(`api/user_search?page=${currentPage}&verified=false`)
    );
  }, [searchDependancy, dispatch, currentPage]);
  const columns = [
    {
      title: t('content.15'),
      dataIndex: 'first_name',
      width: '20em',
      render: (first_name, allUsersList) => {
        return allUsersList?.is_poused ? (
          <Col className='hidedRow'>{first_name}</Col>
        ) : (
          first_name
        );
      },
    },

    {
      title: t('placeholder.5'),
      dataIndex: 'last_name',
      width: '20em',
      render: (last_name, allUsersList) => {
        return allUsersList?.is_poused ? (
          <Col className='hidedRow'>{last_name}</Col>
        ) : (
          last_name
        );
      },
    },
    {
      title: t('content.17'),
      dataIndex: 'phone_with_country_code',

      render: (phone_with_country_code, allUsersList) => {
        const IsEmpty =
          phone_with_country_code === 'null'
            ? true
            : phone_with_country_code === 'undefined'
            ? true
            : _.isEmpty(phone_with_country_code);
        return (
          IsEmpty === false &&
          (allUsersList?.is_poused ? (
            <Col className='hidedRow'>
              {phone_with_country_code &&
                CountryViseFormat(phone_with_country_code)}
            </Col>
          ) : (
            phone_with_country_code &&
            CountryViseFormat(phone_with_country_code)
          ))
        );
      },
    },

    {
      dataIndex: 'id',
      render: (id) => <ActionComponent id={id} currentPage={currentPage} />,

      // width: "15em"
    },
  ];
  const HandleNameMethod = (Name) => {
    const Text = Name.split(' ');
    setFirstName(Text[0]);

    setLastName(Text[1]);
  };
  const Firstname = _.capitalize(firstName && firstName);
  const Lastname = _.capitalize(lastName && lastName);
  const searchUserMethod = () => {
    let url = `api/user_search?page=${currentPage}}`;
    const Text = number;
    const TextText = Text.replace(`+${countryCode}`, '');
    if (firstName && lastName && number) {
      url = `api/user_search?first_name=${Firstname}&last_name=${Lastname}&phone=${TextText}&page=${currentPage}&verified=false`;
    } else if (firstName && lastName) {
      url = `api/user_search?first_name=${Firstname}&last_name=${Lastname}&page=${currentPage}&verified=false`;
    } else if (firstName) {
      url = `api/user_search?first_name=${Firstname}&page=${currentPage}&verified=false`;
    } else if (lastName) {
      url = `api/user_search?last_name=${Lastname}&page=${currentPage}&verified=false`;
    } else if (number) {
      url = `api/user_search?phone=${TextText}&page=${currentPage}&verified=false`;
    } else {
      url = `api/user_search?page=${currentPage}&verified=false`;
    }
    dispatch(getSearchUser(url));
  };
  const SearchUsersByFirstName = _.uniqBy(searchUsers.users, (users) => {
    return users.first_name;
  });
  const SearchFirstName = SearchUsersByFirstName.map((val) => {
    return { value: val.first_name };
  });
  const phoneNumberMethod = (value, country, e, formattedValue) => {
    setCountryCode(country.dialCode);
    setNumber(formattedValue);
  };
  const height = window.innerHeight - 400;
  return (
    <Col>
      <Row justify='space-around' gutter={[0, 10]}>
        <Col span={23}>
          <Row gutter={[10, 0]}>
            <Col xl={6} lg={8} md={12} sm={22} xs={22}>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Typography className='firstname'>
                    {t('content.246')}
                  </Typography>
                </Col>
                <Col span={24}>
                  <AutoComplete
                    style={{ width: '100%', borderRadius: '5px' }}
                    // value={SearchUsersByFirstName}
                    options={SearchFirstName}
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    // filterOption={SearchUsersByFirstName}
                    // onChange={(event) => setFirstName(event)}
                    onChange={(e) => HandleNameMethod(e)}
                    //  onSelect={() => searchUserMethod()}
                  />
                </Col>
              </Row>
            </Col>
            <Col xl={6} lg={8} md={12} sm={22} xs={22}>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Typography className='firstname'>
                    {t('content.247')}
                  </Typography>
                </Col>
                <Col span={24}>
                  <PhoneInput
                    country={'in'}
                    onChange={(value, country, e, formattedValue) =>
                      phoneNumberMethod(value, country, e, formattedValue)
                    }
                    placeholder='Enter Your Contact Number'
                    inputStyle={{ width: '100%', height: '32px' }}
                    autoFormat={false}
                    onlyCountries={['in', 'ca', 'us', 'au', 'nz']}
                  />
                </Col>
              </Row>
            </Col>

            <Col xl={6} lg={6} md={6} sm={22} xs={22}>
              <Row gutter={[10, 27]}>
                <Col span={24}></Col>
                <Col span={24}>
                  <Button
                    type='primary'
                    className='button-search'
                    onClick={() => {
                      analytics?.track('Admin_VerifyUser_Search');
                      searchUserMethod();
                    }}
                  >
                    {t('btn.35')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={23}>
          <LoadingIndicator loading={isSearchUserLoading}>
            <Col
              style={{
                height: height,
                overflow: 'hidden',
                overflowY: 'scroll',
              }}
            >
              {searchUsers?.users && searchUsers?.users.length > 0 ? (
                <Table
                  columns={columns}
                  dataSource={searchUsers?.users}
                  rowKey={(record) => {
                    return record.id;
                  }}
                  className='table'
                  size='middle'
                  pagination={false}
                />
              ) : (
                !isSearchUserLoading && (
                  <Row justify='center' className='InformationContainer'>
                    <Empty
                      description={
                        <Typography className='empty-details'>
                          {t('content.292')}
                        </Typography>
                      }
                      image={<SearchOutlined style={{ fontSize: '50px' }} />}
                    ></Empty>
                  </Row>
                )
              )}
            </Col>
          </LoadingIndicator>
        </Col>
        <Col span={23}>
          <Row justify='end' style={{ margin: '10px 0px' }}>
            <Pagination
              total={searchUsers?.community_users_length}
              onChange={(e) => setCurrentPage(e)}
            />
          </Row>
        </Col>
      </Row>
    </Col>
  );
};
export default VerifyUsers;

const ActionComponent = (props) => {
  const { id, currentPage } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleVerifyMethod = (Id) => {
    dispatch(updateVerifyUserAction(Id, SuccessVerifyUserMethod));
  };
  const SuccessVerifyUserMethod = () => {
    dispatch(
      getSearchUser(`api/user_search?page=${currentPage}&verified=false`)
    );
    setTimeout(() => {
      message.success(t('content.342'));
    }, 1000);
  };

  return (
    <Button
      type='primary'
      className='editDesignationButton'
      onClick={() => handleVerifyMethod(id)}
    >
      {t('btn.14')}
      {/* Verify */}
    </Button>
  );
};
