import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import './FindOtherPeople.css';
import {
  Table,
  Typography,
  Row,
  Col,
  Button,
  AutoComplete,
  Pagination,
  Empty,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { getSearchUser } from '../Actions/searchUserAction';
import '../Events/Events.css';
import ViewMemberDetails from '../Screens/AddFamilymember/familyDetails';
import { useTranslation } from 'react-i18next';
import '../Screens/AddFamilymember/Addfamily.css';
import { ArrowLeftOutlined, SearchOutlined } from '@ant-design/icons';
import { getCommunityById } from '../Actions/communitiesAction';
import { getUserByIdAction } from '../Actions/authenticationAction';
import LoadingIndicator from '../Screens/LoadingIndicator/LoadingIndicator';
import { history } from '../Utils/history';
import { CountryViseFormat } from '../Screens/CommonLogics/CommonMethods';
import { analytics } from '../components/Mixpanel/Mixpanel';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const PeopleTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isPeopleDetailsVisible, setIsPeopleDetailsVisible] = useState(false);
  const [peopleDetailsId, setPeopleDetailsId] = useState('');
  const searchDependancy = firstName !== '' || lastName !== '';
  const {
    searchUsers,
    isSearchUserLoading,
    community,
    setCommunityId,
    userById,
    currentUser,
  } = useSelector((state) => {
    const { searchUsers, isSearchUserLoading } = state.searchUser;
    const { community, setCommunityId } = state.communities;
    const { userById, currentUser } = state.authentication;
    return {
      searchUsers,
      isSearchUserLoading,
      community,
      setCommunityId,
      userById,
      currentUser,
    };
  });
  const isFindOtherPeopleVisibleByAdmin = community?.show_find_other_people;
  const redirectMethod = () => {
    history.push('/add_family');
  };
  const [currentPage, setCurrentPage] = useState(1);
  const localStorageCommunityId = localStorage.getItem('community_id');

  useEffect(() => {
    dispatch(
      getSearchUser(`api/user_search?page=${currentPage}&verified=true`)
    );
    dispatch(
      getCommunityById(
        localStorageCommunityId ? localStorageCommunityId : setCommunityId
      )
    );
    if (
      currentUser?.verified === false
        ? true
        : isFindOtherPeopleVisibleByAdmin === false
    ) {
      redirectMethod();
    }
  }, [
    searchDependancy,
    peopleDetailsId,
    dispatch,
    isFindOtherPeopleVisibleByAdmin,
    currentPage,
    currentUser?.verified,
  ]);
  const Firstname = _.capitalize(firstName && firstName);
  const Lastname = _.capitalize(lastName && lastName);
  const SearchUsers = _.isObject(searchUsers)
    ? searchUsers.users
      ? _.isArray(searchUsers?.users)
        ? searchUsers?.users
        : Object.keys(searchUsers?.users)
      : []
    : [];
  const filteredUsers = SearchUsers
    ? SearchUsers?.filter((val) => {
        return val.native !== null;
      }).filter((el) => {
        return el.native !== null && el.native !== '';
      })
    : null;
  const columns = [
    {
      title: t('content.15'),
      dataIndex: 'first_name',
      width: '20%',
    },

    {
      title: t('placeholder.5'),
      dataIndex: 'last_name',
      width: '10em',
    },
    {
      title: t('content.151'),
      dataIndex: 'native',
      width: '20%',
      filters: _.uniqBy(filteredUsers, (users) => {
        return users?.native && _.zipObject(['name'], users?.native)?.name;
      })?.map((val) => {
        return (
          val?.native?.length > 0 && {
            text: val?.native,
            value: val?.native,
          }
        );
      }),
      onFilter: (value, record) => {
        return record?.native?.includes(value[0]);
      },
      render: (val) => {
        return _.uniq(val, (Name) => {
          return Name;
        }).join(',');
      },
    },
    {
      title: t('content.17'),
      dataIndex: 'phone_with_country_code',
      width: '12em',
      render: (Number) => {
        const IsEmpty =
          Number === 'null'
            ? true
            : Number === 'undefined'
            ? true
            : _.isEmpty(Number);
        return (
          IsEmpty === false && Number && CountryViseFormat(Number && Number)
        );
      },
    },
    {
      dataIndex: 'id',
      render: (id) => (
        <ActionComponent
          id={id}
          setIsPeopleDetailsVisible={setIsPeopleDetailsVisible}
          setPeopleDetailsId={setPeopleDetailsId}
          isPeopleDetailsVisible={isPeopleDetailsVisible}
          getUserByIdAction={getUserByIdAction}
          dispatch={dispatch}
        />
      ),
      width: '3em',
    },
  ];
  const height = window.innerHeight - 64;
  const SearchUsersByFirstName = _.uniqBy(searchUsers.users, (users) => {
    return users.first_name;
  });
  const SearchFirstName = SearchUsersByFirstName.map((val) => {
    return { value: val.first_name };
  });
  const SearchUsersByLastName = _.uniqBy(searchUsers.users, (users) => {
    return users.last_name;
  });

  const SearchLastName = SearchUsersByLastName.map((val) => {
    return { value: val.last_name };
  });

  const searchUserMethod = () => {
    let url = `api/user_search?page=${currentPage}&verified=true`;
    if (firstName && lastName) {
      url = `api/user_search?page=${currentPage}&first_name=${Firstname}&last_name=${Lastname}&verified=true`;
    } else if (firstName) {
      url = `api/user_search?page=${currentPage}&first_name=${Firstname}&verified=true`;
    } else if (lastName) {
      url = `api/user_search?page=${currentPage}&last_name=${Lastname}&verified=true`;
    } else {
      url = `api/user_search?page=${currentPage}&verified=true`;
    }
    dispatch(getSearchUser(url));
  };
  const hidePeopleDetails = () => {
    setIsPeopleDetailsVisible(false);
    setPeopleDetailsId('');
  };

  return (
    <>
      <LoadingIndicator
        loading={!isPeopleDetailsVisible && isSearchUserLoading}
      >
        <Col
          style={{
            height: height,
            overflow: 'hidden',
            overflowY: 'scroll',
            backgroundColor: '#FFFFFF',
          }}
          className='customLayout'
        >
          {isPeopleDetailsVisible ? (
            <Row gutter={[0, 33]} justify='space-between'>
              <Col span={24} />
              <Col
                xl={1}
                lg={1}
                md={1}
                sm={2}
                xs={2}
                style={{ marginLeft: '3.5%' }}
              >
                <ArrowLeftOutlined
                  style={{ fontSize: '20px' }}
                  onClick={() => hidePeopleDetails()}
                />
              </Col>
              <Col style={{ marginRight: '10%' }}>
                <Typography
                  className='newMemberSubDetails'
                  style={{ marginTop: '-2px' }}
                >
                  {t('content.152')}
                </Typography>
              </Col>
            </Row>
          ) : (
            <Row justify='space-around' align='middle' gutter={[0, 10]}>
              <Col span={24} />
              <Col xl={6} lg={6} md={6} sm={22} xs={22}>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <Typography className='NewInputText'>
                      {t('content.70')}
                    </Typography>
                  </Col>
                  <Col span={24}>
                    <AutoComplete
                      style={{ width: '100%' }}
                      options={SearchFirstName}
                      filterOption={SearchFirstName}
                      onChange={(event) => setFirstName(event)}
                      className='NewInputDesign'
                    />
                  </Col>
                </Row>
              </Col>
              <Col xl={6} lg={6} md={6} sm={22} xs={22}>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <Typography className='NewInputText'>
                      {t('content.71')}
                    </Typography>
                  </Col>
                  <Col span={24}>
                    <AutoComplete
                      style={{ width: '100%' }}
                      options={SearchLastName}
                      filterOption={SearchLastName}
                      onChange={(event) => setLastName(event)}
                      className='NewInputDesign'
                    />
                  </Col>
                </Row>
              </Col>

              <Col xl={6} lg={6} md={6} sm={22} xs={22}>
                <Row
                  gutter={[
                    10,
                    { xs: 18, lg: 12, xl: 12, xxl: 12, md: 12, sm: 18 },
                  ]}
                >
                  <Col span={24}></Col>
                  <Col span={24}></Col>

                  <Col span={24}>
                    <Button
                      type='primary'
                      className='button-search'
                      onClick={() => {
                        analytics?.track('FindOtherPeople_Search');
                        searchUserMethod();
                      }}
                    >
                      {t('btn.35')}
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col xl={4} lg={4} md={4} sm={24} xs={24}></Col>
            </Row>
          )}

          {isPeopleDetailsVisible ? (
            <Row justify='space-around'>
              <Col span={23} style={{ marginBottom: '10px' }}>
                <ViewMemberDetails
                  peopleDetail={userById}
                  isPeopleDetailsVisible={isPeopleDetailsVisible}
                />
              </Col>
            </Row>
          ) : (
            <Row style={{ padding: '0px 10px' }}>
              <Col className='totalPeople'>
                {t('content.61')}{' '}
                <Typography component='span' style={{ fontWeight: 700 }}>
                  {searchUsers?.community_users_length}
                </Typography>{' '}
                {t('content.72')}
              </Col>
              <Col xl={23} lg={23} md={23} sm={22} xs={22}>
                {searchUsers?.users && searchUsers?.users.length > 0 ? (
                  <Table
                    columns={columns}
                    dataSource={searchUsers?.users}
                    size='middle'
                    className='table'
                    rowKey={(record) => {
                      return record.id;
                    }}
                    pagination={false}
                  />
                ) : (
                  !isSearchUserLoading && (
                    <Row justify='center' className='InformationContainer'>
                      <Empty
                        description={
                          <Typography className='empty-details'>
                            {t('content.292')}
                          </Typography>
                        }
                        image={<SearchOutlined style={{ fontSize: '50px' }} />}
                      ></Empty>
                    </Row>
                  )
                )}
              </Col>
              <Col span={23}>
                <Row justify='end' style={{ margin: '10px 0px' }}>
                  <Pagination
                    total={searchUsers?.community_users_length}
                    onChange={(e) => setCurrentPage(e)}
                  />
                </Row>
              </Col>
            </Row>
          )}
        </Col>
      </LoadingIndicator>
    </>
  );
};
export default PeopleTable;

const ActionComponent = (props) => {
  const { t } = useTranslation();

  const {
    id,
    setIsPeopleDetailsVisible,
    setPeopleDetailsId,
    getUserByIdAction,
    dispatch,
  } = props;

  const handleOpenDetails = (id) => {
    setIsPeopleDetailsVisible(true);
    setPeopleDetailsId(id);
    dispatch(getUserByIdAction(id));
  };
  return (
    <Link
      to={{
        pathname: `/user/${id}`,
        state: {
          memberId: id,
          session: 'FOP',
        },
      }}
    >
      <Button
        type='primary'
        onClick={() => {
          analytics?.track('FindOtherPeople_ViewDetails');
          handleOpenDetails(id);
        }}
        className='button-search'
      >
        {t('btn.36')}
      </Button>
    </Link>
  );
};
