import React from 'react';
import { Row, Col, Button, Typography, Table, Input } from 'antd';
import './CommunityFeeds.css';
import { ArrowLeftOutlined } from '@ant-design/icons';
import '../../FindThePeoplePage/FindOtherPeople.css';
const FetchPolicyNumber = (props) => {
  const { setStepDeals, stepDeals } = props;
  const TableData = [
    {
      Policy: 'LIC - policy 2020',
      Details: 'Lorem Ipsum policy transaction details',
      Amount: '1000.00',
    },
    {
      Policy: 'LIC - policy 2020',
      Details: 'Lorem Ipsum policy transaction details',
      Amount: '1000.00',
    },
    {
      Policy: 'LIC - policy 2020',
      Details: 'Lorem Ipsum policy transaction details',
      Amount: '1000.00',
    },
    {
      Policy: 'LIC - policy 2020',
      Details: 'Lorem Ipsum policy transaction details',
      Amount: '1000.00',
    },
  ];
  const columns = [
    {
      title: 'Policy',
      dataIndex: 'Policy',
      width: '25%',
    },

    {
      title: 'Details',
      dataIndex: 'Details',
      width: '25%',
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      width: '25%',
    },

    {
      dataIndex: 'id',
      render: () => <ActionComponent />,
      width: '25%',
    },
  ];
  return (
    <Col>
      <Row justify='space-around'>
        <Col span={23}>
          <Row gutter={[0, 21]}>
            <Col span={24} />
            <Col span={24} />
          </Row>
        </Col>
        <Col span={23}>
          <ArrowLeftOutlined
            style={{ fontSize: '20px' }}
            onClick={() => setStepDeals(stepDeals - 1)}
          />
        </Col>
        <Col span={23}>
          <Row gutter={[0, 15]}>
            <Col span={24} />
            <Col span={24} />
          </Row>
        </Col>
        <Col span={23}>
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Typography className='NewInputText'>Policy Number</Typography>
            </Col>
            <Col span={24}>
              <Row gutter={[24, 0]}>
                <Col span={8}>
                  <Input
                    className='NewInputDesign'
                    placeholder='Ex. 919-856-8965'
                  />
                </Col>
                <Col>
                  <Button
                    type='primary'
                    className='button-search'
                    onClick={() => setStepDeals(2)}
                  >
                    Fetch
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={23}>
          <Row gutter={[0, 15]}>
            <Col span={24} />
            <Col span={24}>
              <Typography className='LatestTransactionText'>
                Latest Transaction
              </Typography>
              <Col span={24} />
            </Col>
          </Row>
        </Col>
        <Col span={23}>
          <Row gutter={[0, 15]}>
            <Col span={24} />
            <Col span={24} />
          </Row>
        </Col>
        <Col span={23}>
          <Table
            columns={columns}
            dataSource={TableData}
            size='middle'
            className='table'
            pagination={false}
          />
        </Col>
      </Row>
    </Col>
  );
};
export default FetchPolicyNumber;

const ActionComponent = () => {
  return (
    <Button type='primary' className='button-search'>
      Repeat
    </Button>
  );
};
