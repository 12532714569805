import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Typography,
  Card,
  Avatar,
  Popconfirm,
  Tooltip,
  message,
  Divider,
} from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFamilies,
  getDeletedUserFamilies,
} from '../../Actions/familyAction';
import AddFamilyMember from './addFamilyMemberModal';
import moment from 'moment';
import './Addfamily.css';
import { useTranslation } from 'react-i18next';
import '../../FindThePeoplePage/FindOtherPeople.css';
import ViewBusinessProfile from '../../FindThePeoplePage/BusinessProfiles/ViewBusinessProfile';
import BusinessProfileDetails from '../../FindThePeoplePage/BusinessProfiles/BusinessProfileDetails';
import ViewJobProfile from '../../FindThePeoplePage/JobProfile/ViewJobProfile';
import '../../Events/EventCard.css';
import { deleteUserFamily } from '../../Actions/userFamilyAction';
import JobProfileDetails from '../../FindThePeoplePage/JobProfile/JobProfileDetails';
import FamilyCard from './FamilyCard';
import IdCard from '../IdCard/IdCard';
import {
  CountryViseFormat,
  falseyValueCases,
  newImageUrl,
} from '../CommonLogics/CommonMethods';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

const ViewMemberDetails = (props) => {
  const { allFamilies, userById, isGetUserByIdLoading } = useSelector(
    (state) => {
      const { allFamilies } = state.family;
      const { userById, isGetUserByIdLoading } = state.authentication;
      return {
        allFamilies,
        userById,
        isGetUserByIdLoading,
      };
    }
  );
  const { t } = useTranslation();
  const {
    selectedMemberId,
    setSelectedMemberId,
    setIsViewMemberDetails,
    peopleDetail,
    isPeopleDetailsVisible,
    selectedFamilyId,
    secondColor,
  } = props;
  const family = allFamilies?.filter((family) => {
    return family.id === selectedFamilyId;
  });
  const { user_families } = family[0] ? family[0] : [];
  const the_member = user_families?.filter((mem) => {
    return mem.id === selectedMemberId;
  });
  const { first_name, id: userId } = the_member?.[0]?.user || {};
  const isOwner = family?.filter((val, i) => {
    return val.owner_id !== userId;
  });
  const dispatch = useDispatch();
  const [isUpdateMemberModalVisible, setIsUpdateMemberModalVisible] =
    useState(false);
  const [selectedBusinessProfileId, setSelectedBusinessProfileId] =
    useState('');
  const [isIdCardVisible, setIsIdCardVisible] = useState(false);

  const [
    isSelectedBusinessProfileVisible,
    setIsSelectedBusinessProfileVisible,
  ] = useState(false);
  const [selectedJobProfileId, setSelectedJobProfileId] = useState('');

  const [isSelectedJobProfileVisible, setIsSelectedJobProfileVisible] =
    useState(false);
  const deleteMem = (memberId) => {
    dispatch(deleteUserFamily(memberId, DeleteCallBack));
  };
  const DeleteCallBack = () => {
    setSelectedMemberId('');
    setIsViewMemberDetails(false);
    setTimeout(() => {
      message.success(t('content.133'));
    }, 1000);
    dispatch(getFamilies());
    dispatch(getDeletedUserFamilies());
  };
  const handleUpdateMemberModal = () => {
    setIsUpdateMemberModalVisible(true);
  };

  const { business_profiles } = peopleDetail || {};

  const selectedBusiness = business_profiles?.filter((business) => {
    return business.id === selectedBusinessProfileId;
  });
  const selectedJobProfile = business_profiles?.filter((Businesses) => {
    return Businesses.id === selectedJobProfileId;
  });
  const color = '#' + Math.floor(Math.random() * 1677).toString(14);
  const onlyBusinessData = business_profiles?.filter((Businesses) => {
    return Businesses.is_business === true;
  });
  const onlyJobProfileData = business_profiles?.filter((Businesses) => {
    return Businesses.is_business === false;
  });
  const { families } = userById || [];
  const openViewYourIdMethod = () => {
    setIsIdCardVisible(true);
  };
  const userOldDays =
    (falseyValueCases(userById?.birth_year)
      ? 'Data missing'
      : moment(
          userById?.birth_year +
            '-' +
            userById?.birth_month +
            '-' +
            userById?.birth_date
        ).fromNow('years old')) === 'Invalid date'
      ? 'Few days'
      : falseyValueCases(userById?.birth_year)
      ? 'Data missing'
      : moment(
          userById?.birth_year +
            '-' +
            userById?.birth_month +
            '-' +
            userById?.birth_date
        ).fromNow('years old');
  const [newUserImage, SetNewUserImage] = useState(null);

  useEffect(() => {
    (async () => {
      if (userById?.user_image?.image) {
        const url = await newImageUrl(userById?.user_image?.image);
        SetNewUserImage(url);
      }
    })();
  }, [userById?.user_image?.image]);

  return (
    <>
      <LoadingIndicator loading={isGetUserByIdLoading}>
        {!isGetUserByIdLoading && (
          <Col>
            {isSelectedBusinessProfileVisible ? (
              <BusinessProfileDetails
                selectedBusiness={selectedBusiness[0]}
                isSelectedBusinessProfileVisible={
                  isSelectedBusinessProfileVisible
                }
              />
            ) : isSelectedJobProfileVisible ? (
              <JobProfileDetails
                selectedJobProfile={selectedJobProfile[0]}
                isSelectedJobProfileVisible={isSelectedJobProfileVisible}
              />
            ) : (
              <Col>
                <Row gutter={[0, 15]}>
                  <Col xl={13} xs={24} lg={13} md={20} sm={24} xxl={10}>
                    <Card className='familyDetailsCard'>
                      {!isPeopleDetailsVisible && (
                        <Row justify='end' gutter={[30, 10]}>
                          <Col span={24} />
                          <Col>
                            <Tooltip title={t('btn.27')}>
                              <EditOutlined
                                onClick={() => handleUpdateMemberModal()}
                              />
                            </Tooltip>
                            <AddFamilyMember
                              isUpdateMemberModalVisible={
                                isUpdateMemberModalVisible
                              }
                              setIsUpdateMemberModalVisible={
                                setIsUpdateMemberModalVisible
                              }
                              the_member={the_member && the_member[0]}
                              familyId={selectedFamilyId}
                            />
                          </Col>
                          <Col>
                            <Tooltip title='View your id'>
                              <EyeOutlined
                                onClick={() => openViewYourIdMethod()}
                              />
                            </Tooltip>
                            <IdCard
                              isIdCardVisible={isIdCardVisible}
                              setIsIdCardVisible={setIsIdCardVisible}
                              SelectedMember={the_member && the_member[0]?.user}
                            />
                          </Col>
                          <Col>
                            {isOwner?.length > 0 && (
                              <Popconfirm
                                onConfirm={() => deleteMem(the_member?.[0]?.id)}
                                title={t('btn.29')}
                                okText={t('btn.28')}
                                placement='topRight'
                                cancelText={t('btn.19')}
                              >
                                <Tooltip title={t('btn.28')}>
                                  <DeleteOutlined />
                                </Tooltip>
                              </Popconfirm>
                            )}
                          </Col>
                        </Row>
                      )}
                      <Row gutter={[0, { xs: 10, sm: 10 }]}>
                        <Col xl={7} sm={24} md={7} xs={24} lg={9}>
                          <Avatar
                            shape='circle'
                            size={145}
                            style={{ backgroundColor: secondColor || color }}
                            src={newUserImage}
                          >
                            <Typography
                              style={{ fontSize: '70px', color: 'white' }}
                            >
                              {first_name?.[0]}
                              {isPeopleDetailsVisible &&
                                userById?.first_name?.[0]}
                            </Typography>
                          </Avatar>
                        </Col>
                        <Col span={1} />
                        <Col xl={16} sm={24} md={15} lg={13}>
                          <Row gutter={[17, 15]}>
                            <Col
                              span={24}
                              className='title'
                              style={{ fontSize: '21px' }}
                            >
                              {!isPeopleDetailsVisible && first_name}
                              {isPeopleDetailsVisible && userById?.first_name}
                              &nbsp;
                              {isPeopleDetailsVisible && userById?.last_name}
                            </Col>

                            <Col span={24} />
                          </Row>
                          {!isPeopleDetailsVisible && (
                            <Col span={24} className='memberStyle'>
                              {userOldDays}&nbsp;
                              {userById?.birth_year && 'old'}
                            </Col>
                          )}
                          <Row gutter={[0, 5]}>
                            <Col span={24} />
                            <Col>
                              <Row gutter={[5, 0]}>
                                <Col>
                                  <Typography
                                    className='newMemberDetails'
                                    style={{ fontSize: '14px' }}
                                  >
                                    {isPeopleDetailsVisible
                                      ? t('content.35')
                                      : t('content.18')}
                                    :
                                  </Typography>
                                </Col>
                                <Col>
                                  <Typography className='newMemberSubDetails'>
                                    {isPeopleDetailsVisible &&
                                    falseyValueCases(userById?.email)
                                      ? 'Data missing'
                                      : isPeopleDetailsVisible
                                      ? userById?.email
                                      : falseyValueCases(
                                          userById?.education?.name
                                        )
                                      ? 'Data missing'
                                      : userById?.education?.name}
                                  </Typography>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={24}>
                              <Row gutter={[5, 0]}>
                                <Col>
                                  <Typography className='newMemberDetails'>
                                    {isPeopleDetailsVisible
                                      ? t('content.34')
                                      : t('content.20')}
                                    :
                                  </Typography>
                                </Col>
                                <Col>
                                  <Typography className='newMemberSubDetails'>
                                    {isPeopleDetailsVisible ? (
                                      !falseyValueCases(userById?.phone) ? (
                                        CountryViseFormat(
                                          userById?.phone_with_country_code
                                        )
                                      ) : (
                                        'Data missing'
                                      )
                                    ) : falseyValueCases(
                                        userById?.birth_date
                                      ) ? (
                                      'Data missing'
                                    ) : (
                                      <>
                                        {userById.birth_date}/
                                        {userById.birth_month}/
                                        {userById.birth_year}
                                      </>
                                    )}
                                  </Typography>
                                </Col>
                              </Row>
                            </Col>

                            <Col span={24}>
                              <Row gutter={[5, 0]}>
                                <Col>
                                  <Typography className='newMemberDetails'>
                                    {isPeopleDetailsVisible
                                      ? t('content.80')
                                      : t('content.80')}
                                    :
                                  </Typography>
                                </Col>
                                <Col>
                                  <Typography className='newMemberSubDetails'>
                                    {isPeopleDetailsVisible
                                      ? falseyValueCases(
                                          userById?.industry?.name
                                        )
                                        ? 'Data missing'
                                        : userById?.industry?.name
                                      : falseyValueCases(
                                          userById?.industry?.name
                                        )
                                      ? 'Data missing'
                                      : userById?.industry?.name}
                                  </Typography>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  {isPeopleDetailsVisible && families?.length > 0 && (
                    <Col span={24}>
                      <Divider className='Divider'>
                        {t('navigationHeader.11')}
                      </Divider>
                    </Col>
                  )}
                  {isPeopleDetailsVisible &&
                    families?.length > 0 &&
                    families?.map((FamilyDetailAtFOP, i) => {
                      return (
                        <Col xl={12} lg={18} md={18} sm={24} xs={24} key={i}>
                          <FamilyCard
                            FamilyId={FamilyDetailAtFOP?.id}
                            FamilyDetailAtFOP={FamilyDetailAtFOP}
                            isPeopleDetailsVisible={isPeopleDetailsVisible}
                            session='FOP'
                          />
                        </Col>
                      );
                    })}
                  {isPeopleDetailsVisible && onlyBusinessData?.length > 0 && (
                    <Col span={24}>
                      <Divider className='Divider'>
                        {t('navigationHeader.15')}
                      </Divider>
                    </Col>
                  )}
                  {isPeopleDetailsVisible &&
                    onlyBusinessData &&
                    onlyBusinessData?.length > 0 && (
                      <Col span={24}>
                        <ViewBusinessProfile
                          business_profiles={onlyBusinessData}
                          isPeopleDetailsVisible={isPeopleDetailsVisible}
                          setSelectedBusinessProfileId={
                            setSelectedBusinessProfileId
                          }
                          setIsSelectedBusinessProfileVisible={
                            setIsSelectedBusinessProfileVisible
                          }
                        />
                      </Col>
                    )}
                  {isPeopleDetailsVisible && onlyJobProfileData?.length > 0 && (
                    <Col span={24}>
                      <Divider className='Divider'>{t('content.248')}</Divider>
                    </Col>
                  )}
                  {isPeopleDetailsVisible && onlyJobProfileData?.length > 0 && (
                    <Col span={24}>
                      <ViewJobProfile
                        business_profiles={onlyJobProfileData}
                        setSelectedJobProfileId={setSelectedJobProfileId}
                        isPeopleDetailsVisible={isPeopleDetailsVisible}
                        setIsSelectedJobProfileVisible={
                          setIsSelectedJobProfileVisible
                        }
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            )}
          </Col>
        )}
      </LoadingIndicator>
    </>
  );
};
export default ViewMemberDetails;
