import React from 'react';
import {
  Row,
  Col,
  Button,
  Table,
  Select,
  Typography,
  Modal,
  message,
  Empty,
} from 'antd';
import {
  getDocuments,
  deleteDocumentAction,
} from '../../Actions/documentsAction';
import PdfViewer from '../Information/PdfViewer';
import { useDispatch } from 'react-redux';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import { analytics } from '../../components/Mixpanel/Mixpanel';
function DocumnetTable(props) {
  const {
    allDocuments,
    setDocumentYear,
    setEditDocumentId,
    isDocumentVisible,
    setIsDocumentVisible,
    selectedDocument,
    isDocumentLoading,
    documentYear,
  } = props;
  const { t } = useTranslation();
  const columns = [
    {
      title: t('content.242'),
      dataIndex: 'file_name',
      key: 'file_name',
    },
    {
      title: t('content.243'),
      dataIndex: 'upload_date',
      key: 'upload_date',
    },
    {
      dataIndex: 'id',
      render: (id) => (
        <DocumentActionComponent
          setEditDocumentId={setEditDocumentId}
          id={id}
          setIsDocumentVisible={setIsDocumentVisible}
          documentYear={documentYear}
        />
      ),
      width: '15em',
    },
  ];
  const dispatch = useDispatch();
  const DocumentActionComponent = (props) => {
    const { setEditDocumentId, id, setIsDocumentVisible, documentYear } = props;
    const handleViewMethod = (Id) => {
      setEditDocumentId(Id);
      setIsDocumentVisible(true);
    };

    const handleDeleteMethod = (Id) => {
      dispatch(deleteDocumentAction(Id, SuccessDeleteMethod));
    };

    const SuccessDeleteMethod = () => {
      dispatch(getDocuments(documentYear));
      setTimeout(() => {
        message.success(t('content.329'));
      }, 1000);
    };
    return (
      <Row gutter={[30, 0]}>
        <Col span={10}>
          <Button
            className='editButton'
            type='primary'
            onClick={() => {
              analytics?.track('Admin_Documents_Edit');
              handleViewMethod(id);
            }}
          >
            {t('content.238')}
          </Button>
        </Col>
        <Col span={10}>
          <Button
            className='deleteButton'
            type='primary'
            onClick={() => {
              analytics?.track('Admin_Document_Delete');
              handleDeleteMethod(id);
            }}
          >
            {t('btn.48')}
          </Button>
        </Col>
      </Row>
    );
  };
  const earliestYear = 2000;
  const options = [];
  const d = new Date();
  let y = d.getFullYear();

  for (let i = earliestYear; i <= y; i++) {
    options.push({
      label: i,
      value: i,
    });
  }
  const handleDocumentMethod = (Year) => {
    dispatch(getDocuments(Year));
    setDocumentYear(Year);
  };
  return (
    <>
      <Row gutter={[30, 10]}>
        <Col xl={6} xxl={6} lg={6} sm={8} md={8} xs={24}>
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Typography className='content'>{t('content.234')}:</Typography>
            </Col>
            <Col span={24}>
              <Select
                placeholder='Select financial year'
                name='yearSelector'
                defaultValue={y}
                style={{ width: '100%' }}
                onChange={(e) => handleDocumentMethod(e)}
                size='large'
                options={options}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <LoadingIndicator loading={isDocumentLoading}>
        {allDocuments.length > 0 ? (
          <Table
            dataSource={allDocuments}
            columns={columns}
            className='table'
            size='middle'
            style={{ marginTop: '20px' }}
            rowKey={(record) => {
              return record.id;
            }}
          />
        ) : (
          <Row justify='center' className='InformationContainer'>
            <Empty
              description={
                <Typography className='empty-details'>
                  {t('content.288')}
                  {/* No Documents Yet */}
                </Typography>
              }
              image={<InfoCircleOutlined style={{ fontSize: '50px' }} />}
            ></Empty>
          </Row>
        )}
      </LoadingIndicator>
      <Modal
        open={isDocumentVisible}
        onCancel={() => setIsDocumentVisible(false)}
        width={800}
        footer={null}
      >
        <Col>
          <PdfViewer selectedDocument={selectedDocument[0]} />
        </Col>
      </Modal>
    </>
  );
}

export default DocumnetTable;
