import React, { useEffect } from 'react';
import { Row, Col, Typography } from 'antd';
import './LandingPage.css';
import SamajIconSvg from '../../assets/SamajIconSvg.svg';
import { history } from '../../Utils/history';
import {
  PhoneOutlined,
  MailOutlined,
  EnvironmentOutlined,
  InstagramFilled,
  FacebookFilled,
  TwitterCircleFilled,
  PhoneFilled,
} from '@ant-design/icons';

import { useBreakpoints } from '../CommonLogics/ResponsiveBreakPoints';
import { useDispatch, useSelector } from 'react-redux';

const Footer = () => {
  const { IsSM, IsXS, IsXXL } = useBreakpoints();

  const disptach = useDispatch();
  const handleOnClick = (value) => {
    disptach({
      type: 'SET_FOOTER_REDIRECTION_KEY_TYPE',
      payload: value,
    });

    history.push('/login');
  };
  useEffect(() => {
    disptach({
      type: 'SET_FOOTER_REDIRECTION_KEY_TYPE',
      payload: null,
    });
  }, []);
  return (
    <>
      <Row justify='center' className='footerContainer'>
        <Col
          style={{
            width: '940px',
          }}
        >
          <Row justify='space-between'>
            <Col xs={24} sm={24} md={24} lg={8} className='FoterDataContainer'>
              <Row justify={IsSM || IsXS ? 'center' : 'start'} gutter={[0, 10]}>
                <Col span={24}>
                  <Row justify='center'>
                    <img
                      src={SamajIconSvg}
                      className='FooterLogo'
                      alt='Loading'
                    />
                  </Row>
                </Col>
                <Col span={24} className='FooterHeadingChild'>
                  <Row justify='center'>
                    <Col xs={24} sm={24} md={24} lg={2}>
                      <PhoneFilled
                        style={{ fontSize: '22px', color: '#2ACCFF' }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={20}>
                      <Typography style={{ padding: '0 15px 0 15px' }}>
                        {' '}
                        +91 94090 52511
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} className='FooterHeadingChild'>
                  <Row justify='center'>
                    <Col xs={24} sm={24} md={24} lg={2}>
                      <EnvironmentOutlined
                        style={{ fontSize: '22px', color: '#2ACCFF' }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={20}>
                      <Typography style={{ padding: '0 15px 0 15px' }}>
                        {' '}
                        B-203, Titanium Heights, Opp. Vodafone House,
                        Makarba-Prahladnagar, Ahmedabad. Gujarat, 380015.
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} className='FooterHeadingChild'>
                  <Row justify='center'>
                    <Col xs={24} sm={24} md={24} lg={2}>
                      <MailOutlined
                        style={{ fontSize: '22px', color: '#2ACCFF' }}
                      />{' '}
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={20}>
                      <Typography style={{ padding: '0 15px 0 15px' }}>
                        {' '}
                        support@defx.in
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={4} className='FoterDataContainer'>
              <Row justify={IsSM || IsXS ? 'center' : 'start'} gutter={[0, 20]}>
                <Typography className='FooterHeading'>Company Info</Typography>

                <Col
                  span={24}
                  className='FooterHeadingChild'
                  onClick={() => history.push('/about_us')}
                >
                  About Us
                </Col>
                <Col span={24} className='FooterHeadingChild'>
                  <a href='#features'>Features</a>
                </Col>
                <Col
                  span={24}
                  className='FooterHeadingChild'
                  onClick={() => history.push('/faqs')}
                >
                  FAQs
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} lg={4} className='FoterDataContainer'>
              <Row justify={IsSM || IsXS ? 'center' : 'start'} gutter={[0, 20]}>
                <Typography className='FooterHeading'>Legal</Typography>
                <Col
                  span={24}
                  className='FooterHeadingChild'
                  onClick={() => {
                    history.push('/terms');
                  }}
                >
                  T&C
                </Col>
                <Col
                  span={24}
                  className='FooterHeadingChild'
                  onClick={() => {
                    history.push('/disclaimer');
                  }}
                >
                  Disclaimer
                </Col>
                <Col
                  span={24}
                  className='FooterHeadingChild'
                  onClick={() => {
                    history.push('/privacy_policy');
                  }}
                >
                  Privacy Policy
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} lg={4} className='FoterDataContainer'>
              <Row justify={IsSM || IsXS ? 'center' : 'start'} gutter={[0, 20]}>
                <Typography className='FooterHeading'>Features</Typography>
                <Col span={24} className='FooterHeadingChild'>
                  <a href='#business'>Business or Jobs</a>
                </Col>
                <Col span={24} className='FooterHeadingChild'>
                  <a href='#matrimonial'>Matrimonial</a>
                </Col>
                <Col span={24} className='FooterHeadingChild'>
                  <a href='#events'>Events</a>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} lg={4} className='FoterDataContainer'>
              <Row justify={IsSM || IsXS ? 'center' : 'start'} gutter={[0, 20]}>
                <Typography className='FooterHeading'>More</Typography>
                <Col
                  span={24}
                  className='FooterHeadingChild'
                  onClick={() => handleOnClick('FOP')}
                >
                  Find Other People
                </Col>
                <Col
                  span={24}
                  className='FooterHeadingChild'
                  onClick={() => handleOnClick('AYF')}
                >
                  Add Your Family
                </Col>
                <Col
                  span={24}
                  className='FooterHeadingChild'
                  onClick={() => handleOnClick('DOC')}
                >
                  Documents
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify='center' className='footer2Section'>
        <Col
          style={{
            height: IsXS || IsSM ? '100px' : '72px',
          }}
        >
          <Row
            justify='center'
            align='middle'
            style={{ height: '100%' }}
            gutter={[0, 20]}
          >
            <Col
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Row
                justify={IsXS || IsSM ? 'center' : 'space-between'}
                gutter={[0, { xs: 20, sm: 20, md: 0, lg: 0, xl: 0 }]}
                style={{ width: '940px' }}
              >
                <Col>
                  <Typography className='footer2SectionTitle'>
                    © Samaj. All rights reserved.
                  </Typography>
                </Col>
                <Col xs={24} xl={7} lg={7} md={7} sm={24}>
                  {/* <Row
                    gutter={[22, 0]}
                    justify={IsXS || IsSM ? 'center' : 'end'}
                  >
                    <Col>
                      <a href='https://facebook.com' target='blank'>
                        <FacebookFilled
                          style={{ fontSize: '22px', color: '#2ACCFF' }}
                        />
                      </a>
                    </Col>

                    <Col>
                      <a href='https://instagram.com' target='blank'>
                        <InstagramFilled
                          style={{ fontSize: '22px', color: '#2ACCFF' }}
                        />
                      </a>
                    </Col>
                    <Col>
                      <a href='https://twitter.com' target='blank'>
                        <TwitterCircleFilled
                          style={{ fontSize: '22px', color: '#2ACCFF' }}
                        />
                      </a>
                    </Col>
                  </Row> */}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Footer;
