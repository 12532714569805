import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Typography,
  Select,
  Avatar,
  message,
  DatePicker,
  Drawer,
} from 'antd';
import { Controller, useForm } from 'react-hook-form';
import {
  createTrustee,
  updateTrustee,
  getTrustees,
} from '../../Actions/trusteesAction';
import { useDispatch, useSelector } from 'react-redux';
import { trusteeSchema, trusteeEditSchema } from '../../Utils/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import '../AddFamilymember/Addfamily.css';
import { useTranslation } from 'react-i18next';
import './../../FindThePeoplePage/FindOtherPeople.css';
import { getSearchUser } from '../../Actions/searchUserAction';
import { getDesignations } from '../../Actions/designationsAction';
import _ from 'lodash';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import moment from 'moment';
const TrusteeModal = (props) => {
  const { t } = useTranslation();
  const {
    isCreateTrusteeModalVisible,
    setIsCreateTrusteeModalVisible,
    trustee,
    isUpdateTrusteeModalVisible,
    setIsUpdateTrusteeModalVisible,
    trusteeArray,
    isCreateTrusteesLoading,
    editTrusteeId,
    isUpdateTrusteesLoading,
    setEditTrusteeId,
  } = props;
  const { Option } = Select;
  const dispatch = useDispatch();
  const { searchUsers, allDesignations } = useSelector((state) => {
    const { searchUsers } = state.searchUser;
    const { allDesignations } = state.designations;
    return {
      searchUsers,
      allDesignations,
    };
  });
  const userType = JSON.stringify('["regular","admin"]');
  useEffect(() => {
    dispatch(getSearchUser(`api/user_search?type=${userType}&verified=true`));
    dispatch(getDesignations());
  }, [dispatch, userType]);
  const selectedTrustee = _.filter(trusteeArray, (val) => {
    return val?.id === editTrusteeId;
  });
  const newFromYear = moment(selectedTrustee[0]?.from_year, 'yyyy');
  const newToYear = moment(selectedTrustee[0]?.to_year, 'yyyy');
  const newArray = selectedTrustee?.map((obj) => {
    return {
      ...obj,
      from_year: newFromYear,
      to_year: newToYear,
      designation_id: obj.designation.designation_title,
    };
  });
  const [preloadedTrusteeValues, setPreloadedTrusteeValues] = useState(null);
  const {
    handleSubmit,

    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(
      isCreateTrusteeModalVisible ? trusteeSchema : trusteeEditSchema
    ),
    defaultValues: preloadedTrusteeValues,
  });

  useEffect(() => {
    setPreloadedTrusteeValues(newArray[0]);
    reset(newArray[0]);
  }, [selectedTrustee[0], reset]);

  const [failureMessage, setFailureMessage] = useState('');
  const hideCreateTrusteeModal = () => {
    setIsCreateTrusteeModalVisible(false);
    reset();
    setFailureMessage('');
    setValue('user_id', '');
    setValue('designation_id', '');
    setValue('from_year', '');
    setValue('to_year', '');
  };
  const createTrusteeMethod = (data) => {
    const FromDate = moment(data?.from_year);
    data.from_year = moment(FromDate._d).format('yyyy');
    const ToDate = moment(data?.to_year);
    data.to_year = moment(ToDate._d).format('yyyy');
    dispatch(
      createTrustee(data, successCreateTrusteeMethod, faliureTrusteeMethod)
    );
  };
  const successCreateTrusteeMethod = () => {
    setIsCreateTrusteeModalVisible(false);
    reset();
    setEditTrusteeId('');
    setValue('user_id', '');
    setValue('designation_id', '');
    setValue('from_year', '');
    setValue('to_year', '');
    setTimeout(() => {
      message.success(t('content.145'));
    }, 1000);
    dispatch(getTrustees());
  };
  const hideUpdateTrusteeModal = () => {
    setIsUpdateTrusteeModalVisible(false);
    reset();
    setEditTrusteeId('');
    setValue('designation_id', '');
    setValue('from_year', '');
    setValue('to_year', '');
  };
  const containsNumber = (str) => {
    return /\d/.test(str);
  };
  const updateTrusteeMethod = (data) => {
    data.id = editTrusteeId;

    data.user_id = trustee.id;
    data.designation_id = containsNumber(data.designation_id)
      ? data.designation_id
      : selectedTrustee[0]?.designation.id;
    const FromDate = moment(data?.from_year);
    data.from_year = moment(FromDate._d).format('yyyy');
    const ToDate = moment(data?.to_year);
    data.to_year = moment(ToDate._d).format('yyyy');
    dispatch(updateTrustee(data, successUpdateTrusteeMethod));
  };

  const successUpdateTrusteeMethod = () => {
    setIsUpdateTrusteeModalVisible(false);

    setTimeout(() => {
      message.success(t('content.146'));
    }, 1000);
    reset();
    dispatch(getTrustees());
  };
  const onlyPublishedDesignations =
    allDesignations.length > 0 &&
    allDesignations?.filter((Designations) => Designations.published === true);
  const faliureTrusteeMethod = (FailureCallback) => {
    FailureCallback.designation_id &&
      setFailureMessage('User with this designation is already created');
  };
  useEffect(() => {
    if (!editTrusteeId) {
      setEditTrusteeId('');
      setValue('designation_id', '');
      setValue('from_year', '');
      setValue('to_year', '');
    }
  }, [setValue, !editTrusteeId]);
  return (
    <Drawer
      open={
        isUpdateTrusteeModalVisible
          ? isUpdateTrusteeModalVisible
          : isCreateTrusteeModalVisible
      }
      footer={null}
      onClose={
        isUpdateTrusteeModalVisible
          ? hideUpdateTrusteeModal
          : hideCreateTrusteeModal
      }
      title={
        isUpdateTrusteeModalVisible ? (
          <Typography>{t('welcomeText.5')}</Typography>
        ) : (
          <Typography>{t('welcomeText.6')}</Typography>
        )
      }
      maskClassName='DrawerClass'
      className='DrawerBorderClass'
    >
      <LoadingIndicator
        loading={
          isUpdateTrusteeModalVisible
            ? isUpdateTrusteesLoading
            : isCreateTrusteesLoading
        }
      >
        <form
          onSubmit={handleSubmit(
            isUpdateTrusteeModalVisible
              ? updateTrusteeMethod
              : createTrusteeMethod
          )}
        >
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Row gutter={[10, 0]}>
                {isCreateTrusteeModalVisible && (
                  <Col span={12}>
                    <Typography
                      className='label'
                      style={{ paddingBottom: '4px' }}
                    >
                      {t('content.4')}
                    </Typography>
                    <Controller
                      as={
                        <Select
                          placeholder={t('content.4')}
                          style={{ width: '100%', borderRadius: '4px' }}
                          className='inputLabel'
                          showSearch={true}
                          // options={SearchUsersByFirstName}

                          filterOption={(input, option) => {
                            return (
                              option.children.props.children[2]
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                          size='large'
                        >
                          {searchUsers &&
                            searchUsers.users?.length > 0 &&
                            searchUsers.users.map((val, i) => {
                              const backgroundColor =
                                '#' +
                                Math.floor(Math.random() * 1677).toString(14);

                              return (
                                <Option value={val.id} key={i}>
                                  <>
                                    {val.image ? (
                                      <img
                                        src={val.image}
                                        style={{
                                          width: '25px',
                                          height: '25px',
                                          borderRadius: '50px',
                                        }}
                                        alt=''
                                      />
                                    ) : (
                                      <Avatar
                                        style={{
                                          backgroundColor: backgroundColor,
                                          width: '25px',
                                          height: '25px',
                                          lineHeight: '25px',
                                        }}
                                      >
                                        {val.first_name[0]}
                                      </Avatar>
                                    )}
                                    &nbsp;&nbsp;
                                    {val.first_name + ' ' + val.last_name}
                                  </>
                                </Option>
                              );
                            })}
                        </Select>
                      }
                      name='user_id'
                      control={control}
                    />
                    {errors.user_id && (
                      <p style={{ color: 'red' }}>{t('content.104')}</p>
                    )}
                  </Col>
                )}
                <Col span={isUpdateTrusteeModalVisible ? 24 : 12}>
                  <Typography
                    className='label'
                    style={{ paddingBottom: '4px' }}
                  >
                    {t('content.5')}
                  </Typography>
                  <Controller
                    as={
                      <Select
                        style={{ width: '100%' }}
                        placeholder={t('placeholder.9')}
                        // options={designationList}
                        className='inputLabel'
                        onClick={() => setFailureMessage('')}
                        size='large'
                      >
                        {onlyPublishedDesignations.length > 0 &&
                          onlyPublishedDesignations?.map((val, i) => {
                            return (
                              <Option value={val.id} key={i}>
                                {val.designation_title}
                              </Option>
                            );
                          })}
                      </Select>
                    }
                    name='designation_id'
                    control={control}
                  />
                  {errors.designation_id && (
                    <p style={{ color: 'red' }}>{t('content.105')}</p>
                  )}
                  {failureMessage && (
                    <p style={{ color: 'red' }}>{failureMessage}</p>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[10, 0]}>
                <Col span={12}>
                  <Typography
                    className='label'
                    style={{ paddingBottom: '4px' }}
                  >
                    From
                  </Typography>
                  <Controller
                    as={
                      <DatePicker
                        placeholder='Select Year'
                        className='inputLabel'
                        picker='year'
                        size='large'
                      />
                    }
                    name='from_year'
                    control={control}
                    style={{ width: '100%' }}
                  />
                  {errors.from_year && (
                    <p style={{ color: 'red' }}>{errors.from_year.message}</p>
                  )}
                </Col>
                <Col span={12}>
                  <Typography
                    className='label'
                    style={{ paddingBottom: '4px' }}
                  >
                    To
                  </Typography>
                  <Controller
                    as={
                      <DatePicker
                        placeholder='Select Year'
                        className='inputLabel'
                        picker='year'
                        size='large'
                      />
                    }
                    name='to_year'
                    control={control}
                    style={{ width: '100%' }}
                  />
                  {errors.to_year && (
                    <p style={{ color: 'red' }}>{errors.to_year.message}</p>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[10, 10]} justify='end'>
                <Col span={24} />
                <Col>
                  <Button
                    onClick={
                      isUpdateTrusteeModalVisible
                        ? hideUpdateTrusteeModal
                        : hideCreateTrusteeModal
                    }
                    className='allButtonsCancel'
                  >
                    {t('btn.19')}
                  </Button>
                </Col>
                <Col>
                  {isUpdateTrusteeModalVisible ? (
                    <Button htmlType='submit' className='allButtons'>
                      {t('btn.17')}
                    </Button>
                  ) : (
                    <Button htmlType='submit' className='allButtons'>
                      {t('btn.18')}
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </LoadingIndicator>
    </Drawer>
  );
};
export default TrusteeModal;
