import React from 'react';
import { Row, Table, Button, Col, message, Empty, Typography } from 'antd';
import '../../FindThePeoplePage/FindOtherPeople.css';
import './Admin.css';
import NativeEmptyScreen from '../../assets/NativeEmptyScreen.svg';
import { useDispatch } from 'react-redux';
import {
  getCommunitiesNative,
  updateCommunitiesNativeAction,
} from '../../Actions/communitiesNativeAction';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { useBreakpoints } from '../CommonLogics/ResponsiveBreakPoints';
import { analytics } from '../../components/Mixpanel/Mixpanel';

const Nativetable = (props) => {
  const {
    setEditNativeId,
    setIsEditNativeModalVisible,
    allCommunitiesNative,
    isCommunitiesNativeLoading,
    setCommunityId,
  } = props;
  const { t } = useTranslation();
  const { IsXS, IsSM } = useBreakpoints();
  const AllCommunitiesNative = _.isArray(allCommunitiesNative)
    ? allCommunitiesNative
    : Object.keys(allCommunitiesNative);

  const columns = [
    {
      title: `${t('content.151')} (${allCommunitiesNative?.length})`,
      dataIndex: 'name',
      width: IsXS || IsSM ? '50%' : '80%',
      render: (name, allNativesList) => {
        return allNativesList.is_published ? (
          name
        ) : (
          <Col className='hidedRow'>{name}</Col>
        );
      },
    },
    {
      dataIndex: 'id',
      render: (id, allNatives) => (
        <NativeActionComponent
          id={id}
          setEditNativeId={setEditNativeId}
          setIsEditNativeModalVisible={setIsEditNativeModalVisible}
          allNatives={allNatives}
          setCommunityId={setCommunityId}
        />
      ),
      width: '15em',
    },
  ];
  return (
    <Col>
      {AllCommunitiesNative.length > 0 ? (
        <LoadingIndicator loading={isCommunitiesNativeLoading}>
          <Col className='AdminTable'>
            <Table
              columns={columns}
              dataSource={AllCommunitiesNative}
              size='middle'
              className='table'
              rowKey={(record) => {
                return record.id;
              }}
            />
          </Col>
        </LoadingIndicator>
      ) : (
        <Row
          justify='center'
          gutter={[0, 10]}
          className='emptyScreenAdminCenter'
        >
          <Empty
            description={
              <Typography className='emptyScreenAdmin'>
                {t('content.294')}
              </Typography>
            }
            image={NativeEmptyScreen}
            imageStyle={{
              height: 38,
            }}
          />
        </Row>
      )}
    </Col>
  );
};

export default Nativetable;

const NativeActionComponent = (props) => {
  const {
    id,
    setEditNativeId,
    setIsEditNativeModalVisible,
    allNatives,
    setCommunityId,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let UnpublishData = {};
  let PublishData = {};
  const handleEditCommunityNative = (Id) => {
    setEditNativeId(Id);
    setIsEditNativeModalVisible(true);
  };
  const handlePublishEducationMethod = (EditId) => {
    PublishData.id = EditId;
    PublishData.is_published = true;
    dispatch(
      updateCommunitiesNativeAction(PublishData, successUpdatePublishMethod)
    );
  };
  const handleUnpublishEducationMethod = (EditId) => {
    UnpublishData.id = EditId;

    UnpublishData.is_published = false;
    dispatch(
      updateCommunitiesNativeAction(UnpublishData, successUpdateUnpublishMethod)
    );
  };

  const successUpdateUnpublishMethod = () => {
    setTimeout(() => {
      message.success(t('content.338'));
    }, 1000);
    dispatch(getCommunitiesNative(setCommunityId));
  };
  const successUpdatePublishMethod = () => {
    setTimeout(() => {
      message.success(t('content.339'));
    }, 1000);
    dispatch(getCommunitiesNative(setCommunityId));
  };
  return (
    <Row>
      <Col span={10}>
        <Button
          onClick={() => {
            analytics?.track('Admin_Native_Edit');
            handleEditCommunityNative(id);
          }}
          className='editDesignationButton'
          type='primary'
        >
          {t('btn.27')}
        </Button>
      </Col>
      <Col span={10}>
        {allNatives?.is_published ? (
          <Button
            type='primary'
            className='unPublishButton'
            onClick={() => {
              analytics?.track('Admin_Native_Unpublish');
              handleUnpublishEducationMethod(id);
            }}
          >
            {t('content.164')}
          </Button>
        ) : (
          <Button
            type='primary'
            className='publishButton'
            onClick={() => {
              analytics?.track('Admin_Native_Publish');
              handlePublishEducationMethod(id);
            }}
          >
            {t('content.163')}
          </Button>
        )}
      </Col>
    </Row>
  );
};
