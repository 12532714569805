import constants from "../Utils/constants";
const initialState = {
  isGetEventsLoading: false,
  isCreateEventsLoading: false,
  isDeleteEventsLoading: false,
  isUpdateEventsLoading: false,
  allEvents: [],
};

export const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_EVENTS_REQUEST:
      return {
        ...state,
        allEvents: [],
        isGetEventsLoading: true,
      };
    case constants.GET_EVENTS_SUCCESS:
      return {
        ...state,
        isGetEventsLoading: false,
        allEvents: action.payload,
      };
    case constants.GET_EVENTS_FAILURE:
      return {
        ...state,
        isGetEventsLoading: false,
      };
    case constants.CREATE_EVENTS_REQUEST:
      return {
        ...state,
        isCreateEventsLoading: true,
      };
    case constants.CREATE_EVENTS_SUCCESS:
      return {
        ...state,
        isCreateEventsLoading: false,
      };
    case constants.CREATE_EVENTS_FAILURE:
      return {
        ...state,
        isCreateEventsLoading: false,
      };
    case constants.DELETE_EVENTS_REQUEST:
      return {
        ...state,
        isDeleteEventsLoading: true,
      };
    case constants.DELETE_EVENTS_SUCCESS:
      return {
        ...state,
        isDeleteEventsLoading: false,
      };
    case constants.DELETE_EVENTS_FAILURE:
      return {
        ...state,
        isDeleteEventsLoading: false,
      };
    case constants.UPDATE_EVENTS_REQUEST:
      return {
        ...state,
        isUpdateEventsLoading: true,
      };
    case constants.UPDATE_EVENTS_SUCCESS:
      return {
        ...state,
        isUpdateEventsLoading: false,
      };
    case constants.UPDATE_EVENTS_FAILURE:
      return {
        ...state,
        isUpdateEventsLoading: false,
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
