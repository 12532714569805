import API from "../Utils/API";
export const getCommunities = () => (dispatch) => {
  dispatch({ type: "GET_COMMUNITIES_REQUEST" });
  API.get("api/communities/")
    .then((response) => {
      dispatch({ type: "GET_COMMUNITIES_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_COMMUNITIES_FAILURE",
        payload: error.response?.data,
      });
    });
};

export const getCommunityById =
  (Id, communitySucesss, commmunityFailure) => (dispatch) => {
    dispatch({ type: "GET_COMMUNITY_BY_ID_REQUEST" });
    API.get(`api/communities/${Id}`)
      .then((response) => {
        dispatch({
          type: "GET_COMMUNITY_BY_ID_SUCCESS",
          payload: response.data,
        });
        communitySucesss && communitySucesss(response);
      })
      .catch((error) => {
        dispatch({
          type: "GET_COMMUNITY_BY_ID_FAILURE",
          payload: error.response.data.error,
        });
        commmunityFailure && commmunityFailure(error.response.data);
      });
  };

export const updateCommunitiesAction =
  (data, successCallback, IsEmpty) => (dispatch) => {
    dispatch({ type: "UPDATE_COMMUNITIES_REQUEST" });
    let formData = new FormData();
    formData.append("community[name]", data.name);
    formData.append("community[description]", data.description);
    formData.append("community[show_trustee]", data.show_trustee);
    formData.append("community[show_event]", data.show_event);
    formData.append(
      "community[show_find_other_people]",
      data.show_find_other_people
    );
    formData.append("community[show_matrimonial]", data.show_matrimonial);
    formData.append(
      "community[show_business_profile]",
      data.show_business_profile
    );
    IsEmpty
      ? formData.append("community_logo", data.community_logo)
      : formData.append("community[community_logo]", data.community_logo);
    API.put(`api/communities/${data.id}`, formData)
      .then((response) => {
        dispatch({
          type: "UPDATE_COMMUNITIES_SUCCESS",
          payload: response.data,
        });
        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_COMMUNITIES_FAILURE",
          payload: error.response.data,
        });
      });
  };
