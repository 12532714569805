import { isEmpty } from "lodash";
import constants from "../Utils/constants";
const initialState = {
  isLoginUserLoading: false,
  isVerifyOtpLoading: false,
  isCreateUserLoading: false,
  isAuthenticated: false,
  currentUser: null,
  isUpdateUserLoading: false,
  isGetUserByIdLoading:false,
  userById:{}
};

export const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_USERS_REQUEST:
      return {
        ...state,
        isGetUsersLoading: true,
      };
    case constants.GET_USERS_SUCCESS:
      return {
        ...state,
        isGetUsersLoading: false,
        currentUser: action.payload,
      };

      case constants.GET_USERS_FAILURE:
      return {
        ...state,
        isGetUsersLoading: false,
      };
    case constants.LOGIN_USER_REQUEST:
      return {
        ...state,
        isLoginUserLoading: true,
      };
    case constants.LOGIN_USER_SUCCESS:
      return {
        ...state,
        isLoginUserLoading: false,
      };
    case constants.LOGIN_USER_FAILURE:
      return {
        ...state,
        isLoginUserLoading: false,
      };
    case constants.VERIFY_OTP_REQUEST:
      return {
        ...state,
        isVerifyOtpLoading: true,
      };
    case constants.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        isVerifyOtpLoading: false,
      };
    case constants.VERIFY_OTP_FAILURE:
      return {
        ...state,
        isVerifyOtpLoading: false,
      };
    case constants.CREATE_USER_REQUEST:
      return {
        ...state,
        isCreateUserLoading: true,
      };
    case constants.CREATE_USER_SUCCESS:
      return {
        ...state,
        isCreateUserLoading: false,
      };
    case constants.CREATE_USER_FAILURE:
      return {
        ...state,
        isCreateUserLoading: false,
      };
    
      case constants.SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case constants.REMOVE_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case constants.UPDATE_USER_REQUEST:
      return {
        ...state,
        isUpdateUserLoading: true,
      };
    case constants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isUpdateUserLoading: false,
      };
    case constants.UPDATE_USER_FAILURE:
      return {
        ...state,
        isUpdateUserLoading: false,
      };
      case constants.GET_USER_BY_ID_REQUEST:
      return {
        ...state,
        userById:{},
        isGetUserByIdLoading: true,
      };
    case constants.GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        isGetUserByIdLoading: false,
        userById: action.payload,
      };
    case constants.GET_USER_BY_ID_FAILURE:
      return {
        ...state,
        isGetUserByIdLoading: false,
      }
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
   
      default:
      return state;
  }
};
