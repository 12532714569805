import React, { useState } from 'react';
import {
  Modal,
  Row,
  Col,
  Input,
  Button,
  Upload,
  Typography,
  message,
  Drawer,
} from 'antd';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import {
  createDocumentAction,
  getDocuments,
} from '../../Actions/documentsAction';
import { useDispatch, useSelector } from 'react-redux';
import { documentSchema } from '../../Utils/Schema';
function UploadDocument(props) {
  const {
    isUploadDocsVisible,
    setIsUploadDocsVisible,
    documentYear,
    isCreateDocumentLoading,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [newFiles, setNewFiles] = useState([]);
  const [fileAllowed, setFileAllowed] = useState('');
  const { community } = useSelector((state) => {
    const { community } = state.communities;
    return {
      community,
    };
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(documentSchema),
  });
  const closeUploadDocsMethod = () => {
    setIsUploadDocsVisible(false);
    reset();
    setFileAllowed('');
    setNewFiles([]);
    setValue('file_name', '');
  };
  const createDocument = (data) => {
    if (!newFiles || newFiles.type !== 'application/pdf') {
      setFileAllowed('File must be required having type pdf only');
    } else {
      data.document_file = newFiles;
      data.year = documentYear;
      data.community_id = community?.id;
      dispatch(createDocumentAction(data, successCreateDocumentMethod));
    }
  };
  const successCreateDocumentMethod = () => {
    dispatch(getDocuments(documentYear));
    setIsUploadDocsVisible(false);
    reset();
    setTimeout(() => {
      message.success(t('content.341'));
    }, 1000);
    setFileAllowed('');
    setNewFiles([]);
    setValue('file_name', '');
  };
  const propsForUpload = {
    action: createDocument,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles(file);
      return false;
    },

    multiple: false,
  };

  return (
    <Drawer
      title={t('content.241')}
      open={isUploadDocsVisible}
      onClose={closeUploadDocsMethod}
      okText='Save'
      footer={null}
      maskClassName='DrawerClass'
      className='DrawerBorderClass'
    >
      <LoadingIndicator loading={isCreateDocumentLoading}>
        <Typography className='content'>
          {t('content.244')} : {documentYear}
        </Typography>
        <form
          onSubmit={handleSubmit(createDocument)}
          style={{ marginTop: '20px' }}
        >
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Typography className='content'>{t('content.245')}</Typography>
            </Col>
            <Col span={24}>
              <Controller
                as={<Input size='large' />}
                name='file_name'
                control={control}
              />
              {errors.file_name && (
                <p style={{ color: 'red' }}>{errors.file_name.message}</p>
              )}
            </Col>
          </Row>

          <Row gutter={[0, 10]}>
            <Col span={24} />
            <Col span={24}>
              <Col
                style={{
                  border: '1px solid #d9d9d9',
                  padding: '3px',
                }}
              >
                <Row justify='space-between'>
                  <Col>
                    <Typography
                      style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {newFiles?.name}
                    </Typography>
                  </Col>
                  <Col>
                    <Upload
                      {...propsForUpload}
                      showUploadList={false}
                      onChange={() => setFileAllowed('')}
                    >
                      <Button type='primary'>{t('btn.25')}</Button>
                    </Upload>
                  </Col>
                </Row>
              </Col>
              {fileAllowed && <p style={{ color: 'red' }}>{fileAllowed}</p>}
            </Col>
          </Row>
          <Row gutter={[10, 10]} justify='end'>
            <Col span={24} />
            <Col>
              <Button
                className='allButtonsCancel'
                onClick={() => closeUploadDocsMethod()}
              >
                {t('btn.19')}
              </Button>
            </Col>
            <Col>
              <Button htmlType='submit' className='allButtons'>
                {t('btn.25')}
              </Button>
            </Col>
          </Row>
        </form>
      </LoadingIndicator>
    </Drawer>
  );
}

export default UploadDocument;
