import API from "../Utils/API";
export const getDesignations = () => (dispatch) => {
  dispatch({ type: "GET_DESIGNATIONS_REQUEST" });
  API.get("api/designations/")
    .then((response) => {
      dispatch({ type: "GET_DESIGNATIONS_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_DESIGNATIONS_FAILURE",
        payload: error.response.data,
      });
    });
};

export const createDesignation = (data, successCallback) => (dispatch) => {
  dispatch({ type: "CREATE_DESIGNATIONS_REQUEST", payload: data });
  API.post("api/designations", data)
    .then((response) => {
      dispatch({ type: "CREATE_DESIGNATIONS_SUCCESS", payload: response.data });
      successCallback();
    })
    .catch((error) => {
      dispatch({ type: "CREATE_DESIGNATIONS_FAILURE", payload: error });
    });
};

export const updateDesignation = (data, successCallback) => (dispatch) => {
  dispatch({ type: "UPDATE_DESIGNATIONS_REQUEST" });
  API.put(`api/designations/${data.id}`, data)
    .then((response) => {
      dispatch({ type: "UPDATE_DESIGNATIONS_SUCCESS", payload: response.data });
      successCallback();
    })
    .catch((error) => {
      dispatch({
        type: "UPDATE_DESIGNATIONS_FAILURE",
        payload: error.response.data,
      });
    });
};

export const getDesignationById = (Id) => (dispatch) => {
  dispatch({ type: "GET_DESIGNATIONS_BY_ID_REQUEST" });

  API.get(`api/designations/${Id}`)
    .then((response) => {
      dispatch({
        type: "GET_DESIGNATIONS_BY_ID_SUCCESS",
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: "GET_DESIGNATIONS_BY_ID_FAILURE",
        payload: error.response.data,
      });
    });
};
