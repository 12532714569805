import React from 'react';
import { Row, Col, Button, Typography, Input, Empty } from 'antd';
import './CommunityFeeds.css';
import PayNowPolicyImage from '../../assets/PayNowPolicyImage.svg';
import { ArrowLeftOutlined } from '@ant-design/icons';
import '../../FindThePeoplePage/FindOtherPeople.css';
import { useTranslation } from 'react-i18next';

const PayNow = (props) => {
  const { setStepDeals, stepDeals } = props;
  const { t } = useTranslation();

  return (
    <Col>
      <Row justify='space-around'>
        <Col span={23}>
          <Row gutter={[0, 21]}>
            <Col span={24} />
            <Col span={24} />
          </Row>
        </Col>
        <Col span={23}>
          <ArrowLeftOutlined
            style={{ fontSize: '20px' }}
            onClick={() => setStepDeals(stepDeals - 1)}
          />
        </Col>
        <Col span={23}>
          <Row gutter={[0, 21]}>
            <Col span={24} />
            <Col span={24} />
          </Row>
        </Col>
        <Col span={23}>
          <Row justify='center'>
            <Empty
              description={
                <Typography className='empty-details'>
                  {t('content.298')}
                  <br /> {t('content.299')}
                </Typography>
              }
              image={PayNowPolicyImage}
            ></Empty>
          </Row>
        </Col>
        <Col span={23}>
          <Row gutter={[0, 21]}>
            <Col span={24} />
            <Col span={24} />
          </Row>
        </Col>
        <Col span={23}>
          <Row justify='center'>
            <Col span={4} />
            <Col span={15}>
              <Col span={24}>
                <Typography className='NewInputText'>Payment Amount</Typography>
              </Col>
              <Col span={24}>
                <Row gutter={[24, 0]}>
                  <Col span={13}>
                    <Input className='NewInputDesign' />
                  </Col>
                  <Col>
                    <Button
                      type='primary'
                      className='button-search'
                      onClick={() => setStepDeals(3)}
                    >
                      Pay Now
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};
export default PayNow;
