import React from 'react';
import { Row, Col, Typography, Avatar } from 'antd';
import { useSelector } from 'react-redux';
import { newImageUrl } from '../CommonLogics/CommonMethods';
const CommunityMarriageCard = (props) => {
  const { mappedData } = props;
  const { allTemplates } = useSelector((state) => {
    const { allTemplates } = state.templates;

    return {
      allTemplates,
    };
  });

  return (

    <Col>

      <Row gutter={[0, 16]} justify='space-between'>
        {allTemplates.templates?.map((val, i) => {
          return (
            <Col key={i}>
              <Row>
                <Col span={18}>
                  {/* <Card > */}
                  <Avatar
                    size={60}
                    shape='square'
                    src={newImageUrl(val.template_image)}
                  ></Avatar>
                </Col>
              </Row>
            </Col>
          );
        })}
        <Col>
          <Typography className='CommunityTextCardTitle2'>
            {mappedData?.name}
          </Typography>
        </Col>
        <Col span={24}>
          <Typography className='communityCardDescription1'>
            {mappedData?.description}
          </Typography>
        </Col>
        <Col>
          <Typography className='CommunityTextCardTitle2'></Typography>
        </Col>
      </Row>
    </Col>
  );
};
export default CommunityMarriageCard;
