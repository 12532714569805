import React, { useState, useEffect } from 'react';
import {
  Input,
  Button,
  Row,
  Col,
  Typography,
  Alert,
  message,
  Empty,
  Select,
  Drawer,
} from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import './Addfamily.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  createFamily,
  getFamilies,
  updateFamily,
  getDeletedUserFamilies,
  getFamilyByIdAction,
} from '../../Actions/familyAction';
import { getCommunitiesNative } from '../../Actions/communitiesNativeAction';
import './Addfamily.css';
import { userSchema } from '../../Utils/Schema';
import _ from 'lodash';
import svgImage from '../../assets/familyImage.svg';
import FamilyCard from './FamilyCard';
import ViewMemberDetails from './familyDetails';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './../../FindThePeoplePage/FindOtherPeople.css';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import JoinFamilyModal from './JoinFamilyModal';
import { getEducationsAction } from '../../Actions/educationAction';
import { getIndustriesAction } from '../../Actions/industryAction';
import { history } from '../../Utils/history';
import { analytics } from '../../components/Mixpanel/Mixpanel';

const Familybtn = (props) => {
  const {
    allFamilies,
    isFamiliesLoading,
    isCreateFamilyLoading,
    isUpdateFamilyLoading,
    allCommunitiesNative,
    setCommunityId,
    allDeletedUserFamilies,
    Family,
  } = useSelector((state) => {
    const {
      allFamilies,
      isFamiliesLoading,
      isCreateFamilyLoading,
      isUpdateFamilyLoading,
      allDeletedUserFamilies,
      Family,
    } = state.family;
    const { setCommunityId } = state.communities;
    const { allCommunitiesNative, setSelectedCommunityNativeId } =
      state.communitiesNative;
    return {
      allFamilies,
      isFamiliesLoading,
      isCreateFamilyLoading,
      isUpdateFamilyLoading,
      setCommunityId,
      allCommunitiesNative,
      setSelectedCommunityNativeId,
      allDeletedUserFamilies,
      Family,
    };
  });
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { user_families } = Family;
  const [successCreate, setSuccessCreate] = useState(false);
  const [isViewMemberDetailsVisible, setIsViewMemberDetails] = useState(false);
  const [selectedFamilyId, setSelectedFamilyId] = useState('');
  const [selectedMemberId, setSelectedMemberId] = useState('');
  const [editFamilyId, setEditFamilyId] = useState('');
  const [isEditFamilyModalVisible, setIsEditFamilyModalVisible] =
    useState(false);
  const [isJoinFamilyModalVisible, setIsJoinFamilyModalVisible] =
    useState(false);
  const [opendrawer, setOpendrawer] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFamilies());
    dispatch(getCommunitiesNative(setCommunityId));
    dispatch(getEducationsAction());
    dispatch(getIndustriesAction());
    dispatch(getDeletedUserFamilies());
    allDeletedUserFamilies &&
      allDeletedUserFamilies.length > 0 &&
      dispatch(getFamilyByIdAction(allDeletedUserFamilies[0].family?.id));
    const createAlert = () => {
      setSuccessCreate(false);
    };
    const set = setInterval(createAlert, 3000);
    clearInterval(set);
  }, []);

  const the_family = allFamilies?.filter((o) => {
    return o.id === editFamilyId;
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(userSchema),
  });
  const { Option } = Select;
  const height = window.innerHeight - 64;
  const createFam = (data) => {
    dispatch(createFamily(data, callback));
  };
  const callback = () => {
    analytics?.track('Families_CreateFamiliy');
    setValue('last_name', '');
    setValue('community_native_id', '');
    reset();
    setIsModalVisible(false);
    setTimeout(() => {
      message.success(t('content.125'));
    }, 1000);
    dispatch(getFamilies());
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setEditFamilyId('');
    setIsEditFamilyModalVisible(false);
    reset('');
  };
  const containsNumber = (str) => {
    return /\d/.test(str);
  };

  const UpdateFamily = (data) => {
    const { community_native } = the_family[0];
    data.id = editFamilyId;
    data.community_native_id = containsNumber(data.community_native_id)
      ? data.community_native_id
      : community_native?.id;
    dispatch(updateFamily(data, UpdateCallBack));
  };

  const UpdateCallBack = () => {
    reset();
    setIsEditFamilyModalVisible(false);
    dispatch(getFamilies());
    setTimeout(() => {
      message.success(t('content.126'));
    }, 1000);
  };

  const hideViewMemberDetails = () => {
    setIsViewMemberDetails(false);
  };

  const membersArray = _.map(allFamilies, _.property('members'));
  const secondColor = '#' + Math.floor(Math.random() * 1677).toString(14);
  const publishedNatives = allCommunitiesNative?.filter((Natives) => {
    return Natives.is_published === true;
  });
  const FilteredDeletedUserFamilies =
    allDeletedUserFamilies &&
    allDeletedUserFamilies.length > 0 &&
    allDeletedUserFamilies.map((val) => {
      return (
        val?.family?.id === Family?.id && {
          ...val,
          user_families: Family.user_families,
          isDeleted: val?.family?.id === Family?.id,
        }
      );
    });
  return (
    <Col
      style={{
        backgroundColor: '#FFFFFF',
        height: '100%',
      }}
      className='customLayout'
    >
      <>
        {isViewMemberDetailsVisible ? (
          <Row justify='space-around'>
            <Col span={23}>
              <Row gutter={[0, 30]}>
                <Col span={24} />
                <ArrowLeftOutlined
                  style={{ fontSize: '20px' }}
                  onClick={() => hideViewMemberDetails()}
                />
                <Col span={24} />
              </Row>
            </Col>
            <Col span={23}>
              <ViewMemberDetails
                hideViewMemberDetails={hideViewMemberDetails}
                selectedMemberId={selectedMemberId}
                setSelectedMemberId={setSelectedMemberId}
                setIsViewMemberDetails={setIsViewMemberDetails}
                membersArray={membersArray}
                selectedFamilyId={selectedFamilyId}
                secondColor={secondColor}
              />
            </Col>
          </Row>
        ) : (
          <>
            <LoadingIndicator
              loading={
                history.location.pathname === '/add_family' && isFamiliesLoading
              }
            >
              <Col
                style={{
                  height: height,
                  overflow: 'hidden',
                  overflowY: 'scroll',
                }}
                className='customLayout'
              >
                <Row justify='center' gutter={[0, 20]}>
                  <Col span={24}>
                    <Row gutter={[0, 10]}>
                      <Col span={24} />
                    </Row>
                  </Col>
                  <Col xl={23} lg={21} md={23} xs={23} sm={23}>
                    <Row
                      justify='space-between'
                      gutter={[0, { xs: 20, lg: 20, md: 20, sm: 20 }]}
                    >
                      <Col>
                        <Typography className='FamilyFound'>
                          {t('welcomeText.9')}&nbsp;
                          {[...allDeletedUserFamilies, ...allFamilies]?.length}
                          &nbsp;
                          {t('welcomeText.10')}
                        </Typography>
                      </Col>
                      {successCreate ? (
                        <Alert
                          message='Family Created Successfully'
                          showIcon
                          type='success'
                        />
                      ) : (
                        <Col>
                          <Row gutter={[10, 0]}>
                            <Col>
                              <Button
                                type='primary'
                                onClick={() => {
                                  analytics?.track('Families_CreateFamiliy');
                                  setIsModalVisible(true);
                                }}
                                style={{ borderRadius: '4px' }}
                                className='button-search'
                              >
                                {t('btn.21')}
                              </Button>
                            </Col>
                            <Col>
                              <Button
                                type='primary'
                                onClick={() => {
                                  analytics?.track('Families_JoinFamily');
                                  setIsJoinFamilyModalVisible(true);
                                }}
                                style={{ borderRadius: '4px' }}
                                className='button-search'
                              >
                                {t('btn.51')}
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </Row>

                    <JoinFamilyModal
                      isJoinFamilyModalVisible={isJoinFamilyModalVisible}
                      setIsJoinFamilyModalVisible={setIsJoinFamilyModalVisible}
                    />

                    <Drawer
                      open={isModalVisible || isEditFamilyModalVisible}
                      title={
                        isEditFamilyModalVisible ? (
                          <Typography className='title'>
                            {t('welcomeText.8')}
                          </Typography>
                        ) : (
                          <Typography className='title'>
                            {t('welcomeText.7')}
                          </Typography>
                        )
                      }
                      onClose={handleCancel}
                      maskClassName='DrawerClass'
                      className='DrawerBorderClass'
                    >
                      <>
                        <LoadingIndicator
                          loading={
                            isEditFamilyModalVisible
                              ? isUpdateFamilyLoading
                              : isCreateFamilyLoading
                          }
                        >
                          <form
                            onSubmit={handleSubmit(
                              isEditFamilyModalVisible
                                ? UpdateFamily
                                : createFam
                            )}
                          >
                            <Row gutter={[0, 30]}>
                              <Col span={24}>
                                <Typography
                                  className='label'
                                  style={{ paddingBottom: 9 }}
                                >
                                  {t('content.13')}
                                </Typography>
                                <Controller
                                  as={
                                    <Input
                                      style={{ borderRadius: '4px' }}
                                      placeholder={t('content.158')}
                                    />
                                  }
                                  name='last_name'
                                  control={control}
                                />
                                {errors.last_name && (
                                  <p style={{ color: 'red' }}>
                                    {t('content.115')}
                                  </p>
                                )}
                              </Col>
                              <Col span={24}>
                                <Typography
                                  className='label'
                                  style={{ paddingBottom: 9 }}
                                >
                                  {t('content.14')}
                                </Typography>
                                <Controller
                                  as={
                                    <Select
                                      style={{ width: '100%' }}
                                      placeholder={t('placeholder.12')}
                                      className='inputLabel'
                                    >
                                      {publishedNatives?.map((val, i) => {
                                        return (
                                          <Option value={val.id} key={i}>
                                            {val.name}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  }
                                  name='community_native_id'
                                  control={control}
                                />
                                {errors.community_native_id && (
                                  <p style={{ color: 'red' }}>
                                    {t('content.116')}
                                  </p>
                                )}
                              </Col>
                            </Row>

                            <Col span={24}>
                              <Row
                                gutter={[10, 10]}
                                justify='end'
                                style={{ marginTop: '13px' }}
                              >
                                {isEditFamilyModalVisible ? (
                                  <Col>
                                    <Button
                                      onClick={handleCancel}
                                      className='allButtonsCancel'
                                    >
                                      {t('btn.19')}
                                    </Button>
                                  </Col>
                                ) : (
                                  <Col>
                                    <Button
                                      onClick={handleCancel}
                                      className='allButtonsCancel'
                                    >
                                      {t('btn.19')}
                                    </Button>
                                  </Col>
                                )}
                                {isEditFamilyModalVisible ? (
                                  <Col>
                                    <Button
                                      htmlType='submit'
                                      className='allButtons'
                                    >
                                      {t('btn.17')}
                                    </Button>
                                  </Col>
                                ) : (
                                  <Col>
                                    <Button
                                      htmlType='submit'
                                      className='allButtons'
                                    >
                                      {t('btn.18')}
                                    </Button>
                                  </Col>
                                )}
                              </Row>
                            </Col>
                          </form>
                        </LoadingIndicator>
                      </>
                    </Drawer>

                    <Row gutter={[0, 24]}>
                      <Col span={24} />
                      <Col span={24} />
                      <Col span={24} />

                      {!isFamiliesLoading &&
                      (allDeletedUserFamilies.length > 0
                        ? allDeletedUserFamilies
                        : allFamilies
                      )?.length <= 0 ? (
                        <>
                          <Col span={24} />
                          <Col span={24} />
                          <Col span={24} />

                          <Col span={24}>
                            <Row justify='center'>
                              <Col>
                                <Empty
                                  description={
                                    <Typography className='empty-details'>
                                      {t('emptyScreen.2')}
                                    </Typography>
                                  }
                                  imageStyle={{ height: 38 }}
                                  image={svgImage}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </>
                      ) : (
                        !isViewMemberDetailsVisible &&
                        (FilteredDeletedUserFamilies.length > 0
                          ? allFamilies.length > 0
                            ? [...FilteredDeletedUserFamilies, ...allFamilies]
                            : FilteredDeletedUserFamilies
                          : allFamilies
                        )?.map((family, i) => {
                          return (
                            <Col
                              xl={12}
                              lg={18}
                              md={18}
                              sm={24}
                              xs={24}
                              key={i}
                            >
                              <FamilyCard
                                family={family}
                                selectedFamilyId={
                                  selectedFamilyId && selectedFamilyId
                                }
                                setSelectedFamilyId={
                                  setSelectedFamilyId && setSelectedFamilyId
                                }
                                selectedMemberId={
                                  selectedMemberId && selectedMemberId
                                }
                                setSelectedMemberId={
                                  setSelectedMemberId && setSelectedMemberId
                                }
                                setIsViewMemberDetails={
                                  setIsViewMemberDetails &&
                                  setIsViewMemberDetails
                                }
                                editFamilyId={editFamilyId && editFamilyId}
                                setEditFamilyId={
                                  setEditFamilyId && setEditFamilyId
                                }
                                setIsModalVisible={
                                  setIsModalVisible && setIsModalVisible
                                }
                                setIsEditFamilyModalVisible={
                                  setIsEditFamilyModalVisible &&
                                  setIsEditFamilyModalVisible
                                }
                                setValue={setValue && setValue}
                                secondColor={secondColor}
                                allDeletedUserFamilies={allDeletedUserFamilies}
                                DeletedUserMembers={user_families}
                                session='families'
                              />
                            </Col>
                          );
                        })
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </LoadingIndicator>
          </>
        )}
      </>
    </Col>
  );
};

export default Familybtn;
