import React from 'react';
import { Row, Col, Card } from 'antd';
import { previewTemplate } from '../CommonLogics/CommonMethods';

const CommunityCongratulationsCard = (props) => {
  const { mappedData } = props;

  return (

    <Col>
      <Card className='communityCard2'>
        <Row gutter={[0, 10]} justify='space-between'>
          <Col span={24}>
            <img src={previewTemplate(mappedData?.card_file_key)} />
          </Col>
        </Row>
      </Card>

    </Col>
  );
};
export default CommunityCongratulationsCard;
