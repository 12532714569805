import React, { useEffect } from 'react';
import { Row, Table, Button, Col, message, Progress } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import '../../FindThePeoplePage/FindOtherPeople.css';
import './VolunteerSideTable.css';
import { history } from '../../Utils/history';
import { updateVerifiedByVolunteerAction } from '../../Actions/nativeVolunteerAction';
import { useBreakpoints } from '../CommonLogics/ResponsiveBreakPoints';
import { getFamiliesByNativeForVolunterAction } from '../../Actions/FamiliesByNativeForVolunteerAction';

const VolunteerSideTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    currentUser,
    allFamiliesByNativeForVolunteer,
    isFamiliesByNativeForVolunteerLoading,
  } = useSelector((state) => {
    const { searchUsers, isSearchUserLoading } = state.searchUser;
    const { currentUser } = state.authentication;
    const {
      allFamiliesByNativeForVolunteer,
      isFamiliesByNativeForVolunteerLoading,
    } = state.FamiliesByNativeForVolunteer;
    return {
      searchUsers,
      isSearchUserLoading,
      currentUser,
      allFamiliesByNativeForVolunteer,
      isFamiliesByNativeForVolunteerLoading,
    };
  });
  const { users_types } = currentUser || {};
  const isUserVolunteer = users_types.includes('volunteer');
  const redirectMethod = () => {
    history.push('/add_family');
  };
  useEffect(() => {
    dispatch(getFamiliesByNativeForVolunterAction());
    if (!isUserVolunteer) {
      redirectMethod();
    }
  }, [dispatch, isUserVolunteer]);
  const notVerifiedFamilies = allFamiliesByNativeForVolunteer.filter(
    (Families) => {
      return Families.verified_family === false;
    }
  );
  const { IsXL } = useBreakpoints();

  const columns = [
    {
      title: t('content.219'),
      width: !IsXL && '15em',
      render: ({ last_name }) => {
        return last_name;
      },
    },
    {
      title: t('content.220'),
      render: ({ family_progres }) => {
        return (
          <Progress
            percent={family_progres}
            strokeColor={
              family_progres < 50
                ? 'red'
                : family_progres < 70
                ? '#0064FF'
                : 'green'
            }
          />
        );
      },
      width: !IsXL && '15em',
    },
    {
      dataIndex: 'id',
      render: (id) => <ActionComponent id={id} />,

      width: !IsXL && '15em',
    },
  ];

  const height = window.innerHeight - 64;

  return (
    <Col
      style={{
        height: height,
        overflow: 'hidden',
        overflowY: 'scroll',
        backgroundColor: '#FFFFFF',
      }}
      className='customLayout'
    >
      <Row justify='space-around' style={{ margin: '20px' }}>
        <Col span={24}>
          <LoadingIndicator loading={isFamiliesByNativeForVolunteerLoading}>
            <Col className='AdminTable'>
              <Table
                columns={columns}
                dataSource={notVerifiedFamilies}
                className='table'
                rowKey={(record) => {
                  return record.id;
                }}
              />
            </Col>
          </LoadingIndicator>
        </Col>
      </Row>
    </Col>
  );
};
export default VolunteerSideTable;

const ActionComponent = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = props;
  const acceptUserMethod = (UserId) => {
    let acceptUserData = {};
    acceptUserData.id = UserId;
    acceptUserData.verified_family = true;
    dispatch(
      updateVerifiedByVolunteerAction(acceptUserData, successAcceptUserMethod)
    );
  };
  const successAcceptUserMethod = () => {
    setTimeout(() => {
      message.success(t('content.300'));
    }, 1000);
    dispatch(getFamiliesByNativeForVolunterAction());
  };

  return (
    <Row justify='space-around'>
      <Col span={10}>
        <Button
          type='primary'
          className='acceptButton'
          onClick={() => acceptUserMethod(id)}
        >
          {t('content.156')}
        </Button>
      </Col>
    </Row>
  );
};
