import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTicketByIdAction } from '../../Actions/ticketAction';
import {
  Row,
  Col,
  Typography,
  Avatar,
  Input,
  Drawer,
  Upload,
  Spin,
} from 'antd';
import '../../FindThePeoplePage/FindOtherPeople.css';
import './HelpAndSupport.css';
import {
  PictureOutlined,
  SendOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { createMessageAction } from '../../Actions/messageAction';
import _ from 'lodash';
import moment from 'moment';
import DisplayImageModel from './DisplayImageModel';
const ChatDrawer = (props) => {
  const dispatch = useDispatch();
  const {
    isChatDrawerVisible,
    createTicketSuccessData,
    setIsChatDrawerVisible,
    setTicketByIdState,
    ticketByIdState,
    backgroundColor,
  } = props;
  const { ticket, currentUser, isCreateMessageLoading } = useSelector(
    (state) => {
      const { ticket } = state.tickets;
      const { currentUser } = state.authentication;
      const { isCreateMessageLoading } = state.message;
      return {
        ticket,
        currentUser,
        isCreateMessageLoading,
      };
    }
  );
  useEffect(() => {
    setTicketByIdState(ticket);
  }, [ticket, setTicketByIdState, dispatch]);

  const { messages } = ticketByIdState || {};
  const [inputData, setInputData] = useState('');
  const [newFiles, setNewFiles] = useState([]);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [isDisplayImageVisible, setIsDisplayImageVisible] = useState(false);
  const [selectedImagesState, setSelectedImagesState] = useState([]);
  const closeChatDrawerMethod = () => {
    setIsChatDrawerVisible(false);
    setTicketByIdState({});
    setShowUploadImage(false);
  };
  let data = {};
  const createMessageMethod = () => {
    data.ticket_id = createTicketSuccessData?.id
      ? createTicketSuccessData?.id
      : ticketByIdState?.id;
    data.description = inputData;
    data.files = newFiles;
    dispatch(
      createMessageAction(
        data,
        createMessageSuccessMethod,
        createMessageFailureMethod,
        false
      )
    );
  };
  const createMessageSuccessMethod = (SuccessResponse) => {
    dispatch(
      getTicketByIdAction(
        createTicketSuccessData?.id
          ? createTicketSuccessData?.id
          : SuccessResponse?.ticket?.id,
        getTicketByIdSuccessMethod
      )
    );
    setInputData('');
    setShowUploadImage(false);
  };
  const createMessageFailureMethod = () => {};
  const getTicketByIdSuccessMethod = (SuccessResponse) => {
    setTicketByIdState(SuccessResponse);
  };

  document.getElementById('linkToDiv')?.scrollIntoView();

  const propsForUpload = {
    action: createMessageMethod,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles(...new file());
      return false;
    },
    multiple: false,
  };
  const handleChangeUploadMethod = (e) => {
    setNewFiles(e.fileList);
    setShowUploadImage(true);
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: '#4593EF',
      }}
    />
  );
  const handleOpenDisplayImageMethod = (Images) => {
    setIsDisplayImageVisible(true);
    const selectedImages = Images?.map((val, i) => {
      return {
        original: val.url,
      };
    });
    setSelectedImagesState(selectedImages);
  };
  return (
    <Col>
      <Drawer
        open={isChatDrawerVisible}
        title={
          <Row>
            <Col>
              <Typography className='IdTextChatDrawer'>
                {createTicketSuccessData
                  ? createTicketSuccessData?.ticket_number
                  : ticketByIdState?.ticket_number}
              </Typography>
            </Col>
            <Col>
              <Col
                className={
                  (createTicketSuccessData
                    ? createTicketSuccessData?.ticket_status
                    : ticketByIdState?.ticket_status) === 'Closed'
                    ? 'ClosedStatus'
                    : 'OpenStatus'
                }
              >
                {createTicketSuccessData
                  ? createTicketSuccessData?.ticket_status
                  : ticketByIdState?.ticket_status}
              </Col>
            </Col>
          </Row>
        }
        width={600}
        onClose={closeChatDrawerMethod}
        maskClassName='DrawerClass'
        className='DrawerBorderClass'
      >
        <Row gutter={[0, 39]} style={{ marginBottom: '50px' }}>
          {messages &&
            messages?.length > 0 &&
            messages?.map((val, i) => {
              const { attachable_records } = val;
              const filteredRecords = _.filter(
                attachable_records,
                (records) => {
                  return !_.endsWith(records.url, 'doc');
                }
              );
              const recordsWithoutPdfAndDoc = _.filter(
                filteredRecords,
                (records) => {
                  return !_.endsWith(records.url, 'pdf');
                }
              );
              return val?.user?.id === currentUser?.id ? (
                <Col span={24} key={i} id={i}>
                  <Row gutter={[4, 0]}>
                    <Col span={2} />
                    <Col
                      span={20}
                      style={{ display: 'flex', flexDirection: 'row-reverse' }}
                    >

                      <Col className='UserChatBox'>
                        <Typography className='UserChatBoxText'>
                          {val?.description}{' '}
                          {recordsWithoutPdfAndDoc.length > 0 &&
                            recordsWithoutPdfAndDoc.map((val, i) => {
                              return (
                                <Avatar
                                  src={val?.url}
                                  size={40}
                                  style={{
                                    margin: '0px 10px',
                                    cursor: 'pointer',
                                  }}
                                  shape='square'
                                  key={i}
                                  onClick={() =>
                                    handleOpenDisplayImageMethod(
                                      recordsWithoutPdfAndDoc
                                    )
                                  }
                                />
                              );
                            })}
                        </Typography>
                      </Col>
                    </Col>
                    <Col span={2}>
                      <Row
                        align='bottom'
                        justify='end'
                        style={{ height: '100%' }}
                      >
                        <Avatar
                          size={37}
                          src={ticketByIdState?.ticket_creator?.image}
                          style={{
                            cursor: 'pointer',
                            backgroundColor:
                              !ticketByIdState?.ticket_creator?.image &&
                              backgroundColor,
                          }}
                        >
                          {ticketByIdState?.ticket_creator?.first_name[0]}
                        </Avatar>
                      </Row>
                    </Col>
                    <Col span={22} style={{ marginTop: '7px' }}>
                      <Row justify='end'>
                        <Typography className='DateAndTime'>
                          {moment(val.created_at).format('dddd LT')}
                        </Typography>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              ) : (
                <Col span={24} key={i}>
                  <Row gutter={[4, 0]}>
                    <Col span={2}>
                      <Row align='bottom' style={{ height: '100%' }}>
                        <Avatar
                          size={37}
                          src={val?.user?.image}
                          style={{
                            cursor: 'pointer',
                            backgroundColor:
                              !val?.user?.image && backgroundColor,
                          }}
                        >
                          {val?.user?.first_name[0]}
                        </Avatar>
                      </Row>
                    </Col>
                    <Col span={20}>

                      <Col className='AdminChatBox'>

                        <Typography className='AdminChatBoxText'>
                          {val?.description}
                          {recordsWithoutPdfAndDoc?.length > 0 &&
                            recordsWithoutPdfAndDoc?.map((val, i) => {
                              return (
                                <Avatar
                                  src={val?.url}
                                  size={40}
                                  style={{
                                    margin: '0px 10px',
                                    cursor: 'pointer',
                                  }}
                                  shape='square'
                                  key={i}
                                  onClick={() =>
                                    handleOpenDisplayImageMethod(
                                      recordsWithoutPdfAndDoc
                                    )
                                  }
                                />
                              );
                            })}
                        </Typography>
                      </Col>
                    </Col>
                    <Col span={2} />
                    <Col span={24} style={{ marginTop: '7px' }}>
                      <Row>
                        <Col span={2} />
                        <Typography className='DateAndTime'>
                          {moment(val.created_at).format('dddd LT')}
                        </Typography>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              );
            })}
        </Row>

        <Col className='ChatBoxMessager'>
          <Input
            addonBefore={
              <Upload
                {...propsForUpload}
                listType='picture-card'
                maxCount={1}
                onChange={(e) => handleChangeUploadMethod(e)}
                showUploadList={showUploadImage}
                isImageUrl={(image) => {
                  return <img src={image?.thumbUrl} width={40} alt='' />;
                }}
                className='helpAndSupportUpload'
                accept='image/png'
              >
                <PictureOutlined style={{ color: '#4593EF' }} />
              </Upload>
            }
            addonAfter={
              isCreateMessageLoading ? (
                <Spin indicator={antIcon} />
              ) : (
                <SendOutlined
                  style={{
                    color: '#4593EF',
                    pointerEvents: inputData.toString().length < 1 && 'none',
                  }}
                  onClick={() => createMessageMethod()}
                />
              )
            }
            className='emailInput'
            placeholder='Start a New Message'
            size='large'
            onChange={(e) => setInputData(e.target.value)}
            value={inputData}
          />

        </Col>
        <Col id='linkToDiv' />
        <DisplayImageModel
          isDisplayImageVisible={isDisplayImageVisible}
          setIsDisplayImageVisible={setIsDisplayImageVisible}
          selectedImagesState={selectedImagesState}
        />
      </Drawer>
    </Col>
  );
};
export default ChatDrawer;
