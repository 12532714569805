import React from 'react';
import { Row, Col, Typography, Avatar, Card } from 'antd';
import './CommunityFeeds.css';
import { ArrowLeftOutlined } from '@ant-design/icons';
import '../../FindThePeoplePage/FindOtherPeople.css';
import LicImage from '../../assets/LicImage.svg';

const SelectPaymentMethod = (props) => {
  const { setStepDeals, stepDeals } = props;

  return (
    <Col>
      <Row justify='space-around'>
        <Col span={23}>
          <Row gutter={[0, 21]}>
            <Col span={24} />
            <Col span={24} />
          </Row>
        </Col>
        <Col span={23}>
          <ArrowLeftOutlined
            style={{ fontSize: '20px' }}
            onClick={() => setStepDeals(stepDeals - 1)}
          />
        </Col>
        <Col span={23}>
          <Row gutter={[0, 21]}>
            <Col span={24} />
            <Col span={24} />
          </Row>
        </Col>
        <Col span={23}>
          <Row justify='center'>
            <Card className='SelectPaymentCard'>
              <Col style={{ background: '#4593EF' }}>
                <Row gutter={[15, 20]}>
                  <Col span={24} />
                  <Col span={2} />
                  <Col span={8}>
                    <Avatar src={LicImage} size={90} />
                  </Col>
                  <Col span={12}>
                    <Row gutter={[0, 18]}>
                      <Col span={24}>
                        <Typography className='SelectPaymentTitle'>
                          LIC
                        </Typography>
                      </Col>
                      <Col span={24}>
                        <Typography className='SelectPaymentAmount'>
                          ₹ 10,000.00
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24} />
                </Row>
              </Col>
              <Row style={{ marginTop: '39px' }}>
                <Col>
                  <Typography className='SelectPaymentTitle1'>
                    Select Payment Method
                  </Typography>
                </Col>
              </Row>
            </Card>
          </Row>
        </Col>
        <Col span={23}>
          <Row gutter={[0, 21]}>
            <Col span={24} />
            <Col span={24} />
          </Row>
        </Col>
      </Row>
    </Col>
  );
};
export default SelectPaymentMethod;
