import constants from '../Utils/constants';
const initialState = {
  isCreateFamilyMemberLoading: false,
  isDeleteFamilyMemberLoading: false,
  isUpdateFamilyMemberLoading: false,
  isOwnerLoading: false,
  isUpdateDeletedFamilyMemberLoading: false,
  isGetFamilyMemberByIdLoading: false,
  memberData: {},
};
export const familyMemberReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_FAMILY_MEMBER_REQUEST:
      return {
        ...state,
        isCreateFamilyMemberLoading: true,
      };
    case constants.CREATE_FAMILY_MEMBER_SUCCESS:
      return {
        ...state,
        isCreateFamilyMemberLoading: false,
      };
    case constants.CREATE_FAMILY_MEMBER_FAILURE:
      return {
        ...state,
        isCreateFamilyMemberLoading: false,
      };
    case constants.GET_FAMILY_MEMBER_BY_ID_REQUEST:
      return {
        ...state,
        isGetFamilyMemberByIdLoading: true,
      };
    case constants.GET_FAMILY_MEMBER_BY_ID_SUCCESS:
      return {
        ...state,
        isGetFamilyMemberByIdLoading: false,
        memberData: action.payload,
      };
    case constants.GET_FAMILY_MEMBER_BY_ID_FAILURE:
      return {
        ...state,
        isGetFamilyMemberByIdLoading: false,
      };
    case constants.DELETE_FAMILY_MEMBER_REQUEST:
      return {
        ...state,
        isDeleteFamilyMemberLoading: true,
      };
    case constants.DELETE_FAMILY_MEMBER_SUCCESS:
      return {
        ...state,
        isDeleteFamilyMemberLoading: false,
      };
    case constants.DELETE_FAMILY_MEMBER_FAILURE:
      return {
        ...state,
        isDeleteFamilyMemberLoading: false,
      };
    case constants.UPDATE_FAMILY_MEMBER_REQUEST:
      return {
        ...state,
        isUpdateFamilyMemberLoading: true,
      };
    case constants.UPDATE_FAMILY_MEMBER_SUCCESS:
      return {
        ...state,
        isUpdateFamilyMemberLoading: false,
      };
    case constants.UPDATE_FAMILY_MEMBER_FAILURE:
      return {
        ...state,
        isUpdateFamilyMemberLoading: false,
      };
    case constants.UPDATE_OWNERSHIP_REQUEST:
      return {
        ...state,
        isOwnerLoading: true,
      };
    case constants.UPDATE_OWNERSHIP_SUCCESS:
      return {
        ...state,
        isOwnerLoading: false,
      };
    case constants.UPDATE_OWNERSHIP_FAILURE:
      return {
        ...state,
        isOwnerLoading: false,
      };
    case constants.UPDATE_DELETED_FAMILY_MEMBER_REQUEST:
      return {
        ...state,
        isUpdateDeletedFamilyMemberLoading: true,
      };
    case constants.UPDATE_DELETED_FAMILY_MEMBER_SUCCESS:
      return {
        ...state,
        isUpdateDeletedFamilyMemberLoading: false,
      };
    case constants.UPDATE_DELETED_FAMILY_MEMBER_FAILURE:
      return {
        ...state,
        isUpdateDeletedFamilyMemberLoading: false,
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
