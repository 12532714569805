import API from "../Utils/API";
export const getCommunitiesNative = (Id , SuccessCallback) => (dispatch) => {
  dispatch({ type: "GET_COMMUNITIES_NATIVE_REQUEST" });
  API.get("api/community_natives")
    .then((response) => {
      dispatch({
        type: "GET_COMMUNITIES_NATIVE_SUCCESS",
        payload: response.data,
      });
      SuccessCallback && SuccessCallback()
    })
    .catch((error) => {
      dispatch({
        type: "GET_COMMUNITIES_NATIVE_FAILURE",
        payload: error.response.data,
      });
    });
};

export const createCommunitiesNative =
  (data, successCallback) => (dispatch) => {
    dispatch({ type: "CREATE_COMMUNITIES_NATIVE_REQUEST", payload: data });
    API.post("api/community_natives", data)

      .then((response) => {
        dispatch({
          type: "CREATE_COMMUNITIES_NATIVE_SUCCESS",
          payload: response.data,
        });
        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_COMMUNITIES_NATIVE_FAILURE",
          payload: error.response.data,
        });
      });
  };

export const updateCommunitiesNativeAction =
  (data, successCallback) => (dispatch) => {
    dispatch({ type: "UPDATE_COMMUNITIES_NATIVE_REQUEST" });
    API.put(`api/community_natives/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: "UPDATE_COMMUNITIES_NATIVE_SUCCESS",
          payload: response.data,
        });
        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_COMMUNITIES_NATIVE_FAILURE",
          payload: error.response.data,
        });
      });
  };
  export const getCommunityNativeBeforeSignup = (Id) => (dispatch) => {
    dispatch({ type: "GET_COMMUNITIES_NATIVE_BEFORE_SIGNUP_BY_ID_REQUEST" });
    API.get(`api/native_with_community/${Id}`)
      .then((response) => {
        dispatch({
          type: "GET_COMMUNITIES_NATIVE_BEFORE_SIGNUP_BY_ID_SUCCESS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_COMMUNITIES_NATIVE_BEFORE_SIGNUP_BY_ID_FAILURE",
          payload: error.response.data,
        });
      });
  }