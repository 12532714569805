import React, { useState } from 'react';
import { Button, Row, Col, Drawer, Image, Typography } from 'antd';
import './LandingPage.css';
import { ArrowRightOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { history } from '../../Utils/history';
import SamajIcon from '../../assets/SamajIcon.png';
import { useBreakpoints } from '../CommonLogics/ResponsiveBreakPoints';
import AppBanner from 'smart-app-banner-react';
import _ from 'lodash';
import SamajIconSvg from '../../assets/SamajIconSvg.svg';
import playStoreIcon from '../../assets/playStoreIcon.svg';
import crossButton from '../../assets/crossButton.png';
import AppStoreIcon from '../../assets/AppStoreIcon.png';
import samajCircleIcon from '../../assets/samajCircleIcon.svg';

const LandingPageHeader = () => {
  const { IsMD, IsSM, IsXS } = useBreakpoints();

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const handleOpenDrawerMethod = () => {
    setIsDrawerVisible(true);
  };
  const redirectMethod = (url) => {
    history.push(url);
    setIsDrawerVisible(false);
  };
  const [isBannerVisible, setBannerVisible] = useState(true);
  const handleAppBannerCloseButton = (event) => {
    event.stopPropagation();
    setBannerVisible(false);
  };

  const ismac = /iPhone|iPod|iPad|BlackBerry/i.test(navigator.userAgent);
  const handleApplicationLink = () => {
    if (ismac) {
      window.location.href =
        'https://apps.apple.com/us/app/samaj-app/id1640436240?platform=iphone';
    } else {
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.samaj.app';
    }
  };
  const is_signup = true;
  return (
    <Col className='header' background='black'>
      <Row
        justify='space-between'
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '940px',
        }}
      >
        {IsXS && isBannerVisible && (
          <Row className='bannerRow' justify='center'>
            <Col className='bannerMainCol'>
              <Row
                className='bannerInnerRow'
                justify='space-between'
                align='middle'
                onClick={() => handleApplicationLink()}
              >
                <Col xs={3} sm={4}>
                  <Image src={samajCircleIcon} className='AppBannerImage' />
                </Col>
                <Col xs={18}>
                  <Typography className='bannerHeading'>SAMAJ</Typography>
                  <Typography className='bannerDescription'>
                    Download our SAMAJ mobile application.
                  </Typography>
                </Col>
                <Col xs={3}>
                  <Image
                    src={ismac ? AppStoreIcon : playStoreIcon}
                    className='AppBannerImage'
                  />
                </Col>
                <Col
                  className='AppBannerCloseButton'
                  onClick={handleAppBannerCloseButton}
                >
                  <Image src={crossButton} className='CrossButton' />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <Col>
          <Col
            style={{
              width: '60px',
              height: '60px',
              background: '#FFFFFF',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '24px',
            }}
            onClick={() => history.push('/')}
          >
            <img
              src={SamajIcon}
              style={{ width: '48px', height: '48px', cursor: 'pointer' }}
              alt=''
            />
          </Col>
        </Col>
        {!(IsMD || IsSM || IsXS) ? (
          <>
            <Col xl={9} md={10} lg={10}>
              <Row gutter={[21, 0]}>
                <Col className='aboutTitle' onClick={() => history.push('/')}>
                  Home
                </Col>
                <Col
                  className='aboutTitle'
                  onClick={() => history.push('/blogs')}
                >
                  Blogs
                </Col>
                <Col
                  className='aboutTitle'
                  onClick={() => history.push('/about_us')}
                >
                  About
                </Col>
                <Col>
                  <a
                    className='aboutTitle'
                    href='#features'
                    onClick={() => history.push('/')}
                  >
                    Features
                  </a>
                </Col>
                <Col>
                  <a
                    className='aboutTitle'
                    href='#contactus'
                    onClick={() => history.push('/')}
                  >
                    Contact
                  </a>
                </Col>
              </Row>
            </Col>

            <Col>
              <Row gutter={[45, 0]}>
                <Col
                  className='loginButton'
                  onClick={() => history.push('/login')}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  Login
                </Col>
                <Col>
                  <Button
                    className='becomeAMemberButton'
                    icon={<ArrowRightOutlined />}
                    onClick={() => history.push('/login', { is_signup })}
                  >
                    Become a member
                  </Button>
                </Col>
              </Row>
            </Col>
          </>
        ) : (
          <Col>
            <MenuFoldOutlined onClick={() => handleOpenDrawerMethod()} />
            <Drawer
              open={isDrawerVisible}
              onClose={() => setIsDrawerVisible(false)}
              placement='top'
              className='LandingPageHeader DrawerBorderClass'
            >
              <Row justify='center' gutter={[0, 5]}>
                <Col
                  span={24}
                  className='hamBurgerMenuSubTitle'
                  onClick={() => redirectMethod('/')}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  Home
                </Col>
                <Col
                  span={24}
                  className='hamBurgerMenuSubTitle'
                  onClick={() => redirectMethod('/blogs')}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  Blogs
                </Col>
                <Col
                  span={24}
                  className='hamBurgerMenuSubTitle'
                  onClick={() => redirectMethod('/about_us')}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  About
                </Col>
                <Col
                  span={24}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <a
                    className='hamBurgerMenuSubTitle'
                    href='#features'
                    onClick={() => redirectMethod('/')}
                  >
                    Features
                  </a>
                </Col>
                <Col
                  span={24}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <a
                    className='hamBurgerMenuSubTitle'
                    href='#contactSection'
                    onClick={() => redirectMethod('/')}
                  >
                    Contact
                  </a>
                </Col>
              </Row>
            </Drawer>
          </Col>
        )}
      </Row>
    </Col>
  );
};

export default LandingPageHeader;
