import { Row, Col, Button } from 'antd';
import React, { useState } from 'react';
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";
// import JobProfileModalForm from "./JobProfileModalForm";
import JobProfileDetails from './JobProfileDetails';
import ViewJobProfile from './ViewJobProfile';
import { useTranslation } from 'react-i18next';
import { analytics } from '../../components/Mixpanel/Mixpanel';

// const schema = yup.object().shape({});
const JobProfile = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [viewBusinessProfileId, setViewBusinessProfileId] = useState('');
  const [editId, setEditId] = useState('');
  // const [selectedUserId,setSelectedUserId] = useState("");

  // const {} = useForm({
  //   resolver: yupResolver(schema),
  //   mode: "onSubmit",
  // });

  // const handleUserIdChangeMethod = (val) => {
  //     // setSelectedUserId(val)

  // }
  return (
    <Col className='div'>
      <Row justify='end' style={{ padding: '10px 20px' }}>
        <Col span={24}></Col>
        <Col>
          {!viewBusinessProfileId && (
            <Button
              type='primary'
              onClick={() => {
                analytics?.track('AddJobProfile');
                setVisible(true);
              }}
              className='button-matrimonialProfile'
            >
              {t('btn.34')}
            </Button>
          )}
          {/* <JobProfileModalForm
            visible={visible}
            setVisible={setVisible}
            editId={editId}
            setEditId={setEditId}
            // handleCancel={handleCancel}
            // setSelectedUserId={setSelectedUserId}
            // selectedUserId={selectedUserId}
            handleUserIdChangeMethod={handleUserIdChangeMethod}
       /> */}
        </Col>
      </Row>
      <Row style={{ padding: 20 }}>
        <Col span={24}>
          {viewBusinessProfileId ? (
            <JobProfileDetails
              editId={editId}
              setEditId={setEditId}
              visible={visible}
              setVisible={setVisible}
              viewBusinessProfileId={viewBusinessProfileId}
              setViewBusinessProfileId={setViewBusinessProfileId}
            />
          ) : (
            <ViewJobProfile
              editId={editId}
              setEditId={setEditId}
              visible={visible}
              setVisible={setVisible}
              viewBusinessProfileId={viewBusinessProfileId}
              setViewBusinessProfileId={setViewBusinessProfileId}
            />
          )}
        </Col>
      </Row>
    </Col>
  );
};
export default JobProfile;
