import constants from "../Utils/constants";
const initialState = {
  isFamiliesByNativeForVolunteerLoading: false,
  allFamiliesByNativeForVolunteer: [],
};

export const FamiliesByNativeForVolunteerReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case constants.GET_FAMILIES_BY_NATIVE_FOR_VOLUNTEER_REQUEST:
      return {
        ...state,
        isFamiliesByNativeForVolunteerLoading: true,
        // allFamiliesByNativeForVolunteer: [],
      };

    case constants.GET_FAMILIES_BY_NATIVE_FOR_VOLUNTEER_SUCCESS:
      return {
        ...state,
        isFamiliesByNativeForVolunteerLoading: false,
        allFamiliesByNativeForVolunteer: action.payload,
      };
    case constants.GET_FAMILIES_BY_NATIVE_FOR_VOLUNTEER_FAILURE:
      return {
        ...state,
        isFamiliesByNativeForVolunteerLoading: false,
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
