import React from 'react';
import { Row, Col, Typography, Card } from 'antd';
import './CommunityFeeds.css';
import moment from 'moment/moment';
import { newImageUrl } from '../CommonLogics/CommonMethods';
import _ from 'lodash';

const CommunityImageCard = (props) => {
  const { mappedData, isCommunityCardModal, imgUrl } = props;
  const mappedDataDate = moment(mappedData?.created_at).format('MMM Do YYYY');
  const date = new Date(mappedData?.expiry_date);
  const typedDataDate = moment(date).format('MMM Do YYYY');

  return (
    <Card className='communityCard'>
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Row justify='space-between'>
                <Col>
                  <Typography className='CommunityTextCardTitle'>
                    {mappedData?.name}
                  </Typography>
                </Col>
                <Col>
                  <Typography className='communityCardDate'>
                    {isCommunityCardModal ? typedDataDate : mappedDataDate}
                  </Typography>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Typography className='communityCardDescription'>
                {mappedData?.description}
              </Typography>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <img
            src={
              _.startsWith(imgUrl, 'data')
                ? imgUrl
                : newImageUrl(imgUrl ? imgUrl : mappedData?.card_file_key)
            }
            style={{
              width: '100%',
              height: '190px',
              borderRadius: '4px',
            }}
            alt=''
          />
        </Col>
      </Row>
    </Card>
  );
};
export default CommunityImageCard;
