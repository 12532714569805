import React from 'react';
import { Row, Col, Typography, Card } from 'antd';
import './CommunityFeeds.css';
import moment from 'moment/moment';

const CommunityLinkCard = (props) => {
  const { mappedData, isCommunityCardModal } = props;
  const mappedDataDate = moment(mappedData?.created_at).format('MMM Do YYYY');
  const date = new Date(mappedData?.expiry_date);
  const typedDataDate = moment(date).format('MMM Do YYYY');
  return (
    <a href={mappedData?.url} target='blank'>
      <Col>

        <Card className='communityCard'>
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Row gutter={[0, 20]}>
                <Col span={24}>
                  <Row justify='space-between'>
                    <Col>
                      <Typography className='CommunityTextCardTitle'>
                        {mappedData?.name}
                      </Typography>
                    </Col>
                    <Col>
                      <Typography className='communityCardDate'>
                        {isCommunityCardModal ? typedDataDate : mappedDataDate}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Typography className='communityCardDescription'>
                {mappedData?.description}
              </Typography>
            </Col>
          </Row>
        </Card>
      </Col>
    </a>
  );
};
export default CommunityLinkCard;
