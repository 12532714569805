import API from '../Utils/API';
export const getJourney = (url) => (dispatch) => {
  dispatch({ type: 'GET_JOURNEY_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({ type: 'GET_JOURNEY_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_JOURNEY_FAILURE',
        payload: error.response.data,
      });
    });
};
export const getJourneyById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_JOURNEY_BY_ID_REQUEST' });
  API.get(`api/journeys/${Id}`)
    .then((response) => {
      dispatch({ type: 'GET_JOURNEY_BY_ID_SUCCESS', payload: response?.data });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_JOURNEY_BY_ID_FAILURE',
        payload: error?.response?.data,
      });
    });
};
