import React, { useEffect } from 'react';
import { Row, Col, Typography } from 'antd';
import LandingPageHeader from './LandingPageHeader';
import './LandingPage.css';
import '../TermsAndConditions/TermsAndConditions.css';
import './Faqs.css';
import Footer from './Footer';
const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.querySelector('body').scrollTo(0, 0);
  }, []);
  return (
    <Col className='MainbackgroundColor'>
      <Col className='TermsAndConditionsImageContainer'>
        <LandingPageHeader />
        <Row style={{ height: '100%' }} justify='center' align='middle'>
          <Typography className='TermsAndConditionsH1'>
            Privacy Policy
          </Typography>
        </Row>
      </Col>
      <Col
        style={{
          background: '#FFFFFF',
          borderRadius: '50px 50px 0 0',
          marginTop: '100px',
        }}
      >
        <Row justify='center'>
          <Col className='ContentContainer'>
            <Row gutter={[0, 30]}>
              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  <b>Privacy Policy for Samaj</b>
                </Typography>
              </Col>

              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  At Samaj, accessible from getsamaj.com, one of our main
                  priorities is the privacy of our visitors. This Privacy Policy
                  document contains types of information that is collected and
                  recorded by Samaj and how we use it. If you have additional
                  questions or require more information about our Privacy
                  Policy, do not hesitate to contact us.
                </Typography>
              </Col>

              {/* <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  If you have additional questions or require more information
                  about our Privacy Policy, do not hesitate to contact us.
                </Typography>
              </Col> */}

              <Col span={24}>
                <Typography className='TermsAndConditionsContentTitle1'>
                  <b>Log Files</b>
                </Typography>
              </Col>

              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  Samaj follows a standard procedure of using log files. These
                  files log visitors when they visit websites. All hosting
                  companies do this and a part of hosting services' analytics.
                  The information collected by log files include internet
                  protocol (IP) addresses, browser type, Internet Service
                  Provider (ISP), date and time stamp, referring/exit pages, and
                  possibly the number of clicks. These are not linked to any
                  information that is personally identifiable. The purpose of
                  the information is for analyzing trends, administering the
                  site, tracking users' movement on the website, and gathering
                  demographic information. Our Privacy Policy was created with
                  the help of the Privacy Policy Generator.
                </Typography>
              </Col>

              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  <b>Privacy Policies</b>
                </Typography>
              </Col>

              <Col span={24}>
                <Typography className='TermsAndConditionsContentTitle1'>
                  You may consult this list to find the Privacy Policy for each
                  of the advertising partners of Samaj.
                </Typography>
              </Col>

              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  Third-party ad servers or ad networks uses technologies like
                  cookies, JavaScript, or Web Beacons that are used in their
                  respective advertisements and links that appear on Samaj,
                  which are sent directly to users' browser. They automatically
                  receive your IP address when this occurs. These technologies
                  are used to measure the effectiveness of their advertising
                  campaigns and/or to personalize the advertising content that
                  you see on websites that you visit.
                </Typography>
              </Col>

              <Col span={24}>
                <Typography className='TermsAndConditionsContentTitle1'>
                  Note that Samaj has no access to or control over these cookies
                  that are used by third- party advertisers.
                </Typography>
              </Col>

              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  <b>Third Party Privacy Policies</b>
                </Typography>
              </Col>

              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  Samaj's Privacy Policy does not apply to other advertisers or
                  websites. Thus, we are advising you to consult the respective
                  Privacy Policies of these third-party ad servers for more
                  detailed information. It may include their practices and
                  instructions about how to opt-out of certain options.
                </Typography>
              </Col>

              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  You can choose to disable cookies through your individual
                  browser options. To know more detailed information about
                  cookie management with specific web browsers, it can be found
                  at the browsers' respective websites. What Are Cookies?
                </Typography>
              </Col>

              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  <b>Children's Information</b>
                </Typography>
              </Col>

              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  Another part of our priority is adding protection for children
                  while using the internet. We encourage parents and guardians
                  to observe, participate in, and/or monitor and guide their
                  online activity.
                </Typography>
              </Col>

              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  Samaj does not knowingly collect any Personal Identifiable
                  Information from children under the age of 13. If you think
                  that your child provided this kind of information on our
                  website, we strongly encourage you to contact us immediately
                  and we will do our best efforts to promptly remove such
                  information from our records.
                </Typography>
              </Col>

              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  <b>Online Privacy Policy Only</b>
                </Typography>
              </Col>

              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  This Privacy Policy applies only to our online activities and
                  is valid for visitors to our website with regards to the
                  information that they shared and/or collect in Samaj. This
                  policy is not applicable to any information collected offline
                  or via channels other than this website.
                </Typography>
              </Col>

              <Col>
                <Typography className='TermsAndConditionsContentTitle1'>
                  <b>Consent</b>
                </Typography>
              </Col>

              <Col span={24}>
                <Typography className='TermsAndConditionsContentTitle1'>
                  By using our website, you hereby consent to our Privacy Policy
                  and agree to its Terms and Conditions.
                </Typography>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify='center'>
          <Col>
            <Footer />
          </Col>
        </Row>
      </Col>
    </Col>
  );
};
export default PrivacyPolicy;
