import React, { useEffect, useState } from "react";
import { Row, Col, Button, Popconfirm, Table, message } from "antd";
import "../../FindThePeoplePage/FindOtherPeople.css";
import "./Admin.css";

import { useTranslation } from "react-i18next";
import _ from "lodash";
import { CountryViseFormat } from "../CommonLogics/CommonMethods";
import { useDispatch, useSelector } from "react-redux";
import { getSearchUser } from "../../Actions/searchUserAction";
import "react-phone-input-international/lib/style.css";
import TrusteeModal from "../TrusteeView/trusteeModal";
import { getTrustees, deleteTrustee } from "../../Actions/trusteesAction";
import { analytics } from "../../components/Mixpanel/Mixpanel";
const ManageTrustee = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    allTrustees,
    isCreateTrusteesLoading,
    isUpdateTrusteesLoading,
    currentUser,
    community,
  } = useSelector((state) => {
    const {
      allTrustees,
      isGetTrusteesLoading,
      isCreateTrusteesLoading,
      isUpdateTrusteesLoading,
    } = state.trustees;
    const { setCommunityId } = state.communities;
    return {
      allTrustees,
      isGetTrusteesLoading,
      isCreateTrusteesLoading,
      isUpdateTrusteesLoading,
      setCommunityId,
      currentUser,
      community,
    };
  });
  useEffect(() => {
    dispatch(getTrustees());
  }, []);
  const [isCreateTrusteeModalVisible, setIsCreateTrusteeModalVisible] =
    useState(false);
  const [isUpdateTrusteeModalVisible, setIsUpdateTrusteeModalVisible] =
    useState(false);
  const trustee = _.map(allTrustees, _.property("user"));

  const [editTrusteeId, setEditTrusteeId] = useState("");

  const columns = [
    {
      title: "Name",
      dataIndex: "id",
      render: (id, AllTrustees) => {
        return AllTrustees?.user.first_name + " " + AllTrustees?.user.last_name;
      },
    },
    {
      title: "Designation",
      dataIndex: "id",
      render: (id, AllTrustees) => {
        return AllTrustees?.designation.designation_title;
      },
    },
    {
      title: t("content.17"),
      dataIndex: "id",

      render: (id, AllTrustees) => {
        const IsEmpty =
          AllTrustees?.user?.phone_with_country_code === "null"
            ? true
            : AllTrustees?.user?.phone_with_country_code === "undefined"
            ? true
            : _.isEmpty(AllTrustees?.user?.phone_with_country_code);
        return (
          IsEmpty === false &&
          CountryViseFormat(AllTrustees?.user?.phone_with_country_code)
        );
      },
    },
    {
      title: "From",
      dataIndex: "from_year",
    },
    {
      title: "To",
      dataIndex: "to_year",
    },
    {
      dataIndex: "id",
      render: (id, AllTrustees) => (
        <ActionComponent
          id={id}
          setEditTrusteeId={setEditTrusteeId}
          setIsUpdateTrusteeModalVisible={setIsUpdateTrusteeModalVisible}
        />
      ),
    },
  ];

  return (
    <Row gutter={[0, 20]}>
      <Col span={24}>
        <Row justify="end">
          <Button
            onClick={() => {
              analytics?.track("Admin_CreateTrustee");
              setIsCreateTrusteeModalVisible(true);
            }}
            type="primary"
            style={{ borderRadius: "4px" }}
            className="button-search"
          >
            {t("btn.16")}
          </Button>
          <TrusteeModal
            isCreateTrusteeModalVisible={isCreateTrusteeModalVisible}
            setIsCreateTrusteeModalVisible={setIsCreateTrusteeModalVisible}
            isCreateTrusteesLoading={isCreateTrusteesLoading}
            trustee={trustee}
            isUpdateTrusteeModalVisible={isUpdateTrusteeModalVisible}
            setIsUpdateTrusteeModalVisible={setIsUpdateTrusteeModalVisible}
            trusteeArray={allTrustees}
            editTrusteeId={editTrusteeId}
            isUpdateTrusteesLoading={isUpdateTrusteesLoading}
            setEditTrusteeId={setEditTrusteeId}
          />
        </Row>
      </Col>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={allTrustees}
          className="table"
          size="middle"
          rowKey={(record) => {
            return record.id;
          }}
        />
      </Col>
    </Row>
  );
};
export default ManageTrustee;

const ActionComponent = (props) => {
  const { id, setEditTrusteeId, setIsUpdateTrusteeModalVisible } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleEditTrusteeMethod = (Id) => {
    setEditTrusteeId(Id);
    setIsUpdateTrusteeModalVisible(true);
  };
  const handleDeleteTrustee = (trusteeId) => {
    dispatch(deleteTrustee(trusteeId, successDeleteTrustee));
  };
  const userType = JSON.stringify('["regular","admin"]');
  const successDeleteTrustee = () => {
    dispatch(getTrustees());
    setTimeout(() => {
      message.success(t("content.147"));
    }, 1000);
    dispatch(getSearchUser(`api/user_search?type=${userType}&verified=true`));
  };
  return (
    <Row gutter={[20, 0]}>
      <Col>
        <Button
          onClick={() => {
            analytics?.track("Admin_ManageTrustee_Edit");
            handleEditTrusteeMethod(id);
          }}
          type="primary"
          className="editDesignationButton"
        >
          {t("btn.27")}
        </Button>
      </Col>
      <Col>
        <Popconfirm
          onConfirm={() => {
            analytics?.track("Admin_ManageTrustee_Delete");
            handleDeleteTrustee(id);
          }}
          okText={t("btn.28")}
          title={t("btn.29")}
          placement="topRight"
          cancelText={t("btn.19")}
        >
          <Button type="primary" className="unPublishButton">
            Delete
          </Button>
        </Popconfirm>
      </Col>
    </Row>
  );
};
