import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Typography,
  Divider,
  Button,
  Empty,
  Tooltip,
  message,
  Popconfirm,
} from 'antd';
import '../FindOtherPeople.css';
import { useDispatch, useSelector } from 'react-redux';
import { deleteBusiness, getBusiness } from '../../Actions/businessAction';
import LoadingIndicator from '../../Screens/LoadingIndicator/LoadingIndicator';
import { history } from '../../Utils/history';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import BusinessEmpty from '../../assets/BusinessEmpty.svg';
import { useTranslation } from 'react-i18next';
import { getCommunityById } from '../../Actions/communitiesAction';
import { analytics } from '../../components/Mixpanel/Mixpanel';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const ViewBusinessProfile = (props) => {
  const { t } = useTranslation();
  const {
    setEditId,
    setVisible,
    setViewBusinessProfileId,
    business_profiles,
    isPeopleDetailsVisible,
    setSelectedBusinessProfileId,
    setIsSelectedBusinessProfileVisible,
  } = props;
  const {
    allBusiness,
    isBusinessLoading,
    community,
    setCommunityId,
    currentUser,
  } = useSelector((state) => {
    const { allBusiness, isBusinessLoading } = state.business;
    const { community, setCommunityId } = state.communities;
    const { currentUser } = state.authentication;
    return {
      allBusiness,
      isBusinessLoading,
      community,
      setCommunityId,
      currentUser,
    };
  });
  const handleEdit = (val) => {
    setEditId(val);
    setVisible(true);
  };

  const handleDeleteBusinessMethod = (businessId) => {
    dispatch(deleteBusiness(businessId, successDeleteBusinessMethod));
  };
  const successDeleteBusinessMethod = () => {
    analytics?.track('BuisnessProfile_Deleted');
    setTimeout(() => {
      message.success(t('content.303'));
    }, 1000);
  };
  const dispatch = useDispatch();

  const isBusinessProfileVisibleByAdmin = community?.show_business_profile;
  const redirectMethod = () => {
    history.push('/add_family');
  };
  const localStorageCommunityId = localStorage.getItem('community_id');
  useEffect(() => {
    dispatch(getBusiness(setCommunityId));
    dispatch(
      getCommunityById(
        localStorageCommunityId ? localStorageCommunityId : setCommunityId
      )
    );
    if (
      currentUser?.verified === false
        ? true
        : isBusinessProfileVisibleByAdmin === false &&
          isPeopleDetailsVisible === false
    ) {
      redirectMethod();
    }
  }, [dispatch, isBusinessProfileVisibleByAdmin]);

  // const handleClick = (businessProfileId) => {
  //   if (isPeopleDetailsVisible) {
  //     setSelectedBusinessProfileId(businessProfileId);

  //     setIsSelectedBusinessProfileVisible(true);
  //   } else {
  //     setViewBusinessProfileId(businessProfileId);
  //   }
  // };
  const allBusinessData =
    allBusiness &&
    allBusiness.length > 0 &&
    allBusiness?.filter((o) => {
      return o.is_business === true;
    });
  const height = window.innerHeight * 0.95;
  return (
    <LoadingIndicator loading={isBusinessLoading}>
      <Col style={{ height: height, overflow: 'hidden', overflowY: 'scroll' }}>
        {isBusinessLoading ? null : allBusiness &&
          allBusinessData.length > 0 ? (
          <Row gutter={[32, 32]}>
            {isPeopleDetailsVisible
              ? business_profiles?.map((val, i) => {
                  return (
                    <Col
                      xl={12}
                      lg={17}
                      md={16}
                      sm={21}
                      xs={24}
                      key={i}
                      xxl={11}
                    >
                      <Card className='BusinessCard'>
                        <Row gutter={[0, 16]}>
                          <Col xl={20} lg={24} md={18} sm={18} xs={24}>
                            <Row gutter={[16, 16]}>
                              <Col span={24}>
                                <Typography className='profile-name'>
                                  {val?.company_name}
                                </Typography>
                              </Col>
                              <Col>
                                <Typography className='BusinessEstablishedDate'>
                                  {t('content.53')}:{' '}
                                  <Typography
                                    component='span'
                                    className='BusinessEstablishedDateData'
                                  >
                                    {val?.working_here_since}
                                  </Typography>
                                </Typography>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row gutter={[0, 16]}>
                          <Divider className='JobProfileCardDivider' />
                        </Row>
                        <Row>
                          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Typography className='profile-address'>
                              {val.business_location}
                            </Typography>
                          </Col>
                          <Col
                            xl={24}
                            lg={24}
                            md={24}
                            sm={24}
                            xs={24}
                            style={{ textAlign: 'end' }}
                            onClick={analytics?.track('Buisness_ProfileInfo')}
                          >
                            <Link
                              to={`/businessProfileDetails/${val?.id}`}
                              className=' View-fullProfile'
                              onClick={() => {
                                analytics?.track('Buisness_ProfileInfo');
                              }}
                            >
                              {t('btn.26')}
                            </Link>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  );
                })
              : allBusinessData?.map((val, i) => {
                  return (
                    <Col
                      xl={12}
                      lg={12}
                      md={18}
                      sm={20}
                      xs={24}
                      key={i}
                      xxl={11}
                    >
                      <Card className='BusinessCard'>
                        <Row gutter={[0, 4]}>
                          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Row gutter={[16, 16]} justify='space-between'>
                              <Col>
                                <Typography className='profile-name'>
                                  {val?.company_name}
                                </Typography>
                              </Col>
                              {currentUser?.id === val?.user?.id && (
                                <Col style={{ textAlign: 'end' }}>
                                  <Tooltip title={t('btn.27')}>
                                    <Button
                                      icon={<EditOutlined />}
                                      type='text'
                                      onClick={() => handleEdit(val.id)}
                                    />
                                  </Tooltip>
                                  <Tooltip title={t('btn.28')}>
                                    <Popconfirm
                                      title={t('btn.29')}
                                      onConfirm={() =>
                                        handleDeleteBusinessMethod(val.id)
                                      }
                                      okText={t('btn.28')}
                                      cancelText={t('btn.19')}
                                      placement='topLeft'
                                    >
                                      <Button
                                        icon={<DeleteOutlined />}
                                        type='text'
                                      />
                                    </Popconfirm>
                                  </Tooltip>
                                </Col>
                              )}
                            </Row>
                          </Col>

                          <Col span={24}>
                            <Typography className='BusinessEstablishedDate'>
                              {t('content.53')}:&nbsp;
                              <Typography
                                component='span'
                                className='BusinessEstablishedDateData'
                              >
                                {val?.working_here_since}
                              </Typography>
                            </Typography>
                          </Col>
                        </Row>

                        <Divider className='JobProfileCardDivider' />

                        <Row>
                          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Typography className='profile-address'>
                              {val.business_location}
                            </Typography>
                          </Col>
                          <Col
                            xl={24}
                            lg={24}
                            md={24}
                            sm={24}
                            xs={24}
                            style={{ textAlign: 'end' }}
                            onClick={analytics?.track('Buisness_ProfileInfo')}
                          >
                            <Link
                              to={`/businessProfileDetails/${val?.id}`}
                              className=' View-fullProfile'
                            >
                              {t('btn.26')}
                            </Link>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  );
                })}
          </Row>
        ) : (
          <>
            {!isPeopleDetailsVisible && !isBusinessLoading && (
              <Row justify='center' style={{ paddingTop: '150px' }}>
                <Col>
                  <Empty
                    image={BusinessEmpty}
                    imageStyle={{
                      height: 30,
                    }}
                    description={
                      <span className='empty-details'>
                        {t('emptyScreen.4')}
                      </span>
                    }
                  ></Empty>
                </Col>
              </Row>
            )}
          </>
        )}
      </Col>
    </LoadingIndicator>
  );
};
export default ViewBusinessProfile;
