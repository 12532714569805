import API from "../Utils/API";
export const getEvents = (communityId) => (dispatch) => {
  dispatch({ type: "GET_EVENTS_REQUEST" });
  API.get(`api/community_events?community_id=${communityId}`)
    .then((response) => {
      dispatch({ type: "GET_EVENTS_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: "GET_EVENTS_FAILURE", payload: error.response.data });
    });
};

export const createEvents =
  (data, successCreateEvent, IsEmpty) => (dispatch) => {
    dispatch({ type: "CREATE_EVENTS_REQUEST", payload: data });
    let formData = new FormData();
    IsEmpty
      ? formData.append("community_event_image", data.community_event_image)
      : formData.append(
          "community_event[community_event_image]",
          data.community_event_image
        );

    formData.append("community_id", data.community_id);
    formData.append("community_event[event_name]", data.event_name);
   
    formData.append("community_event[city_name]", data.city_name);
    formData.append("community_event[event_location]", data.event_location);
    formData.append("community_event[event_detail]", data.event_detail);
    formData.append("community_event[event_datetime]", data.event_datetime);
    API.post("api/community_events", formData)
      .then((response) => {
        dispatch({ type: "CREATE_EVENTS_SUCCESS", payload: response.data });
        successCreateEvent(true);
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_EVENTS_FAILURE",
          payload: error.response.data,
        });
      });
  };

export const deleteEvents = (data, successDeleteCallBack) => (dispatch) => {
  dispatch({ type: "DELETE_EVENTS_REQUEST" });
  API.delete(`api/community_events/${data}`)
    .then((response) => {
      dispatch({ type: "DELETE_EVENTS_SUCCESS", payload: response.data });
      successDeleteCallBack();
    })
    .catch((error) => {
      dispatch({ type: "DELETE_EVENTS_FAILURE", payload: error.response.data });
    });
};

export const updateEvents = (data, successUpdate, IsEmpty) => (dispatch) => {
  dispatch({ type: "UPDATE_EVENTS_REQUEST" });
  let formData = new FormData();
  IsEmpty
    ? formData.append("community_event_image", data.community_event_image)
    : formData.append(
        "community_event[community_event_image]",
        data.community_event_image
      );

  formData.append("community_id", data.community_id);
  formData.append("community_event[event_name]", data.event_name);
  formData.append("community_event[city_name]", data.city_name);

  formData.append("community_event[event_location]", data.event_location);
  formData.append("community_event[event_detail]", data.event_detail);
  formData.append("community_event[event_datetime]", data.event_datetime);
  API.put(`api/community_events/${data.id}`, formData)
    .then((response) => {
      dispatch({ type: "UPDATE_EVENTS_SUCCESS", payload: response.data });
      successUpdate();
    })
    .catch((error) => {
      dispatch({ type: "UPDATE_EVENTS_FAILURE", payload: error.response.data });
    });
};
