import constants from "../Utils/constants";
const initialState = {
  isCommumitiesloading: false,
  allCommunities: [],
  community: {},
  setCommunityId: "",
  isCommunityByIdLoading: false,
  isUpdateCommunityLoading: false,
};

export const communitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_COMMUNITIES_REQUEST:
     
    return {
        ...state,
        isCommumitiesloading: true,
      };
    case constants.GET_COMMUNITIES_SUCCESS:
      return {
        ...state,
        isCommumitiesloading: false,
        allCommunities: action.payload,
      };
    case constants.GET_COMMUNITIES_FAILURE:

    return {
        ...state,
        isCommumitiesloading: false,
      };
    case constants.GET_COMMUNITY_BY_ID_REQUEST:
      return {
        ...state,
        isCommunityByIdLoading: true,
      };
    case constants.GET_COMMUNITY_BY_ID_SUCCESS:
      return {
        ...state,
        isCommunityByIdLoading: false,
        community: action.payload,
      };
    case constants.GET_COMMUNITY_BY_ID_FAILURE:
      return {
        ...state,
        isCommunityByIdLoading: false,
      };

      case constants.UPDATE_COMMUNITIES_REQUEST:
      return {
        ...state,
        isUpdateCommunityLoading: true,
      };
    case constants.UPDATE_COMMUNITIES_SUCCESS:
      return {
        ...state,
        isUpdateCommunityLoading: false,
      };
    case constants.UPDATE_COMMUNITIES_FAILURE:
      return {
        ...state,
        isUpdateCommunityLoading: false,
      };

    case constants.GET_SELECTED_COMMUNITY_ID:
      return {
        ...state,
        setCommunityId: action.payload,
      };
  
      case constants.RESET_APP_DATA:
      return {
        ...initialState,
      }
      default:
      return state;  
    }
};
