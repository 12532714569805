import API from "../Utils/API";
export const getIndustriesAction = () => (dispatch) => {
  dispatch({ type: "GET_INDUSTRIES_REQUEST" });
  API.get("api/industries")
    .then((response) => {
      dispatch({ type: "GET_INDUSTRIES_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_INDUSTRIES_FAILURE",
        payload: error.response.data,
      });
    });
};
export const createIndustryAction = (data, successCallback) => (dispatch) => {
  dispatch({ type: "CREATE_INDUSTRIES_REQUEST", payload: data });

  API.post("api/industries", data)
    .then((response) => {
      dispatch({ type: "CREATE_INDUSTRIES_SUCCESS", payload: response.data });
      successCallback();
    })
    .catch((error) => {
      dispatch({
        type: "CREATE_INDUSTRIES_FAILURE",
        payload: error.response.data,
      });
    });
};
export const updateIndustryAction = (data, successCallback) => (dispatch) => {
  dispatch({ type: "UPDATE_INDUSTRIES_REQUEST" });
  API.put(`api/industries/${data.id}`, data)
    .then((response) => {
      dispatch({ type: "UPDATE_INDUSTRIES_SUCCESS", payload: response.data });
      successCallback();
    })
    .catch((error) => {
      dispatch({
        type: "UPDATE_INDUSTRIES_FAILURE",
        payload: error.response.data,
      });
    });
};
