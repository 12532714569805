import React, { useCallback, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Typography,
  Divider,
  Button,
  Empty,
  Tooltip,
  Popconfirm,
  message,
} from 'antd';
import '../FindOtherPeople.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteMatrimonial,
  getMatrimonial,
  matrimonialById,
  updateMatrimonialProfileStatusAction,
} from '../../Actions/matrimonialAction';
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import matrimonialEmpty from '../../assets/matrimonialEmpty.svg';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import LoadingIndicator from '../../Screens/LoadingIndicator/LoadingIndicator';
import { newImageUrl } from '../../Screens/CommonLogics/CommonMethods';
import { analytics } from '../../components/Mixpanel/Mixpanel';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

dayjs.extend(advancedFormat);

const ViewMatrimonialProfile = (props) => {
  const { t } = useTranslation();
  const {
    setEditId,
    setVisible,
    setViewMatrimonialProfileId,
    setIsViewMyMatchesVisible,
    currentPage,
    setSelectedUserId,
  } = props;
  const { allMatrimonial, isMatrimonialLoading } = useSelector((state) => {
    const { allMatrimonial, isMatrimonialLoading } = state.matrimonial;
    return {
      allMatrimonial,
      isMatrimonialLoading,
    };
  });
  const handleEdit = (val) => {
    setEditId(val);
    setVisible(true);
    dispatch(matrimonialById(val));
  };
  const dispatch = useDispatch();
  const handleDeleteFamily = (matrimonialId) => {
    dispatch(deleteMatrimonial(matrimonialId, SuccessDeleteCallbackMethod));
  };
  const SuccessDeleteCallbackMethod = () => {
    analytics?.track('Matrimonials_Deleted');
    setTimeout(() => {
      message.success(t('content.309'));
    }, 1000);
    dispatch(getMatrimonial(currentPage));
  };
  const GetMatrimonial = useCallback(() => {
    dispatch(getMatrimonial(currentPage));
  }, [dispatch, currentPage]);
  useEffect(() => {
    GetMatrimonial();
  }, [GetMatrimonial]);
  const handleClick = (matrimonialProfileId) => {
    setViewMatrimonialProfileId(matrimonialProfileId);
    setIsViewMyMatchesVisible(false);
  };
  const handleViewMyMatchesMethod = (matrimonialProfileId, userId) => {
    setViewMatrimonialProfileId(matrimonialProfileId);
    setIsViewMyMatchesVisible(true);
    setSelectedUserId(userId);
  };
  let profileStatusData = {};
  const handleProfileStatusMethod = (Id, Status) => {
    profileStatusData.id = Id;
    profileStatusData.profile_status =
      Status === 'Active' ? 'in-active' : 'active';
    dispatch(
      updateMatrimonialProfileStatusAction(
        profileStatusData,
        successMatrimonialProfileStatusMethod
      )
    );
  };
  const successMatrimonialProfileStatusMethod = () => {
    setTimeout(() => {
      message.success(t('content.310'));
    }, 1000);
    dispatch(getMatrimonial(currentPage));
  };

  const ImageGalleryComponent = ({ imageKeys }) => {
    const [galleryImages, setGalleryImages] = React.useState([]);

    React.useEffect(() => {
      const loadImages = async () => {
        const imagePromises = imageKeys?.map(async (val, i) => {
          return {
            original: await newImageUrl(val),
          };
        });

        const images = await Promise.all(imagePromises);
        setGalleryImages(images);
      };

      if (imageKeys?.length > 0) {
        loadImages();
      }
    }, [imageKeys]);

    return (
      <Row>
        <Col span={24}>
          {galleryImages.length > 0 && (
            <ImageGallery
              items={galleryImages}
              additionalClass='ImageViewMatrimonial'
              showNav={false}
              showBullets={true}
              showThumbnails={false}
              originalClass='ImageViewMatrimonial'
            />
          )}
        </Col>
      </Row>
    );
  };

  return isMatrimonialLoading ? (
    <Col
      span={24}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '50vh',
      }}
    >
      <LoadingIndicator loading={isMatrimonialLoading} />
    </Col>
  ) : (
    <Col
      style={{
        overflow: 'hidden',
        overflowY: 'scroll',
      }}
    >
      {allMatrimonial && allMatrimonial?.matrimonials?.length > 0 ? (
        <Row gutter={[32, 32]}>
          {allMatrimonial?.matrimonials?.map((val, i) => {
            const noData1 = _.flatten(
              val?.user?.user_addresses?.filter((address, i) => {
                return address.length && val.user.user_addresses[i].length === 0
                  ? 0
                  : 1;
              })
            );
            return (
              <Col xl={8} lg={12} md={12} sm={24} xs={24} key={i}>
                <Card className='MatchesCard'>
                  {val?.matrimonial_user_image_keys.length > 0 && (
                    <Row>
                      <Col span={24}>
                        <ImageGalleryComponent
                          imageKeys={val?.matrimonial_user_image_keys}
                        />
                      </Col>
                    </Row>
                  )}
                  <Col style={{ paddingTop: 24, paddingBottom: 24 }}>
                    <Row gutter={[0, 17]}>
                      <Col span={24}>
                        <Typography
                          className='profile-name'
                          style={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {val.user.first_name + ' ' + val.user.last_name}
                        </Typography>
                      </Col>
                      <Col span={24}>
                        <Row justify='space-between' align='middle'>
                          <Col>
                            <Typography className='profile-birthdate'>
                              {dayjs(
                                val.user.birth_month +
                                  '/' +
                                  val.user.birth_date +
                                  '/' +
                                  val.user.birth_year
                              ).format('Do MMMM YYYY')}
                            </Typography>
                          </Col>
                          <Col>
                            <Tooltip title={t('btn.27')}>
                              <Button
                                icon={<EditOutlined />}
                                type='text'
                                onClick={() => handleEdit(val.id, val)}
                              />
                            </Tooltip>

                            <Tooltip title='Profile Status'>
                              <Popconfirm
                                title={`${
                                  val.profile_status === 'Active'
                                    ? 'Inactive'
                                    : 'Active'
                                } this profile ?`}
                                onConfirm={() =>
                                  handleProfileStatusMethod(
                                    val.id,
                                    val.profile_status
                                  )
                                }
                                okText='Ok'
                                cancelText={t('btn.19')}
                                placement='topLeft'
                              >
                                <Button
                                  icon={
                                    val.profile_status === 'Active' ? (
                                      <EyeInvisibleOutlined />
                                    ) : (
                                      <EyeOutlined />
                                    )
                                  }
                                  type='text'
                                />
                              </Popconfirm>
                            </Tooltip>

                            <Tooltip title={t('btn.28')}>
                              <Popconfirm
                                title={t('btn.29')}
                                onConfirm={() => handleDeleteFamily(val.id)}
                                okText={t('btn.28')}
                                cancelText={t('btn.19')}
                                placement='topLeft'
                              >
                                <Button icon={<DeleteOutlined />} type='text' />
                              </Popconfirm>
                            </Tooltip>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row gutter={[0, 16]}>
                      <Divider />
                    </Row>
                    <Row>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Typography className='profile-address'>
                          {noData1?.length > 0 &&
                            noData1?.map((Address, i) => {
                              return (
                                <Row key={i}>
                                  <Col span={24}>
                                    {Address?.address_line_1 +
                                      ' ' +
                                      Address.home_number +
                                      ' ' +
                                      Address.address_line_2 +
                                      ' ' +
                                      Address.city +
                                      ',' +
                                      Address.zip}
                                  </Col>
                                </Row>
                              );
                            })}
                        </Typography>
                      </Col>
                      <Col span={24}>
                        <Row justify='space-between'>
                          <Col>
                            {val?.profile_status === 'Active' && (
                              <Button
                                className=' View-fullProfile'
                                onClick={() =>
                                  handleViewMyMatchesMethod(true, val?.user?.id)
                                }
                                style={{ padding: '4px 0px' }}
                              >
                                {t('btn.31')}
                              </Button>
                            )}
                          </Col>
                          <Col>
                            {/* <Button
                              className=' View-fullProfile'
                              onClick={() => handleClick(val.id)}
                              style={{ padding: '4px 0px' }}
                            > */}
                            <Link
                              to={`/matrimonials/${val?.id}`}
                              className='View-fullProfile'
                            >
                              {t('btn.26')}
                            </Link>
                            {/* </Button> */}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Card>
              </Col>
            );
          })}
        </Row>
      ) : (
        !isMatrimonialLoading && (
          <Row justify='center' style={{ paddingTop: '150px' }}>
            <Col>
              <Empty
                image={matrimonialEmpty}
                imageStyle={{
                  height: 38,
                }}
                description={
                  <span className='empty-details'>{t('emptyScreen.3')}</span>
                }
              ></Empty>
            </Col>
          </Row>
        )
      )}
    </Col>
  );
};
export default ViewMatrimonialProfile;
