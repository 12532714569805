import constants from "../Utils/constants";

const initialState = {
  isCommunityMeetingsLoading: false,
  isCreateCommunityMeetingLoading: false,
  isEditCommunityMeetingLoading: false,
  allMeetings: [],
};

export const communityMeetingReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_COMMUNITY_MEETINGS_REQUEST:
      return {
        ...state,
        isCommunityMeetingsLoading: true,
      };

    case constants.GET_COMMUNITY_MEETINGS_SUCCESS:
      return {
        ...state,
        isCommunityMeetingsLoading: false,
        allMeetings: action.payload,
      };

    case constants.GET_COMMUNITY_MEETINGS_FAILURE:
      return {
        ...state,
        isCommunityMeetingsLoading: false,
      };

    case constants.CREATE_COMMUNITY_MEETING_REQUEST:
      return {
        ...state,
        isCreateCommunityMeetingLoading: true,
      };

    case constants.CREATE_COMMUNITY_MEETING_SUCCESS:
      return {
        ...state,
        isCreateCommunityMeetingLoading: false,
      };

    case constants.CREATE_COMMUNITY_MEETING_FAILURE:
      return {
        ...state,
        isCreateCommunityMeetingLoading: false,
      };

    case constants.UPDATE_PUBLISH_COMMUNITY_MEETING_REQUEST:
      return {
        ...state,
      };

    case constants.UPDATE_PUBLISH_COMMUNITY_MEETING_SUCCESS:
      return {
        ...state,
      };

    case constants.UPDATE_PUBLISH_COMMUNITY_MEETING_FAILURE:
      return {
        ...state,
      };
    case constants.UPDATE_COMMUNITY_MEETING_REQUEST:
      return {
        ...state,
        isEditCommunityMeetingLoading: true,
      };

    case constants.UPDATE_COMMUNITY_MEETING_SUCCESS:
      return {
        ...state,
        isEditCommunityMeetingLoading: false,
      };

    case constants.UPDATE_COMMUNITY_MEETING_FAILURE:
      return {
        ...state,
        isEditCommunityMeetingLoading: false,
      };

    case constants.DELETE_COMMUNITY_MEETING_REQUEST:
      return {
        ...state,
        isCommunityMeetingsLoading: true,
      };

    case constants.DELETE_COMMUNITY_MEETING_SUCCESS:
      return {
        ...state,
        isCommunityMeetingsLoading: false,
      };

    case constants.DELETE_COMMUNITY_MEETING_FAILURE:
      return {
        ...state,
        isCommunityMeetingsLoading: false,
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
