import React, { useState } from 'react';
import {
  Table,
  Button,
  Row,
  Col,
  message,
  Select,
  Popconfirm,
  Typography,
  Empty,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import '../../FindThePeoplePage/FindOtherPeople.css';
import { updatePublishCommunityMeeting } from '../../Actions/communityMeetingsAction';
import './Admin.css';
import { getCommunityMeetings } from '../../Actions/communityMeetingsAction';
import { deleteCommunityMeeting } from '../../Actions/communityMeetingsAction';
import ViewMeetings from './ViewMeetings';
import { useTranslation } from 'react-i18next';
import '../Information/Information.css';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { InfoCircleOutlined } from '@ant-design/icons';
import { analytics } from '../../components/Mixpanel/Mixpanel';

function MeetingTable(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    setEditMeetingId,
    setIseditMeetingModalVisible,
    allMeetings,
    isCommunityMeetingsLoading,
    isPublishMeeting,
    setIsPublishMeeting,
    setCommunityId,
    setYear,
    year,
  } = props;
  const [isMeetingDetailsVisible, setIsMeetingDetailsVisible] = useState(false);
  const [meetingDetailId, setMeetingDetailId] = useState('');
  const columns = [
    {
      title: t('content.215'),
      dataIndex: 'community_meeting_no',
      key: 'community_meeting_no',
      sorter: (a, b) => a.community_meeting_no - b.community_meeting_no,
      defaultSortOrder: 'descend',
      render: (record, object) => {
        return object.community_meeting_no;
      },
    },
    {
      title: t('content.214'),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: t('content.346'),
      dataIndex: 'place',
      key: 'place',
    },
    {
      title: t('content.217'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('content.225'),
      dataIndex: 'year',
      key: 'year',
      render: (year) => {
        let actualYear = year?.split(' ');
        actualYear = actualYear + '-' + (Number(actualYear) + 1);
        return actualYear;
      },
    },
    {
      dataIndex: 'id',
      render: (record, object) => (
        <PublishButton
          isPublishMeeting={isPublishMeeting}
          setIsPublishMeeting={setIsPublishMeeting}
          record={record}
          object={object}
          setCommunityId={setCommunityId}
        />
      ),
    },
    {
      dataIndex: 'id',
      render: (id, record, object) => (
        <MeetingActionComponent
          id={id}
          setEditMeetingId={setEditMeetingId}
          setIseditMeetingModalVisible={setIseditMeetingModalVisible}
          record={record}
          object={object}
          isMeetingDetailsVisible={isMeetingDetailsVisible}
          setIsMeetingDetailsVisible={setIsMeetingDetailsVisible}
          setMeetingDetailId={setMeetingDetailId}
          setCommunityId={setCommunityId}
          year={year}
        />
      ),
      width: '15em',
    },
  ];
  const handleYearChange = (e) => {
    setYear(e);
    dispatch(getCommunityMeetings(e));
  };
  const d = new Date();
  let y = d.getFullYear();
  const earliestYear = 2000;
  const options = [];

  for (let i = earliestYear; i <= y; i++) {
    options.push({
      label: i + '-' + (Number(i) + 1),
      value: i,
    });
  }

  const selectedMeeting = allMeetings?.filter((meetings) => {
    return meetings.id === meetingDetailId;
  });

  return (
    <Col>
      {isMeetingDetailsVisible ? (
        <Row justify='space-around'>
          <Col span={23}>
            <Row gutter={[0, 33]}>
              <Col xl={1} lg={1} md={1} sm={2} xs={2}>
                <ArrowLeftOutlined
                  style={{ fontSize: '20px', cursor: 'pointer' }}
                  onClick={() => setIsMeetingDetailsVisible(false)}
                />
              </Col>
              <Col>
                <Typography
                  className='newMemberSubDetails'
                  style={{ marginTop: '-2px' }}
                >
                  {t('content.152')}
                </Typography>
              </Col>
              <Col span={24} />
            </Row>
            <Row>
              <Col span={8}>
                <ViewMeetings selectedMeeting={selectedMeeting[0]} />
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col span={23}>
              <Row gutter={[30, 10]}>
                <Col span={24}>
                  <Typography className='DocumentsTitle3'>
                    {t('content.234')}:{/* Select Year: */}
                  </Typography>
                </Col>
                <Col xl={6} lg={6} md={8} sm={10} xs={15}>
                  <Select
                    onChange={(e) => handleYearChange(e)}
                    placeholder='Select financial year'
                    name='yearSelector'
                    defaultValue={year}
                    style={{ width: '100%' }}
                    value={year}
                    className='Heading'
                    size='large'
                    options={options}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <LoadingIndicator loading={isCommunityMeetingsLoading}>
            {allMeetings?.length > 0 ? (
              <Table
                dataSource={allMeetings}
                columns={columns}
                className='table'
                size='middle'
                style={{ marginTop: '10px' }}
                rowKey={(record) => {
                  return record.id;
                }}
              />
            ) : (
              <Row justify='center' className='InformationContainer'>
                <Empty
                  description={
                    <Typography className='empty-details'>
                      {t('content.291')}
                    </Typography>
                  }
                  image={<InfoCircleOutlined style={{ fontSize: '50px' }} />}
                ></Empty>
              </Row>
            )}
          </LoadingIndicator>
        </>
      )}
    </Col>
  );
}
export default MeetingTable;

const MeetingActionComponent = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    setIseditMeetingModalVisible,
    setEditMeetingId,
    id,
    record,
    setMeetingDetailId,
    setIsMeetingDetailsVisible,

    year,
  } = props;

  const handleEditMeeting = (id) => {
    setEditMeetingId(id);
    setIseditMeetingModalVisible(true);
  };

  const handleDelete = (id) => {
    dispatch(deleteCommunityMeeting(id, successDeleteMethod));
  };

  const successDeleteMethod = () => {
    setTimeout(() => {
      message.success(t('content.336'));
    }, 1000);
    dispatch(getCommunityMeetings(year.toString().slice(0, 4)));
  };

  const handleOpenMeetingDetails = (id) => {
    setIsMeetingDetailsVisible(true);
    setMeetingDetailId(id);
  };

  return (
    <Row gutter={[10, 0]}>
      {record.published ? (
        <>
          <Col span={10}>
            <Button
              className='editButton'
              type='primary'
              onClick={() => {
                analytics?.track('Admin_CommunityMeetings_View');
                handleOpenMeetingDetails(id);
              }}
            >
              {t('btn.47')}
            </Button>
          </Col>
        </>
      ) : (
        <>
          <Col span={10}>
            <Button
              className='editButton'
              type='primary'
              onClick={() => handleEditMeeting(id)}
            >
              {t('btn.46')}
            </Button>
          </Col>
          <Col span={10}>
            <Popconfirm
              title='Confirm delete?'
              okText='Confirm'
              cancelText='Cancel'
              onConfirm={() => handleDelete(id)}
              placement='topLeft'
            >
              <Button className='deleteButton' type='primary'>
                {t('btn.28')}
              </Button>
            </Popconfirm>
          </Col>
        </>
      )}
    </Row>
  );
};

const PublishButton = (props) => {
  const { t } = useTranslation();
  const { record, object } = props;
  const dispatch = useDispatch();
  const handlePublish = () => {
    dispatch(updatePublishCommunityMeeting(record, successPublishMethod));
  };

  const successPublishMethod = (updatePublishDataReponse) => {
    setTimeout(() => {
      message.success(t('content.337'));
    }, 1000);
    dispatch(getCommunityMeetings(updatePublishDataReponse.year));
  };

  return (
    <Row gutter={[0, 0]}>
      <Col>
        <Button
          type='primary'
          className='publishButton'
          onClick={() => {
            analytics?.track('Admin_CommunityMetting_Publish');
            handlePublish();
          }}
          disabled={object.published ? true : false}
        >
          {record && object.published === true ? t('btn.45') : t('btn.44')}
        </Button>
      </Col>
    </Row>
  );
};
