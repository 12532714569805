import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Breadcrumb,
  Image,
  Steps,
  Card,
} from 'antd';
import LandingPageHeader from './LandingPageHeader';
import './LandingPage.css';
import '../TermsAndConditions/TermsAndConditions.css';
import _, { sortBy } from 'lodash';
import { history } from '../../Utils/history';
import './Faqs.css';
import '../Community/Community.css';
import { getCommunities } from '../../Actions/communitiesAction';
import { useDispatch, useSelector } from 'react-redux';
import Footer from './Footer';
import { useBreakpoints } from '../CommonLogics/ResponsiveBreakPoints';
import {
  CaretRightOutlined,
  HomeOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import JoinUsHowtos from '../../assets/JoinUsHowtos.png';
import { getJourneyById } from '../../Actions/JourneyAction';
import { newImageUrl } from '../CommonLogics/CommonMethods';
import ImageView from '../../components/ImageView';

const Journey = () => {
  const dispatch = useDispatch();
  const { journey } = useSelector((state) => state.journey);
  const { id } = useParams();

  useEffect(() => {
    dispatch(getJourneyById(id));
  }, []);

  const journeyRoutes = sortBy(journey?.journey_routes, ['position']);
  const { IsMD, IsSM, IsXS, IsLG, IsXL, IsXXL } = useBreakpoints();

  console.log({ journeyRoutes });
  return (
    <Col className='MainbackgroundColor'>
      <Col className='TermsAndConditionsImageContainer'>
        <LandingPageHeader />
        <Row
          style={{ height: '100%', padding: (IsXS || IsSM) && '100px' }}
          justify='center'
          align='middle'
        >
          <Typography className='TermsAndConditionsH1'>Blogs</Typography>
        </Row>
      </Col>
      <Col style={{ background: '#FFFFFF', borderRadius: '50px 50px 0 0' }}>
        <Row justify='center' style={{ marginTop: '50px' }}>
          <Col className='ContentContainer'>
            <Row>
              <Col
                span={24}
                style={{ padding: IsXS ? '0px 25px 25px 25px' : 0 }}
              >
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to='/'>
                      <HomeOutlined />
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to='/blogs'>Blogs</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {' '}
                    <Link to='/howto'>How tos</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>{journey?.title}</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col></Col>
              <Col span={24} style={{ marginTop: '50px' }}>
                <Row gutter={[20, 20]} style={{ padding: IsXS ? '25px' : 0 }}>
                  <Col xs={24} sm={24} md={4} xl={6} lg={8}>
                    <ImageView
                      imageKeys={journey?.journey_image_key}
                      type='single'
                    />
                  </Col>
                  <Col xs={24} sm={24} md={20} xl={18} lg={16}>
                    <Row
                      gutter={[0, 30]}
                      style={{ marginTop: '2px' }}
                      justify={IsXS ? 'center' : 0}
                    >
                      <Col>
                        <Typography className='HowtosCardTitle'>
                          {journey?.title}
                        </Typography>
                      </Col>
                      <Col span={24}>
                        <Typography
                          className='HowtosCardDiscription'
                          style={{ textAlign: IsXS ? 'center' : 'left' }}
                        >
                          {journey?.description}
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col style={{ marginTop: '100px' }}>
                <Row gutter={[0, 20]} style={{ padding: IsXS ? '20px' : '0' }}>
                  {journeyRoutes?.map((val, i) => {
                    return (
                      <Card
                        style={{
                          background: '#F6F6F6',
                          borderRadius: '20px',
                        }}
                        key={i}
                      >
                        <Row gutter={[0, 20]} justify={IsXS ? 'center' : ''}>
                          <Col xs={24} sm={24} md={6}>
                            <Row>
                              <Col
                                className='SelectComunityCardStepsContainer'
                                style={{ right: '25px' }}
                                x
                              >
                                <Row
                                  gutter={[20, 0]}
                                  style={{ paddingLeft: '20px' }}
                                >
                                  <Col span={20}>
                                    <Typography className='SelectComunityCardSteps'>
                                      Steps: {val?.position}
                                    </Typography>
                                  </Col>
                                  <Col span={2} align='center'>
                                    {/* <Typography
                                      className='SelectComunityCardStepsNumber'
                                      style={{
                                        paddingRight: '10px',
                                      }}
                                    >
                                      {val?.position}
                                    </Typography> */}
                                  </Col>
                                </Row>
                              </Col>
                              <Col
                                className='SelectComunityCardStepsContainer1'
                                style={{ right: '25px' }}
                              >
                                {/* <Typography
                                  className='SelectComunityCardSteps'
                                  style={{ right: '25px' }}
                                >
                                  {val?.position}
                                </Typography> */}
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              paddingTop: '20px',
                            }}
                            xs={24}
                            sm={24}
                            md={18}
                          >
                            <Row
                              gutter={[0, 20]}
                              justify={IsXS ? 'center' : ''}
                            >
                              <Col>
                                {' '}
                                <Typography className='HowtosCardTitle'>
                                  {val?.title}
                                </Typography>
                              </Col>
                              <Col span={24}>
                                <Typography
                                  className='HowtosCardDiscription'
                                  style={{
                                    textAlign: IsXS ? 'center' : 'left',
                                  }}
                                >
                                  {val?.description}
                                </Typography>
                              </Col>
                            </Row>
                          </Col>

                          <Col
                            style={{ padding: IsXS ? '0' : '0 0 0 225px' }}
                            align='center'
                          >
                            {val?.journey_route_image_key && (
                              // <Image
                              //   src={newImageUrl()}
                              //   height='256px'
                              //   width='208px'
                              // />

                              <ImageView
                                imageKeys={val?.journey_route_image_key}
                                type='single'
                                imageHeight='256px'
                                imageWidth='208px'
                              />
                            )}
                          </Col>
                        </Row>
                      </Card>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify='center'>
          <Col>
            <Footer />
          </Col>
        </Row>
      </Col>
    </Col>
  );
};

export default Journey;
