import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

export const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  Layout = Layout === undefined ? (props) => <>{props.children}</> : Layout;
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};
export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useSelector((state) => state.authentication);

  // const storeToken = localStorage.access_token;
  // const decoded = storeToken ? jwtDecode(storeToken) : null;
  // useEffect(() => {
  //   if (storeToken) {
  //     setAuthToken(storeToken);
  //     const data = new Date(decoded.exp * 1000);
  //     const expire = moment(
  //       data,
  //       (moment.suppressDeprecationWarnings = true)
  //     ).format("YYYY-MM-DD");
  //     const current = moment().format("YYYY-MM-DD");
  //     const remain = moment(current).diff(expire, "days");
  //     console.log({ current, remain });
  //     if (remain >= 0) {
  //       // window.open(window.location.href);
  //     } else {
  //       dispatch(getUsers());
  //       dispatch(setCurrentUser(decoded));
  //     }
  //   }
  // }, [storeToken, dispatch, decoded]);
  const token = localStorage.getItem("access_token");

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : token ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};
