import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { concat, isEmpty, isObject } from 'lodash';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';

export const phoneFormatterAccordingToCountryCode = (
  countryFormat,
  phoneNumberArray
) => {
  var pattern = /[.](?!\d*$.)/g;
  var replacement = ' ';
  return countryFormat.replace(pattern, function (match) {
    replacement = phoneNumberArray.shift();
    if (typeof replacement == 'number' || typeof replacement == 'string') {
      return replacement;
    }
    return '';
  });
};

export const CheckTokenExpired = () => {
  const token = localStorage.getItem('access_token');
  const decodedToken = token ? jwtDecode(token) : null;
  const newDate = new Date(decodedToken.exp * 1000);
  const formattedNewDate = moment(newDate).format('YYYY-MM-DD');
  const currentDate = moment().format('YYYY-MM-DD');
  const differenceDate = moment(currentDate).diff(formattedNewDate, 'days');
  const isTokenExpired = differenceDate > 0;
  return isTokenExpired;
};

export const CountryViseFormat = (CountryNumber) => {
  const phoneNumber = parsePhoneNumber(CountryNumber);
  if (isValidPhoneNumber(CountryNumber)) {
    return phoneNumber.formatInternational();
  } else if (phoneNumber.isValid()) {
    return phoneNumber.formatInternational();
  } else {
    return CountryNumber;
  }
};

export const newImageUrl = async (imageKey) => {
  if (imageKey) {
    const clientParams = {
      region: 'ap-south-1',
      credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      },
    };
    const getObjectParams = {
      Bucket: 'dev-samaj',
      Key: imageKey,
    };

    const client = new S3Client(clientParams);
    const command = new GetObjectCommand(getObjectParams);
    const image_url = await getSignedUrl(client, command, {
      expiresIn: 14400,
    });

    return image_url;
  }
};

export const previewTemplate = (templateKey) => {
  const [galleryImage, setGalleryImage] = useState(null);
  useEffect(() => {
    const loadImage = async () => {
      if (templateKey) {
        const original = await newImageUrl(templateKey);
        setGalleryImage(original);
      }
    };
    loadImage();
  }, [templateKey]);

  return galleryImage;
};

export const falseyValueCases = (data) => {
  if (isObject(data)) {
    switch (true) {
      case isEmpty(data):
        return true;
      default:
        return false;
    }
  } else {
    switch (data) {
      case null:
        return true;
      case 'null':
        return true;
      case '':
        return true;
      case undefined:
        return true;
      case 'undefined':
        return true;
      case NaN:
        return true;
      case 'NaN':
        return true;
      default:
        return false;
    }
  }
};

export const findAndCreate = (data, newdata, position) => {
  const result = concat(
    position === 'last' ? data : newdata,
    position === 'last' ? newdata : data
  );
  return result;
};
