import React from 'react';
import { useDispatch } from 'react-redux';
import {
  getTicketsAction,
  createTicketAction,
} from '../../Actions/ticketAction';
import { Row, Col, Button, Typography, Drawer, Select, message } from 'antd';
import '../../FindThePeoplePage/FindOtherPeople.css';
import { Controller, useForm } from 'react-hook-form';
import './HelpAndSupport.css';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { getTicketByIdAction } from '../../Actions/ticketAction';
import { ticketSchema } from '../../Utils/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

const CreateTicketDrawer = (props) => {
  const { t } = useTranslation();

  const {
    isCreateTicketVisible,
    setIsCreateTicketVisible,
    page,
    setIsChatDrawerVisible,
    setCreateTicketSuccessData,
    isCreateTicketsLoading,
    setTicketByIdState,
  } = props;
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(ticketSchema),
  });
  const options = [
    { label: 'Get verified issue', value: 'Get verified issue' },
    { label: 'Login related issue', value: 'Login related issue' },
    { label: 'Family related issue', value: 'Family related issue' },
    { label: 'Matrimonial related issue', value: 'Matrimonial related issue' },
    { label: 'Jobs related issue', value: 'Jobs related issue' },
    { label: 'Community related issue', value: 'Community related issue' },
    { label: 'Other', value: 'Other' },
  ];
  const closeCreateDrawerMethod = () => {
    setIsCreateTicketVisible(false);
    setValue('name', '');
    reset();
  };
  const createTicketMethod = (data) => {
    dispatch(
      createTicketAction(
        data,
        createTicketSuccessMethod,
        createFailureTicketMethod
      )
    );
  };
  const createTicketSuccessMethod = (SuccessResponse) => {
    setTicketByIdState(SuccessResponse);
    dispatch(getTicketsAction(page));
    setIsCreateTicketVisible(false);
    setIsChatDrawerVisible(true);
    setCreateTicketSuccessData(SuccessResponse);
    reset();
    dispatch(getTicketByIdAction(SuccessResponse?.id));
    setValue('name', '');
    setTimeout(() => {
      message.success(t('content.345'));
    }, 1000);
  };
  const createFailureTicketMethod = () => {};
  return (

    <Col className='DrawerBorderClass'>
      <Drawer
        open={isCreateTicketVisible}
        placement='right'
        title={
          <Typography className='CreateTicketDrawerInputMainText'>
            {t('content.262')}
          </Typography>
        }
        onClose={closeCreateDrawerMethod}
        maskClassName='DrawerClass'
        className='DrawerBorderClass'
        width={600}
      >
        <LoadingIndicator loading={isCreateTicketsLoading}>
          <form onSubmit={handleSubmit(createTicketMethod)}>
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <Row gutter={[0, 10]}>
                  <Col span={24}>
                    <Typography className='CreateTicketDrawerInputText'>
                      {t('content.258')}
                    </Typography>
                  </Col>
                  <Col span={24}>
                    <Controller
                      as={
                        <Select
                          style={{ width: '100%' }}
                          placeholder={t('content.263')}
                          options={options}
                          className='emailInput'
                          size='large'
                        />
                      }
                      name='name'
                      control={control}
                    />
                  </Col>
                  {errors.name && (
                    <p style={{ color: 'red' }}>{errors.name.message}</p>
                  )}
                </Row>
              </Col>
              <Col span={24}>
                <Button
                  type='primary'
                  style={{ borderRadius: '4px' }}
                  className='button-search'
                  htmlType='submit'
                >
                  Send
                </Button>
              </Col>
            </Row>
          </form>
        </LoadingIndicator>
      </Drawer>
    </Col>
  );
};
export default CreateTicketDrawer;
