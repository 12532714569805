import React, { useEffect, useState } from 'react';
import { Spin, Table, message, Select,Col } from 'antd';
import './Admin.css';
import '../../FindThePeoplePage/FindOtherPeople.css';
import { useDispatch, useSelector } from 'react-redux';
import { getCommunitiesNative } from '../../Actions/communitiesNativeAction';
import { getSearchUser } from '../../Actions/searchUserAction';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import {
  createNativeVolunteerAction,
  deleteNativeVolunteerAction,
} from '../../Actions/nativeVolunteerAction';
import _ from 'lodash';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

const VolunteerTable = (props) => {
  const { t } = useTranslation();
  const { setCommunityId } = props;
  const dispatch = useDispatch();
  const {
    allCommunitiesNative,
    isCreateNativeVolunteerLoading,
    isDeleteNativeVolunteerLoading,
    searchUsers,
    isSearchUserLoading,
  } = useSelector((state) => {
    const { allCommunitiesNative, SuccessNativeVolunteerResponse } =
      state.communitiesNative;
    const { searchUsers, isSearchUserLoading } = state.searchUser;
    const {
      allCommunitiesNativeVolunteer,
      isCreateNativeVolunteerLoading,
      isDeleteNativeVolunteerLoading,
    } = state.nativeVolunteers;
    return {
      allCommunitiesNative,
      searchUsers,
      allCommunitiesNativeVolunteer,
      isCreateNativeVolunteerLoading,
      isDeleteNativeVolunteerLoading,
      SuccessNativeVolunteerResponse,
      isSearchUserLoading,
    };
  });
  useEffect(() => {
    dispatch(getCommunitiesNative(setCommunityId));
    dispatch(getSearchUser(`api/user_search?verified=true`, callbackFunctions));
  }, [setCommunityId]);
  const onlyPublishedNatives = allCommunitiesNative.filter((NativesList) => {
    return NativesList.is_published === true;
  });
  let data = {};
  const [volunteerArray, setVolunteerArray] = useState([]);
  const [isFocus, setIsFocus] = useState(false);
  const [userDidEnterSomething, setUserDidEnterSomething] = useState(false);
  const [isRecordsFound, setIsRecordsFound] = useState(true);
  const searchUserData = _.map(searchUsers?.users, (val) => {
    return { label: val.first_name, value: val.id };
  });
  const [options, setOptions] = useState(searchUserData);

  const MyDebounceMethod = debounce((username) => {
    setUserDidEnterSomething(true);
    if (username.toString().length > 0) {
      dispatch(
        getSearchUser(
          `api/user_search?community_id=${setCommunityId}&first_name=${_.capitalize(
            username
          )}`,
          callbackFunctions
        )
      );
      setIsFocus(true);
    } else {
      dispatch(
        getSearchUser(
          `api/user_search?community_id=${setCommunityId}&verified=true`,
          callbackFunctions
        )
      );
    }
  }, 800);
  const createVolunteerMethod = (UserId, CommunityNative) => {
    data.community_native_id = CommunityNative;
    data.user_id = UserId;
    dispatch(
      createNativeVolunteerAction(data, successCreateNativeVolunteerMethod)
    );
  };
  const successCreateNativeVolunteerMethod = (SuccessResponse) => {
    dispatch({
      type: 'SUCCESS_RESPONSE_DATA',
      payload: SuccessResponse,
    });
    setVolunteerArray([...volunteerArray, SuccessResponse]);
    setTimeout(() => {
      message.success(t('content.343'));
    }, 1000);
    dispatch(getCommunitiesNative(setCommunityId));
    dispatch(getSearchUser(`api/user_search?verified=true`, callbackFunctions));
  };
  const deleteVolunteerMethod = (VolunteerName) => {
    const userVolunteer = volunteerArray?.filter((val) => {
      return val.user.id === VolunteerName;
    });
    dispatch(
      deleteNativeVolunteerAction(
        userVolunteer.length > 0 ? userVolunteer[0].id : VolunteerName,
        successDeleteNativeVolunteerMathod
      )
    );
  };
  const successDeleteNativeVolunteerMathod = () => {
    setTimeout(() => {
      message.success(t('content.344'));
    }, 1000);
    dispatch(getCommunitiesNative(setCommunityId));
  };
  const handleMouseLeave = () => {
    if (isFocus && userDidEnterSomething && isRecordsFound) {
      dispatch(
        getSearchUser(
          `api/user_search?community_id=${setCommunityId}&verified=true`,
          callbackFunctions
        )
      );
    }
    setIsFocus(false);
    setUserDidEnterSomething(false);
  };
  const handleFocus = () => {
    setIsFocus(true);
  };
  const callbackFunctions = (data) => {
    const searchedData = _.map(data, (val) => {
      return { label: val.first_name, value: val.id };
    });
    setOptions(searchedData);
    if (data.length > 0) {
      setIsRecordsFound(false);
    } else {
      setIsRecordsFound(true);
    }
  };
  const columns = [
    {
      title: t('content.151'),
      dataIndex: 'name',
      width: '20%',
    },
    {
      title: t('content.206'),
      width: '30%',
      render: ({ id, natives_volunteers_users, native_users, ...p }) => {
        return (
          <Col key={id}>
            <Select
              mode='multiple'
              placeholder={t('content.4')}
              style={{
                width: '100%',
                borderRadius: '4px',
              }}
              className='inputLabel'
              showSearch={true}
              options={options}
              filterOption={false}
              onSelect={(e) => createVolunteerMethod(e, id)}
              notFoundContent={'No users found'}
              showArrow={true}
              onSearch={(e) => MyDebounceMethod(e)}
              defaultValue={natives_volunteers_users.map((user) => {
                return {
                  label:
                    user.volunteer.first_name + ' ' + user.volunteer.last_name,
                  value: user.id,
                };
              })}
              onDeselect={(e) => deleteVolunteerMethod(e, id)}
              dropdownRender={(menu) => {
                return (
                  <Col>
                    <Spin spinning={isSearchUserLoading}>{menu}</Spin>
                  </Col>
                );
              }}
              onMouseLeave={() => handleMouseLeave()}
              onFocus={() => handleFocus()}
            ></Select>
          </Col>
        );
      },
    },
  ];
  return (
    <LoadingIndicator
      loading={
        isCreateNativeVolunteerLoading
          ? isCreateNativeVolunteerLoading
          : isDeleteNativeVolunteerLoading
      }
      style={{ zIndex: '100' }}
    >
      <Col className='AdminTable'>
        <Table
          size='middle'
          className='table'
          columns={columns}
          dataSource={onlyPublishedNatives}
          style={{ zIndex: '1' }}
          rowKey={(record) => {
            return record.id;
          }}
        />
      </Col>
    </LoadingIndicator>
  );
};

export default VolunteerTable;
